import { useNavigate, useParams } from "react-router-dom";
import { InterfaceShowStateTheSlip } from "./InterfaceShowStateTheSlip";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { MainCard } from "../../../myProfile/MyProfileStyle";
import { useEffect, useState } from "react";

import SteperGuide from "../../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import ParentWithHeightAnimation from "../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { AddRounded, BackHandRounded, DoneRounded, DownloadRounded, PaymentsRounded, SendAndArchiveRounded, UploadFileRounded } from "@mui/icons-material";
import { EnumSTepsParent, EnumStepsSlip } from "../../../../config/enums/EnumSteps";

import ButtonCancel from "../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import { EnumStateValueOfSlip, InterfaceNTTSlipMain } from "../../../../NTTs/Slip/InterfaceNTTSlipMain";
import SteperGuideForSlip from "../../../../components/complex/SteperGuide/SteperGuideForSlip/SteperGuideForSlip";
import dayjs from "dayjs";
import TextDescription from "../../../../components/basic/Texts/TextDescription/TextDescription";
import { TrashIcon } from "../../../../assets/icons/svg/TrashIcon";
import Colors, { ColorThemForButton } from "../../../../config/Colors";
import DialogAcceptProcess from "../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess";
import { WalletIcon } from "../../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../../helper/CurrencySymbols";
import ArrayText from "../../../../components/basic/Texts/ArrayText/ArrayText";
import OpenProfile from "../../../../components/basic/OpenProfile/OpenProfile";
import ButtonColor from "../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import { DownloadFile } from "../../../../helper/Helper";
import moment from "moment-jalaali";
import ToolTipText from "../../../../components/basic/ToolTipText/ToolTipText";
import ShowUnitOfMoneyThreePart from "../../../../components/complex/ShowUnitOfMoneyThreePart/ShowUnitOfMoneyThreePart";
function ShowStateTheSlip(paramsComponent: InterfaceShowStateTheSlip) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const { idMainSlip } = useParams();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("2");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    const [getSlipMain, setSlipMain] = useState<InterfaceNTTSlipMain>();
    const [getIsLoading, setIsLoading] = useState<boolean>(true);
    const [getIsActiveDialogCompleteSlip, setIsActiveDialogCompleteSlip] = useState<boolean>(false);
    const [getIsActiveDialogDeleteSlip, setIsActiveDialogDeleteSlip] = useState<boolean>(false);

    const callApiForRetrieveTheSlip = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.slip.getSlipInitiate;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainSlip}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTSlipMain;
                setSlipMain(t);
                setIsLoading(false);
                setKeyOfGroupThatSelected(`${EnumSTepsParent.slip}`);
                if (t.state.value === EnumStateValueOfSlip.created) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.slip}.${EnumStepsSlip.waitingForApprove}`)
                }
                else if (t.state.value === EnumStateValueOfSlip.completed) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.slip}.${EnumStepsSlip.approved}`)
                }


                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForUpdateStateOfThisSlip = async (stateValue: EnumStateValueOfSlip) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.slip.updateSlipInitiate;
        let DataObj = {
            "state": stateValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainSlip}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTSlipMain;
                setSlipMain(t);
                setIsLoading(false);
                if (t.state.value === EnumStateValueOfSlip.created) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.slip}.${EnumStepsSlip.waitingForApprove}`)
                }
                else if (t.state.value === EnumStateValueOfSlip.completed) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.slip}.${EnumStepsSlip.approved}`)
                }
                navigate(-1);
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForDeleteOfThisSlip = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.slip.deleteThisSlip;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainSlip}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                setIsLoading(false);
                navigate(-1);
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        callApiForRetrieveTheSlip();
    }, [])

    return (
        <ContainerPage title={"Slip"}
            isLoading={getIsLoading}>
            <SteperGuideForSlip
                steps={confComps.steperGuide.slip.steps}
                callbackOnClickOneStep={(keySelected: string) => {
                    console.log(keySelected);
                    setKeyOfStepThatSelected(keySelected);

                }}
                callbackOnClickOneGroup={(keySelected: string) => {
                    setKeyOfGroupThatSelected(keySelected)
                }}
                keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                keyOfOneStepThatSelected={getKeyOfStepThatSelected}
                slip={getSlipMain}
            />

            <MainCard sx={{
                gap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.77vh" },
                display: 'flex', flexDirection: 'column', alignItems: 'start',
                justifyContent: 'start',
            }}>
                <TitleMedium18 style={{
                    boxSizing: "border-box",
                }}>
                    {"Slip"}
                </TitleMedium18>
                {getSlipMain &&
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '0.2rem',
                    }}>
                        <Grid container
                            justifyContent="start" alignItems="flex-start"
                            // columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                            rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.4rem',
                                    }}
                                >
                                    <Typography variant="subtitle2">{`${"ID"}:`}</Typography>
                                    <Typography variant="body1">
                                        {getSlipMain?.id}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.4rem',
                                    }}
                                >
                                    <Typography variant="subtitle2">{`Code:`}</Typography>
                                    <Typography variant="body1">
                                        {getSlipMain?.code}
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.4rem',
                                    }}
                                >
                                    <Typography variant="subtitle2">{`Create Time:`}</Typography>
                                    <Typography variant="body1">
                                        <ToolTipText
                                            textHint={`Jalali: ${moment.unix(getSlipMain?.created_at ?? 0).format("jYYYY/jM/jD")}`}
                                        >
                                            {`${dayjs.unix(getSlipMain?.created_at ?? 0).format("YYYY/MM/DD")}`}
                                        </ToolTipText>
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.4rem',
                                    }}
                                >
                                    <Typography variant="subtitle2">{`Last Updated:`}</Typography>
                                    <Typography variant="body1">
                                        <ToolTipText
                                            textHint={`Jalali: ${moment.unix(getSlipMain?.updated_at ?? 0).format("jYYYY/jM/jD")}`}
                                        >
                                            {`${dayjs.unix(getSlipMain?.updated_at ?? 0).format("YYYY/MM/DD")}`}
                                        </ToolTipText>
                                    </Typography>

                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "0.2rem" }}>
                                    <ArrayText
                                        texts={[
                                            {
                                                text: `From: `,
                                                style: {
                                                    color: Colors.theme.blue.light,
                                                }
                                            },
                                        ]}
                                    />
                                    <OpenProfile user={getSlipMain.from_person.user}
                                        profile={getSlipMain.from_person.profile}

                                        is_trusty={getSlipMain.from_person.is_trusty}
                                    />
                                </div>
                            </Grid>
                            {/* Amount */}
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                        {`${confComps.translate.amount} (${getSlipMain?.currency_unit.currency.symbol}):`}
                                    </Typography>
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark,
                                        display: 'flex', flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.4rem',
                                    }}>
                                        {Number(parseFloat(getSlipMain?.amount ?? "0")).toLocaleString()}
                                        <Typography variant="body1" sx={{
                                            color: Colors.theme.blue.dark, gap: '0.4rem',
                                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                                        }}>
                                            <ShowUnitOfMoneyThreePart currencyNTT={getSlipMain?.currency_unit} />
                                        </Typography>
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "0.2rem" }}>
                                    <ArrayText
                                        texts={[
                                            {
                                                text: `To: `,
                                                style: {
                                                    color: Colors.theme.blue.light,
                                                }
                                            },
                                        ]}
                                    />
                                    <OpenProfile user={getSlipMain.to_person.user}
                                        profile={getSlipMain.to_person.profile}

                                        is_trusty={getSlipMain.to_person.is_trusty}
                                    />
                                </div>
                            </Grid>

                            {getSlipMain.file && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                                    border: `1px solid ${Colors.theme.blue.border}`,
                                    borderRadius: "0.8rem",
                                    paddingInline: "1rem",
                                    paddingBlock: "1.5rem",
                                    width: "100%",
                                    boxSizing: "border-box",
                                }}>
                                    <div style={{
                                        display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between",
                                        width: "100%",
                                        boxSizing: "border-box",
                                    }}>
                                        <div style={{
                                            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                            gap: "1rem",
                                        }}>
                                            <DownloadRounded style={{ color: Colors.theme.blue.blue }} />

                                            <p style={{
                                                fontFamily: confComps.font.poppins.Regular,
                                                fontSize: "0.96rem",
                                                color: Colors.theme.blue.dark,
                                            }}>
                                                {(getSlipMain.file ?? "image").split("/")[(getSlipMain.file ?? "image").split("/").length - 1]}
                                            </p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: '0.56rem' }}>


                                            <ButtonColor
                                                text={"Download"}
                                                onclick={() => {
                                                    if (getSlipMain.file) {
                                                        DownloadFile(getSlipMain.file, (getSlipMain.file ?? "image").split("/")[(getSlipMain.file ?? "image").split("/").length - 1])
                                                        // DownloadFile(file.attachment, "Document")
                                                    }
                                                }}
                                                iconStart={<DownloadRounded
                                                    style={{
                                                        fontSize: '1.1rem',
                                                        color: Colors.theme.blue.Secondary,
                                                    }} />
                                                }
                                                styleMainDiv={{
                                                    border: `1px solid ${Colors.theme.blue.blue}`,
                                                    gap: '0.2rem',
                                                }}
                                                color={ColorThemForButton.LightBlueColorAndTransparentBackground}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>}
                            {(getSlipMain?.description.length ?? 0) > 0 &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <TextDescription>
                                        {getSlipMain?.description}
                                    </TextDescription>
                                </Grid>}
                            {getSlipMain &&
                                getSlipMain.state.value === EnumStateValueOfSlip.created &&
                                getKeyOfStepThatSelected === `${EnumSTepsParent.slip}.${EnumStepsSlip.waitingForApprove}` &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row',
                                        alignItems: 'center', justifyContent: 'end',
                                        gap: '0.56rem',
                                        width: '100%',
                                    }}>
                                        <ButtonCancel
                                            text={"Delete"}
                                            callbackOnClick={() => {
                                                setIsActiveDialogDeleteSlip(true);
                                            }}
                                            iconStart={<TrashIcon
                                                style={{
                                                    fontSize: '1.1rem', marginBottom: '0.1rem',
                                                    color: Colors.theme.orange.sharp,
                                                }}
                                            />}
                                            style={{
                                                background: "#fff",
                                                color: Colors.theme.orange.sharp,
                                                borderColor: `${Colors.theme.orange.sharp}`,
                                            }
                                            }
                                        />
                                        <ButtonSave
                                            text={"Finalize"}
                                            callbackOnClick={() => {
                                                setIsActiveDialogCompleteSlip(true);
                                            }}
                                            iconStart={<SendAndArchiveRounded
                                                style={{
                                                    fontSize: '1.2rem',
                                                    color: Colors.theme.green.dark,
                                                    marginTop: "-0.1rem",
                                                }} />
                                            }
                                            style={{
                                                background: "#fff",
                                                color: Colors.theme.green.dark,
                                                borderColor: `${Colors.theme.green.dark}`,

                                            }}
                                        />
                                    </div>
                                </Grid>
                            }
                        </Grid>

                    </div>
                }
            </MainCard>
            <DialogAcceptProcess
                isOpen={getIsActiveDialogDeleteSlip}
                title={"Are you sure For Delete?"}
                description={"The Slip will Removed."}
                callbackClickAccept={function (): void {
                    callApiForDeleteOfThisSlip();
                }} callbackClickReject={function (): void {
                    setIsActiveDialogDeleteSlip(false);
                }}
                greenButtonTitle="Yes"
                redButtonTitle="No"
            />
            <DialogAcceptProcess
                isOpen={getIsActiveDialogCompleteSlip}
                title={"Are you save this Slip?"}
                description={"you must save this transaction in (سپیدار)"}
                callbackClickAccept={function (): void {
                    callApiForUpdateStateOfThisSlip(EnumStateValueOfSlip.completed);
                    setIsActiveDialogCompleteSlip(false);
                }} callbackClickReject={function (): void {
                    setIsActiveDialogCompleteSlip(false);
                }}
            />
        </ContainerPage >

    );
}
export default ShowStateTheSlip;
