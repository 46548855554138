import { Box, Grid, useTheme } from "@mui/material";
import { InterfaceCreateCommissionOfTrade } from "./InterfaceCreateCommissionOfTrade";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../NTTs/InterfaceNTTCurrency";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import ButtonCancel from "../../../basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../basic/Buttons/ButtonSave/ButtonSave";
import TextFieldMultiLine from "../../../basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import TextFieldSimple from "../../../basic/TextField/TextFieldSimple/TextFieldSimple";
import { enumTypeOfAlert } from "../../../MUIConfig/alert_popup/interfaceAlertPopup";
import { InterfaceSelectedItemForSelectCommon } from "../../Selector/SelectCommon/InterfaceSelectCommon";
import SelectCommon from "../../Selector/SelectCommon/SelectCommon";
import SelectCurrencyWithTypeAndLocation from "../../Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import { InterfaceGetAPITrustyOrCustomer, InterfaceTypeSelectTrustyOrCustomer } from "../../Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import SelectTrustyOrCustomer from "../../Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";

function CreateCommissionOfTrade(paramsComponent: InterfaceCreateCommissionOfTrade) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getCustomerSelected, setCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getSelectedCurrencyType, setSelectedCurrencyType] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getAmountValueTypeCurrency, setAmountValueTypeCurrency] = useState<string>("");
    const [getDebtStatusTypeCurrency, setDebtStatusTypeCurrency] = useState<InterfaceSelectedItemForSelectCommon>(confComps.component.selectorCommon.SelectStateDebt[0]);
    const [getDescriptionValue, setDescriptionValue] = useState<string>("");
    useEffect(() => {
        setCustomerSelected(paramsComponent.defaultValue?.person);
        setSelectedCurrencyType(paramsComponent.defaultValue?.currency_unit);
        setAmountValueTypeCurrency(paramsComponent.defaultValue?.amount ?? "");
        setDebtStatusTypeCurrency(paramsComponent.defaultValue?.debt_status ?
            confComps.component.selectorCommon.SelectStateDebt
                .filter(item => item.serverNeeded === paramsComponent.defaultValue?.debt_status.value)[0]
            : confComps.component.selectorCommon.SelectStateDebt[0]);
        setDescriptionValue(paramsComponent.defaultValue?.description ?? "");
    }, [paramsComponent.defaultValue])

    return (
        <div style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'start', width: '100%',
            justifyContent: 'start',
            gap: '0.2rem',
        }}>
            <Grid container
                justifyContent="start" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            >
                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                    <SelectTrustyOrCustomer
                        title={"Customer/Trusty"}
                        defaultUser={{
                            realUserSelect: getCustomerSelected,
                            isAllSelected: false
                        }}
                        onChangeSelectedUser={(selectCustomer: InterfaceTypeSelectTrustyOrCustomer) => {
                            setCustomerSelected(selectCustomer.realUserSelect);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectCurrencyWithTypeAndLocation
                        title={"Currency Type 1"}
                        onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                            setSelectedCurrencyType(selectCountry)
                        }}
                        style={{

                            alignItems: "stretch",
                        }}
                        defaultCurrency={getSelectedCurrencyType}
                    />

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextFieldSimple
                        title={`${confComps.translate.amount} ${getSelectedCurrencyType?.currency.symbol ? `(${getSelectedCurrencyType?.currency.symbol})` : ""}`}
                        textValue={
                            `${[getAmountValueTypeCurrency.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            ...getAmountValueTypeCurrency.replace(/[^0-9.]/g, '').split(".").slice(1)
                            ].join(".")}`
                        }
                        type="text"
                        onChangeValue={function (newText: string): void {
                            const validText = newText.replace(/[^0-9.]/g, '');
                            setAmountValueTypeCurrency(validText)
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectCommon
                        title={"Debt Status Type1"}
                        onChangeSelectStateDebt={(selected?: InterfaceSelectedItemForSelectCommon) => {
                            if (selected) {
                                setDebtStatusTypeCurrency(selected);
                            }
                        }}
                        defaultSelected={getDebtStatusTypeCurrency}
                        listItem={confComps.component.selectorCommon.SelectStateDebt} />

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextFieldMultiLine
                        title={confComps.translate.explanation}
                        text={getDescriptionValue}
                        onChangeText={setDescriptionValue}
                        styleParent={{
                            boxSizing: 'border-box',
                            border: `1px solid ${Colors.theme.blue.border}`,
                            background: Colors.theme.white.white90,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                        marginBlock: "1rem",
                        width: "100%",
                        gap: "0.5rem",
                        position: "sticky",
                        bottom: "20px",
                    }}>
                        <ButtonCancel text={confComps.translate.cancel}
                            callbackOnClick={() => {
                                paramsComponent.callbackCancel();
                            }} />
                        <ButtonSave text={"Add"}
                            callbackOnClick={() => {
                                if (getSelectedCurrencyType && getDebtStatusTypeCurrency
                                    && getCustomerSelected
                                ) {
                                    paramsComponent.callBackSave({
                                        amount: getAmountValueTypeCurrency,
                                        currency_unit: getSelectedCurrencyType,
                                        person: getCustomerSelected,
                                        description: getDescriptionValue,
                                        debt_status: {
                                            label: getDebtStatusTypeCurrency.text,
                                            value: getDebtStatusTypeCurrency.serverNeeded,
                                        },
                                    });
                                }
                                else {
                                    dispatch(ShowAlert({ text: 'Please Fill All fields!', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
                                }
                            }} />


                    </div>
                </Grid>
            </Grid>
        </div >
    );
}
export default CreateCommissionOfTrade;