import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import Colors, { ColorThemForButton } from "../../../../../config/Colors";
import { HistoryToggleOffRounded, PaymentsRounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import ArrayText from "../../../../../components/basic/Texts/ArrayText/ArrayText";
import { getColorCodeOfBalanceNumber } from "../../../../../helper/Helper";
import { useNavigate } from "react-router-dom";
import Links from "../../../../../config/Links";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { InterfaceRowSlip } from "./InterfaceRowSlip";
import OpenProfile from "../../../../../components/basic/OpenProfile/OpenProfile";
import dayjs from "dayjs";
import ProgressBarGradient from "../../../../../components/basic/progressBar/ProgressBarGradient/ProgressBarGradient";
import { TickWithCircleIcon } from "../../../../../assets/icons/svg/TickWithCircleIcon";
import ButtonColor from "../../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import { ArrowRightIcon } from "../../../../../assets/icons/svg/ArrowRightIcon";
import { useEffect, useState } from "react";
import { InterfaceSteperGroup } from "../../../../../components/complex/SteperGuide/SteperGroup/InterfaceSteperGroup";
import { EnumStateValueOfSlip } from "../../../../../NTTs/Slip/InterfaceNTTSlipMain";
import { WalletIcon } from "../../../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../../../helper/CurrencySymbols";
import ShowUnitOfMoneyThreePart
    from "../../../../../components/complex/ShowUnitOfMoneyThreePart/ShowUnitOfMoneyThreePart";
import ToolTipText from "../../../../../components/basic/ToolTipText/ToolTipText";
import moment from "moment-jalaali";
const ParentDiv = styled("div")(({ theme }) => ({
    width: "100%", padding: "1px",
    background: "white",
    boxShadow: Colors.shadow.cardList,
    borderRadius: "0.5rem",
    cursor: "pointer",
    "&:hover": {
        background: Colors.gradients.blueGreenBlue,
    }
}));
const TagService = styled("div")(({ theme }) => ({
    background: Colors.theme.white.PrimaryLight,
    color: Colors.theme.blue.dark,
    boxShadow: Colors.shadow.cardList,
    paddingInline: "0.5rem",
    paddingBlock: "0.16rem",
    borderRadius: "500px",
    boxSizing: "border-box",
    lineHeight: "1.2rem",
    fontSize: "1rem",


}));
function RowSlip(paramsComponent: InterfaceRowSlip) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);

    return (
        <ParentDiv style={{ height: "100%" }}
            onClick={() => {
                navigate(Links.screens.panel.deals.slip.ShowStateTheSlip + `${paramsComponent.SlipMainInfo.id}/`)
            }}>
            <Box

                sx={{
                    background: "white",
                    height: "100%",
                    width: "100%", display: "flex", flexDirection: "column",
                    alignItems: "center", justifyContent: "space-between",
                    borderRadius: "0.5rem",
                    paddingInline: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 },//{{ xs: "0.96rem" }},
                    paddingTop: "1.28rem",
                    paddingBottom: { xs: 1, sm: 1, md: 2, lg: 3, xl: 4 },
                    boxSizing: "border-box",

                }}>
                <Grid container
                    justifyContent="flex-start" alignItems="stretch"
                    columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 2, xl: 4 }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ArrayText
                            texts={[
                                {
                                    text: `Slip ID: `,
                                    style: {
                                        color: Colors.theme.blue.light,
                                    }
                                },
                                {
                                    text: `${paramsComponent.SlipMainInfo.id}`,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }

                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{
                            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "0.32rem"
                        }}>
                            <ArrayText
                                texts={[
                                    {
                                        text: `${confComps.translate.date}: `,
                                        style: {
                                            color: Colors.theme.blue.light,
                                        }
                                    },
                                ]}
                            />
                            <ToolTipText
                                textHint={`Jalali: ${moment.unix(paramsComponent.SlipMainInfo?.created_at ?? 0).format("jYYYY/jM/jD")}`}
                            >
                                <p style={{
                                    fontFamily: confComps.font.poppins.Regular,
                                    fontSize: "1.03rem",
                                    lineHeight: "1.44rem",
                                    textAlign: "start",
                                    color: Colors.theme.blue.dark,
                                }}>

                                    {`${dayjs.unix(paramsComponent.SlipMainInfo.created_at).format("YYYY/MM/DD")}`}

                                </p>
                            </ToolTipText>
                        </div>
                    </Grid>
                    {/* from */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "0.2rem" }}>
                            <ArrayText
                                texts={[
                                    {
                                        text: `From: `,
                                        style: {
                                            color: Colors.theme.blue.light,
                                        }
                                    },
                                ]}
                            />
                            <OpenProfile
                                person_type={paramsComponent.SlipMainInfo.from_person.person_type}
                                user={paramsComponent.SlipMainInfo.from_person.user}
                                profile={paramsComponent.SlipMainInfo.from_person.profile}
                                is_trusty={paramsComponent.SlipMainInfo.from_person.is_trusty}
                            />
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ArrayText
                                texts={[
                                    {
                                        text: `Balance: `,
                                        style: {
                                            color: Colors.theme.blue.light,
                                        }
                                    },
                                    {
                                        text: `${paramsComponent.SlipMainInfo.from_person.balance} (AED)`,
                                        style: {
                                            color: getColorCodeOfBalanceNumber(paramsComponent.SlipMainInfo.from_person.balance),
                                        }
                                    },

                                ]}
                            />
                        </div>
                    </Grid> */}
                    {/* to */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: "0.2rem" }}>
                            <ArrayText
                                texts={[
                                    {
                                        text: `To: `,
                                        style: {
                                            color: Colors.theme.blue.light,
                                        }
                                    },
                                ]}
                            />
                            <OpenProfile user={paramsComponent.SlipMainInfo.to_person.user}
                                profile={paramsComponent.SlipMainInfo.to_person.profile}
                                person_type={paramsComponent.SlipMainInfo.to_person.person_type}
                                is_trusty={paramsComponent.SlipMainInfo.to_person.is_trusty}
                            />
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ArrayText
                                texts={[
                                    {
                                        text: `Balance: `,
                                        style: {
                                            color: Colors.theme.blue.light,
                                        }
                                    },
                                    {
                                        text: `${paramsComponent.SlipMainInfo.to_person.balance} (AED)`,
                                        style: {
                                            color: getColorCodeOfBalanceNumber(paramsComponent.SlipMainInfo.to_person.balance),
                                        }
                                    },

                                ]}
                            />
                        </div>
                    </Grid> */}
                    {/*amount  */}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`${confComps.translate.amount} (${paramsComponent.SlipMainInfo.currency_unit.currency.symbol}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: Colors.theme.blue.dark,
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                {Number(parseFloat(paramsComponent.SlipMainInfo.amount)).toLocaleString()}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    <ShowUnitOfMoneyThreePart currencyNTT={paramsComponent.SlipMainInfo.currency_unit} />
                                </Typography>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <ArrayText
                            texts={[
                                {
                                    text: `Code: `,
                                    style: {
                                        color: Colors.theme.blue.light,
                                    }
                                },
                                {
                                    text: `${paramsComponent.SlipMainInfo.code}`,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }

                                }
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                        <ProgressBarGradient percentage={parseFloat((
                            (paramsComponent.SlipMainInfo.state.value === EnumStateValueOfSlip.created ? 60 : 100)
                        ).toFixed(2))} label={{
                            title: paramsComponent.SlipMainInfo.state.value === EnumStateValueOfSlip.created ?
                                "Created" : "Saved",
                            link: ""
                        }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: "0.32rem" }}>

                            {paramsComponent.SlipMainInfo.state.value !== EnumStateValueOfSlip.completed ?
                                <div style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.5rem',
                                }}>
                                    <HistoryToggleOffRounded
                                        style={{
                                            fontSize: "1.5rem",
                                            color: Colors.theme.orange.light,
                                        }}
                                    />
                                    <h5 style={{
                                        fontSize: "1rem",
                                        fontFamily: confComps.font.poppins.SemiBold,
                                        lineHeight: "1.4rem",
                                        color: Colors.theme.orange.light,
                                    }}>
                                        {confComps.translate.inProgress}

                                    </h5>
                                </div>
                                :
                                <div style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.5rem',
                                }}>
                                    <TickWithCircleIcon style={{ color: Colors.theme.green.dark, background: Colors.theme.green.lightLemon }} />
                                    <h5 style={{
                                        fontSize: "0.8rem", fontFamily: confComps.font.poppins.SemiBold, lineHeight: "1.4rem",
                                        color: Colors.theme.green.dark
                                    }}>
                                        {confComps.translate.completed}

                                    </h5>
                                </div>

                            }
                        </div>

                    </Grid>
                    <Grid item xs={12}>
                        <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "-1rem",
                            justifyContent: 'end',

                        }}>
                            <ButtonColor text={confComps.translate.showDetails}
                                onclick={function (): void {
                                    navigate(Links.screens.panel.deals.slip.ShowStateTheSlip + `${paramsComponent.SlipMainInfo.id} / `)
                                }}
                                iconStart={undefined}
                                color={ColorThemForButton.grayColorAndTransparentBackground}
                                iconEnd={<ArrowRightIcon />}
                            />

                        </div>
                    </Grid>


                </Grid>

            </Box>
        </ParentDiv >
    )
}
export default RowSlip;