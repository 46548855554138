export enum EnumSectionsSidebar {
    dashboard = "dashboard",
    recap = "recap",
    deals = "deals",
    services = "services",
    people = "people",
    report = "report",
    mySetting = "my_setting",
    myNotification = "my_notification",
    myProfile = "my_profile",
};