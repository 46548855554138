import { useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { InterfaceArrayText } from "./InterfaceArrayText";
import Colors from "../../../../config/Colors";

function ArrayText(paramsComponent: InterfaceArrayText) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    return (
        <div style={{
            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "0.32rem"
        }}>
            {paramsComponent.texts.map((text, index, arr) => {
                return (
                    <p style={{
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: "1.03rem",
                        lineHeight: "1.44rem",
                        textAlign: "start",
                        color: Colors.theme.blue.light,
                        ...text.style
                    }}>
                        {text.text}
                    </p>
                )
            })}

        </div>
    )

}
export default ArrayText;