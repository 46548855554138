import { Grid, useTheme } from "@mui/material";
import { InterfaceSelectedItemForSelectCommon } from "../../Selector/SelectCommon/InterfaceSelectCommon";
import SelectCommon from "../../Selector/SelectCommon/SelectCommon";
import SelectUser from "../../Selector/SelectUser/SelectUser";
import { useState } from "react";
import { interfaceUser } from "../../MentionTextarea/MentionTextarea";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import SelectEmployee from "../../Selector/SelectEmployee/SelectEmployee";
import ButtonCancel from "../../../basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../basic/Buttons/ButtonSave/ButtonSave";
import { InterfaceCreateMultiNetProfit } from "./InterfaceCreateMultiNetProfit";
import SelectCurrencyWithTypeAndLocation from "../../Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../NTTs/InterfaceNTTCurrency";
import TextFieldSimple from "../../../basic/TextField/TextFieldSimple/TextFieldSimple";

function CreateMultiNetProfit(paramsComponent: InterfaceCreateMultiNetProfit) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getTotalProfit, setTotalProfit] = useState<string>("0");
    const [getTotalProfitCurrencyType, setTotalProfitCurrencyType] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getFinalTotalProfit, setFinalTotalProfit] = useState<string>("0");
    return (
        <div style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            width: '100%'
        }}>
            <Grid container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectCurrencyWithTypeAndLocation
                        title={"Currency Type"}
                        onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                            setTotalProfitCurrencyType(selectCountry)
                        }}
                        style={{
                            alignItems: "stretch",
                        }}
                        defaultCurrency={getTotalProfitCurrencyType}
                    />

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextFieldSimple
                        title={`Net Profit ${getTotalProfitCurrencyType?.currency.symbol ? `(${getTotalProfitCurrencyType?.currency.symbol})` : ""}`}
                        textValue={
                            `${[getTotalProfit.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            ...getTotalProfit.replace(/[^0-9.]/g, '').split(".").slice(1)
                            ].join(".")}`
                        }
                        type="text"
                        onChangeValue={function (newText: string): void {
                            const validText = newText.replace(/[^0-9.]/g, '');
                            setTotalProfit(validText)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <TextFieldSimple
                        title={`Net Profit For Company ${getTotalProfitCurrencyType?.currency.symbol ? `(${getTotalProfitCurrencyType?.currency.symbol})` : ""}`}
                        textValue={
                            `${[getFinalTotalProfit.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                            ...getFinalTotalProfit.replace(/[^0-9.]/g, '').split(".").slice(1)
                            ].join(".")}`
                        }
                        type="text"
                        onChangeValue={function (newText: string): void {
                            const validText = newText.replace(/[^0-9.]/g, '');
                            setFinalTotalProfit(validText)
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                        marginBlock: "1rem",
                        width: "100%",
                        gap: "0.5rem",
                        position: "sticky",
                        bottom: "20px",
                    }}>
                        <ButtonCancel text={confComps.translate.cancel}
                            callbackOnClick={() => {
                                paramsComponent.callbackCancel();
                            }} />
                        <ButtonSave text={"Add"}
                            callbackOnClick={() => {
                                if (getTotalProfitCurrencyType) {
                                    paramsComponent.callbackSave({
                                        currency_unit: getTotalProfitCurrencyType,
                                        final_total_profit: getFinalTotalProfit,
                                        total_profit: getTotalProfit,
                                    });
                                }
                            }}
                        />


                    </div>
                </Grid>

            </Grid>

        </div>
    )
};
export default CreateMultiNetProfit;