import { ChangeEvent, useEffect, useRef, useState } from "react";
import { InterfaceSelectPriority } from "./InterfaceSelectPriority";
import Colors from "../../../../config/Colors";
import { styled, useTheme } from "@mui/material";
import { Priority0Icon } from "../../../../assets/icons/svg/Priority/Priority0Icon";
import { Priority1Icon } from "../../../../assets/icons/svg/Priority/Priority1Icon";
import { Priority2Icon } from "../../../../assets/icons/svg/Priority/Priority2Icon";
import { Priority3Icon } from "../../../../assets/icons/svg/Priority/Priority3Icon";
import { Priority4Icon } from "../../../../assets/icons/svg/Priority/Priority4Icon";
import LineSeparator from "../../../basic/LineSeprator/LineSeparator";
import { BottomTriangleIcon } from "../../../../assets/icons/svg/BottomTriangleIcon";
import { useDispatch } from "react-redux";
import { ConfigComps } from "../../../../config/ConfigCOMP";

// Styled components
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    paddingInline: "0.5rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,
    }
}));

// const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({ isDropdownOpen }) => ({
//     overflow: "hidden",
//     position: "absolute",
//     top: "calc(100% + 0.5rem)",
//     left: "0",
//     zIndex: isDropdownOpen ? "3" : "-1",
//     width: "100%",
//     background: "#F8F8F8",
//     boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
//     borderInline: "1px solid #ccc",
//     borderBlockStart: "1px solid #DFDFDF",
//     borderBlockEnd: "1px solid #ccc",
//     boxSizing: "border-box",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: 'center',
//     justifyContent: "start",
//     borderEndEndRadius: "1rem",
//     borderEndStartRadius: "1rem",
//     opacity: isDropdownOpen ? 1 : 0,
//     transition: "opacity 0.3s ease, transform 0.5s ease",
//     transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
//     visibility: isDropdownOpen ? "visible" : "hidden",
// }));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});

function SelectPriority({ callbackOnChangeTypeOfFreestyleStep, defaultSelected, style, isDisable }: InterfaceSelectPriority) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const priorities = [
        { label: "Lower Priority", icon: <Priority0Icon /> },
        { label: "Low Priority", icon: <Priority1Icon /> },
        { label: "Minor Priority", icon: <Priority2Icon /> },
        { label: "High Priority", icon: <Priority3Icon /> },
        { label: "Higher Priority", icon: <Priority4Icon /> }
    ];

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [selectedPriority, setSelectedPriority] = useState(defaultSelected);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleSelectPriority = (index: number) => {
        setSelectedPriority(index);
        setIsDropdownOpen(false);
        callbackOnChangeTypeOfFreestyleStep(index);
    };

    useEffect(() => {
        setSelectedPriority(defaultSelected);
    }, [defaultSelected]);

    useEffect(() => {
        if (isDropdownOpen) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsDropdownOpen(false);
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [isDropdownOpen]);

    return (
        <div style={{ position: "relative", ...style }} ref={containerRef}>
            <label style={{
                fontWeight: "300",
                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                userSelect: "none",
                color: isDropdownOpen ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {"Priority"}
            </label>
            <div
                style={{
                    background: Colors.theme.white.white90,
                    border: isDropdownOpen ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                    boxShadow: isDropdownOpen ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                    borderRadius: '0.4rem',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    width: "100%",
                    color: Colors.theme.blue.dark,
                    outline: 'none',
                    lineHeight: "1.68rem",
                    display: "flex", flexDirection: "row",
                    alignItems: 'center',
                    paddingInlineStart: "0.5rem",
                    boxSizing: "border-box",
                }}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
                <div style={{
                    display: 'flex', alignItems: 'center',
                    flexGrow: 1,
                    flexBasis: 0,
                }}>

                    <div style={{
                        width: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'start',
                        justifyContent: 'center',
                    }}>

                        {selectedPriority !== undefined ? priorities[selectedPriority].icon : undefined}
                    </div>
                    <span style={{
                        marginLeft: '0.1rem',
                        cursor: isDisable ? "not-allowed" : "pointer",
                        width: 0,
                        boxSizing: "border-box",
                        flexGrow: 1,
                        background: Colors.theme.white.white90,
                        paddingInline: '0.64rem',
                        textWrap: 'nowrap',
                        paddingBlock: '0.32rem',
                        borderRadius: '0.4rem',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        lineHeight: "1.68rem",
                    }}>
                        {selectedPriority !== undefined ? priorities[selectedPriority].label : undefined}
                    </span>

                </div>
                <BottomTriangleIcon style={{
                    height: '1.66rem',
                    marginBlock: '0.3rem',
                    transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s ease-in-out",
                    marginInlineEnd: "0.5rem",
                }} />
            </div>

            <DropdownBox isDropdownOpen={isDropdownOpen}>
                {priorities.flatMap((priority, index, arr) => {
                    let list = [
                        <RowOfList key={index} onClick={() => handleSelectPriority(index)}>
                            <div style={{
                                width: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'start',
                                justifyContent: 'center',
                            }}>
                                {priority.icon}
                            </div>
                            <span style={{ marginInlineStart: "0.5rem" }}>{priority.label}</span>
                        </RowOfList>
                    ];
                    if ((index + 1) !== arr.length) {
                        list.push(<LineSeparator />)
                    }
                    return list;
                }

                )}
            </DropdownBox>
        </div>
    );
}

export default SelectPriority;
