import { DownloadRounded, FileOpenRounded, UploadFileRounded } from "@mui/icons-material";
import { TrashIcon } from "../../../../../../../../assets/icons/svg/TrashIcon";
import Colors, { ColorThemForButton } from "../../../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { DownloadFile, getTypeOfFileFromLink } from "../../../../../../../../helper/Helper";
import { RequestHandler } from "../../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { EnumStateValueOfFreestyleStep, InterfaceNTTStepOfFreestyle } from "../../../../../../../../NTTs/Freestyle/InterfaceNTTStepOfFreestyle";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InterfaceFile } from "../../../../../../../../components/complex/ListFiles/InterfaceListFiles";
import ButtonCancel from "../../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { InterfaceListFilesThatAttachedToStepOfFreestyle } from "./InterfaceListFilesThatAttachedToStepOfFreestyle";
import ButtonColor from "../../../../../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import { useEffect, useState } from "react";
import { EnumStepsFreestyle, EnumSTepsParent } from "../../../../../../../../config/enums/EnumSteps";
import { EnumStateValueOfFreestyle } from "../../../../../../../../NTTs/Freestyle/InterfaceNTTFreestyleMain";


function ListFilesThatAttachedToStepOfFreestyle(paramsComponent: InterfaceListFilesThatAttachedToStepOfFreestyle) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateOfMainFreestyle, setStateOfMainFreestyle] = useState<EnumStateValueOfFreestyle>(paramsComponent.stateOfMainFreestyle)
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>(paramsComponent.keyOfGroupThatSelected);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>(paramsComponent.keyOfStepThatSelected);

    const [getThisStep, setThisStep] = useState<InterfaceNTTStepOfFreestyle>(paramsComponent.step);
    useEffect(() => {
        setThisStep(paramsComponent.step);
    }, [paramsComponent.step])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.keyOfStepThatSelected)
    }, [paramsComponent.keyOfStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected)
    }, [paramsComponent.keyOfGroupThatSelected])
    const callApiForDeleteThisFileFromStep = async (idInServer: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.deleteAttachedFileToThisStep;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idInServer}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackRemoveFile();
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "start",
            gap: "1rem",
            width: "100%",
        }}>
            {paramsComponent.attachments.map((file, index, arr) => {
                return (
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                        border: `1px solid ${Colors.theme.blue.border}`,
                        borderRadius: "0.8rem",
                        paddingInline: "1rem",
                        paddingBlock: "1.5rem",
                        width: "100%",
                        boxSizing: "border-box",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between",
                            width: "100%",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                gap: "1rem",
                            }}>
                                <DownloadRounded style={{ color: Colors.theme.blue.blue }} />

                                <p style={{
                                    fontFamily: confComps.font.poppins.Regular,
                                    fontSize: "0.96rem",
                                    color: Colors.theme.blue.dark,
                                }}>
                                    {(file.attachment ?? "image").split("/")[(file.attachment ?? "image").split("/").length - 1]}
                                </p>


                                <div style={{
                                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                    gap: "0.2rem",
                                }}>

                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.light,
                                    }}>
                                        {confComps.translate.description}
                                        {":  "}
                                    </p>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>

                                        {file.title}
                                    </p>
                                </div>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: '0.56rem' }}>

                                {paramsComponent.step.state.value === EnumStateValueOfFreestyleStep.approvedByManager &&
                                    getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}` &&
                                    getStateOfMainFreestyle === EnumStateValueOfFreestyle.approvedByManager &&
                                    <ButtonColor
                                        text={"Delete"}
                                        onclick={() => {
                                            callApiForDeleteThisFileFromStep(file.id);
                                        }}
                                        iconStart={<TrashIcon
                                            style={{
                                                width: '1.1rem',
                                                color: Colors.theme.orange.sharp,
                                            }}
                                        />
                                        }
                                        styleMainDiv={{
                                            border: `1px solid ${Colors.theme.orange.sharp}`,
                                            gap: '0.3rem',
                                        }}
                                        color={ColorThemForButton.orangeColorAndTransparentBackground}
                                    />
                                }
                                <ButtonColor
                                    text={"Download"}
                                    onclick={() => {
                                        if (file.attachment) {
                                            DownloadFile(file.attachment, (file.attachment ?? "image").split("/")[(file.attachment ?? "image").split("/").length - 1])
                                            // DownloadFile(file.attachment, "Document")
                                        }
                                    }}
                                    iconStart={<DownloadRounded
                                        style={{
                                            fontSize: '1.1rem',
                                            color: Colors.theme.blue.Secondary,
                                        }} />
                                    }
                                    styleMainDiv={{
                                        border: `1px solid ${Colors.theme.blue.blue}`,
                                        gap: '0.2rem',
                                    }}
                                    color={ColorThemForButton.LightBlueColorAndTransparentBackground}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}

        </div >
    );
}
export default ListFilesThatAttachedToStepOfFreestyle;