import { useState } from "react";
import { DialogShowImage } from "./dialogShowImage/DialogShowImage";
import { interfaceImageSquare } from "./interfaceImageSquare";

export function ImageSquare(componenetParams: interfaceImageSquare) {
    const [getIsDialogShow, setIsDialogShow] = useState<boolean>(false);
    function onCancelDialog() {
        setIsDialogShow(false);
    }

    return (
        // <div>
        <div
            onClick={(getIsDialogShow) ? undefined :
                () => {
                    if (componenetParams.isScaleable) {
                        setIsDialogShow(true);
                    }
                    else if (componenetParams.callbackOnclick) {
                        componenetParams.callbackOnclick()
                    }
                }
            }
            style={{
                cursor: (componenetParams.callbackOnclick || componenetParams.isScaleable) ? "pointer" : undefined,
                boxSizing: "border-box",
                width: componenetParams.width,
                position: "relative",
                color: componenetParams.color,
                background: componenetParams.background,
                overflow: componenetParams.style?.overflow ?? "hidden",
                border: componenetParams.border,
                borderRadius: componenetParams.borderRadius,
                flexGrow: componenetParams.style?.flexGrow,
                opacity: componenetParams.style?.opacity,
                transition: componenetParams.style?.transition,
                bottom: componenetParams.style?.bottom,
                right: componenetParams.style?.right,
                top: componenetParams.style?.bottom,
                left: componenetParams.style?.right,
                display: "flex",
                flexDirection: "row",
                boxShadow: componenetParams.style?.boxShadow,
                alignItems: componenetParams.style?.alignItems,
            }}>
            <div style={{ paddingBottom: "100%", position: "relative", height: 0, flexGrow: "1", zIndex: 2, boxSizing: "border-box", }}>
                {componenetParams.elementStyle?.position && componenetParams.element}
            </div>
            {componenetParams.img &&
                <img
                    src={`${componenetParams.img}`}
                    alt={`${componenetParams.img}`}

                    style={{
                        objectFit: "cover",
                        height: "100%",
                        width: "100%",
                        borderRadius: componenetParams.borderRadius,
                        position: "absolute", top: "0", left: "0",
                        boxSizing: "border-box",
                    }}
                />
            }
            {

                componenetParams.iconSvg &&
                typeof componenetParams.iconSvg === 'object' && 'muiName' ?
                <componenetParams.iconSvg
                    style={{
                        fontSize: componenetParams.fontsizeIcon,
                        height: "100%",
                        boxSizing: "border-box",
                        width: "100%",
                        position: "absolute",
                        top: "50%",
                        transform: "translate(0,-50%)",
                        left: "0",
                        color: componenetParams.color,
                        background: componenetParams.background,
                        borderRadius: componenetParams.borderRadius,
                    }} /> : componenetParams.iconSvg

            }
            {
                componenetParams.element && (componenetParams.elementStyle?.position === undefined) &&
                < div style={{
                    fontSize: componenetParams.fontsizeIcon,
                    width: "100%", position: "absolute", top: "50%", transform: "translate(0,-50%)", left: "0",
                    color: componenetParams.color,
                    background: componenetParams.background,
                    display: "flex",
                    boxSizing: "border-box",
                    flexDirection: "row", alignItems: "center", justifyContent: "center",
                }}
                    key={`${componenetParams.element}`}
                >
                    {componenetParams.element}
                </div>
            }

            {/* </div > */}
            <DialogShowImage
                isOpen={getIsDialogShow}
                imgUrl={(componenetParams.img ?? "")}
                callbackOnCloseDialog={onCancelDialog}
                appendData={componenetParams.isScaleable}
            />
        </div>

    )
}