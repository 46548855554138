import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { SyncRounded } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import LineSeparator from "../../../basic/LineSeprator/LineSeparator";
import { BottomTriangleIcon } from "../../../../assets/icons/svg/BottomTriangleIcon";
import { InterfaceSelectCommon, InterfaceSelectedItemForSelectCommon } from "./InterfaceSelectCommon";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    gap: "1rem",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function SelectCommon(paramsComponent: InterfaceSelectCommon) {
    var confComps = ConfigComps();
    const [getSelectedCategory, setSelectedCategory] = useState<InterfaceSelectedItemForSelectCommon | undefined>(paramsComponent.defaultSelected);
    const [getIsFocused, setIsFocused] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && getIsFocused && !containerRef.current.contains(event.target as Node)) {
                    setIsFocused(false);
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [getIsFocused]);
    useEffect(() => {
        paramsComponent.onChangeSelectStateDebt(getSelectedCategory);
    }, [getSelectedCategory])
    useEffect(() => {
        setSelectedCategory(paramsComponent.defaultSelected);
    }, [paramsComponent.defaultSelected])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
            ref={containerRef}
        >
            <label style={{
                fontWeight: "300",

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {paramsComponent.title}
            </label>
            <div style={{
                background: Colors.theme.white.white90,
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                borderRadius: '0.4rem',
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.12rem',
                width: "100%",
                color: Colors.theme.blue.dark,
                outline: 'none',
                lineHeight: "1.68rem",
                display: "flex", flexDirection: "row",
                alignItems: 'center',
                boxSizing: "border-box",
            }}>
                <input

                    autoComplete="off"
                    type="text"
                    onFocus={() => setIsFocused(true)}
                    style={{
                        width: 0,
                        boxSizing: "border-box",
                        paddingInline: '0.64rem',
                        paddingBlock: '0.32rem',
                        flexGrow: "1",
                        cursor: "pointer",
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        lineHeight: "1.68rem",
                    }}
                    value={getSelectedCategory === undefined ? undefined : getSelectedCategory.text}
                // onChange={handleChangeInput}

                />
                <BottomTriangleIcon style={{
                    transform: getIsFocused ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s ease-in-out",
                    marginInlineEnd: "0.5rem",
                }} />
            </div>
            {

                <DropdownBox
                    isDropdownOpen={getIsFocused}
                >
                    {paramsComponent.listItem.flatMap((item, index, arr) => {
                        let listRes = [
                            <RowOfList onClick={() => {
                                setSelectedCategory(item);
                                setIsFocused(false);
                            }}>
                                {item.text}
                            </RowOfList>

                        ]
                        if(index !==arr.length-1){
                            listRes.push(<LineSeparator />)
                        }
                        return listRes;
                    })}

                </DropdownBox>


            }
        </div>

    );
}
export default SelectCommon;