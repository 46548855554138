import { AddRounded } from "@mui/icons-material";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { TrashIcon } from "../../../assets/icons/svg/TrashIcon";
import Colors from "../../../config/Colors";
import { InterfaceNTTProfile } from "../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../NTTs/InterfaceNTTProfileType";
import { EnumInnerBeneficiaryOrderForStepTrade, InterfaceInnerBeneficiaryOrder } from "../../../screens/Deals/Trade/ShowStateTheTrade/ListStepsOfTrade/OneStepOfTrade/InterfaceCreateOneStepOfTrade";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../store/profileStateSlice";
import CreateInnerBeneficiary from "./CreateInnerBeneficiary/CreateInnerBeneficiary";
import { InterfaceInnerBeneficiary } from "./InterfaceInnerBeneficiary";

function InnerBeneficiary(paramsComponent: InterfaceInnerBeneficiary) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getAddInnerBeneficiaryActive, setAddInnerBeneficiaryActive] = useState<boolean>(false);
    const [getInnerBeneficiaryOrders, setInnerBeneficiaryOrders] = useState<InterfaceInnerBeneficiaryOrder[]>([]);
    useEffect(() => {
        setInnerBeneficiaryOrders(paramsComponent.defaultList);
    }, [paramsComponent.defaultList])

    return (
        <Box sx={{
            boxSizing: 'border-box',
            display: 'flex', flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
        }}>

            <label style={{
                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: Colors.theme.blue.light,
                marginBottom: '0.22rem',
            }}>
                {"Inner Beneficiaries:"}
            </label>


            {!getAddInnerBeneficiaryActive && <div style={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                border: `1px solid ${Colors.theme.gray.gray}`,
                background: Colors.theme.white.white90,
                borderRadius: '0.37rem',
                paddingBlock: '0.37rem',
                boxSizing: 'border-box',
                width: '100%',
            }}>



                {getInnerBeneficiaryOrders.flatMap((innerBeneficiary, index, arr) => {
                    let listElements = [
                        <div style={{
                            width: '100%',
                            display: 'flex', flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'start',
                            boxSizing: 'border-box',
                            paddingInline: "0.88rem",
                            marginBlockEnd: '0.4rem',
                        }}>
                            <p style={{
                                display: 'inline-flex', flexDirection: 'row',
                                alignItems: 'start',
                                justifyContent: 'start',
                                flexGrow: 0,
                                flexBasis: '2rem',
                                fontSize: '0.96rem',
                                lineHeight: '1.55rem',
                                flexWrap: 'nowrap',
                            }}>
                                {`${index + 1} :`}
                            </p>
                            <div style={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: "0.57rem",
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '0.9rem',
                                flexGrow: '1',
                                flexBasis: '0',
                            }}>


                                {innerBeneficiary.user.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(innerBeneficiary.user.profile as InterfaceNTTProfile).first_name}  ${(innerBeneficiary.user.profile as InterfaceNTTProfile).last_name}`
                                }
                                {innerBeneficiary.user.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(innerBeneficiary.user.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {innerBeneficiary.user.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(innerBeneficiary.user.profile as InterfaceNTTProfileIranLegal).name} (${(innerBeneficiary.user.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }
                                {innerBeneficiary.user.person_type.value === EnumProfileTypeNameInServer.employee &&
                                    `${(innerBeneficiary.user.profile as InterfaceNTTProfile).first_name}  ${(innerBeneficiary.user.profile as InterfaceNTTProfile).last_name}`
                                }
                                {`(${innerBeneficiary.mediator_type.value === EnumInnerBeneficiaryOrderForStepTrade.exchanger ? "Exchanger" :
                                    innerBeneficiary.mediator_type.value === EnumInnerBeneficiaryOrderForStepTrade.from ?
                                        "From" : "To"
                                    })`
                                }
                            </div>
                            {paramsComponent.isCreationActive && <div>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                                    onClick={() => {
                                        const updatedCommissions = getInnerBeneficiaryOrders.filter((_, i) => i !== index);
                                        paramsComponent.callbackUpdateList(updatedCommissions);
                                    }}
                                >
                                    <TrashIcon style={{ marginInlineStart: '0.58rem' }} />
                                </div>
                            </div>}
                        </div>
                    ]
                    if (index !== (arr.length - 1) || paramsComponent.isCreationActive) {
                        listElements.push(<div style={{
                            width: '100%',
                            height: '1px',
                            borderRadius: '10px',
                            background: '#E2E4EC',
                        }} />)
                    }
                    return listElements;

                })}


                {paramsComponent.isCreationActive &&
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'start',
                        width: '100%',
                        paddingInline: '0.88rem',
                        boxSizing: 'border-box',

                    }}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'start',
                            fontFamily: confComps.font.poppins.Medium,
                            fontSize: '0.88rem',
                            lineHeight: '1.33rem',
                            color: Colors.theme.orange.light,
                            cursor: 'pointer',
                            userSelect: 'none',
                        }}
                            onClick={() => { setAddInnerBeneficiaryActive(true) }}
                        >
                            <AddRounded style={{
                                color: Colors.theme.orange.light, fontSize: '0.88rem',
                                userSelect: 'none',
                            }} />
                            {"Add New Inner Beneficiary"}
                        </div>
                    </div>
                }
            </div>}
            {getAddInnerBeneficiaryActive &&
                <Box sx={{
                    width: '100%',
                    border: `0.5px solid ${Colors.theme.blue.border}`,
                    boxSizing: 'border-box',
                    boxShadow: Colors.shadow.cardList,
                    paddingInline: { xs: "0.7rem", sm: "1rem", md: "2.66rem", lg: "2.96rem", xl: "3.25rem" },
                    paddingBlock: "1.18rem",
                    borderRadius: '0.38rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'flex-start',
                    gap: "1rem",
                }}>
                    <CreateInnerBeneficiary
                        callbackCancel={() => {
                            setAddInnerBeneficiaryActive(false);
                        }}
                        callbackSave={function (params: Omit<InterfaceInnerBeneficiaryOrder, "order">): void {
                            setAddInnerBeneficiaryActive(false);
                            paramsComponent.callbackUpdateList([...getInnerBeneficiaryOrders,
                            {
                                ...params,
                                order: getInnerBeneficiaryOrders.length + 1,
                            }])
                        }}
                    />
                </Box>
            }

        </Box>
    );
}
export default InnerBeneficiary;