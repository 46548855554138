import { Box, Grid, useTheme } from "@mui/material";
import Colors from "../../../../../../../config/Colors";
import SubTitleSection from "../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { useEffect, useState } from "react";
import TextFieldSimple from "../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import FilesDragAndDrop from "../../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { PaperUploadIcon } from "../../../../../../../assets/icons/svg/PaperUploadIcon";
import ListFiles from "../../../../../../../components/complex/ListFiles/ListFiles";
import { InterfaceFile } from "../../../../../../../components/complex/ListFiles/InterfaceListFiles";
import { DialogAttachDetailOfFile } from "../../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";

import { isVisibilityProgressBar, updateProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../api/API";
import { InterfaceNTTActivity } from "../../../../../../../NTTs/InterfaceNTTActivity";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import AutoCompleteSimple from "../../../../../../../components/complex/AutoCompleteSimple/AutoCompleteSimple";
import { wait } from "@testing-library/user-event/dist/utils";
import ButtonCancel from "../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import { InterfaceNTTCurrency, InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../../NTTs/InterfaceNTTCurrency";
import { InterfaceNTTCountry } from "../../../../../../../NTTs/InterfaceNTTCountry";
import { InterfaceNTTCity } from "../../../../../../../NTTs/InterfaceNTTCity";
import SelectCity from "../../../../../../../components/complex/Selector/SelectCity/SelectCity";
import SelectCountry from "../../../../../../../components/complex/Selector/SelectCountry/SelectCountry";
import SelectCurrency from "../../../../../../../components/complex/Selector/SelectCurrency/SelectCurrency";
import MultiSelectCurrencies from "../../../../../../../components/complex/MultiSelectCurrencies/MultiSelectCurrencies";
import { InterfaceCreateReceptionRate } from "./InterfaceCreateReceptionRate";
import SelectUser from "../../../../../../../components/complex/Selector/SelectUser/SelectUser";
import { InterfaceNTTUser } from "../../../../../../../NTTs/InterfaceNTTUser";
import SelectCurrencyWithTypeAndLocation from "../../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import { XELogoIcon } from "../../../../../../../assets/icons/svg/XELogoIcon";
import { ImageSquare } from "../../../../../../../components/complex/imageSquare/ImageSquare";
import { TwoArrowIcon } from "../../../../../../../assets/icons/svg/TwoArrowIcon";
import { AddIcon } from "../../../../../../../assets/icons/svg/AddIcon";
function CreateReceptionRate(paramsComponent: InterfaceCreateReceptionRate) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getCommissionRate, setCommissionRate] = useState<number>(Number(paramsComponent.defaultReceptionRate?.rate ?? 0));
    const [getSelectedCurrency, setSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation | undefined>(paramsComponent.defaultReceptionRate?.currency_unit);
    const [getDescription, setDescription] = useState<string>("");

    const callApiCreateReceptionRate = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.rate.receptionRate.postAddReceptionRate;
        let dataObj = {
            "rate": getCommissionRate,
            "currency_unit": getSelectedCurrency?.id ?? -1,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/reception-rates/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackCancel();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: confComps.translate.createdAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForUpdateReceptionRate = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.rate.receptionRate.updateTheReceptionRate;
        let dataObj = {
            "rate": getCommissionRate,
            "currency_unit": getSelectedCurrency?.id ?? -1,
        }

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.defaultReceptionRate?.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackCancel();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: confComps.translate.createdAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    useEffect(() => {
    }, [])
    return (
        <Box sx={{
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
            width: "100%",
            boxSizing: "border-box",
            paddingInline: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
            border: `1px solid ${Colors.theme.blue.border}`,
            borderRadius: "0.4rem",
            position: "sticky",
            top: "20px",
            zIndex: 2,
            background: "white",
        }}>
            <Box sx={{
                boxSizing: "border-box",
                width: "100%",
                marginBlockEnd: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
            }}>
                <SubTitleSection title={confComps.translate.addNewReceptionRate} />
                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                    marginInline: { xs: 0, sm: 1, md: 1, lg: 2, xl: 2 },
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "start",
                    columnGap: { xs: '1.72rem', sm: '1.9rem', md: '2.17rem', lg: "2.37rem", xl: "2.57rem" },
                    rowGap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
                    flexWrap: "wrap",
                    height: '100%',
                }}>
                    <Grid container
                        justifyContent="space-between" alignItems="stretch"
                        columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                        rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}

                        >
                            <SelectCurrencyWithTypeAndLocation
                                title={"Currency Type"}
                                style={{
                                    alignItems: "stretch",
                                    boxSizing: "border-box",
                                    fontFamily: confComps.font.poppins.Regular,
                                }}
                                onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                    setSelectedCurrency(selectCountry)
                                }}
                                defaultCurrency={getSelectedCurrency}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
                        >
                            <TextFieldSimple
                                style={{
                                    alignItems: "stretch",
                                    // boxSizing: "border-box",
                                }}
                                title={"Rate in Percent"}
                                textValue={`${getCommissionRate}`}
                                onChangeValue={(newText: string) => setCommissionRate(Number(newText))}
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </Box>

            </Box>

            <div style={{
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                marginBlock: "1rem",
                width: "100%",
                gap: "0.5rem",
                // position: "sticky",
                // bottom:"20px",
            }}>
                <ButtonCancel text={confComps.translate.cancel}
                    callbackOnClick={() => {
                        paramsComponent.callbackCancel();
                    }} />
                <ButtonSave text={paramsComponent.defaultReceptionRate ? confComps.translate.update : confComps.translate.save}
                    callbackOnClick={() => {
                        paramsComponent.defaultReceptionRate ?
                            callApiForUpdateReceptionRate() :
                            callApiCreateReceptionRate();
                    }} />


            </div>

        </Box >

    );

}
export default CreateReceptionRate;