import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "../../../basic/LineSeprator/LineSeparator";
import { InterfaceGetAPITrustiesOrCustomers, InterfaceGetAPITrustyOrCustomer, InterfaceSelectTrustyOrCustomer, InterfaceTypeSelectTrustyOrCustomer } from "./InterfaceSelectTrustyOrCustomer";
import { BottomTriangleIcon } from "../../../../assets/icons/svg/BottomTriangleIcon";
import ArrayText from "../../../basic/Texts/ArrayText/ArrayText";
import { getColorCodeOfBalanceNumber } from "../../../../helper/Helper";
import { EnumProfileTypeNameInServer } from "../../../../NTTs/InterfaceNTTProfileType";
import { InterfaceNTTProfile } from "../../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../../NTTs/InterfaceNTTProfileIranLegal";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    fontSize: "1.12rem",
    lineHeight: "1.68rem",
    width: "100%",
    paddingInline: "1rem",
    paddingBlock: "0.32rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "1.12rem",
    lineHeight: "1.6rem",
    marginInlineStart: "0.5rem",
    color: Colors.theme.orange.light,

}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#fff",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function SelectTrustyOrCustomer(paramsComponent: InterfaceSelectTrustyOrCustomer) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getResponseAllUsers, setResponseAllUsers] = useState<InterfaceGetAPITrustyOrCustomer[]>([]);
    const [getSelectedUser, setSelectedUser] = useState<InterfaceTypeSelectTrustyOrCustomer>({
        realUserSelect: undefined,
        isAllSelected: false,
    });
    const [getSearchText, setSearchText] = useState<string>("");
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const [getIsFocused, setIsFocused] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const containerRef2 = useRef<HTMLDivElement>(null);


    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("call changed", event.target.value)
        // Clear the previous timeout if it exists
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // Get the current value from the input
        const { value } = event.target;
        // Set a new timeout
        const newTimeoutId = setTimeout(() => {
            // Call your API function here with the updated value
            callApiForGetListAllUsers(value);
        }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
        // Update state to store the timeout ID
        setTimeoutId(newTimeoutId);
        // Update the input value state
        setSearchText(value);
    };




    const callApiForGetListAllUsers = async (searchText: string) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.recap.getTrustyAndCustomer;
        var jsonObj = {
            "search": searchText.split(/[^a-zA-Z0-9]+/)
                .filter(word => word.length > 0),
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, jsonObj, profileInStore)
            .then((response: any) => {
                console.log("response user:):)");
                console.log(response);
                let t = response as InterfaceGetAPITrustiesOrCustomers;
                setResponseAllUsers([...t.DLP, ...t.FLP, ...t.IND]);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsSearching(false);


    }
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside2 = (event: MouseEvent) => {
                if (containerRef2.current && !containerRef2.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    // {getSelectedUser?.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                    //     `${(getSelectedUser?.profile as InterfaceNTTProfile).first_name}  ${(getSelectedUser?.profile as InterfaceNTTProfile).last_name}`
                    // }
                    // {getSelectedUser?.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                    //     `${(getSelectedUser?.profile as InterfaceNTTProfileForeignLegal).name}`
                    // }
                    // {getSelectedUser?.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                    //     `${(getSelectedUser?.profile as InterfaceNTTProfileIranLegal).name} (${(getSelectedUser?.profile as InterfaceNTTProfileIranLegal).name_fa})`
                    // }
                    // if (getSelectedUser?.person_type.value === EnumProfileTypeNameInServer.individualProfile) {
                    //     setSearchText((getSelectedUser?.profile as InterfaceNTTProfile).first_name ?? "");
                    // }
                    // else if (getSelectedUser?.person_type.value === EnumProfileTypeNameInServer.foreignLegal) {
                    //     setSearchText((getSelectedUser?.profile as InterfaceNTTProfileForeignLegal).name ?? "");
                    // }
                    // else if (getSelectedUser?.person_type.value === EnumProfileTypeNameInServer.iranLegal) {
                    //     setSearchText((getSelectedUser?.profile as InterfaceNTTProfileForeignLegal).name ?? "");
                    // }

                };
            }

            document.addEventListener("mousedown", handleClickOutside2);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside2);
            };
        }
    }, [getIsFocused]);

    useEffect(() => {
        callApiForGetListAllUsers("");
    }, [])

    useEffect(() => {
        setSelectedUser(paramsComponent.defaultUser);
    },

        [paramsComponent.defaultUser])
    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
            ref={containerRef2}
        >
            <label style={{
                fontWeight: "300",
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                marginBottom: '0.22rem'
            }}>
                {paramsComponent.title}
            </label>
            <div
                style={{
                    background: Colors.theme.white.white90,
                    border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                    boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                    borderRadius: '0.4rem',
                    boxSizing: "border-box",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    overflow: "hidden",
                    justifyContent: "space-between",
                }}
                onClick={() => setIsFocused(true)}
            >
                {!getSelectedUser.realUserSelect && getSelectedUser.isAllSelected && !getIsFocused &&
                    <div>
                        <RowOfList
                            onClick={() => {
                                // You can clear text, close dropdown
                                setSearchText("");
                                setIsFocused(false);
                                // And call the parent's special callback
                                paramsComponent.onChangeSelectedUser({
                                    isAllSelected: true,
                                    realUserSelect: undefined
                                });
                            }}
                        >
                            {"All Customers"}
                        </RowOfList>
                        {getResponseAllUsers.length > 0 &&
                            <LineSeparator />
                        }
                        {/* If you want a separator */}
                        {/* <LineSeparator /> */}
                    </div>
                }
                {!getSelectedUser.isAllSelected && getSelectedUser.realUserSelect && !getIsFocused &&
                    <div>
                        <RowOfList onClick={() => {
                            setSearchText("");
                            setIsFocused(false);
                        }}>

                            {getSelectedUser.realUserSelect.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                `${(getSelectedUser.realUserSelect.profile as InterfaceNTTProfile).first_name}  ${(getSelectedUser.realUserSelect.profile as InterfaceNTTProfile).last_name}`
                            }
                            {getSelectedUser.realUserSelect.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                `${(getSelectedUser.realUserSelect.profile as InterfaceNTTProfileForeignLegal).name}`
                            }
                            {getSelectedUser.realUserSelect.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                `${(getSelectedUser.realUserSelect.profile as InterfaceNTTProfileIranLegal).name} (${(getSelectedUser.realUserSelect.profile as InterfaceNTTProfileIranLegal).name_fa})`
                            }
                            <div style={{
                                width: "1px",
                                height: "1rem",
                                marginInline: "0.5rem",
                                boxSizing: "border-box",
                                background: Colors.theme.blue.verticalSeparator,

                            }} />
                            {getSelectedUser.realUserSelect.is_customer &&
                                <TagOfUser style={{ color: Colors.theme.orange.light }}>
                                    {confComps.translate.customer}
                                </TagOfUser>
                            }
                            {getSelectedUser.realUserSelect.is_trusty &&
                                <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                    {confComps.translate.trusty}
                                </TagOfUser>
                            }
                            <div style={{
                                width: "1px",
                                height: "1rem",
                                marginInline: "0.5rem",
                                boxSizing: "border-box",
                                background: Colors.theme.blue.verticalSeparator,

                            }} />
                            <ArrayText texts={[
                                {
                                    text: confComps.translate.credit,
                                }, {
                                    text: getSelectedUser.realUserSelect.credit,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }
                                }, {
                                    text: confComps.panel.people.customerList.pageInfo.item.unit,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }
                                }]}
                            />
                            <div style={{
                                width: "1px",
                                height: "1rem",
                                marginInline: "0.5rem",
                                boxSizing: "border-box",
                                background: Colors.theme.blue.verticalSeparator,

                            }} />
                            <ArrayText texts={[
                                {
                                    text: confComps.panel.people.customerList.pageInfo.item.Balance,
                                }, {
                                    text: getSelectedUser.realUserSelect.balance,
                                    style: {
                                        color: getColorCodeOfBalanceNumber(getSelectedUser.realUserSelect.balance),
                                    }
                                }, {
                                    text: confComps.panel.people.customerList.pageInfo.item.unit,
                                    style: {
                                        color: getColorCodeOfBalanceNumber(getSelectedUser.realUserSelect.balance),
                                    }
                                }]}
                            />

                        </RowOfList>
                    </div>
                }
                {(getIsFocused || (!getSelectedUser.realUserSelect && !getSelectedUser.isAllSelected)) &&
                    <input
                        autoComplete="off"
                        type="text"
                        style={{
                            width: "100%",
                            paddingInline: '0.64rem',
                            paddingBlock: '0.32rem',
                            boxSizing: "border-box",
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: '1.12rem',
                            color: Colors.theme.blue.dark,
                            outline: 'none',
                            lineHeight: "1.68rem",
                        }}
                        value={getSearchText}
                        onChange={handleChangeInput}

                    />
                }
                <BottomTriangleIcon style={{
                    transform: getIsFocused ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s ease-in-out",
                    marginInlineEnd: "0.5rem",
                }} />
            </div>
            {getIsFocused &&
                <DropdownBox
                    isDropdownOpen={getIsFocused}
                >
                    {getStateIsSearching &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            <SpinnerIcon />
                        </div>
                    }
                    {!getStateIsSearching && (getResponseAllUsers.length === 0) &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            {confComps.translate.notFound}
                        </div>
                    }
                    {/* (NEW) If there's an onChangeSelectedAllUser callback, show "All Customers" row first */}
                    {paramsComponent.isAllUserActive && (
                        <>
                            <RowOfList
                                onClick={() => {
                                    // You can clear text, close dropdown
                                    setSearchText("");
                                    setIsFocused(false);
                                    // And call the parent's special callback
                                    paramsComponent.onChangeSelectedUser({
                                        isAllSelected: true,
                                        realUserSelect: undefined,
                                    });
                                }}
                            >
                                <input
                                    autoComplete="off"
                                    type="radio" checked={getSelectedUser.isAllSelected &&
                                        (paramsComponent.isAllUserActive !== undefined)}
                                    style={{
                                        margin: 0,
                                        boxSizing: "border-box", marginInlineEnd: "0.5rem",
                                    }}
                                />
                                {"All Customers"}
                            </RowOfList>
                            {getResponseAllUsers.length > 0 &&
                                <LineSeparator />
                            }
                            {/* If you want a separator */}
                            {/* <LineSeparator /> */}
                        </>
                    )}
                    {getResponseAllUsers.flatMap((user, index, arr) => {
                        let row = [
                            <RowOfList onClick={() => {
                                setSearchText("");
                                paramsComponent.onChangeSelectedUser({ realUserSelect: user, isAllSelected: false });
                                setIsFocused(false);
                            }}>
                                <input
                                    autoComplete="off"
                                    type="radio" checked={user.user.id === (getSelectedUser.realUserSelect?.user.id ?? -1)} style={{
                                        margin: 0,
                                        boxSizing: "border-box", marginInlineEnd: "0.5rem",
                                    }}
                                />
                                {/* {(user.profile.first_name.length === 0) ? "??" : user.profile.first_name}
                                {" "}
                                {user.profile.last_name} */}
                                {user?.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(user?.profile as InterfaceNTTProfile).first_name}  ${(user?.profile as InterfaceNTTProfile).last_name}`
                                }
                                {user?.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(user?.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {user?.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(user?.profile as InterfaceNTTProfileIranLegal).name} (${(user?.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }
                                <div style={{
                                    width: "1px",
                                    height: "1rem",
                                    marginInline: "0.5rem",
                                    boxSizing: "border-box",
                                    background: Colors.theme.blue.verticalSeparator,

                                }} />
                                {user.is_customer &&
                                    <TagOfUser style={{ color: Colors.theme.orange.light }}>
                                        {confComps.translate.customer}
                                    </TagOfUser>
                                }
                                {user.is_trusty &&
                                    <TagOfUser style={{ color: Colors.theme.blue.Secondary }}>
                                        {confComps.translate.trusty}
                                    </TagOfUser>
                                }
                                <div style={{
                                    width: "1px",
                                    height: "1rem",
                                    marginInline: "0.5rem",
                                    boxSizing: "border-box",
                                    background: Colors.theme.blue.verticalSeparator,

                                }} />
                                <ArrayText texts={[
                                    {
                                        text: confComps.translate.credit,
                                    }, {
                                        text: user.credit,
                                        style: {
                                            color: Colors.theme.blue.dark,
                                        }
                                    }, {
                                        text: confComps.panel.people.customerList.pageInfo.item.unit,
                                        style: {
                                            color: Colors.theme.blue.dark,
                                        }
                                    }]}
                                />
                                <div style={{
                                    width: "1px",
                                    height: "1rem",
                                    marginInline: "0.5rem",
                                    boxSizing: "border-box",
                                    background: Colors.theme.blue.verticalSeparator,

                                }} />
                                <ArrayText texts={[
                                    {
                                        text: confComps.panel.people.customerList.pageInfo.item.Balance,
                                    }, {
                                        text: user.balance,
                                        style: {
                                            color: getColorCodeOfBalanceNumber(user.balance),
                                        }
                                    }, {
                                        text: confComps.panel.people.customerList.pageInfo.item.unit,
                                        style: {
                                            color: getColorCodeOfBalanceNumber(user.balance),
                                        }
                                    }]}
                                />
                            </RowOfList>
                        ]
                        if ((index + 1) !== arr.length) {
                            row.push(<LineSeparator />)
                        }

                        // 

                        return row;
                    })
                    }
                </DropdownBox>


            }
        </div >

    );
}
export default SelectTrustyOrCustomer;