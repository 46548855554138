import TitleMedium18 from "../../../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import ParentRecapMoneyPayment
    from "../../../../recap/ParentRecapService/ParentRecapMoneyPayment/ParentRecapMoneyPayment";
import ParentRecapSellCottage from "../../../../recap/ParentRecapService/ParentRecapSellCottage/ParentRecapSellCottage";
import { MainCard } from "../../../../myProfile/MyProfileStyle";
import { useState } from "react";
import { InterfaceNTTRecapMain } from "../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, urlsList } from "../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../api/API";
import {
    interfaceResponseErrorFromServer,
    isAnySimilarInterfaceResponseErrorFromServer
} from "../../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { useTheme } from "@mui/material";
import BoxExportTrade from "./BoxExportTrade/BoxExportTrade";
import { InterfaceExportStepTrustyOrCustomer } from "./InterfaceExportStepTrustyOrCustomer";
import BoxExportAllTransaction from "./BoxExportAllTransaction/BoxExportAllTransaction";


function ExportStepTrustyOrCustomer(paramsComponent: InterfaceExportStepTrustyOrCustomer) {
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const dispatch = useDispatch();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const mTheme = useTheme();

    const callApiForCreateRecapMain = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.postCreateRecapMainInitiate;
        let bodyObj = {

        }

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                setRecapMain(response);
                // let t = response as InterfaceGetAPICustomersList;

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    return (
        <>
            <MainCard sx={{
                gap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "6vh" },
                display: 'flex', flexDirection: 'column', alignItems: 'start',
                justifyContent: 'start',
            }}>
                <TitleMedium18 style={{
                    boxSizing: "border-box",
                }}>
                    {confComps.translate.export}
                </TitleMedium18>
                {/* Export Slip */}
                {/* <BoxExportSlip
                /> */}
                {/* export trade */}
                <BoxExportTrade
                    userInfoBasic={paramsComponent.userInfoBasic}
                />
                <BoxExportAllTransaction
                    userInfoBasic={paramsComponent.userInfoBasic}
                />
                {/*Export ALL State Debit */}
                {/* <BoxExportAllStateDebit    
                /> */}
                {/* Export Wallet */}
                {/* <BoxExportWallet
                /> */}


            </MainCard>
        </>
    )
}

export default ExportStepTrustyOrCustomer;

