import { Grid, useTheme } from "@mui/material";
import { InterfaceSelectedItemForSelectCommon } from "../../Selector/SelectCommon/InterfaceSelectCommon";
import SelectCommon from "../../Selector/SelectCommon/SelectCommon";
import SelectUser from "../../Selector/SelectUser/SelectUser";
import { InterfaceCreateInnerBeneficiary } from "./InterfaceCreateInnerBeneficiary";
import { useState } from "react";
import { EnumInnerBeneficiaryOrderForStepTrade } from "../../../../screens/Deals/Trade/ShowStateTheTrade/ListStepsOfTrade/OneStepOfTrade/InterfaceCreateOneStepOfTrade";
import { interfaceUser } from "../../MentionTextarea/MentionTextarea";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import SelectEmployee from "../../Selector/SelectEmployee/SelectEmployee";
import ButtonCancel from "../../../basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../basic/Buttons/ButtonSave/ButtonSave";

function CreateInnerBeneficiary(paramsComponent: InterfaceCreateInnerBeneficiary) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getUserSelect, setUserSelect] = useState<interfaceUser>();
    const [getPosition, setPosition] = useState<EnumInnerBeneficiaryOrderForStepTrade>();
    return (
        <div style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            width: '100%'
        }}>
            <Grid container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectEmployee
                        title={"Beneficiary"}
                        defaultUser={getUserSelect}
                        onChangeSelectedUser={setUserSelect}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <SelectCommon
                        onChangeSelectStateDebt={(selected?: InterfaceSelectedItemForSelectCommon) => {
                            setPosition(selected?.serverNeeded);
                        }}
                        listItem={confComps.component.selectorCommon.SelectStateBeneficiary}
                        title={"Beneficiary type"}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                        marginBlock: "1rem",
                        width: "100%",
                        gap: "0.5rem",
                        position: "sticky",
                        bottom: "20px",
                    }}>
                        <ButtonCancel text={confComps.translate.cancel}
                            callbackOnClick={() => {
                                paramsComponent.callbackCancel();
                            }} />
                        <ButtonSave text={"Add"}
                            callbackOnClick={() => {
                                if (getPosition && getUserSelect) {
                                    paramsComponent.callbackSave({
                                        mediator_type: {
                                            label: "",
                                            value: getPosition
                                        },
                                        user: getUserSelect,
                                    });
                                }
                            }}
                        />


                    </div>
                </Grid>

            </Grid>

        </div>
    )
};
export default CreateInnerBeneficiary;