import { DownloadRounded, FileOpenRounded, UploadFileRounded } from "@mui/icons-material";
import { InterfaceListFilesForUploadToStepOfFreestyle } from "./InterfaceListFilesForUploadToStepOfFreestyle";
import { TrashIcon } from "../../../../../../../../assets/icons/svg/TrashIcon";
import Colors from "../../../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { getTypeOfFileFromLink } from "../../../../../../../../helper/Helper";
import { RequestHandler } from "../../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { EnumStateValueOfFreestyleStep } from "../../../../../../../../NTTs/Freestyle/InterfaceNTTStepOfFreestyle";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InterfaceFile } from "../../../../../../../../components/complex/ListFiles/InterfaceListFiles";
import ButtonCancel from "../../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";


function ListFilesForUploadToStepOfFreestyle(paramsComponent: InterfaceListFilesForUploadToStepOfFreestyle) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const callApiForAttachFileToThisStep = async (file: InterfaceFile, indexOfList: number) => {
        if (!file.file) {
            dispatch(ShowAlert({ text: `File is not exist!!`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.postAttachFileToThisStep;
        let DataObj = {
            "number": indexOfList + 1 + paramsComponent.step.attachments.length,
            "title": file.description,
            "amount": 0,
            "attachment": new File([file.file], file.title, { type: file.file.type }),
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.step.id}/attachments/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackUploadFiles(indexOfList);
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "start",
            gap: "1rem",
        }}>
            {paramsComponent.Files.map((file, index, arr) => {
                return (
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                        border: `1px solid ${Colors.theme.blue.border}`,
                        borderRadius: "0.8rem",
                        paddingInline: "1rem",
                        paddingBlock: "1.5rem",
                        width: "100%",
                        boxSizing: "border-box",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between",
                            width: "100%",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                gap: "1rem",
                            }}>
                                {!file.document && <UploadFileRounded style={{ color: Colors.theme.blue.blue }} />}
                                {file.document && <DownloadRounded style={{ color: Colors.theme.blue.blue }} />}
                                {file.file &&
                                    (<p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>{file.title.length > 0 ? `${(file.title)}${getTypeOfFileFromLink(file.file.name)}` : file.file.name}</p>)
                                }
                                {file.document &&
                                    (<p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>{file.title}{getTypeOfFileFromLink(file.document ?? "")}</p>
                                    )
                                }
                                <div style={{
                                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                    gap: "0.2rem",
                                }}>

                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.light,
                                    }}>
                                        {confComps.translate.description}
                                        {":  "}
                                    </p>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>

                                        {file.description}
                                    </p>
                                </div>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: '0.56rem' }}>
                                <ButtonCancel
                                    text={"Reject"}
                                    callbackOnClick={() => {
                                        paramsComponent.callbackRemoveFiles(index);
                                    }}
                                    iconStart={<TrashIcon
                                        style={{
                                            fontSize: '1.1rem', marginBottom: '-5%',
                                            color: Colors.theme.orange.sharp,
                                        }}
                                    />}
                                    style={{
                                        background: "#fff",
                                        color: Colors.theme.orange.sharp,
                                        borderColor: `${Colors.theme.orange.sharp}`,
                                    }
                                    }
                                />
                                <ButtonSave
                                    text={"Upload"}
                                    callbackOnClick={() => {
                                        callApiForAttachFileToThisStep(file, index);
                                    }}
                                    iconStart={<UploadFileRounded
                                        style={{
                                            fontSize: '1.1rem',
                                            color: Colors.theme.green.dark,
                                        }} />
                                    }
                                    style={{
                                        background: "#fff",
                                        color: Colors.theme.green.dark,
                                        borderColor: `${Colors.theme.green.dark}`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    );
}
export default ListFilesForUploadToStepOfFreestyle;