import { useNavigate } from "react-router-dom";
import { InterfaceAllServiceOfFinalRecap, InterfaceCreateTrade, InterfaceFinalRecap, InterfaceGetAPIFinalRecapsList } from "./InterfaceCreateTrade";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { MainCard } from "../../../myProfile/MyProfileStyle";
import { useEffect, useState } from "react";
import { EnumStateStepGroup } from "../../../../components/basic/ArrowStep/InterfaceArrowStep";
import SteperGroups from "../../../../components/complex/SteperGuide/SteperGroup/SteperGroup";
import SteperGuide from "../../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../api/UrlsList";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { InterfacePaginationInServer, InterfaceSearchInServer } from "../../../../api/interfaces/InterfaceSearchInServer";
import { EnumStateFetchList } from "../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfaceAdvanceSearchParams } from "../../../../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import { InterfaceItemOfSorting } from "../../../../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceGetAPIRecapsList } from "../../../recap/RecapsList/InterfaceRecapsList";
import AddButtonWithShadowEffect from "../../../../components/basic/Buttons/AddButtonWithShadowEffect/AddButtonWithShadowEffect";
import InfiniteScrollList from "../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import AdvanceSearchBox from "../../../../components/complex/advanceSearchBox/AdvanceSearchBox";
import SortingBox from "../../../../components/complex/SortingBox/SortingBox";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import Colors from "../../../../config/Colors";
import Links from "../../../../config/Links";
import RowRecap from "../../../recap/RecapsList/RowRecap/RowRecap";
import RowFinalRecap from "./RowFinalRecap/RowFinalRecap";
import MergeSelectedRecaps from "./MergeSelectedRecaps/MergeSelectedRecaps";
import TextFieldSimple from "../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import SteperGuideForTrade from "../../../../components/complex/SteperGuide/SteperGuideForTrade/SteperGuideForFreestyle";
import SubTitleSection from "../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import ButtonCancel from "../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { EnumSTepsParent, EnumStepsTrade } from "../../../../config/enums/EnumSteps";

function CreateTrade(paramsComponent: InterfaceCreateTrade) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getTitle, setTitle] = useState<string>("");
    const [getDescription, setDescription] = useState<string>("");



    const callApiForCreateTradeMain = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.trade.postCreateTradeInitiate;
        let bodyObj = {
            title: getTitle,
            description: getDescription,
            recipients: [],
            recaps: [],
        }

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // setTradeMain(response);
                // let t = response as InterfaceGetAPICustomersList;
                navigate(Links.screens.panel.deals.trade.ShowStateTheTrade + `${response.id}`)

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    return (
        <ContainerPage title={confComps.translate.newTrade}>
            <SteperGuideForTrade
                steps={confComps.steperGuide.trade.steps}
                callbackOnClickOneStep={(keySelected: string) => {
                    if (keySelected !== `${EnumSTepsParent.trade}.${EnumStepsTrade.creator}` && keySelected !== `${EnumSTepsParent.trade}`) {
                        dispatch(ShowAlert({ text: confComps.translate.youMustCreateAtFirst, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                    }
                }}
                callbackOnClickOneGroup={(keySelected: string) => {
                }}
                keyOfGroupThatSelected={`${EnumSTepsParent.trade}`}
                keyOfOneStepThatSelected={`${EnumSTepsParent.trade}.${EnumStepsTrade.creator}`}
                trade={undefined}
            />
            <div style={{
                display: "flex", boxSizing: "border-box", flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                width: "100%", flexGrow: 1,
                background: Colors.theme.white.white80,
                border: `1px solid ${Colors.theme.white.white70}`,
                padding: "1.6rem",
                borderRadius: "0.32rem",
            }}
            >
                <Box sx={{
                    display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                    width: "100%",
                    boxSizing: "border-box",
                    paddingInline: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
                    border: `1px solid ${Colors.theme.blue.border}`,
                    borderRadius: "0.4rem",
                    position: "sticky",
                    top: "20px",
                    zIndex: 2,
                    background: "white",
                }}>
                    <Box sx={{
                        boxSizing: "border-box",
                        width: "100%",
                        marginBlockEnd: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
                    }}>
                        <SubTitleSection title={"Create New Trade"} />
                        <Box sx={{
                            marginInline: { xs: 1, sm: 2, md: 1, lg: 1.5, xl: 2 },
                            // marginInlineStart: { xs: 1, sm: 2, md: 1, lg: 1.5, xl: 2 },
                            // marginInlineEnd: { xs: 1, sm: 2, md: 5, lg: 12, xl: "10vw" },
                            boxSizing: "border-box",
                        }}>
                            <Grid container
                                justifyContent="space-between" alignItems="stretch"
                                // columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 7, xl: "9vw" }}
                                // rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}
                                columnSpacing={{ xs: '1.72rem', sm: '1.9rem', md: '2.17rem', lg: "2.37rem", xl: "2.57rem" }}
                                rowSpacing={{ xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" }}
                                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                            >
                                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                    <TextFieldSimple
                                        title={confComps.translate.title}
                                        textValue={getTitle}
                                        type="text"

                                        onChangeValue={setTitle}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <TextFieldMultiLine
                                        title={confComps.translate.explanation}
                                        text={getDescription}
                                        onChangeText={setDescription}
                                        styleParent={{
                                            boxSizing: "border-box",
                                            border: `1px solid ${Colors.theme.blue.border}`,
                                            background: Colors.theme.white.white90,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div style={{
                                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                                        marginBlock: "1rem",
                                        width: "100%",
                                        gap: "0.5rem",
                                        position: "sticky",
                                        bottom: "20px",
                                    }}>
                                        <ButtonCancel text={confComps.translate.cancel}
                                            callbackOnClick={() => {
                                                navigate(-1);
                                            }} />
                                        <ButtonSave text={"Create Trade"}
                                            callbackOnClick={() => {
                                                callApiForCreateTradeMain();
                                            }} />


                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box >
                </Box>
            </div>

            {/* </MainCard> */}
        </ContainerPage>

    );
}
export default CreateTrade;
