import { useNavigate, useParams } from "react-router-dom";
import { InterfaceShowStateTheFreestyle } from "./InterfaceShowStateTheFreestyle";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { MainCard } from "../../../myProfile/MyProfileStyle";
import { useEffect, useState } from "react";

import SteperGuide from "../../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { EnumStateValueOfFreestyle, InterfaceNTTFreestyleMain } from "../../../../NTTs/Freestyle/InterfaceNTTFreestyleMain";
import SteperGuideForFreestyle from "../../../../components/complex/SteperGuide/SteperGuideForFreestyle/SteperGuideForFreestyle";
import PersonListForSenderAndRecipient from "../../../../components/complex/PersonListForSenderAndRecipient/PersonListForSenderAndRecipient";
import MergeSelectedRecaps from "../CreateFreestyle/MergeSelectedRecaps/MergeSelectedRecaps";
import SelectedSolutionsPreview from "../../../../components/complex/recaps/SelectedSolutionsPreview/SelectedSolutionsPreview";
import CreateOneStepOfFreestyle from "./ListStepsOfFreestyle/OneStepOfFreestyle/CreateOneStepOfFreestyle";
import ListStepsOfFreestyle from "./ListStepsOfFreestyle/ListStepsOfFreestyle";
import ParentWithHeightAnimation from "../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { AddRounded, BackHandRounded, DoneRounded } from "@mui/icons-material";
import { EnumStepsFreestyle, EnumSTepsParent } from "../../../../config/enums/EnumSteps";
import { EnumStateValueOfFreestyleStep } from "../../../../NTTs/Freestyle/InterfaceNTTStepOfFreestyle";
import ButtonCancel from "../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
function ShowStateTheFreestyle(paramsComponent: InterfaceShowStateTheFreestyle) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const { idMainFreestyle } = useParams();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("2");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    const [getFreestyleMain, setFreestyleMain] = useState<InterfaceNTTFreestyleMain>();
    const [getIsLoading, setIsLoading] = useState<boolean>(true);
    const [getIsActiveCreation, setIsActiveCreation] = useState<boolean>(false);

    const callApiForRetrieveTheFreestyle = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.getFreestyleInitiate;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainFreestyle}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTFreestyleMain;
                setFreestyleMain(t);
                setIsLoading(false);
                if (t.state.value === EnumStateValueOfFreestyle.created) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.refinement) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.pendingForCheckByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.approval}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.approvedByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.completed) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.calculatePANDL}`)
                }

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForUpdateStateOfThisFreeStyle = async (stateValue: EnumStateValueOfFreestyle) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.updateFreestyleInitiate;
        let DataObj = {
            "state": stateValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainFreestyle}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTFreestyleMain;
                setFreestyleMain(t);
                setIsLoading(false);
                if (t.state.value === EnumStateValueOfFreestyle.created) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.refinement) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.pendingForCheckByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.approval}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.approvedByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}`)
                }
                else if (t.state.value === EnumStateValueOfFreestyle.completed) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.calculatePANDL}`)
                }

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        callApiForRetrieveTheFreestyle();
    }, [])

    return (
        <ContainerPage title={confComps.translate.freestyle}
            isLoading={getIsLoading}>
            <SteperGuideForFreestyle
                steps={confComps.steperGuide.freestyle.steps}
                callbackOnClickOneStep={(keySelected: string) => {
                    console.log(keySelected);
                    setKeyOfStepThatSelected(keySelected);

                }}
                callbackOnClickOneGroup={(keySelected: string) => {
                    setKeyOfGroupThatSelected(keySelected)
                }}
                keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                keyOfOneStepThatSelected={getKeyOfStepThatSelected}
                freestyle={getFreestyleMain}
            />

            <MainCard sx={{
                gap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.77vh" },
                display: 'flex', flexDirection: 'column', alignItems: 'start',
                justifyContent: 'start',
            }}>
                <TitleMedium18 style={{
                    boxSizing: "border-box",
                }}>
                    {confComps.translate.freestyle}
                </TitleMedium18>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '100%',
                    gap: '0.2rem',
                }}>
                    <PersonListForSenderAndRecipient
                        title={confComps.translate.senders}
                        persons={getFreestyleMain?.senders ?? []}
                    />
                    <PersonListForSenderAndRecipient
                        title={confComps.translate.recipients}
                        persons={getFreestyleMain?.recipients ?? []}
                    />
                    <SelectedSolutionsPreview recaps={getFreestyleMain?.recaps ?? []} />
                    {getFreestyleMain &&
                        <ListStepsOfFreestyle
                            freestyle={getFreestyleMain}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatSelected={getKeyOfStepThatSelected}
                            callbackOnClickDeleteOneStep={() => {
                                callApiForRetrieveTheFreestyle();
                            }}
                            callbackReload={callApiForRetrieveTheFreestyle}
                        />
                    }
                    <ParentWithHeightAnimation in={getIsActiveCreation} style={{ width: '100%' }}>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                        }}>
                            {getFreestyleMain &&
                                <CreateOneStepOfFreestyle
                                    freestyle={getFreestyleMain}
                                    isShowThisBox={getIsActiveCreation}
                                    createStep={{
                                        callbackOnClickCancel: () => {
                                            setIsActiveCreation(false);
                                        },
                                        callbackOnClickStepAdded: () => {
                                            callApiForRetrieveTheFreestyle();
                                            setIsActiveCreation(false);
                                        }
                                    }}
                                />
                            }
                        </div>
                    </ParentWithHeightAnimation>
                    {/* this box for creation step */}
                    {!getIsActiveCreation &&
                        getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}` &&//selected step in guide step is add step
                        getFreestyleMain?.state.value === EnumStateValueOfFreestyle.created &&
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between", width: "100%"
                        }}>
                            <ButtonSave text={"Add New Step"}
                                callbackOnClick={() => {
                                    setIsActiveCreation(true);
                                }}
                                iconStart={<AddRounded />}
                            />
                            <ButtonSave text={"Submit All Step To Manager"}
                                callbackOnClick={() => {
                                    callApiForUpdateStateOfThisFreeStyle(EnumStateValueOfFreestyle.pendingForCheckByManager);
                                }}
                                iconStart={<DoneRounded />}
                            />
                        </div>
                    }
                    {/* this box for refinement step */}
                    {!getIsActiveCreation &&
                        getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}` &&//selected step in guide step is add step
                        getFreestyleMain?.state.value === EnumStateValueOfFreestyle.refinement &&
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between", width: "100%"
                        }}>
                            <ButtonSave text={"Add New Step"}
                                callbackOnClick={() => {
                                    setIsActiveCreation(true);
                                }}
                                iconStart={<AddRounded />}
                            />
                            <ButtonSave text={"Resend these Step"}
                                callbackOnClick={() => {
                                    callApiForUpdateStateOfThisFreeStyle(EnumStateValueOfFreestyle.pendingForCheckByManager);
                                }}
                                iconStart={<DoneRounded />}
                            />
                        </div>
                    }
                    {!getIsActiveCreation &&  // this box for approval step
                        getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.approval}` &&//selected step in guide step is add step
                        getFreestyleMain?.state.value === EnumStateValueOfFreestyle.pendingForCheckByManager &&
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "end", width: "100%",
                            gap: '0.56rem',
                        }}>


                            <ButtonCancel text={"Reject"}
                                callbackOnClick={() => {
                                    callApiForUpdateStateOfThisFreeStyle(EnumStateValueOfFreestyle.refinement);
                                }}
                                iconStart={<BackHandRounded
                                    style={{ fontSize: '1rem' }} />}
                            />
                            <ButtonSave
                                style={{
                                    opacity: (getFreestyleMain.steps.filter(step => step.state.value === EnumStateValueOfFreestyleStep.approvedByManager).length === getFreestyleMain.steps.length) ?
                                        1 : 0.5,
                                }}
                                text={"Approve All Step"}
                                callbackOnClick={() => {
                                    if ((getFreestyleMain.steps.filter(step => step.state.value === EnumStateValueOfFreestyleStep.approvedByManager).length === getFreestyleMain.steps.length)) {
                                        callApiForUpdateStateOfThisFreeStyle(EnumStateValueOfFreestyle.approvedByManager);
                                    }
                                    else {
                                        dispatch(ShowAlert({ text: `You must approve All Step`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                                    }
                                }}
                                iconStart={<DoneRounded />}
                            />
                        </div>
                    }
                    {/* add attachment */}
                    {!getIsActiveCreation &&
                        getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}` &&//selected step in guide step is add step
                        getFreestyleMain?.state.value === EnumStateValueOfFreestyle.approvedByManager &&
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "end", width: "100%",
                            gap: '0.56rem',
                        }}>
                            <ButtonSave
                                style={{
                                    opacity: (getFreestyleMain.steps.filter(step => step.state.value === EnumStateValueOfFreestyleStep.completed).length === getFreestyleMain.steps.length) ?
                                        1 : 0.5,
                                }}
                                text={"Complete"}
                                callbackOnClick={() => {
                                    if ((getFreestyleMain.steps.filter(step => step.state.value === EnumStateValueOfFreestyleStep.completed).length === getFreestyleMain.steps.length)) {
                                        callApiForUpdateStateOfThisFreeStyle(EnumStateValueOfFreestyle.completed);
                                    }
                                    else {
                                        dispatch(ShowAlert({ text: `You must add file to any step!`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                                    }
                                }}
                                iconStart={<DoneRounded />}
                            />
                        </div>
                    }
                </div>
            </MainCard>
        </ContainerPage >

    );
}
export default ShowStateTheFreestyle;
