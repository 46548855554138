import React, { useEffect, useRef, useState } from 'react';
import Colors from "../../../../../config/Colors";
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../../../store/profileStateSlice';
import { keyframes, styled, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigComps } from '../../../../../config/ConfigCOMP';
import { isVisibilityProgressBar } from '../../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../../api/UrlsList';
import { RequestHandler } from '../../../../../api/API';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../../MUIConfig/alert_popup/interfaceAlertPopup';

import { InterfaceNTTRoleEmployee } from '../../../../../NTTs/InterfaceNTTRoleEmployee';
import { SyncRounded } from '@mui/icons-material';
import LineSeparator from '../../../LineSeprator/LineSeparator';
import AutoCompleteWithUnderLine from '../../../../complex/AutoCompleteWithUnderLine/AutoCompleteWithUnderLine';
import { InterfaceTextFieldMultiSelectorCurrencyUnitSimple } from './InterfaceTextFieldMultiSelectorCurrencyUnitSimple';
import { InterfaceNTTCurrency, InterfaceNTTCurrencyWithTypeAndLocation } from '../../../../../NTTs/InterfaceNTTCurrency';


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ParentDiv = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // borderRadius: ConstStyle.borderRadius,
    fontSize: "1rem",
    flexGrow: 1,
    position: "relative",
    // boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
    borderRadius: "500px",
    paddingBlockStart: "0.32rem",
    boxSizing: "border-box",

    boxShadow: Colors.shadow.shadowEditTextBoxNC,
    "&:hover": {
        boxShadow: Colors.shadow.shadowEditTextBoxClicked,
    }

}));

function TextFieldMultiSelectorCurrencyUnitSimple(componentParams: InterfaceTextFieldMultiSelectorCurrencyUnitSimple) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const [getIsFocused, setIsFocused] = useState(false);

    const [getCurrenciesSelected, setCurrenciesSelected] = useState<number[]>([]);
    const [getResponseAllCurrency, setResponseAllCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation[]>([]);
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);


    const callApiForGetListCurrencies = async (searchText?: string) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.currency.getCurrenciesWidthTypeAndLocation;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response user:):)");
                console.log(response);
                let t = response as (InterfaceNTTCurrencyWithTypeAndLocation[]);
                setResponseAllCurrency(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsSearching(false);
    }

    useEffect(() => {
        callApiForGetListCurrencies();
    }, [])
    useEffect(() => {
        componentParams.selectedCurrencies && setCurrenciesSelected(componentParams.selectedCurrencies)
    }, [componentParams.selectedCurrencies]);

    useEffect(() => {
        componentParams.onChangeSelectedCurrency(getCurrenciesSelected);
    }, [getCurrenciesSelected])
    return (
        <ParentDiv
            style={{
                ...componentParams.styleMain,
            }}
        >
            {componentParams.iconStart && (
                <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                    <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                </div>
            )}
            <div style={{
                flex: 1, paddingInlineEnd: "1rem",
                paddingInlineStart: "0.32rem",
                boxSizing: "border-box",
            }}>
                <AutoCompleteWithUnderLine
                    valuesList={getResponseAllCurrency.map(currency => {
                        return {
                            title: (currency.is_cash ? "Cash" : "None Cash") + " | " + currency.country.name + " | " + currency.currency.title,
                            id: currency.id,
                        }
                    })}

                    onChangeSelectedItem={(items) => {
                        componentParams.onChangeSelectedCurrency(items.map((item, index, arr) => {
                            return ({
                                ...getResponseAllCurrency.filter(ac => ac.id === item.id)[0]
                            })
                        }).map(select => select.id));
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getResponseAllCurrency.filter(role => getCurrenciesSelected.filter(idSelect => idSelect === role.id).length > 0)
                        .map((currency) => {
                            return {
                                title: (currency.is_cash ? "Cash" : "None Cash") + " | " + currency.country.name + " | " + currency.currency.title,
                                id: currency.id,
                            }
                        }
                        )
                    }

                />
            </div>
            {
                <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                    {
                        (componentParams.iconEnd && (
                            <componentParams.iconEnd
                                style={{
                                    background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    width: "0.9rem",
                                }} />
                        ))
                    }
                </div>
            }
        </ParentDiv>
    );
}

export default TextFieldMultiSelectorCurrencyUnitSimple;
