import { Person2Rounded } from "@mui/icons-material";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { ImageSquare } from "../../../../../../components/complex/imageSquare/ImageSquare";
import {DivImageProfile, DivNameAndAddressOfProfile} from "../../../../../myProfile/MyProfileStyle";
import { InterfaceCustomerProfileHeader } from "./InterfaceCustomerProfileHeader";
import { EditPenCircle } from "../../../../../../assets/icons/svg/EditPenCircleIcon";
import Colors from "../../../../../../config/Colors";
import { PinLocationIcon } from "../../../../../../assets/icons/svg/PinLocationIcon";
import ArrayText from "../../../../../../components/basic/Texts/ArrayText/ArrayText";
import { getColorCodeOfBalanceNumber } from "../../../../../../helper/Helper";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { styled, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import DialogAcceptProcess from "../../../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { InterfaceUploadFileOfProfile } from "../../../Trusties/TrustyProfile/TrustyProfileHeader/TrustyProfileHeader";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import FilesDragAndDrop from "../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { InterfaceNTTProfile } from "../../../../../../NTTs/InterfaceNTTProfile";
import { EnumProfileTypeNameInServer } from "../../../../../../NTTs/InterfaceNTTProfileType";
import { InterfaceNTTProfileForeignLegal } from "../../../../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../../../../NTTs/InterfaceNTTProfileIranLegal";
export const HeaderParent = styled('div')(({ theme }) => ({
    width: "100%",
    boxSizing: "border-box",
    paddingInline: "1.44rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "stretch",
    gap: "0.8rem",
    // display: "flex", justifyContent: "start", alignItems: "stretch",
    // boxSizing: "border-box",
    //     //
    marginBottom: "0.52rem",
    //
    // marginBottom: "0.52rem",
    [theme.breakpoints.up('xs')]: {
        flexDirection: "column",
        alignItems: "center",
        paddingInline: "0.44rem",
        // gap: "0.8rem",
        gap: "2.56rem",
        marginBottom: "0.52rem",
        justifyContent: "start",
        // alignItems: "stretch",
        // alignItems: "stretch",

    },
    [theme.breakpoints.up('sm')]: {
        paddingInline: "1.44rem",
        gap: "1.16rem",
    },
    [theme.breakpoints.up('md')]: {
        paddingInline: "2.74rem",
        gap: "1.26rem",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "stretch",

    },
    [theme.breakpoints.up('lg')]: {
        paddingInline: "2.84rem",
        gap: "2.06rem",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "stretch",
    },
    [theme.breakpoints.up('xl')]: {
        paddingInline: "3.84rem",
        gap: "1vw",
    },
}));
function CustomerProfileHeader(paramsComponent: InterfaceCustomerProfileHeader) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getWalletSelected, setWalletSelected] = useState<boolean>(false);
    const [getFileForImageProfile, setFileForImageProfile] = useState<InterfaceUploadFileOfProfile>();
    const callApiForUploadImageOfProfile = async () => {
        if (!getFileForImageProfile?.candidaFile?.file) {
            dispatch(isVisibilityProgressBar(false));
            return;
        }
        dispatch(isVisibilityProgressBar(true));


        let urlObj = urlsList.panel.trusty.postAddProfileImageTrusty;

        const formData = new FormData();
        formData.append("profile_pic", getFileForImageProfile.candidaFile.file);

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfo?.profile.id}/profile-pic/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, formData, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                setFileForImageProfile(prev => { return ({ candidaFile: undefined, savedFileUrl: prev?.candidaFile?.urlServer }) });
                // paramsComponent.
                dispatch(ShowAlert({ text: confComps.translate.updatedProfile, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setWalletSelected(paramsComponent.isWalletSelected);
    }, [paramsComponent.isWalletSelected]);
    useEffect(() => {
        if (paramsComponent.userInfo?.profile.profile_pic) {
            setFileForImageProfile({ candidaFile: undefined, savedFileUrl: `${baseUrl}${paramsComponent.userInfo.profile.profile_pic}` })
        }
    }, [paramsComponent.userInfo?.profile.profile_pic])

    useEffect(() => {
        if (getFileForImageProfile && getFileForImageProfile.candidaFile && getFileForImageProfile.candidaFile.file) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setFileForImageProfile(prev => {
                    return ({
                        ...prev,
                        candidaFile: { urlServer: event.target.result as string, file: prev?.candidaFile?.file }
                    })
                });
            };

            reader.readAsDataURL(getFileForImageProfile.candidaFile.file);
        }
    }, [getFileForImageProfile, getFileForImageProfile?.candidaFile]);

    return (
        <HeaderParent style={{
        }}>
            <DivImageProfile>
                <FilesDragAndDrop
                    onUpload={(files) => {
                        files.forEach((value) => {
                            setFileForImageProfile(prev => {
                                return (
                                    { ...prev, candidaFile: { file: value } }
                                )
                            });
                        })
                    }}
                    count={1}
                    formats={['jpg', 'png']}
                    containerStyles={{
                        borderRadius: "4px",
                        boxSizing: "border-box",
                    }}
                    openDialogOnClick
                >
                    <ImageSquare width={"100%"}
                        borderRadius="500px"
                        style={{ position: "relative", overflow: "visible", boxSizing: "border-box" }}
                        iconSvg={getFileForImageProfile?.savedFileUrl ? undefined : Person2Rounded}
                        img={getFileForImageProfile?.savedFileUrl}
                        element={
                            <EditPenCircle style={{
                                zIndex: 5, borderRadius: "500px", background: "#fff",
                                position: "absolute",
                                width: "16",
                                height: "16",
                                transform: "translate(50%,50%)",
                                bottom: "14.64466094%", right: "14.64466094%",

                            }}
                            />

                        }
                        elementStyle={{
                            zIndex: 4,
                            position: "absolute",
                            width: "28px",
                            transform: "translate(0,0)",
                            top: "100px", left: "100%",
                        }}
                        border={`2px solid ${Colors.theme.blue.blue}`}
                        color={Colors.theme.blue.light}
                        background={Colors.theme.white.PrimaryLight}
                    />
                </FilesDragAndDrop>
            </DivImageProfile>
            <DivNameAndAddressOfProfile>
                <p style={{
                    color: Colors.theme.blue.dark, fontSize: "1.6rem", lineHeight: "2.4rem",
                    fontFamily: confComps.font.poppins.Medium,
                    fontWeight: "200"
                }}>
                    {paramsComponent.userInfo?.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                        `${(paramsComponent.userInfo?.profile as InterfaceNTTProfile).first_name}  ${(paramsComponent.userInfo?.profile as InterfaceNTTProfile).last_name}`
                    }
                    {paramsComponent.userInfo?.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                        `${(paramsComponent.userInfo?.profile as InterfaceNTTProfileForeignLegal).name}`
                    }
                    {paramsComponent.userInfo?.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                        `${(paramsComponent.userInfo?.profile as InterfaceNTTProfileIranLegal).name} (${(paramsComponent.userInfo?.profile as InterfaceNTTProfileIranLegal).name_fa})`
                    }
                </p>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "0.32rem" }}>
                    <PinLocationIcon style={{ width: "1.2rem", height: "1.2rem", marginTop: "0.3rem" }}/>
                    <p style={{
                        color: Colors.theme.blue.light, fontSize: "1.28rem", lineHeight: "1.92rem",
                        fontFamily: confComps.font.poppins.Regular,
                    }}>
                        {paramsComponent.userInfo?.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                            `${(paramsComponent.userInfo?.profile as InterfaceNTTProfile).address}`
                        }
                        {paramsComponent.userInfo?.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                            `${(paramsComponent.userInfo?.profile as InterfaceNTTProfileForeignLegal).city}, ${(paramsComponent.userInfo?.profile as InterfaceNTTProfileForeignLegal).country}`
                        }
                        {paramsComponent.userInfo?.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                            `${(paramsComponent.userInfo?.profile as InterfaceNTTProfileIranLegal).address}`
                        }
                    </p>
                </div>
            </DivNameAndAddressOfProfile>
            {/*<div style={{*/}
            {/*    display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between",*/}
            {/*    flexGrow: "1",*/}
            {/*    paddingBlock: "1.28rem",*/}

            {/*}}>*/}
                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                    flexGrow: "1",
                    height: "100%",
                }}>
                    <ArrayText texts={[
                        {
                            text: confComps.panel.people.customerList.pageInfo.item.credit,
                            style: {
                                fontSize: "1.28rem",
                                lineHeight: "1.92rem",
                            }
                        }, {
                            text: paramsComponent.userInfo?.credit ?? "",
                            style: {
                                color: Colors.theme.blue.dark,
                                fontSize: "1.28rem",
                                lineHeight: "1.92rem",
                            }
                        }, {
                            text: confComps.panel.people.customerList.pageInfo.item.unit,
                            style: {
                                color: Colors.theme.blue.dark,
                                fontSize: "1.28rem",
                                lineHeight: "1.92rem",
                            }
                        }]}
                    />
                    <ArrayText texts={[
                        {
                            text: confComps.panel.people.customerList.pageInfo.item.Balance,
                            style: {
                                fontSize: "1.28rem",
                                lineHeight: "1.92rem",
                            }
                        },
                        {
                            text: paramsComponent.userInfo?.balance ?? "",
                            style: {
                                color: getColorCodeOfBalanceNumber(paramsComponent.userInfo?.balance ?? ""),
                                fontSize: "1.28rem",
                                lineHeight: "1.92rem",
                            }
                        }, {
                            text: confComps.panel.people.customerList.pageInfo.item.unit,
                            style: {
                                color: getColorCodeOfBalanceNumber(paramsComponent.userInfo?.balance ?? ""),
                                fontSize: "1.28rem",
                                lineHeight: "1.92rem",
                            }
                        }]}
                    />


                </div>
                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
                    flexGrow: "1",
                    height: "100%",
                }}>
                    <div
                        style={{
                            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", boxSizing: "border-box",
                            paddingInline: "1.6rem",
                            paddingBlock: "0.32rem",
                            borderRadius: "500px",
                            cursor: "pointer",
                            color: getWalletSelected ? "white" : Colors.theme.blue.blue,
                            background: getWalletSelected ? Colors.theme.blue.blue : "white",
                            border: `1px solid ${Colors.theme.blue.blue}`,
                        }}
                        onClick={paramsComponent.callbackSelectWallet}
                    >

                        {confComps.panel.people.customerInfo.pageInfo.header.buttonWallet}

                    </div>
                </div>
            {/*</div>*/}
            <DialogAcceptProcess
                callbackClickAccept={function (): void {
                    console.log("safa");
                    callApiForUploadImageOfProfile();
                    setFileForImageProfile(prev => { return ({ ...prev, savedFileUrl: prev?.candidaFile?.urlServer }) })
                }}
                callbackClickReject={function (): void {

                    setFileForImageProfile(prev => { return ({ ...prev, candidaFile: undefined }) })
                }}
                isOpen={getFileForImageProfile?.candidaFile?.file !== undefined}
                title={confComps.translate.uploadPictureForProfile}
                description={confComps.translate.areYouWantThatChangeImageOfProfile}
            >
                <ImageSquare
                    width={"max(10vw , 200px)"}
                    borderRadius="500px"
                    style={{ position: "relative", overflow: "visible", boxSizing: "border-box" }}
                    iconSvg={getFileForImageProfile?.candidaFile?.urlServer ? undefined : Person2Rounded}
                    img={getFileForImageProfile?.candidaFile?.urlServer}
                    elementStyle={{
                        zIndex: 4,
                        position: "absolute",
                        width: "28px",
                        transform: "translate(0,0)",
                        top: "100px", left: "100%",
                    }}
                    border={`2px solid ${Colors.theme.blue.blue}`}
                    color={Colors.theme.blue.light}
                    background={Colors.theme.white.PrimaryLight}
                />
            </DialogAcceptProcess>
        </HeaderParent>
    )
};
export default CustomerProfileHeader;