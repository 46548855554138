import React, { useEffect, useRef, useState } from 'react';
import Colors from "../../../../../config/Colors";
import { InterfaceTextFieldMultiSelectActivity } from './InterfaceTextFieldMultiSelectActivity';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../../../store/profileStateSlice';
import { Checkbox, keyframes, styled, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigComps } from '../../../../../config/ConfigCOMP';
import { isVisibilityProgressBar } from '../../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../../api/UrlsList';
import { RequestHandler } from '../../../../../api/API';
import { InterfaceNTTActivity } from '../../../../../NTTs/InterfaceNTTActivity';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../../MUIConfig/alert_popup/interfaceAlertPopup';
import AutoCompleteWithUnderLine from '../../../../complex/AutoCompleteWithUnderLine/AutoCompleteWithUnderLine';
import { BottomTriangleIcon } from '../../../../../assets/icons/svg/BottomTriangleIcon';
import { CancelRounded, CloseRounded, KeyboardArrowRight, RadioButtonChecked, RadioButtonUnchecked, SyncRounded } from '@mui/icons-material';
import useChange from '../../../../../helper/UseChange';
import { InterfaceNTTEmployee } from '../../../../../NTTs/InterfaceNTTEmployee';
import { EnumProfileTypeNameInServer } from '../../../../../NTTs/InterfaceNTTProfileType';
import LineSeparator from '../../../LineSeprator/LineSeparator';
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "1.12rem",
    lineHeight: "1.68rem",
    width: "100%",
    paddingInline: "1rem",
    paddingBlock: "0.32rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
function TextFieldMultiSelectActivity(componentParams: InterfaceTextFieldMultiSelectActivity) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const { role } = useParams();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [getIsFocused, setIsFocused] = useState(false);
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const [getActivitySelected, setActivitySelected] = useState<InterfaceNTTActivity[]>([]);
    const [getResponseAllActivity, setResponseAllActivity] = useState<InterfaceNTTActivity[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [getParentActivities, setParentActivities] = useState<InterfaceNTTActivity[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const getApiListActivity = async (searchText: string, parentState?: number,) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.common.activity.getActivity;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, { search: searchText, ...((parentState !== undefined) && { parent: parentState }) }),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTActivity[]);
                setResponseAllActivity(t);
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });

        setStateIsSearching(false);


    }
    useEffect(()=>{
        getApiListActivity("", 0);
    },[])
    useChange(() => {
        if (getParentActivities.length === 0) {
            getApiListActivity("", 0);
        }
        else {
            getApiListActivity("", getParentActivities.at(getParentActivities.length - 1)?.id);
        }
    }, [getParentActivities]);
    useChange(() => {
        console.log("list activity that update:)", componentParams.selectedActivities);
        setActivitySelected(componentParams.selectedActivities);
        setParentActivities([]);
    }, [componentParams.selectedActivities]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("call changed", event.target.value)
        // Clear the previous timeout if it exists
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // Get the current value from the input
        const { value } = event.target;
        // Set a new timeout
        const newTimeoutId = setTimeout(() => {
            // Call your API function here with the updated value
            getApiListActivity(value, getParentActivities.at(getParentActivities.length - 1)?.id);
        }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
        // Update state to store the timeout ID
        setTimeoutId(newTimeoutId);
        // Update the input value state

        setInputValue(value);

    };

    const handleAddOptionClick = (option: InterfaceNTTActivity) => {
        const newSelectedValues = [...getActivitySelected, option];
        componentParams.onChangeSelectedActivities(newSelectedValues);
        setInputValue('');
    };
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsFocused(false);
                }
            };
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [getIsFocused]);

    const handleTagRemove = (option: InterfaceNTTActivity) => {
        const newSelectedValues = getActivitySelected.filter((item) => item.title !== option.title);
        // setActivitySelected(newSelectedValues);
        componentParams.onChangeSelectedActivities(newSelectedValues);

    };
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                ...componentParams.styleMain,
                paddingBlockStart: "0.32rem",
                boxSizing: "border-box",

            }}
        >

            <div style={{
                flex: 1,
                // flexGrow: 1,
                paddingInlineStart: "0.32rem",

                boxSizing: "border-box",
            }}>
                <div style={{
                    position: 'relative',
                }}
                    ref={containerRef}
                >
                    <div style={{
                        width: '100%', boxSizing: 'border-box',
                        borderRadius: '4px',
                        paddingBlock: '1px',
                        display: 'flex',
                        flexDirection: "row", flexWrap: 'wrap',
                        cursor: 'pointer',
                        justifyContent: 'space-between',
                    }}
                        onClick={() => inputRef.current?.focus()}
                    >
                        <div style={{
                            width: 0, flexGrow: 1, flexBasis: 0, display: 'flex', flexDirection: 'row',
                            flexWrap: 'wrap',
                            alignItems: 'start',
                            justifyContent: 'start',
                        }}>
                            {getActivitySelected.map((option, index) => (
                                <div key={index} style={{
                                    display: 'flex', alignItems: 'center', height: '24px', margin: '2px', lineHeight: '22px',
                                    backgroundColor: '#fafafa',
                                    borderRadius: '2px', boxSizing: 'content-box',
                                    padding: '0 4px 0 10px',
                                    outline: 0, overflow: 'hidden',
                                    gap: "0.4rem",
                                    paddingBlock: "0.24rem",
                                    ...componentParams.styleItems,
                                }}
                                >
                                    <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                        {option.title}
                                    </span>

                                    <CancelRounded
                                        style={{
                                            color: Colors.theme.orange.sharp,
                                            cursor: "pointer",
                                        }}
                                        onClick={(event) => {
                                            handleTagRemove(option)
                                            event.stopPropagation();
                                        }}
                                    />

                                </div>
                            ))}
                            <input
                                ref={inputRef}
                                value={inputValue}
                                onChange={handleInputChange}
                                onFocus={() => {
                                    setIsFocused(true);
                                }}

                                style={{
                                    height: '30px',
                                    boxSizing: 'border-box',
                                    padding: '4px 6px',
                                    width: 0, minWidth: '40px',
                                    flexGrow: 1, border: 0, margin: 0, outline: 0
                                }}
                            />
                        </div>
                        {
                            <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                                {

                                    <BottomTriangleIcon
                                        style={{
                                            justifySelf: 'end',
                                            transform: getIsFocused ? "rotate(180deg)" : "rotate(0deg)",
                                            transition: "transform 0.5s ease-in-out",
                                            color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                            width: "0.9rem",
                                        }}
                                    />

                                }
                            </div>
                        }
                    </div>
                    {getIsFocused && getResponseAllActivity.length > 0 && (
                        <div
                            style={{
                                width: '100%', margin: '2px 0 0', padding: 0,
                                top: "20",
                                position: 'absolute', listStyle: 'none', backgroundColor: '#fff', overflow: 'auto',
                                borderRadius: '4px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', zIndex: 1
                            }}
                        >
                            {(getParentActivities.length > 0) &&
                                <div style={{
                                    display: 'flex', flexDirection: 'row', width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    color: Colors.theme.blue.dark,
                                    fontSize: '1.4rem',

                                }}>
                                    <div style={{
                                        display: 'inline-flex',
                                        flexGrow: 0,
                                        cursor: 'pointer',
                                    }}
                                        onClick={() => {
                                            setParentActivities([]);
                                        }}>
                                        <CloseRounded style={{
                                            color: Colors.theme.red.red
                                        }} />
                                    </div>
                                    <div style={{
                                        display: 'inline-flex',
                                        flexGrow: 1,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    >
                                        {getParentActivities.at(getParentActivities.length - 1)?.title}
                                    </div>
                                </div>
                            }
                            <ul style={{
                                width: '100%', margin: '2px 0 0', padding: 0,
                                top: "20",
                                paddingBlock: "0.4rem",
                                boxSizing: 'border-box',
                                listStyle: 'none', backgroundColor: '#fff', overflow: 'auto',
                                borderRadius: '4px',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                                zIndex: 1
                            }}>
                                {getResponseAllActivity.filter((activity => activity.title.toLowerCase().includes(inputValue.toLowerCase()))).map((activity, index, arr) => {

                                    let response = [
                                        <RowOfList onClick={() => {
                                            setInputValue("");
                                            if (activity.sub_activities.length === 0) {
                                                if (getActivitySelected.some((item) => item.title === activity.title)) {
                                                    handleTagRemove(activity);
                                                }
                                                else {
                                                    handleAddOptionClick(activity);
                                                }
                                            }
                                            else {
                                                setParentActivities(prev => [...prev, activity]);
                                            }
                                        }}>
                                            <div style={{
                                            }}>
                                                {activity.title}
                                            </div>
                                            <div>
                                                {activity.sub_activities.length === 0 &&
                                                    (getActivitySelected.some((item) => item.title === activity.title) ?
                                                        <RadioButtonChecked />
                                                        :
                                                        <RadioButtonUnchecked />)

                                                }
                                                {(activity.sub_activities.length > 0) &&
                                                    <KeyboardArrowRight />
                                                }
                                            </div>

                                        </RowOfList>
                                    ]
                                    if ((index + 1) !== arr.length) {
                                        response.push(<LineSeparator />)
                                    }
                                    return response;
                                })}
                            </ul>

                        </div>
                    )}
                </div>
                <label
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        left: ((getIsFocused || (getActivitySelected.length > 0)) || !componentParams.iconStart) ? "0.32rem" : "2.4rem",
                        transition: "all 0.2s ease",
                        pointerEvents: "none",
                        top: getIsFocused || (getActivitySelected.length > 0) ? '0px' : 'unset',
                        bottom: getIsFocused || (getActivitySelected.length > 0) ? 'unset' : "0rem",
                        transform: getIsFocused || (getActivitySelected.length > 0) ? "translate(0%, -50%)" : "translate(0%, 0%)",
                        fontSize: (getIsFocused || (getActivitySelected.length > 0)) ? "0.75rem" : "1em",
                        color: Colors.theme.blue.light,
                        backgroundColor: "transparent",
                        padding: "0",
                    }}
                >
                    {componentParams.title}
                </label>
            </div>
            <fieldset
                // aria-hidden="true"
                style={{
                    position: "absolute",
                    boxSizing: "border-box",
                    top: getIsFocused || (getActivitySelected.length > 0) ? "calc(-1.02rem - 0.5px)" : "0px",
                    // height: "100%",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    borderRadius: componentParams.styleMain?.borderRadius,
                    border: "unset",
                    borderBottom: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : `1px solid #C4C7D9`,
                    pointerEvents: "none",
                    padding: "0",
                    margin: "0",
                    display: "block",
                    paddingInline: "0.75rem",
                    // overflow: "hidden"
                }}
            >
                <legend
                    style={{
                        overflow: "hidden",
                        float: "unset",
                        boxSizing: "border-box",
                        transition: "all 0.2s ease",
                        left: getIsFocused || (getActivitySelected.length > 0) ? "1rem" : "1rem",
                        pointerEvents: "none",

                        display: (getIsFocused || (getActivitySelected.length > 0)) ? "block" : "none",
                        // transform: "translate(0%,-60%)",
                        // visibility: "hidden",
                        // background: "red",
                    }}
                >
                    <span style={{
                        opacity: 0,
                        fontSize: "0.75rem",
                        visibility: "visible", display: "inline-block",
                        paddingInlineEnd: "0.5rem",
                        boxSizing: "border-box",
                    }}>
                        {componentParams.title}
                    </span>
                </legend>
                {/* } */}
            </fieldset>
        </div >
    );
}

export default TextFieldMultiSelectActivity;
