import { useNavigate, useParams } from "react-router-dom";
import { InterfaceShowStateTheTrade } from "./InterfaceShowStateTheTrade";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { MainCard } from "../../../myProfile/MyProfileStyle";
import { useEffect, useState } from "react";

import SteperGuide from "../../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import PersonListForSenderAndRecipient from "../../../../components/complex/PersonListForSenderAndRecipient/PersonListForSenderAndRecipient";
import MergeSelectedRecaps from "../CreateTrade/MergeSelectedRecaps/MergeSelectedRecaps";
import SelectedSolutionsPreview from "../../../../components/complex/recaps/SelectedSolutionsPreview/SelectedSolutionsPreview";
import CreateOneStepOfTrade from "./ListStepsOfTrade/OneStepOfTrade/CreateOneStepOfTrade";
import ListStepsOfTrade from "./ListStepsOfTrade/ListStepsOfTrade";
import ParentWithHeightAnimation from "../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { AddRounded, BackHandRounded, DoneRounded } from "@mui/icons-material";
import { EnumSTepsParent, EnumStepsTrade } from "../../../../config/enums/EnumSteps";

import ButtonCancel from "../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import { EnumStateValueOfTrade, InterfaceNTTTradeMain } from "../../../../NTTs/Trade/InterfaceNTTTradeMain";
import SteperGuideForTrade from "../../../../components/complex/SteperGuide/SteperGuideForTrade/SteperGuideForFreestyle";
import { EnumStateValueOfTradeStep } from "../../../../NTTs/Trade/InterfaceNTTStepOfTrade";
import Colors from "../../../../config/Colors";
import Links from "../../../../config/Links";
import { InterfaceNTTProfile } from "../../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../../NTTs/InterfaceNTTProfileType";
import dayjs from "dayjs";
import TextDescription from "../../../../components/basic/Texts/TextDescription/TextDescription";
import ToolTipText from "../../../../components/basic/ToolTipText/ToolTipText";
import moment from "moment-jalaali";

function ShowStateTheTrade(paramsComponent: InterfaceShowStateTheTrade) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const { idMainTrade } = useParams();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("2");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    const [getTradeMain, setTradeMain] = useState<InterfaceNTTTradeMain>();
    const [getIsLoading, setIsLoading] = useState<boolean>(true);
    const [getIsActiveCreation, setIsActiveCreation] = useState<boolean>(false);

    const callApiForRetrieveTheTrade = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.trade.getTradeInitiate;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainTrade}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTTradeMain;
                setTradeMain(t);
                setIsLoading(false);
                setKeyOfGroupThatSelected(`${EnumSTepsParent.trade}`);
                if (t.state.value === EnumStateValueOfTrade.created) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfTrade.refinement) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfTrade.pendingForCheckByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.approval}`)
                }
                else if (t.state.value === EnumStateValueOfTrade.approvedByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.calculatePANDL}`)
                }
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForUpdateStateOfThisTrade = async (stateValue: EnumStateValueOfTrade) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.trade.updateTradeInitiate;
        let DataObj = {
            "state": stateValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainTrade}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTTradeMain;
                setTradeMain(t);
                setIsLoading(false);
                if (t.state.value === EnumStateValueOfTrade.created) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfTrade.refinement) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}`)
                }
                else if (t.state.value === EnumStateValueOfTrade.pendingForCheckByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.approval}`)
                    dispatch(ShowAlert({ text: `Sended To Manager`, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else if (t.state.value === EnumStateValueOfTrade.approvedByManager) {
                    setKeyOfStepThatSelected(`${EnumSTepsParent.trade}.${EnumStepsTrade.calculatePANDL}`)
                }
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        callApiForRetrieveTheTrade();
    }, [])

    return (
        <ContainerPage title={confComps.translate.trade}
            isLoading={getIsLoading}>
            <SteperGuideForTrade
                steps={confComps.steperGuide.trade.steps}
                callbackOnClickOneStep={(keySelected: string) => {
                    console.log(keySelected);
                    setKeyOfStepThatSelected(keySelected);

                }}
                callbackOnClickOneGroup={(keySelected: string) => {
                    setKeyOfGroupThatSelected(keySelected)
                }}
                keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                keyOfOneStepThatSelected={getKeyOfStepThatSelected}
                trade={getTradeMain}
            />

            <MainCard sx={{
                gap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.77vh" },
                display: 'flex', flexDirection: 'column', alignItems: 'start',
                justifyContent: 'start',
            }}>
                <TitleMedium18 style={{
                    boxSizing: "border-box",
                }}>
                    {confComps.translate.trade}
                </TitleMedium18>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '100%',
                    gap: '0.2rem',
                }}>
                    <Grid container
                        justifyContent="start" alignItems="flex-start"
                        // columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                        rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                    >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{`${confComps.translate.title}:`}</Typography>
                                <Typography variant="body1">
                                    {getTradeMain?.title}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{`Code:`}</Typography>
                                <Typography variant="body1">
                                    {getTradeMain?.code}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{`Create Time:`}</Typography>
                                <Typography variant="body1">
                                    <ToolTipText
                                        textHint={`Jalali: ${moment.unix(getTradeMain?.created_at ?? 0).format("jYYYY/jM/jD")}`}
                                    >
                                        {`${dayjs.unix(getTradeMain?.created_at ?? 0).format("YYYY/MM/DD")}`}
                                    </ToolTipText>
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{`Last Updated:`}</Typography>
                                <Typography variant="body1">
                                    <ToolTipText
                                        textHint={`Jalali: ${moment.unix(getTradeMain?.updated_at ?? 0).format("jYYYY/jM/jD")}`}
                                    >
                                        {`${dayjs.unix(getTradeMain?.updated_at ?? 0).format("YYYY/MM/DD")}`}
                                    </ToolTipText>
                                </Typography>
                            </Box>
                        </Grid>
                        {/* Amount */}
                        {((getTradeMain?.description.length ?? 0) > 0) &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextDescription>
                                    {getTradeMain?.description}
                                </TextDescription>
                            </Grid>}
                    </Grid>

                </div>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '100%',
                    gap: '0.2rem',
                }}>


                    {getTradeMain &&
                        <ListStepsOfTrade
                            trade={getTradeMain}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatSelected={getKeyOfStepThatSelected}
                            callbackOnClickDeleteOneStep={() => {
                                callApiForRetrieveTheTrade();
                            }}
                            callbackReload={callApiForRetrieveTheTrade}
                        />
                    }
                    <ParentWithHeightAnimation in={getIsActiveCreation} style={{ width: '100%' }}>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                        }}>
                            {getTradeMain &&
                                <CreateOneStepOfTrade
                                    trade={getTradeMain}
                                    isShowThisBox={getIsActiveCreation}
                                    createStep={{
                                        callbackOnClickCancel: () => {
                                            setIsActiveCreation(false);
                                        },
                                        callbackOnClickStepAdded: () => {
                                            callApiForRetrieveTheTrade();
                                            setIsActiveCreation(false);
                                        }
                                    }}
                                />
                            }
                        </div>
                    </ParentWithHeightAnimation>
                    {/* this box for creation step */}
                    {!getIsActiveCreation &&
                        getKeyOfStepThatSelected === `${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}` &&//selected step in guide step is add step
                        getTradeMain?.state.value === EnumStateValueOfTrade.created &&
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between", width: "100%"
                        }}>
                            <ButtonSave text={"Add New Step"}
                                callbackOnClick={() => {
                                    setIsActiveCreation(true);
                                }}
                                iconStart={<AddRounded />}
                            />
                            <ButtonSave text={"Submit All Step To Manager"}
                                callbackOnClick={() => {
                                    callApiForUpdateStateOfThisTrade(EnumStateValueOfTrade.pendingForCheckByManager);
                                }}
                                iconStart={<DoneRounded />}
                            />
                        </div>
                    }
                    {/* this box for refinement step */}
                    {!getIsActiveCreation &&
                        getKeyOfStepThatSelected === `${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}` &&//selected step in guide step is add step
                        getTradeMain?.state.value === EnumStateValueOfTrade.refinement &&
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between", width: "100%"
                        }}>
                            <ButtonSave text={"Add New Step"}
                                callbackOnClick={() => {
                                    setIsActiveCreation(true);
                                }}
                                iconStart={<AddRounded />}
                            />
                            <ButtonSave text={"Resend these Step"}
                                callbackOnClick={() => {
                                    callApiForUpdateStateOfThisTrade(EnumStateValueOfTrade.pendingForCheckByManager);
                                }}
                                iconStart={<DoneRounded />}
                            />
                        </div>
                    }
                    {!getIsActiveCreation &&  // this box for approval step
                        getKeyOfStepThatSelected === `${EnumSTepsParent.trade}.${EnumStepsTrade.approval}` &&//selected step in guide step is add step
                        getTradeMain?.state.value === EnumStateValueOfTrade.pendingForCheckByManager &&
                        <div style={{
                            display: 'flex',
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "end", width: "100%",
                            gap: '0.56rem',
                        }}>


                            <ButtonCancel text={"Reject"}
                                callbackOnClick={() => {
                                    callApiForUpdateStateOfThisTrade(EnumStateValueOfTrade.refinement);
                                }}
                                iconStart={<BackHandRounded
                                    style={{ fontSize: '1rem' }} />}
                            />
                            <ButtonSave
                                style={{
                                    opacity: (getTradeMain.steps.filter(step => step.state.value === EnumStateValueOfTradeStep.approvedByManager).length === getTradeMain.steps.length) ?
                                        1 : 0.5,
                                }}
                                text={"Approve All Step"}
                                callbackOnClick={() => {
                                    if ((getTradeMain.steps.filter(step => step.state.value === EnumStateValueOfTradeStep.approvedByManager).length === getTradeMain.steps.length)) {
                                        callApiForUpdateStateOfThisTrade(EnumStateValueOfTrade.approvedByManager);
                                    }
                                    else {
                                        dispatch(ShowAlert({ text: `You must approve All Step`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                                    }
                                }}
                                iconStart={<DoneRounded />}
                            />
                        </div>
                    }

                </div>
            </MainCard>
        </ContainerPage >

    );
}
export default ShowStateTheTrade;

