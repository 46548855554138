import { title } from "process";
import { DealsIcon } from "../assets/icons/svg/DealsIcon";
import { HomeIcon } from "../assets/icons/svg/HomeIcon";
import { NotificationIcon } from "../assets/icons/svg/NotificationIcon";
import { PeopleIcon } from "../assets/icons/svg/PeopleIcon";
import { ProfileIcon } from "../assets/icons/svg/ProfileIcon";
import { RecapIcon } from "../assets/icons/svg/RecapIcon";
import { ServicesIcon } from "../assets/icons/svg/ServicesIcon";
import { SettingIcon } from "../assets/icons/svg/SettingIcon";
import { EnumSectionsSidebar } from "./enums/EnumSectionsSidebar";
import Links from "./Links";
import { useTranslation } from "react-i18next";
import { EnumSubPageProfileCustomerId } from "../screens/people/TrustyOrCustomer/customers/CustomerProfile/CustomerProfileParent";
import { EnumSubPageProfileTrustyId } from "../screens/people/TrustyOrCustomer/Trusties/TrustyProfile/TrustyProfileParent";
import { ChatRounded, Password, ShowChartRounded } from "@mui/icons-material";
import { EnumSortingType } from "../components/complex/SortingBox/InterfaceSortingBox";
import { EnumItemThatHandleInSmartSearch } from "../components/complex/advanceSearchBox/AdvanceSearchBox";
import { InterfacePaginationInServer } from "../api/interfaces/InterfaceSearchInServer";
import { EnumStepsFreestyle, EnumSTepsParent, EnumSTepsRecap, EnumStepsSlip, EnumSTepsSolutionRecap, EnumStepsTrade } from "./enums/EnumSteps";
import DomesticCompany from "../screens/people/TrustyOrCustomer/SubInfoProfile/Companies/DomesticCompany/DomesticCompany";
import { EnumInnerBeneficiaryOrderForStepTrade } from "../screens/Deals/Trade/ShowStateTheTrade/ListStepsOfTrade/OneStepOfTrade/InterfaceCreateOneStepOfTrade";
import { EnumTypeStateDebt } from "./enums/EnumTypeStateDebt";
import { ChartIcon } from "../assets/icons/svg/ChartIcon";

export enum EnumTypeOfGroupInAdvanceSearchBox {
    boolean = "boolean",
    string = "string",
}

export const ConfigComps = () => {
    const { t, i18n } = useTranslation();
    return (
        {
            defaultValue: {
                paginationConfigForServer: {
                    page: 1, page_size: 12
                } as InterfacePaginationInServer,
            },
            font: {
                poppins: {
                    Black: "Poppins-Black",
                    BlackItalic: "Poppins-BlackItalic",
                    Bold: "Poppins-Bold",
                    BoldItalic: "Poppins-BoldItalic",
                    ExtraBold: "Poppins-ExtraBold",
                    ExtraBoldItalic: "Poppins-ExtraBoldItalic",
                    ExtraLight: "Poppins-ExtraLight",
                    ExtraLightItalic: "Poppins-ExtraLightItalic",
                    Italic: "Poppins-Italic",
                    Light: "Poppins-Light",
                    LightItalic: "Poppins-LightItalic",
                    Medium: "Poppins-Medium",
                    MediumItalic: "Poppins-MediumItalic",
                    Regular: "Poppins-Regular",
                    SemiBold: "Poppins-SemiBold",
                    SemiBoldItalic: "Poppins-SemiBoldItalic",
                    Thin: "Poppins-Thin",
                    ThinItalic: "Poppins-ThinItalic",

                },
            },
            images: {
                logo: "/assets/img/logo.png",
                logoWithoutText: "/assets/img/logo_without_text.png",
            },
            sidebar: {
                items: [
                    // {
                    //     title: t(`sidebar.items.0.title`),
                    //     icon: HomeIcon,
                    //     key: EnumSectionsSidebar.dashboard,
                    // },
                    {
                        title: t(`sidebar.items.1.title`),
                        icon: RecapIcon,
                        key: EnumSectionsSidebar.recap,
                        link: Links.screens.panel.recap.listRecap,
                    },
                    {
                        title: t(`sidebar.items.2.title`),
                        icon: DealsIcon,
                        key: EnumSectionsSidebar.deals,
                    },
                    {
                        title: t(`sidebar.items.3.title`),
                        icon: ServicesIcon,
                        key: EnumSectionsSidebar.services,
                    },
                    {
                        title: t(`sidebar.items.4.title`),
                        icon: PeopleIcon,
                        key: EnumSectionsSidebar.people,
                    },
                    {
                        title: t(`sidebar.items.5.title`),
                        icon: ChartIcon,
                        key: EnumSectionsSidebar.report,
                    }
                ],
                itemConfig: [
                    {
                        icon: SettingIcon,
                        key: EnumSectionsSidebar.mySetting,
                        link: Links.screens.panel.mySetting.mySetting,
                    },
                    // {
                    //     icon: NotificationIcon,
                    //     key: EnumSectionsSidebar.myNotification,
                    //     link: Links.screens.panel.myNotifications.myNotifications,
                    // },
                    {
                        icon: ProfileIcon,
                        key: EnumSectionsSidebar.myProfile,
                        link: Links.screens.panel.myProfile.myProfile,
                    },
                ]
            },
            component: {
                advanceSearchBox: {
                    hintText: t(`component.advanceSearchBox.hintText`),
                    buttonAdvance: t(`component.advanceSearchBox.buttonAdvance`),
                    allKinds: {
                        slip: {
                            advanceBox: {
                                booleanItem: [],
                                relationIDsItem: [
                                    {
                                        key: "currency_unit",
                                        id: [],
                                        title: "Currency",
                                        placeholder: "currency",
                                        itemType: EnumItemThatHandleInSmartSearch.currency_unit,

                                    }
                                ],
                                numbersItem: [
                                ],
                                dateItem: [
                                    {
                                        key: "created_at",
                                        placeholderStart: t(`translate.from`),
                                        placeholderEnd: t(`translate.end`),
                                        isDialogOpenEnd: false,
                                        isDialogOpenStart: false,
                                        title: "Slip Create",
                                    },
                                    {
                                        key: "paid_at",
                                        placeholderStart: t(`translate.from`),
                                        placeholderEnd: t(`translate.end`),
                                        isDialogOpenEnd: false,
                                        isDialogOpenStart: false,
                                        title: "Paid",
                                    },
                                ],
                                stringsItem: [
                                    {
                                        key: "code",
                                        placeholder: "tr1403dey1",
                                        value: "",
                                        title: "Code",
                                    },
                                    {
                                        key: "from_person__user__username",
                                        placeholder: "a",
                                        value: "",
                                        title: "From (Username)",
                                    },
                                    {
                                        key: "from_person__user__email",
                                        placeholder: "a@a.com",
                                        value: "",
                                        title: "From (Email)",
                                    },
                                    {
                                        key: "to_person__user__username",
                                        placeholder: "a",
                                        value: "",
                                        title: "To (Username)",
                                    },
                                    {
                                        key: "to_person__user__email",
                                        placeholder: "a@a.com",
                                        value: "",
                                        title: "To (Email)",
                                    },
                                ]
                            },
                            groupList: {
                                config: {
                                    typeKeyGroup: EnumTypeOfGroupInAdvanceSearchBox.boolean,
                                    isMultiGroupCanActive: false,
                                },
                                items: [
                                    {
                                        title: "Individual Profile",
                                        key: "state__isnull",
                                        valueSelectedForServer: false,//boolean
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "from_person__individual_profile__first_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (First Name)",
                                                },
                                                {
                                                    key: "from_person__individual_profile__first_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (First Name (FA))",
                                                },

                                                {
                                                    key: "from_person__individual_profile__last_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Last Name)",
                                                },
                                                {
                                                    key: "from_person__individual_profile__last_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Last Name(FA))",
                                                },
                                                {
                                                    key: "to_person__individual_profile__first_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (First Name)",
                                                },
                                                {
                                                    key: "to_person__individual_profile__first_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (First Name (FA))",
                                                },

                                                {
                                                    key: "to_person__individual_profile__last_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Last Name)",
                                                },
                                                {
                                                    key: "to_person__individual_profile__last_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Last Name(FA))",
                                                },
                                            ],
                                        }
                                    },
                                    {
                                        title: "Domestic Legal",
                                        key: "state__isnull",
                                        valueSelectedForServer: false,//boolean
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "from_person__domestic_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Company Name)",
                                                },
                                                {
                                                    key: "from_person__domestic_legal_profile__name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Company Name(FA))",
                                                },
                                                {
                                                    key: "from_person__domestic_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Company Name)",
                                                },
                                                {
                                                    key: "from_person__domestic_legal_profile__name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Company Name(FA))",
                                                },
                                            ],
                                        }
                                    },
                                    {
                                        title: "Domestic Legal",
                                        key: "state__isnull",
                                        valueSelectedForServer: false,//boolean
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "from_person__foreign_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Company Name)",
                                                },
                                                {
                                                    key: "to_person__foreign_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Company Name)",
                                                },
                                            ],
                                        }
                                    },
                                ]
                            }
                        },
                        trade: {
                            advanceBox: {
                                booleanItem: [],
                                relationIDsItem: [
                                ],
                                numbersItem: [
                                ],
                                dateItem: [
                                    {
                                        key: "created_at",
                                        placeholderStart: t(`translate.from`),
                                        placeholderEnd: t(`translate.end`),
                                        isDialogOpenEnd: false,
                                        isDialogOpenStart: false,
                                        title: "Trade Create",
                                    },
                                    {
                                        key: "step__created_at",
                                        placeholderStart: t(`translate.from`),
                                        placeholderEnd: t(`translate.end`),
                                        isDialogOpenEnd: false,
                                        isDialogOpenStart: false,
                                        title: "Trade Steps Create",
                                    },
                                ],
                                stringsItem: [
                                    {
                                        key: "step__code",
                                        placeholder: "tr1403dey1",
                                        value: "",
                                        title: "Step Code",
                                    },
                                    {
                                        key: "step__from_person__user__username",
                                        placeholder: "a",
                                        value: "",
                                        title: "From (Username)",
                                    },
                                    {
                                        key: "step__from_person__user__email",
                                        placeholder: "a@a.com",
                                        value: "",
                                        title: "From (Email)",
                                    },
                                    {
                                        key: "step__to_person__user__username",
                                        placeholder: "a",
                                        value: "",
                                        title: "To (Username)",
                                    },
                                    {
                                        key: "step__to_person__user__email",
                                        placeholder: "a@a.com",
                                        value: "",
                                        title: "To (Email)",
                                    },
                                ]
                            },
                            groupList: {
                                config: {
                                    typeKeyGroup: EnumTypeOfGroupInAdvanceSearchBox.boolean,
                                    isMultiGroupCanActive: false,
                                },
                                items: [
                                    {
                                        title: "Individual Profile",
                                        key: "step__trade__isnull",
                                        valueSelectedForServer: false,//boolean
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "step__from_person__individual_profile__first_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (First Name)",
                                                },
                                                {
                                                    key: "step__from_person__individual_profile__first_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (First Name (FA))",
                                                },

                                                {
                                                    key: "step__from_person__individual_profile__last_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Last Name)",
                                                },
                                                {
                                                    key: "step__from_person__individual_profile__last_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Last Name(FA))",
                                                },
                                                {
                                                    key: "step__to_person__individual_profile__first_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (First Name)",
                                                },
                                                {
                                                    key: "step__to_person__individual_profile__first_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (First Name (FA))",
                                                },

                                                {
                                                    key: "step__to_person__individual_profile__last_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Last Name)",
                                                },
                                                {
                                                    key: "step__to_person__individual_profile__last_name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Last Name(FA))",
                                                },
                                            ],
                                        }
                                    },
                                    {
                                        title: "Domestic Legal",
                                        key: "step__trade__isnull",
                                        valueSelectedForServer: false,//boolean
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "step__from_person__domestic_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Company Name)",
                                                },
                                                {
                                                    key: "step__from_person__domestic_legal_profile__name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Company Name(FA))",
                                                },
                                                {
                                                    key: "step__from_person__domestic_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Company Name)",
                                                },
                                                {
                                                    key: "step__from_person__domestic_legal_profile__name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Company Name(FA))",
                                                },
                                            ],
                                        }
                                    },
                                    {
                                        title: "Domestic Legal",
                                        key: "step__trade__isnull",
                                        valueSelectedForServer: false,//boolean
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "step__from_person__foreign_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "From (Company Name)",
                                                },
                                                {
                                                    key: "step__to_person__foreign_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "To (Company Name)",
                                                },
                                            ],
                                        }
                                    },
                                ]
                            }
                        },
                        recap: {
                            advanceBox: {
                                booleanItem: [],
                                relationIDsItem: [
                                    // {
                                    //     key: "activities",
                                    //     id: [],
                                    //     title: t(`translate.activities`),
                                    //     placeholder: "",
                                    //     itemType: EnumItemThatHandleInSmartSearch.activitiesJob,

                                    // }
                                ],
                                numbersItem: [
                                    // {
                                    //     key: "balance",
                                    //     // min: "-100000",
                                    //     // max: "100000",
                                    //     title: t(`translate.balance`),
                                    //     placeholderMin: "-100000",
                                    //     placeholderMax: "100000",
                                    //     // placeholder ?: string;
                                    // },
                                    // {
                                    //     key: "credit",
                                    //     // min: "0",
                                    //     // max: "100000",
                                    //     placeholderMin: "0",
                                    //     placeholderMax: "100000",
                                    //     title: t(`translate.credit`),
                                    //     // placeholder ?: string;
                                    // }
                                ],
                                dateItem: [
                                    // {
                                    //     key: "user__date_joined",
                                    //     placeholderStart: t(`translate.from`),
                                    //     placeholderEnd: t(`translate.end`),
                                    //     // start?: string;
                                    //     // end?: string;
                                    //     isDialogOpenEnd: false,
                                    //     isDialogOpenStart: false,
                                    //     title: "Date Joined",
                                    // },
                                ],
                                stringsItem: [
                                    // {
                                    //     key: "user__username",
                                    //     placeholder: "",
                                    //     value: "",
                                    //     title: t(`translate.username`),
                                    // },
                                    // {
                                    //     key: "user__email",
                                    //     placeholder: "a@a.com",
                                    //     value: "",
                                    //     title: "Email",
                                    // },
                                ]
                            },
                            groupList: {
                                config: {
                                    typeKeyGroup: EnumTypeOfGroupInAdvanceSearchBox.boolean,
                                    isMultiGroupCanActive: true,
                                },
                                items: [
                                    {
                                        title: "Money Receive",
                                        key: "receipts__isnull",
                                        valueSelectedForServer: true,//boolean
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                                {
                                                    key: "receipts__amount",
                                                    // min: "-100000",
                                                    // max: "100000",
                                                    title: "Amount",
                                                    placeholderMin: "-100000",
                                                    placeholderMax: "100000",
                                                    // placeholder ?: string;
                                                },
                                            ],
                                            dateItem: [
                                            ],
                                            stringsItem: [

                                                {
                                                    key: "receipts__customer__individual_profile__first_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.firstName`),
                                                },
                                                {
                                                    key: "receipts__customer__individual_profile__last_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.lastName`),
                                                },

                                                {
                                                    key: "receipts__customer__domestic_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Domestic Company",
                                                },
                                                {
                                                    key: "receipts__customer__foreign_legal_profile__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Foreign Company",
                                                }
                                            ],
                                        }
                                    },
                                    {
                                        title: "Money Payment",
                                        key: "person_type",
                                        valueSelectedForServer: "FLP",
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                                {
                                                    key: "foreign_legal__authenticity_date",
                                                    placeholderStart: t(`translate.from`),
                                                    placeholderEnd: t(`translate.end`),
                                                    // start?: string;
                                                    // end?: string;
                                                    isDialogOpenEnd: false,
                                                    isDialogOpenStart: false,
                                                    title: "Authenticity Date",
                                                },
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "foreign_legal__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Name",
                                                },
                                                {
                                                    key: "foreign_legal__company_code",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Code",
                                                },
                                                {
                                                    key: "foreign_legal__landline",
                                                    placeholder: "+989xxxxxxx",
                                                    value: "",
                                                    title: "Landline",
                                                },
                                                {
                                                    key: "foreign_legal__country",
                                                    placeholder: "United States",
                                                    value: "",
                                                    title: "Country",
                                                },
                                                {
                                                    key: "foreign_legal__city",
                                                    placeholder: "Denver",
                                                    value: "",
                                                    title: "City",
                                                }
                                            ],
                                        }
                                    },
                                    {
                                        title: "Buy Cottage",
                                        key: "person_type",
                                        valueSelectedForServer: "DLP",
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                                {
                                                    key: "domestic_legal__doe",
                                                    placeholderStart: t(`translate.from`),
                                                    placeholderEnd: t(`translate.end`),
                                                    // start?: string;
                                                    // end?: string;
                                                    isDialogOpenEnd: false,
                                                    isDialogOpenStart: false,
                                                    title: "Establishment Date",
                                                },
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "domestic_legal__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Name",
                                                },
                                                {
                                                    key: "domestic_legal__name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Name(FA)",
                                                },
                                                {
                                                    key: "domestic_legal__landline",
                                                    placeholder: "+982xxxxxxx",
                                                    value: "",
                                                    title: "Landline",
                                                },
                                                {
                                                    key: "domestic_legal__economic_code",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Economic Code",
                                                },
                                                {
                                                    key: "domestic_legal__national_id",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.nationalId`),
                                                }
                                            ],
                                        }
                                    },
                                    {
                                        title: "Sell Cottage",
                                        key: "person_type",
                                        valueSelectedForServer: "DLP",
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                                {
                                                    key: "domestic_legal__doe",
                                                    placeholderStart: t(`translate.from`),
                                                    placeholderEnd: t(`translate.end`),
                                                    // start?: string;
                                                    // end?: string;
                                                    isDialogOpenEnd: false,
                                                    isDialogOpenStart: false,
                                                    title: "Establishment Date",
                                                },
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "domestic_legal__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Name",
                                                },
                                                {
                                                    key: "domestic_legal__name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Name(FA)",
                                                },
                                                {
                                                    key: "domestic_legal__landline",
                                                    placeholder: "+982xxxxxxx",
                                                    value: "",
                                                    title: "Landline",
                                                },
                                                {
                                                    key: "domestic_legal__economic_code",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Economic Code",
                                                },
                                                {
                                                    key: "domestic_legal__national_id",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.nationalId`),
                                                }
                                            ],
                                        }
                                    }
                                ]
                            }
                        },
                        trustyOrCustomerList: {
                            advanceBox: {
                                booleanItem: [],
                                relationIDsItem: [
                                    {
                                        key: "activities",
                                        id: [],
                                        title: t(`translate.activities`),
                                        placeholder: "",
                                        itemType: EnumItemThatHandleInSmartSearch.activitiesJob,

                                    }
                                ],
                                numbersItem: [
                                    {
                                        key: "balance",
                                        // min: "-100000",
                                        // max: "100000",
                                        title: t(`translate.balance`),
                                        placeholderMin: "-100000",
                                        placeholderMax: "100000",
                                        // placeholder ?: string;
                                    },
                                    {
                                        key: "credit",
                                        // min: "0",
                                        // max: "100000",
                                        placeholderMin: "0",
                                        placeholderMax: "100000",
                                        title: t(`translate.credit`),
                                        // placeholder ?: string;
                                    }
                                ],
                                dateItem: [
                                    {
                                        key: "user__date_joined",
                                        placeholderStart: t(`translate.from`),
                                        placeholderEnd: t(`translate.end`),
                                        // start?: string;
                                        // end?: string;
                                        isDialogOpenEnd: false,
                                        isDialogOpenStart: false,
                                        title: "Date Joined",
                                    },
                                ],
                                stringsItem: [
                                    {
                                        key: "user__username",
                                        placeholder: "",
                                        value: "",
                                        title: t(`translate.username`),
                                    },
                                    {
                                        key: "user__email",
                                        placeholder: "a@a.com",
                                        value: "",
                                        title: "Email",
                                    },
                                ]
                            },
                            groupList: {
                                config: {
                                    typeKeyGroup: EnumTypeOfGroupInAdvanceSearchBox.string,
                                    isMultiGroupCanActive: false,
                                },
                                items: [
                                    {
                                        title: "Individual Active",
                                        key: "person_type",
                                        valueSelectedForServer: "IND",
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                                {
                                                    key: "individual_profile__dob",
                                                    placeholderStart: t(`translate.from`),
                                                    placeholderEnd: t(`translate.end`),
                                                    // start?: string;
                                                    // end?: string;
                                                    isDialogOpenEnd: false,
                                                    isDialogOpenStart: false,
                                                    title: t(`translate.dateOfBirth`),
                                                },
                                            ],
                                            stringsItem: [

                                                {
                                                    key: "individual_profile__first_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.firstName`),
                                                },
                                                {
                                                    key: "individual_profile__last_name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.lastName`),
                                                },
                                                {
                                                    key: "individual_profile__phone",
                                                    placeholder: "+989xxxxxxxxx",
                                                    value: "",
                                                    title: t(`translate.phone`),
                                                },
                                                {
                                                    key: "individual_profile__national_id",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.nationalId`),
                                                }
                                            ],
                                        }
                                    },
                                    {
                                        title: "Foreign Legal Active",
                                        key: "person_type",
                                        valueSelectedForServer: "FLP",
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                                {
                                                    key: "foreign_legal__authenticity_date",
                                                    placeholderStart: t(`translate.from`),
                                                    placeholderEnd: t(`translate.end`),
                                                    // start?: string;
                                                    // end?: string;
                                                    isDialogOpenEnd: false,
                                                    isDialogOpenStart: false,
                                                    title: "Authenticity Date",
                                                },
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "foreign_legal__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Name",
                                                },
                                                {
                                                    key: "foreign_legal__company_code",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Code",
                                                },
                                                {
                                                    key: "foreign_legal__landline",
                                                    placeholder: "+989xxxxxxx",
                                                    value: "",
                                                    title: "Landline",
                                                },
                                                {
                                                    key: "foreign_legal__country",
                                                    placeholder: "United States",
                                                    value: "",
                                                    title: "Country",
                                                },
                                                {
                                                    key: "foreign_legal__city",
                                                    placeholder: "Denver",
                                                    value: "",
                                                    title: "City",
                                                }
                                            ],
                                        }
                                    },
                                    {
                                        title: "Iran Legal Active",
                                        key: "person_type",
                                        valueSelectedForServer: "DLP",
                                        isSelectedInFilter: false,
                                        advanceBox: {
                                            booleanItem: [],
                                            relationIDsItem: [
                                            ],
                                            numbersItem: [
                                            ],
                                            dateItem: [
                                                {
                                                    key: "domestic_legal__doe",
                                                    placeholderStart: t(`translate.from`),
                                                    placeholderEnd: t(`translate.end`),
                                                    // start?: string;
                                                    // end?: string;
                                                    isDialogOpenEnd: false,
                                                    isDialogOpenStart: false,
                                                    title: "Establishment Date",
                                                },
                                            ],
                                            stringsItem: [
                                                {
                                                    key: "domestic_legal__name",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Name",
                                                },
                                                {
                                                    key: "domestic_legal__name_fa",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Company Name(FA)",
                                                },
                                                {
                                                    key: "domestic_legal__landline",
                                                    placeholder: "+982xxxxxxx",
                                                    value: "",
                                                    title: "Landline",
                                                },
                                                {
                                                    key: "domestic_legal__economic_code",
                                                    placeholder: "",
                                                    value: "",
                                                    title: "Economic Code",
                                                },
                                                {
                                                    key: "domestic_legal__national_id",
                                                    placeholder: "",
                                                    value: "",
                                                    title: t(`translate.nationalId`),
                                                }
                                            ],
                                        }
                                    }
                                ]
                            }
                        },
                        employeeList: {
                            booleanItem: [],
                            relationIDsItem: [
                                {
                                    key: "role__id",
                                    id: [],
                                    title: t(`translate.role`),
                                    placeholder: "trusty",
                                    itemType: EnumItemThatHandleInSmartSearch.role,

                                }
                            ],
                            numbersItem: [],
                            dateItem: [
                                {
                                    key: "individual__dob",
                                    placeholderStart: t(`translate.from`),
                                    placeholderEnd: t(`translate.end`),
                                    // start?: string;
                                    // end?: string;
                                    isDialogOpenEnd: false,
                                    isDialogOpenStart: false,
                                    title: t(`translate.dateOfBirth`),
                                },
                            ],
                            stringsItem: [
                                {
                                    key: "user__username",
                                    placeholder: "",
                                    value: "",
                                    title: t(`translate.username`),
                                },
                                {
                                    key: "individual__first_name",
                                    placeholder: "",
                                    value: "",
                                    title: t(`translate.firstName`),
                                },
                                {
                                    key: "individual__last_name",
                                    placeholder: "",
                                    value: "",
                                    title: t(`translate.lastName`),
                                },
                                {
                                    key: "user__phone",
                                    placeholder: "+989xxxxxxxxx",
                                    value: "",
                                    title: t(`translate.phone`),
                                },
                                {
                                    key: "user__email",
                                    placeholder: "a@a.com",
                                    value: "",
                                    title: t(`translate.email`),
                                },
                                {
                                    key: "user__national_id",
                                    placeholder: "",
                                    value: "",
                                    title: t(`translate.nationalId`),
                                }
                            ],
                        }

                    },
                },
                SortingBox: {
                    title: t(`component.SortingBox.title`),
                },
                ShowStateFetchList: {
                    searching: {
                        title: t(`component.ShowStateFetchList.searching.title`),
                        button: t(`component.ShowStateFetchList.searching.button`),
                    },
                    foundData: {
                    },
                    NotFounded: {
                        title: t(`component.ShowStateFetchList.NotFounded.title`),
                        button: t(`component.ShowStateFetchList.NotFounded.button`),
                    },
                    errorInRequest: {
                        title: t(`component.ShowStateFetchList.errorInRequest.title`),
                        button: t(`component.ShowStateFetchList.errorInRequest.button`),
                    }
                },
                selectorCommon: {
                    SelectStateDebt: [
                        {
                            text: "None",
                            serverNeeded: EnumTypeStateDebt.none,
                        },
                        {
                            text: "Debit",
                            serverNeeded: EnumTypeStateDebt.debit,
                        },
                        {
                            text: "Credit",
                            serverNeeded: EnumTypeStateDebt.credit,
                        }
                    ],
                    SelectStateBeneficiary: [
                        {
                            text: "From Recommender",
                            serverNeeded: EnumInnerBeneficiaryOrderForStepTrade.from,
                        },
                        {
                            text: "To Recommender",
                            serverNeeded: EnumInnerBeneficiaryOrderForStepTrade.to,
                        },
                        {
                            text: "Exchanger Recommender",
                            serverNeeded: EnumInnerBeneficiaryOrderForStepTrade.exchanger,
                        }
                    ]
                },
            },
            loginAndRegister: {
                loginPage: {
                    api: {
                        success: [t(`loginAndRegister.loginPage.api.success.0`), t(`loginAndRegister.loginPage.api.success.1`)],
                    },
                    pageInfo: {
                        title: t(`loginAndRegister.loginPage.pageInfo.title`),
                        email: {
                            hintText: t(`loginAndRegister.loginPage.pageInfo.email.hintText`),
                        },
                        password: {
                            hintText: t(`loginAndRegister.loginPage.pageInfo.password.hintText`),
                            forgetPassword: t(`loginAndRegister.loginPage.pageInfo.password.forgetPassword`),
                        },
                        button: {
                            title: t(`loginAndRegister.loginPage.pageInfo.button.title`),
                        }
                    }
                }
            },

            sorting: {
                employeeList: [
                    {
                        title: "Username",
                        key: "user__username",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "First Name",
                        key: "individual__first_name",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "First Name(FA)",
                        key: "user__first_name_fa",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Last Name",
                        key: "individual__last_name",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Last Name(FA)",
                        key: "user__last_name_fa",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Date Joined",
                        key: "user__date_joined",
                        typeOrder: EnumSortingType.none,
                    },
                ],
                tradeList: [
                    {
                        title: "Create",
                        key: "created_at",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Update",
                        key: "updated_at",
                        typeOrder: EnumSortingType.none,
                    },
                ],
                slip: [
                    {
                        title: "Create",
                        key: "created_at",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Paid",
                        key: "paid_at",
                        typeOrder: EnumSortingType.none,
                    },
                ],
                recapList: [
                    {
                        title: "Money Receipt Amount",
                        key: "receipts__amount",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Money Payment Amount",
                        key: "payments__amount",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Sell Cottage Amount",
                        key: "sell_cottages__amount",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Buy Cottage Amount",
                        key: "Buy_cottages__amount",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Create Time",
                        key: "created_at",
                        typeOrder: EnumSortingType.none,
                    },
                ],
                customerList: [
                    {
                        title: "Balance",
                        key: "balance",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Credit",
                        key: "credit",
                        typeOrder: EnumSortingType.none,
                    },
                    {
                        title: "Username",
                        key: "user__username",
                        typeOrder: EnumSortingType.none,
                    },
                    // {
                    //     title: "First Name",
                    //     key: "individual__first_name",
                    //     typeOrder: EnumSortingType.none,
                    // },
                    // {
                    //     title: "First Name(FA)",
                    //     key: "user__first_name_fa",
                    //     typeOrder: EnumSortingType.none,
                    // },
                    // {
                    //     title: "Last Name",
                    //     key: "individual__last_name",
                    //     typeOrder: EnumSortingType.none,
                    // },
                    // {
                    //     title: "Last Name(FA)",
                    //     key: "user__last_name_fa",
                    //     typeOrder: EnumSortingType.none,
                    // },
                    {
                        title: "Date Joined",
                        key: "user__date_joined",
                        typeOrder: EnumSortingType.none,
                    },
                ],
            },
            translate: {
                newTrade: "New Trade",
                trade: "Trade",
                newFreestyle: "New Freestyle",
                senders: "Senders",
                recipients: "Recipients",
                newProject: "New Project",
                freestyle: "Freestyle",
                project: "Project",
                wallets: "Wallets",
                trader: "Trader",
                addSolution: "Add Solution",
                create: "Create",
                agree: "Agree",
                disagree: "Disagree",
                definitionCode: "Definition Code",
                doYouHaveCottage: "Do You Have Cottage?",
                domesticCompany: "Domestic Company",
                amount: "Amount",
                yes: "Yes",
                no: "No",
                search: "search",

                export: "Export",
                exportSlip: "Export Slip",
                exportTrade: "Export Trade",
                exportTransactions: "Export Transactions",
                exportAllStateDebit: "Export ALL State Debit",
                exportWallet: "Export Wallet",

                newRecap: t(`translate.newRecap`),
                youMustCreateAtFirst: t(`translate.youMustCreateAtFirst`),
                moneyReceive: t(`translate.moneyReceive`),
                moneyPayment: t(`translate.moneyPayment`),
                buyCottage: t(`translate.buyCottage`),
                sellCottage: t(`translate.sellCottage`),
                deadline: t(`translate.deadline`),
                haveCurrencyAllocation: t(`translate.haveCurrencyAllocation`),
                haveOrderSubmission: t(`translate.haveOrderSubmission`),
                category: t(`translate.category`),
                currencyType: t(`translate.currencyType`),
                expectedCurrencyRate: t(`translate.expectedCurrencyRate`),
                toCurrencyType: t(`translate.toCurrencyType`),
                fromCurrencyType: t(`translate.fromCurrencyType`),

                showDetails: t(`translate.showDetails`),
                completed: t(`translate.completed`),
                inProgress: t(`translate.inProgress`),
                openProfile: t(`translate.openProfile`),
                trustyProfile: t(`translate.trustyProfile`),
                from: t(`translate.from`),
                retry: t(`translate.retry`),
                end: t(`translate.end`),
                employeeList: t(`translate.employeeList`),
                accept: t(`translate.accept`),
                trustyList: t(`translate.trustyList`),
                delete: t(`translate.delete`),
                submit: t(`translate.submit`),
                oldPassword: t(`translate.oldPassword`),
                newPassword: t(`translate.newPassword`),
                confirmNewPassword: t(`translate.confirmNewPassword`),
                addNewNestaWallet: t(`translate.addNewNestaWallet`),
                createdEmployee: t(`translate.createdEmployee`),
                updatedProfile: t(`translate.updatedProfile`),
                DateJoined: t(`translate.DateJoined`),
                password: t(`translate.password`),
                rePassword: t(`translate.rePassword`),
                username: t(`translate.username`),
                role: t(`translate.role`),
                firstName: t(`translate.firstName`),
                firstNameFa: t(`translate.firstNameFa`),
                lastName: t(`translate.lastName`),
                lastNameFa: t(`translate.lastNameFa`),
                phone: t(`translate.phone`),
                email: t(`translate.email`),
                nationalId: t(`translate.nationalId`),
                landline: t(`translate.landline`),
                dateOfBirth: t(`translate.dateOfBirth`),
                address: t(`translate.address`),
                bio: t(`translate.bio`),
                balance: t(`translate.balance`),
                credit: t(`translate.credit`),
                activities: t(`translate.activities`),

                addEmployee: t(`translate.addEmployee`),
                addTrusty: t(`translate.addTrusty`),
                addCustomer: t(`translate.addCustomer`),
                addNewChangeRate: t(`translate.addNewChangeRate`),
                addNewReceptionRate: t(`translate.addNewReceptionRate`),
                cash: t(`translate.cash`),
                noneCash: t(`translate.noneCash`),
                customer: t(`translate.customer`),
                employee: t(`translate.employee`),
                recap: t(`translate.recap`),
                recapID: t(`translate.recapID`),
                date: t(`translate.date`),
                trusty: t(`translate.trusty`),
                rate: t(`translate.rate`),
                receptionRate: t(`translate.receptionRate`),
                changeRate: t(`translate.changeRate`),
                updateCashAccount: t(`translate.updateCashAccount`),
                addNewCashAccount: t(`translate.addNewCashAccount`),
                commissionRate: t(`translate.commissionRate`),
                chooseRecommended: t(`translate.chooseRecommended`),
                chooseRecommender: t(`translate.chooseRecommender`),
                addNewRecommended: t(`translate.addNewRecommended`),
                addNewRecommender: t(`translate.addNewRecommender`),
                bankName: t(`translate.bankName`),
                branchName: t(`translate.branchName`),
                bankAddress: t(`translate.bankAddress`),
                createdAccount: t(`translate.createdAccount`),
                updatedAccount: t(`translate.updatedAccount`),
                cancel: t(`translate.cancel`),
                notFound: t(`translate.notFound`),
                addAccount: t(`translate.addAccount`),
                company: t(`translate.company`),
                companyCode: t(`translate.companyCode`),
                country: t(`translate.country`),
                authenticityDate: t(`translate.authenticityDate`),
                startDate: "Start Date",
                endDate: "End Date",
                accounts: t(`translate.accounts`),
                hideAccounts: t(`translate.hideAccounts`),
                removedFile: t(`translate.removedFile`),
                addedFile: t(`translate.addedFile`),
                updatedCompany: t(`translate.updatedCompany`),
                removedCompany: t(`translate.removedCompany`),
                removedCashAccount: t(`translate.removedCashAccount`),
                removedBankAccount: t(`translate.removedBankAccount`),
                removedEmployee: t(`translate.removedEmployee`),
                removedTrusty: t(`translate.removedTrusty`),
                removedCustomer: t(`translate.removedCustomer`),
                removedRecommended: t(`translate.removedRecommended`),
                removedRecommender: t(`translate.removedRecommender`),
                createdCompany: t(`translate.createdCompany`),
                createdTrusty: t(`translate.createdTrusty`),
                createdCustomer: t(`translate.createdCustomer`),
                yourProfileUpdated: t(`translate.yourProfileUpdated`),
                yourPasswordUpdated: t(`translate.yourPasswordUpdated`),
                yourConfirmPasswordNotMatchWithPassword: t(`translate.yourConfirmPasswordNotMatchWithPassword`),
                save: t(`translate.save`),
                update: t(`translate.update`),
                download: t(`translate.download`),
                title: t(`translate.title`),
                description: t(`translate.description`),
                addNewCompany: t(`translate.addNewCompany`),
                updateCompany: t(`translate.updateCompany`),
                companyName: t(`translate.companyName`),
                city: t(`translate.city`),
                nationalID: t(`translate.nationalID`),
                economicCode: t(`translate.economicCode`),
                activity: t(`translate.activity`),
                exportation: t(`translate.exportation`),
                ceoName: t(`translate.ceoName`),
                ceoID: t(`translate.ceoID`),
                explanation: t(`translate.explanation`),
                uploadFiles: t(`translate.uploadFiles`),
                uploadPictureForProfile: t(`translate.uploadPictureForProfile`),
                areYouWantThatChangeImageOfProfile: t(`translate.areYouWantThatChangeImageOfProfile`),
                dragDropFiles: t(`translate.dragDropFiles`),
                chooseFile: t(`translate.chooseFile`),
                maxFileSize: t(`translate.maxFileSize`),

                accountNumber: t('translate.accountNumber'),
                iBan: t('translate.iBan'),
                swiftCode: t('translate.swiftCode'),
                currency: t('translate.currency'),
                bank: t('translate.bank'),
            },
            steperGuide: {
                recap: {
                    steps: {
                        title: t(`translate.recap`),
                        keyStep: `${EnumSTepsParent.recap}`,
                        link: "#",
                        steps: [
                            {
                                title: t(`translate.initiate`),
                                keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.initiate}`,
                                link: Links.screens.panel.recap.ShowStateTheRecap,
                                steps: [],
                            },
                            {
                                title: t(`translate.fundControl`),
                                keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}`,
                                link: Links.screens.panel.recap.ShowStateTheRecap,
                                steps: [],
                            },
                            {
                                title: t(`translate.risk`),
                                keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}`,
                                link: Links.screens.panel.recap.ShowStateTheRecap,
                                steps: [],
                            },
                            {
                                title: t(`translate.solutions`),
                                keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}`,
                                link: "#",
                                steps: [
                                    {
                                        title: t(`translate.add`),
                                        keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.addSolution}`,
                                        link: Links.screens.panel.recap.ShowStateTheRecap,
                                        steps: [],
                                    },
                                    {
                                        title: t(`translate.risk`),
                                        keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
                                        link: Links.screens.panel.recap.ShowStateTheRecap,
                                        steps: [],
                                    },
                                    {
                                        title: t(`translate.finance`),
                                        keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}`,
                                        link: Links.screens.panel.recap.ShowStateTheRecap,
                                        steps: [],
                                    },
                                    {
                                        title: t(`translate.trusty`),
                                        keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`,
                                        link: Links.screens.panel.recap.ShowStateTheRecap,
                                        steps: [],
                                    },
                                ],
                            },
                            {
                                title: "Select",
                                keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.chooseSolution}`,
                                link: Links.screens.panel.recap.ShowStateTheRecap,
                                steps: [],
                            },
                            {
                                title: t(`translate.manager`),
                                keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.acceptByManager}`,
                                link: Links.screens.panel.recap.ShowStateTheRecap,
                                steps: [],
                            },
                        ],

                    },
                },
                freestyle: {
                    steps: [
                        {
                            title: t(`translate.recap`),
                            keyStep: `${EnumSTepsParent.recap}`,
                            link: "#",
                            steps: [
                                {
                                    title: t(`translate.initiate`),
                                    keyStep: `${EnumSTepsParent.recap}.${EnumSTepsRecap.initiate}`,
                                    link: Links.screens.panel.deals.freeStyle.showSelectedRecap,
                                    steps: [],
                                },
                            ]
                        },
                        {
                            title: t(`translate.freestyle`),
                            keyStep: `${EnumSTepsParent.freestyle}`,
                            link: "#",
                            steps: [
                                {
                                    title: "Select Recap",
                                    keyStep: `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.selectRecap}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "Add Steps",
                                    keyStep: `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "Approval",
                                    keyStep: `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.approval}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "Documents",
                                    keyStep: `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "P&L",
                                    keyStep: `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.calculatePANDL}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },


                            ]
                        }
                    ],
                },
                trade: {
                    steps: [
                        {
                            title: "Trade",
                            keyStep: `${EnumSTepsParent.trade}`,
                            link: "#",
                            steps: [
                                {
                                    title: "Create",
                                    keyStep: `${EnumSTepsParent.trade}.${EnumStepsTrade.creator}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "Add Steps",
                                    keyStep: `${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "Approval",
                                    keyStep: `${EnumSTepsParent.trade}.${EnumStepsTrade.approval}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                // {
                                //     title: "Documents",
                                //     keyStep: `${EnumSTepsParent.trade}.${EnumStepsTrade.uploadDocumentsForSteps}`,
                                //     link: Links.screens.panel.recap.ShowStateTheRecap,
                                //     steps: [],
                                // },
                                {
                                    title: "P&L",
                                    keyStep: `${EnumSTepsParent.trade}.${EnumStepsTrade.calculatePANDL}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },


                            ]
                        }
                    ],
                },
                slip: {
                    steps: [
                        {
                            title: "Slip",
                            keyStep: `${EnumSTepsParent.slip}`,
                            link: "#",
                            steps: [
                                {
                                    title: "Create",
                                    keyStep: `${EnumSTepsParent.slip}.${EnumStepsSlip.creator}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "Approval",
                                    keyStep: `${EnumSTepsParent.slip}.${EnumStepsSlip.waitingForApprove}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                                {
                                    title: "Done",
                                    keyStep: `${EnumSTepsParent.slip}.${EnumStepsSlip.approved}`,
                                    link: Links.screens.panel.recap.ShowStateTheRecap,
                                    steps: [],
                                },
                            ]
                        }
                    ],
                },
            },
            panel: {
                dashboard: {
                    subSidebarItems: [],
                },
                recap: {
                    subSidebarItems: [
                        {
                            subItems: [],
                            title: t(`translate.recap`),
                            link: "#",
                            key: `${EnumSectionsSidebar.recap}/`
                        }
                    ],
                },
                deals: {
                    subSidebarItems: [
                        {
                            subItems: [
                            ],
                            title: "Freestyle",
                            link: Links.screens.panel.deals.freeStyle.listFreestyle,//#
                            key: `${EnumSectionsSidebar.deals}/freestyle`
                        },
                        {
                            subItems: [
                            ],
                            title: "Trade",
                            link: Links.screens.panel.deals.trade.listTrades,//#
                            key: `${EnumSectionsSidebar.deals}/trades`
                        },
                        {
                            subItems: [
                            ],
                            title: "Slip",
                            link: Links.screens.panel.deals.slip.listSlips,//#
                            key: `${EnumSectionsSidebar.deals}/slips`
                        },
                        {
                            subItems: [
                            ],
                            title: "Project",
                            link: Links.screens.panel.deals.project.listProject,//#
                            key: `${EnumSectionsSidebar.deals}/project`
                        },
                    ],
                },
                services: {
                    subSidebarItems: [
                        {
                            subItems: [],
                            title: t(`panel.services.subSidebarItems.0.title`),
                            link: Links.screens.panel.services.cottage.cottageList,
                            key: `${EnumSectionsSidebar.services}/cottage`
                        },
                        {
                            subItems: [],
                            title: t(`panel.services.subSidebarItems.1.title`),
                            link: Links.screens.panel.services.remittance.remittanceList,
                            key: `${EnumSectionsSidebar.services}/remittance`
                        },
                        {
                            subItems: [],
                            title: t(`panel.services.subSidebarItems.2.title`),
                            link: Links.screens.panel.services.exchange.exchangeList,
                            key: `${EnumSectionsSidebar.services}/exchange`
                        },
                        {
                            subItems: [],
                            title: t(`panel.services.subSidebarItems.3.title`),
                            link: Links.screens.panel.services.receipt.receiptList,
                            key: `${EnumSectionsSidebar.services}/receipt`
                        }
                    ],
                    cottage: {


                    },
                    remittance: {

                    },
                    exchange: {

                    },
                    receipt: {
                        header: {
                            title: t(`panel.services.receipt.header.title`),
                        },
                        list: {
                            item: {
                                buttonLinkProfile: t(`panel.services.receipt.list.item.buttonLinkProfile`),
                                agencyTitle: t(`panel.services.receipt.list.item.agencyTitle`),
                                accNumber: t(`panel.services.receipt.list.item.accNumber`),
                                date: t(`panel.services.receipt.list.item.date`),
                                Download: t(`panel.services.receipt.list.item.Download`),
                                showDetails: t(`panel.services.receipt.list.item.showDetails`),
                                state: [
                                    {
                                        title: t(`panel.services.receipt.list.item.state.0.title`),
                                        key: "complete",
                                    },
                                    {
                                        title: t(`panel.services.receipt.list.item.state.1.title`),
                                        key: "inprogress",
                                    },
                                ]
                            }
                        },
                        steps: {
                            title: t(`panel.services.receipt.steps.title`),
                            subGroupItems: [
                                {
                                    title: t(`panel.services.receipt.steps.subGroupItems.0.title`),
                                    subStep: t(`panel.services.receipt.steps.subGroupItems.0.subStep`),
                                },
                                {
                                    title: t(`panel.services.receipt.steps.subGroupItems.1.title`),
                                    subStep: t(`panel.services.receipt.steps.subGroupItems.1.subStep`),
                                },
                                {
                                    title: t(`panel.services.receipt.steps.subGroupItems.2.title`),
                                    subStep: t(`panel.services.receipt.steps.subGroupItems.2.subStep`),
                                },
                                {
                                    title: t(`panel.services.receipt.steps.subGroupItems.3.title`),
                                    subStep: t(`panel.services.receipt.steps.subGroupItems.3.subStep`),
                                }

                            ]

                        }
                    }
                },
                people: {
                    subSidebarItems: [
                        {
                            subItems: [
                                // {
                                //     title: "Customer List",
                                //     link: Links.screens.panel.people.customers.customersList,
                                //     key: Links.screens.panel.people.customers.customersList,
                                // },
                                // {
                                //     title: "New Customer",
                                //     link: Links.screens.panel.people.customers.addCustomer,
                                //     key: Links.screens.panel.people.customers.addCustomer,
                                // }
                            ],
                            title: "Customers",
                            link: Links.screens.panel.people.customers.customersList,//#
                            key: `${EnumSectionsSidebar.people}/customers`
                        },
                        {
                            subItems: [
                                // {
                                //     title: "Trusty List",
                                //     link: Links.screens.panel.people.trusties.trustiesList,
                                //     key: Links.screens.panel.people.trusties.trustiesList,
                                // },
                                // {
                                //     title: "New Trusty",
                                //     link: Links.screens.panel.people.trusties.addTrusty,
                                //     key: Links.screens.panel.people.trusties.addTrusty,
                                // }
                            ],
                            title: "Trusties",
                            link: Links.screens.panel.people.trusties.trustiesList,
                            key: `${EnumSectionsSidebar.people}/trusties`
                        },
                        {
                            subItems: [
                                // {
                                //     title: "Employee List",
                                //     link: Links.screens.panel.people.employees.employeesList,
                                //     key: Links.screens.panel.people.employees.employeesList,
                                // },
                                // {
                                //     title: "New Employee",
                                //     link: Links.screens.panel.people.employees.addEmployee,
                                //     key: Links.screens.panel.people.employees.addEmployee,
                                // }
                            ],
                            title: "Employees",
                            link: Links.screens.panel.people.employees.employeesList,
                            key: `${EnumSectionsSidebar.people}/employees`
                        },
                    ],
                    customerList: {
                        api: {
                            message: {
                                success: {
                                    title: t('panel.people.customerList.api.message.success.title')
                                },
                                error: {
                                    title: t('panel.people.customerList.api.message.error.title')
                                }
                            }
                        },
                        pageInfo: {
                            title: t('panel.people.customerList.pageInfo.title'),
                            addButton: t('panel.people.customerList.pageInfo.addButton'),
                            item: {
                                credit: t('panel.people.customerList.pageInfo.item.credit'),
                                Balance: t('panel.people.customerList.pageInfo.item.Balance'),
                                delete: t('panel.people.customerList.pageInfo.item.delete'),
                                seeProfile: t('panel.people.customerList.pageInfo.item.seeProfile'),
                                unit: t('panel.people.customerList.pageInfo.item.unit'),
                            }
                        }
                    },
                    customerInfo: {
                        api: {
                            message: {
                                success: {
                                    title: t('panel.people.customerList.api.message.success.title')
                                },
                                error: {
                                    title: t('panel.people.customerList.api.message.error.title')
                                }
                            }
                        },
                        pageInfo: {
                            navbarHorizontalItem: [

                                {
                                    text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.0.text'),
                                    id: EnumSubPageProfileCustomerId.profileSection,
                                    child: []
                                },
                                {
                                    text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.1.text'),
                                    id: EnumSubPageProfileCustomerId.cashAccountSection,
                                    child: []
                                },
                                {
                                    text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.3.text'),
                                    id: EnumSubPageProfileCustomerId.recommendation,
                                    child: [
                                        {
                                            text: t('translate.recommender'),
                                            id: EnumSubPageProfileCustomerId.recommenderSection,
                                        },
                                        {
                                            text: t('translate.recommended'),
                                            id: EnumSubPageProfileCustomerId.recommendedSection,
                                        }
                                    ]
                                },
                                {
                                    text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.4.text'),
                                    id: EnumSubPageProfileCustomerId.dealSection,
                                    child: [
                                        {
                                            text: "Trade",
                                            id: EnumSubPageProfileCustomerId.TradeStepsTrustyOrCustomer,
                                        },
                                        {
                                            text: "Slip",
                                            id: EnumSubPageProfileCustomerId.slipStepsTrustyOrCustomer,
                                        },
                                        {
                                            text: "Export",
                                            id: EnumSubPageProfileCustomerId.exportStepsTrustyOrCustomer,
                                        }

                                    ]
                                },
                                {
                                    text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.5.text'),
                                    id: EnumSubPageProfileCustomerId.serviceSection,
                                    child: []
                                },
                                {
                                    text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.6.text'),
                                    id: EnumSubPageProfileCustomerId.company,
                                    child: [
                                        {
                                            text: t('translate.foreign'),
                                            id: EnumSubPageProfileCustomerId.foreignCompanySection,
                                        },
                                        {
                                            text: t('translate.domestic'),
                                            id: EnumSubPageProfileCustomerId.domesticCompanySection,
                                        }
                                    ]

                                },

                            ],

                            title: t('panel.people.customerInfo.pageInfo.title'),
                            header: {

                                credit: t('panel.people.customerInfo.pageInfo.header.credit'),
                                balance: t('panel.people.customerInfo.pageInfo.header.balance'),
                                delete: t('panel.people.customerInfo.pageInfo.header.delete'),
                                seeProfile: t('panel.people.customerInfo.pageInfo.header.seeProfile'),
                                unit: t('panel.people.customerInfo.pageInfo.header.unit'),
                                buttonWallet: t('panel.people.customerInfo.pageInfo.header.buttonWallet'),

                            },
                            subInfo: {
                                customerBasicInformation: {
                                    personalInformation: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.personalInformation'),
                                    name: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.name'),
                                    family: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.family'),
                                    persianName: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.persianName'),
                                    persianFamily: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.persianFamily'),
                                    nationalId: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.nationalId'),
                                    dateOfBirth: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.dateOfBirth'),
                                    phone: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.phone'),
                                    dateJoined: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.dateJoined'),
                                    address: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.address'),
                                    bio: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.bio'),
                                    recentJob: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.recentJob'),
                                    activities: t('panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.activities'),
                                }
                            }
                        },
                    },
                    trusties: {
                        profile: {
                            pageInfo: {
                                navbarHorizontalItem: [

                                    {
                                        text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.0.text'),
                                        id: EnumSubPageProfileTrustyId.profileSection,
                                        child: []
                                    },
                                    {
                                        text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.1.text'),
                                        id: EnumSubPageProfileTrustyId.cashAccountSection,
                                        child: []
                                    },
                                    {
                                        text: t('translate.rate'),
                                        id: EnumSubPageProfileTrustyId.rate,
                                        child: [
                                            {

                                                text: t(`translate.receptionRate`),
                                                id: EnumSubPageProfileTrustyId.receptionRateSection,
                                            },
                                            {
                                                text: t(`translate.changeRate`),
                                                id: EnumSubPageProfileTrustyId.changeRateSection,
                                            },
                                        ]
                                    },
                                    {
                                        text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.3.text'),
                                        id: EnumSubPageProfileTrustyId.recommendation,
                                        child: [
                                            {
                                                text: t('translate.recommender'),
                                                id: EnumSubPageProfileTrustyId.recommenderSection,
                                            },
                                            {
                                                text: t('translate.recommended'),
                                                id: EnumSubPageProfileTrustyId.recommendedSection,
                                            }
                                        ]
                                    },
                                    {
                                        text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.4.text'),
                                        id: EnumSubPageProfileTrustyId.dealSection,
                                        child: [
                                            {
                                                text: "Trade",
                                                id: EnumSubPageProfileTrustyId.TradeStepsTrustyOrCustomer,
                                            },
                                            {
                                                text: "Slip",
                                                id: EnumSubPageProfileTrustyId.slipStepsTrustyOrCustomer,
                                            },
                                            {
                                                text: "Export",
                                                id: EnumSubPageProfileTrustyId.exportStepsTrustyOrCustomer,
                                            },
                                        ]
                                    },
                                    {
                                        text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.5.text'),
                                        id: EnumSubPageProfileTrustyId.serviceSection,
                                        child: []
                                    },
                                    {
                                        text: t('panel.people.customerInfo.pageInfo.navbarHorizontal.6.text'),
                                        id: EnumSubPageProfileTrustyId.company,
                                        child: [
                                            {
                                                text: t('translate.foreign'),
                                                id: EnumSubPageProfileTrustyId.foreignCompanySection,
                                            },
                                            {
                                                text: t('translate.domestic'),
                                                id: EnumSubPageProfileTrustyId.domesticCompanySection,
                                            }
                                        ]

                                    },

                                ],
                            }

                        },
                    },
                    employee: {
                    }
                },
                report: {
                    subSidebarItems: [
                        {
                            subItems: [
                                // {
                                //     title: "Customer List",
                                //     link: Links.screens.panel.people.customers.customersList,
                                //     key: Links.screens.panel.people.customers.customersList,
                                // },
                                // {
                                //     title: "New Customer",
                                //     link: Links.screens.panel.people.customers.addCustomer,
                                //     key: Links.screens.panel.people.customers.addCustomer,
                                // }
                            ],
                            title: "Excel",
                            link: Links.screens.panel.report.excel,//#
                            key: `${EnumSectionsSidebar.report}/excel`
                        },
                    ],
                },
                myProfile: {
                    pageInfo: {
                        title: t('panel.myProfile.pageInfo.title'),
                        fields: {
                            header: t('panel.myProfile.pageInfo.fields.header'),
                            name: {
                                hint: t('panel.myProfile.pageInfo.fields.name.hint'),
                            },
                            family: {
                                hint: t('panel.myProfile.pageInfo.fields.family.hint'),
                            },
                            nationalID: {
                                hint: t('panel.myProfile.pageInfo.fields.nationalID.hint'),
                            },
                            accessibility: {
                                hint: t('panel.myProfile.pageInfo.fields.accessibility.hint'),
                            },
                            phone: {
                                hint: t('panel.myProfile.pageInfo.fields.phone.hint'),
                            },
                            dateOfBirth: {
                                hint: t('panel.myProfile.pageInfo.fields.dateOfBirth.hint'),
                            },
                            joinDate: {
                                hint: t('panel.myProfile.pageInfo.fields.joinDate.hint'),
                            },
                            address: {
                                hint: t('panel.myProfile.pageInfo.fields.address.hint'),
                            },
                            email: {
                                hint: t('panel.myProfile.pageInfo.fields.email.hint'),
                            }

                        },
                        changePassword: {
                            buttonText: t('panel.myProfile.pageInfo.changePassword.buttonText'),
                        },
                        saveButton: t('panel.myProfile.pageInfo.saveButton')
                    }
                },
                mySetting: {
                    title: t('panel.mySetting.title')
                },
                myNotifications: {
                    title: t('panel.myNotifications.title')
                }
            },
        }

    );
}
