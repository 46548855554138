import { PaymentsRounded, TrendingDownRounded, TrendingUpRounded, TrendingFlatRounded, AddRounded } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { TrashIcon } from "../../../assets/icons/svg/TrashIcon";
import { WalletIcon } from "../../../assets/icons/svg/WalletIcon";
import Colors from "../../../config/Colors";
import { CurrencySymbols } from "../../../helper/CurrencySymbols";
import { getColorCodeOfBalanceNumber } from "../../../helper/Helper";
import { InterfaceNTTProfile } from "../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../NTTs/InterfaceNTTProfileType";
import ArrayText from "../../basic/Texts/ArrayText/ArrayText";
import CreateCommissionOfTrade from "./CreateCommissionOfTrade/CreateCommissionOfTrade";
import { InterfaceCreateCommissionTypeValue } from "./CreateCommissionOfTrade/InterfaceCreateCommissionOfTrade";
import { InterfaceMultiCommissionOfTrade } from "./InterfaceMultiCommissionOfTrade";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { InterfaceNTTCommissionOfTrade } from "../../../NTTs/Trade/InterfaceNTTCommissionOfTrade";
import { listenerCancelled } from "@reduxjs/toolkit/dist/listenerMiddleware/exceptions";
import { EnumTypeStateDebt } from "../../../config/enums/EnumTypeStateDebt";
import ShowUnitOfMoneyThreePart from "../ShowUnitOfMoneyThreePart/ShowUnitOfMoneyThreePart";

function MultiCommissionOfTrade(paramsComponent: InterfaceMultiCommissionOfTrade) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const { idMainTrade } = useParams();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("2");
    const [getCommissions, setCommissions] = useState<InterfaceNTTCommissionOfTrade[]>([]);
    const [getAddCommissionActive, setAddCommissionActive] = useState<boolean>(false);
    useEffect(() => {
        setCommissions(paramsComponent.defaultList);
    }, [paramsComponent.defaultList])
    const addNewEmptyCommission = async () => {
        setAddCommissionActive(true);
    }
    return (
        <Box sx={{
            boxSizing: 'border-box',
            display: 'flex', flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
        }}>

            <label style={{
                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: Colors.theme.blue.light,
                marginBottom: '0.22rem',
            }}>
                {"Commissions:"}
            </label>
            {!getAddCommissionActive &&
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    border: `1px solid ${Colors.theme.gray.gray}`,
                    background: Colors.theme.white.white90,
                    borderRadius: '0.37rem',
                    paddingBlock: '0.37rem',
                    boxSizing: 'border-box',
                    width: '100%',
                }}>



                    {getCommissions.flatMap((commission, index, arr) => {
                        let listElement = [
                            <div style={{
                                width: '100%',
                                display: 'flex', flexDirection: 'row',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                justifyContent: 'start',
                                boxSizing: 'border-box',
                                paddingInline: "0.88rem",
                                marginBlockEnd: '0.4rem',
                            }}>
                                <p style={{
                                    display: 'inline-flex', flexDirection: 'row',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    flexGrow: 0,
                                    flexBasis: '2rem',
                                    fontSize: '0.96rem',
                                    lineHeight: '1.55rem',
                                    flexWrap: 'nowrap',
                                }}>
                                    {`${index + 1} :`}
                                </p>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: "0.57rem",
                                    fontFamily: confComps.font.poppins.Regular,
                                    fontSize: '0.9rem',
                                    flexGrow: '1',
                                    flexBasis: '0',
                                }}>


                                    {commission.person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                        `${(commission.person.profile as InterfaceNTTProfile).first_name}  ${(commission.person.profile as InterfaceNTTProfile).last_name}`
                                    }
                                    {commission.person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                        `${(commission.person.profile as InterfaceNTTProfileForeignLegal).name}`
                                    }
                                    {commission.person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                        `${(commission.person.profile as InterfaceNTTProfileIranLegal).name} (${(commission.person.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                    }
                                    {(commission.description.length > 0) && ` (${commission.description})`}
                                </div>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    flexGrow: '0',
                                }}>
                                    <ArrayText
                                        texts={[
                                            {
                                                text: `change value: `,
                                                style: {
                                                    color: Colors.theme.blue.light,
                                                }
                                            },
                                            {
                                                text:
                                                    `${[commission.amount.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                                    ...commission.amount.replace(/[^0-9.]/g, '').split(".").slice(1)
                                                    ].join(".")}`,
                                                style: {
                                                    color: getColorCodeOfBalanceNumber((commission.debt_status.value === EnumTypeStateDebt.none) ? "0" :
                                                        (commission.debt_status.value === EnumTypeStateDebt.debit) ? "-1" : "1"),
                                                }
                                            },

                                        ]}
                                    />
                                    <div style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        textAlign: "start",
                                        fontSize: "0.96rem",
                                        lineHeight: "1.44rem",
                                        color: getColorCodeOfBalanceNumber((commission.debt_status.value === EnumTypeStateDebt.none) ? "0" :
                                            (commission.debt_status.value === EnumTypeStateDebt.debit) ? "-1" : "1"),
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '0.32rem',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={commission.currency_unit} />
                                    </div>
                                    {commission.debt_status.value === EnumTypeStateDebt.debit &&
                                        <TrendingDownRounded
                                            style={{
                                                textAlign: "start",
                                                fontSize: "1.03rem",
                                                lineHeight: "1.44rem",
                                                color: getColorCodeOfBalanceNumber("-1"),
                                            }} />}
                                    {commission.debt_status.value === EnumTypeStateDebt.credit &&
                                        <TrendingUpRounded style={{
                                            textAlign: "start",
                                            fontSize: "0.96rem",
                                            lineHeight: "1.44rem",
                                            color: getColorCodeOfBalanceNumber("1"),
                                        }} />
                                    }
                                    {commission.debt_status.value === EnumTypeStateDebt.none &&
                                        <TrendingFlatRounded style={{
                                            textAlign: "start",
                                            fontSize: "0.96rem",
                                            lineHeight: "1.44rem",
                                            color: getColorCodeOfBalanceNumber("0"),
                                        }} />
                                    }

                                    {paramsComponent.isCreationActive &&
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                            onClick={() => {
                                                const updatedCommissions = getCommissions.filter((_, i) => i !== index);
                                                paramsComponent.callbackUpdateList(updatedCommissions);
                                            }}
                                        >
                                            <TrashIcon style={{ marginInlineStart: '0.58rem' }} />
                                        </div>
                                    }
                                </div>
                            </div>
                        ]
                        if (arr.length !== index + 1 || paramsComponent.isCreationActive) {
                            listElement.push(
                                <div style={{
                                    width: '100%',
                                    height: '1px',
                                    borderRadius: '10px',
                                    background: '#E2E4EC',
                                }} />
                            );
                        }
                        return listElement;
                    })}


                    {paramsComponent.isCreationActive &&
                        <div style={{//add button design
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                            justifyContent: 'start',
                            width: '100%',
                            paddingInline: '0.88rem',
                            boxSizing: 'border-box',

                        }}>
                            <div style={{
                                display: 'inline-flex', flexDirection: 'row',
                                alignItems: 'center', justifyContent: 'start',
                                fontFamily: confComps.font.poppins.Medium,
                                fontSize: '0.88rem',
                                lineHeight: '1.33rem',
                                color: Colors.theme.orange.light,
                                cursor: 'pointer',
                                userSelect: 'none',
                            }}
                                onClick={addNewEmptyCommission}
                            >
                                <AddRounded style={{
                                    color: Colors.theme.orange.light, fontSize: '0.88rem',
                                    userSelect: 'none',
                                }} />

                                {"Add New Commission"}


                            </div>

                        </div>
                    }
                </div>
            }
            {getAddCommissionActive && paramsComponent.isCreationActive &&
                <Box sx={{
                    width: '100%',
                    border: `0.5px solid ${Colors.theme.blue.border}`,
                    boxSizing: 'border-box',
                    boxShadow: Colors.shadow.cardList,
                    paddingInline: { xs: "0.7rem", sm: "1rem", md: "2.66rem", lg: "2.96rem", xl: "3.25rem" },
                    paddingBlock: "1.18rem",
                    borderRadius: '0.38rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'flex-start',
                    gap: "1rem",
                }}>
                    <CreateCommissionOfTrade
                        callBackSave={(info: InterfaceCreateCommissionTypeValue) => {
                            paramsComponent.callbackUpdateList([...getCommissions, info]);
                            setAddCommissionActive(false);
                        }}
                        callbackCancel={() => {
                            setAddCommissionActive(false);
                        }}
                    />
                </Box>
            }
        </Box>
    )
};
export default MultiCommissionOfTrade;