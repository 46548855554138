import { PaymentsRounded } from "@mui/icons-material";
import { WalletIcon } from "../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../helper/CurrencySymbols";
import { InterfaceShowUnitOfMoneyThreePart } from "./InterfaceShowUnitOfMoneyThreePart";
import React, { useEffect, useRef, useState } from "react";
import ToolTipText from "../../basic/ToolTipText/ToolTipText";

const ShowUnitOfMoneyThreePart: any = (paramsComponent: InterfaceShowUnitOfMoneyThreePart) => {


  return (
    <ToolTipText
      textHint={`${paramsComponent.currencyNTT.is_cash ? "cash" : "none cash"} | ${paramsComponent.currencyNTT.country.name} | ${paramsComponent.currencyNTT.currency.title}`}
    >
      <div style={{
        display: 'flex', flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.4rem',
      }}>

        {"("}
        {paramsComponent.currencyNTT.is_cash ?
          <PaymentsRounded style={{
            fontSize: '1.12rem',
            lineHeight: "1.68rem",
          }} />
          :
          <WalletIcon
            style={{
              fontSize: '1.12rem',
              lineHeight: "1.68rem",
            }}
          />
        }
        {paramsComponent.currencyNTT.country && paramsComponent.currencyNTT.country.iso && <img
          src={`https://flagcdn.com/w320/${paramsComponent.currencyNTT.country.iso}.png`} // Fallback image
          alt={`${paramsComponent.currencyNTT.country.iso}`}
          style={{ width: 'auto', height: '1.12rem' }}
        />}
        {CurrencySymbols[paramsComponent.currencyNTT.currency.symbol]}
        {")"}
      </div>
    </ToolTipText>
  )
}

export default ShowUnitOfMoneyThreePart;
