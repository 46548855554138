import { useEffect, useState } from "react";
import Colors from "../../../../config/Colors";
import DatePickerSimple from "../../../basic/Dates/DatePickerSimple/DatePickerSimple";
import TextFieldMultiSelectorActivitiesJobSimple from "../../../basic/TextField/ActivitiesJob/TextFieldMultiSelectorActivitiesJobSimple/TextFieldMultiSelectorActivitiesJobSimple";
import TextFieldSelectorRolesEmployeeSimple from "../../../basic/TextField/RoleEmployee/TextFieldSelectorRolesEmployeeSimple/TextFieldSelectorRolesEmployeeSimple";
import { ImageSquare } from "../../imageSquare/ImageSquare";
import { EnumItemThatHandleInSmartSearch } from "../AdvanceSearchBox";
import { AdvanceItemInputOfNumberMoneyType, AdvanceItemInputOfStringType, AdvanceItemOfNumberMoneyType, AdvanceItemTitleOfStringType } from "../AdvanceSearchBoxStyle";
import { InterfaceAdvanceBox } from "../InterfaceAdvanceSearchBox";
import { InterfaceAllChildrenOfAdvanceBoxGroup } from "./InterfaceAllChildrenOfAdvanceBoxGroup";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import TextFieldMultiSelectorCurrencyUnitSimple from "../../../basic/TextField/CurrencyUnit/TextFieldMultiSelectorCurrencyUnitSimple/TextFieldMultiSelectorCurrencyUnitSimple";

function AllChildrenOfAdvanceBoxGroup(paramsComponent: InterfaceAllChildrenOfAdvanceBoxGroup) {
    var confComps = ConfigComps();
    const [getAdvanceParams, setAdvanceParams] = useState<InterfaceAdvanceBox>(paramsComponent.defaultValue);
    useEffect(() => {
        paramsComponent.callbackOnChangeValue(getAdvanceParams);
    }, [getAdvanceParams])
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
        }}>
            {getAdvanceParams.stringsItem.map((stringItem, index, arr) => {
                return (
                    <AdvanceItemTitleOfStringType>
                        {stringItem.title}
                        {":"}
                        <AdvanceItemInputOfStringType
                            type="text"
                            autoComplete="new-"
                            placeholder={stringItem.placeholder}
                            value={stringItem.value}
                            onChange={(event) => {
                                let newString = event.target.value;
                                setAdvanceParams(prev => {
                                    let prevString = prev.stringsItem;
                                    prevString[index].value = newString;
                                    return ({
                                        ...prev,
                                        strings: prevString,
                                    })
                                })
                            }}
                        />
                    </AdvanceItemTitleOfStringType>
                )
            })}
            {getAdvanceParams.dateItem.flatMap((dateItem, index, arr) => {
                return (
                    [<AdvanceItemTitleOfStringType>
                        {`${dateItem.title} ${confComps.translate.from}`}
                        {":"}
                        <DatePickerSimple
                            title={confComps.translate.dateOfBirth}
                            onCloseDialogDate={() =>
                                setAdvanceParams(prev => {
                                    let prevDates = prev.dateItem;
                                    prevDates[index].isDialogOpenStart = false;
                                    return ({
                                        ...prev,
                                        dateItem: prevDates,
                                    })
                                })
                            }
                            onOpenDialogDate={() =>
                                setAdvanceParams(prev => {
                                    let prevDates = prev.dateItem;
                                    prevDates[index].isDialogOpenStart = true;
                                    return ({
                                        ...prev,
                                        dateItem: prevDates,
                                    })
                                })
                            }
                            onChangeValue={(timeSecond: number | undefined) => {
                                setAdvanceParams(prev => {
                                    let prevDates = prev.dateItem;
                                    prevDates[index].start = timeSecond;
                                    return ({
                                        ...prev,
                                        dateItem: prevDates,
                                    })
                                })
                            }}
                            defaultTimeInSecond={dateItem.start}
                        />
                    </AdvanceItemTitleOfStringType>
                        ,
                    <AdvanceItemTitleOfStringType>
                        {`${dateItem.title} ${confComps.translate.end}`}
                        {":"}
                        <DatePickerSimple
                            title={confComps.translate.dateOfBirth}
                            onCloseDialogDate={() =>
                                setAdvanceParams(prev => {
                                    let prevDates = prev.dateItem;
                                    prevDates[index].isDialogOpenEnd = false;
                                    return ({
                                        ...prev,
                                        dateItem: prevDates,
                                    })
                                })
                            }
                            onOpenDialogDate={() =>
                                setAdvanceParams(prev => {
                                    let prevDates = prev.dateItem;
                                    prevDates[index].isDialogOpenEnd = true;
                                    return ({
                                        ...prev,
                                        dateItem: prevDates,
                                    })
                                })
                            }
                            onChangeValue={(timeSecond: number | undefined) => {
                                setAdvanceParams(prev => {
                                    let prevDates = prev.dateItem;
                                    prevDates[index].end = timeSecond;
                                    return ({
                                        ...prev,
                                        dateItem: prevDates,
                                    })
                                })
                            }}
                            defaultTimeInSecond={dateItem.end}
                        />
                    </AdvanceItemTitleOfStringType>

                    ]
                )

            })}
            {getAdvanceParams.relationIDsItem.map((relationItem, index, arr) => {
                return (
                    <AdvanceItemTitleOfStringType>
                        {relationItem.title}
                        {":"}
                        {relationItem.itemType === EnumItemThatHandleInSmartSearch.role &&
                            <TextFieldSelectorRolesEmployeeSimple
                                styleItems={{
                                    background: Colors.theme.orange.light,
                                    borderRadius: "500px",
                                }}
                                hint={confComps.translate.role}
                                onChangeSelectedRoles={(selectRole: number[]) => {
                                    setAdvanceParams(prev => {
                                        let prevRelation = prev.relationIDsItem;
                                        prevRelation[index].id = selectRole;
                                        return ({
                                            ...prev,
                                            relationIDsItem: prevRelation,
                                        })
                                    })
                                }}
                                selectedRole={relationItem.id}
                            // iconEnd={EditPenIcon}
                            // selectedRole={[]}
                            />}

                        {relationItem.itemType === EnumItemThatHandleInSmartSearch.activitiesJob &&
                            <TextFieldMultiSelectorActivitiesJobSimple
                                styleItems={{
                                    background: Colors.theme.orange.light,
                                    borderRadius: "500px",
                                }}
                                hint={confComps.translate.role}
                                onChangeSelectedRoles={(selectedActivities: number[]) => {
                                    setAdvanceParams(prev => {
                                        let prevRelation = prev.relationIDsItem;
                                        prevRelation[index].id = selectedActivities;
                                        return ({
                                            ...prev,
                                            relationIDsItem: prevRelation,
                                        })
                                    })
                                }}
                                selectedActivities={relationItem.id}
                            // iconEnd={EditPenIcon}
                            // selectedRole={[]}
                            />
                        }
                        {relationItem.itemType === EnumItemThatHandleInSmartSearch.currency_unit &&
                            <TextFieldMultiSelectorCurrencyUnitSimple
                                styleItems={{
                                    background: Colors.theme.orange.light,
                                    borderRadius: "500px",
                                }}
                                hint={"Currency"}
                                onChangeSelectedCurrency={(selectCurrenciesID: number[]) => {
                                    setAdvanceParams(prev => {
                                        let prevRelation = prev.relationIDsItem;
                                        prevRelation[index].id = selectCurrenciesID;
                                        return ({
                                            ...prev,
                                            relationIDsItem: prevRelation,
                                        })
                                    })
                                }}
                                selectedCurrencies={relationItem.id}
                            // iconEnd={EditPenIcon}
                            // selectedRole={[]}
                            />
                        }

                    </AdvanceItemTitleOfStringType>
                );
            })}
            {getAdvanceParams.numbersItem.map((numberItem, index, arr) => {
                return (
                    <AdvanceItemTitleOfStringType>
                        {numberItem.title}
                        {":"}
                        <AdvanceItemOfNumberMoneyType>
                            <ImageSquare
                                width={"1.4rem"}
                                background={Colors.theme.orange.light}
                                borderRadius="500px"
                                element={
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.6rem",
                                        color: Colors.theme.blue.dark,
                                    }}>
                                        AED
                                    </p>
                                }
                            />


                            {"Min:"}
                            <AdvanceItemInputOfNumberMoneyType
                                type="text"
                                autoComplete="new-"
                                placeholder={numberItem.placeholderMin}
                                style={{
                                    width: "100%",
                                    cursor: "pointer",
                                }}
                                value={numberItem.min}
                                onChange={(event) => {
                                    let newString = event.target.value.replace(/[^0-9.]/g, '');
                                    setAdvanceParams(prev => {
                                        let prevNumber = prev.numbersItem;
                                        prevNumber[index].min = newString;
                                        return ({
                                            ...prev,
                                            numbersItem: prevNumber,
                                        })
                                    })
                                }}
                            />
                        </AdvanceItemOfNumberMoneyType>


                        <AdvanceItemOfNumberMoneyType>
                            <ImageSquare
                                width={"1.4rem"}
                                background={Colors.theme.orange.light}
                                borderRadius="500px"
                                element={
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.6rem",
                                        color: Colors.theme.blue.dark,
                                    }}>
                                        AED
                                    </p>
                                }
                            />
                            {"Max:"}
                            <AdvanceItemInputOfNumberMoneyType
                                type="text"
                                autoComplete="new-"
                                placeholder={numberItem.placeholderMax}
                                style={{
                                    width: "100%",
                                    cursor: "pointer",
                                }}
                                value={numberItem.max}
                                onChange={(event) => {
                                    let newString = event.target.value.replace(/[^0-9.]/g, '');
                                    setAdvanceParams(prev => {
                                        let prevNumber = prev.numbersItem;
                                        prevNumber[index].max = newString;
                                        return ({
                                            ...prev,
                                            numbersItem: prevNumber,
                                        })
                                    })
                                }}
                            />
                        </AdvanceItemOfNumberMoneyType>
                    </AdvanceItemTitleOfStringType>
                )
            })}
        </div>
    );
}
export default AllChildrenOfAdvanceBoxGroup;