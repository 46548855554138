import { InterfaceFinalRecap } from "../../screens/Deals/Trade/CreateTrade/InterfaceCreateTrade";
import { InterfaceNTTActivity } from "../InterfaceNTTActivity";
import { InterfaceNTTProfile } from "../InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../InterfaceNTTProfileIranLegal";
import { InterfaceNTTProfileType } from "../InterfaceNTTProfileType";
import { InterfaceNTTUser } from "../InterfaceNTTUser";
import { InterfaceNTTRecapMain } from "../Recap/InterfaceNTTRecapMain";
import { InterfaceNTTStepOfTrade } from "./InterfaceNTTStepOfTrade";
export enum EnumStateValueOfTrade {
    created = 1,
    refinement = 2,
    pendingForCheckByManager = 3,
    approvedByManager = 4,
}
export interface InterfaceNTTTradeMain {
    id: number;
    code: string;
    title: string;
    description: string;
    senders: {
            user: InterfaceNTTUser;
            profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
            person_type:InterfaceNTTProfileType;
            is_customer: boolean;
            is_trusty: boolean;
            balance: string;
            credit: string;
            activities: InterfaceNTTActivity[];
            description: string;
        }[];
    recipients: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile;
        person_type:InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    }[];
    state: {
        label: string;
        value: EnumStateValueOfTrade;
    },
    steps: InterfaceNTTStepOfTrade[];
    recaps: InterfaceFinalRecap[];
    created_at: number;
    updated_at: number;
}