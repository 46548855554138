import { useEffect, useState } from "react";
import { InterfaceCreateOneStepOfTrade, InterfaceInnerBeneficiaryOrder } from "./InterfaceCreateOneStepOfTrade";
import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    AddRounded,
    ArrowRightRounded,
    AutorenewRounded,
    CancelRounded,
    DoneRounded,
    KeyboardArrowDownRounded,
    PaymentsRounded,
    TrendingDownRounded,
    TrendingFlatRounded,
    TrendingUpRounded,
    UpgradeRounded
} from "@mui/icons-material";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import Colors, { ColorThemForButton } from "../../../../../../config/Colors";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import ButtonColor from "../../../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import SelectTrustyOrCustomer from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../NTTs/InterfaceNTTCurrency";
import { InterfaceGetAPITrustyOrCustomer, InterfaceTypeSelectTrustyOrCustomer } from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import TextFieldMultiLine from "../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import TextFieldSimple from "../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import SelectCurrencyWithTypeAndLocation from "../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import SelectYesOrNo from "../../../../../../components/complex/Selector/SelectYesOrNo/SelectYesOrNo";
import { WalletIcon } from "../../../../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../../../../helper/CurrencySymbols";
import ChangeRateWithAmount from "../../../../../../components/complex/ChangeRateWithAmount/ChangeRateWithAmount";
import SelectPriority from "../../../../../../components/complex/Selector/SelectPriority/SelectPriority";
import { TrashIcon } from "../../../../../../assets/icons/svg/TrashIcon";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { EnumStateValueOfTradeStep } from "../../../../../../NTTs/Trade/InterfaceNTTStepOfTrade";
import { InterfaceNTTTradeMain } from "../../../../../../NTTs/Trade/InterfaceNTTTradeMain";
import { InterfaceNTTTypeOfTradeStep } from "../../../../../../NTTs/Trade/InterfaceNTTTypeOfTradeStep";
import SelectTypeOfTradeStep from "../../../../../../components/complex/Selector/SelectTypeOfTradeStep/SelectTypeOfTradeStep";
import { InterfaceSelectCommon, InterfaceSelectedItemForSelectCommon } from "../../../../../../components/complex/Selector/SelectCommon/InterfaceSelectCommon";
import SelectCommon from "../../../../../../components/complex/Selector/SelectCommon/SelectCommon";
import InnerBeneficiary from "../../../../../../components/complex/InnerBeneficiary/InnerBeneficiary";
import MultiNetProfit from "../../../../../../components/complex/MultiNetProfit/MultiNetProfit";
import { InterfaceNTTProfitOfTrade } from "../../../../../../NTTs/Trade/InterfaceNTTProfitOfTrade";
import { InterfaceNTTCommissionOfTrade } from "../../../../../../NTTs/Trade/InterfaceNTTCommissionOfTrade";
import MultiCommissionOfTrade from "../../../../../../components/complex/MultiCommissionOfTrade/MultiCommissionOfTrade";
import { EnumTypeStateDebt } from "../../../../../../config/enums/EnumTypeStateDebt";

function CreateOneStepOfTrade(paramsComponent: InterfaceCreateOneStepOfTrade) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getTradeMain, setTradeMain] = useState<InterfaceNTTTradeMain>();
    const [getIsShowThisBox, setIsShowThisBox] = useState<boolean>(false);
    // from
    const [getFromCustomerSelected, setFromCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getFromSelectedCurrencyType1, setFromSelectedCurrencyType1] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getFromAmountValueTypeCurrency1, setFromAmountValueTypeCurrency1] = useState<string>("");
    const [getFromFixCommissionTypeCurrency1, setFromFixCommissionTypeCurrency1] = useState<string>("");
    const [getFromTotalAmountTypeCurrency1, setFromTotalAmountTypeCurrency1] = useState<string>("");
    const [getFromDebtStatusTypeCurrency1, setFromDebtStatusTypeCurrency1] = useState<InterfaceSelectedItemForSelectCommon | undefined>(confComps.component.selectorCommon.SelectStateDebt[0]);

    const [getFromSelectedCurrencyType2, setFromSelectedCurrencyType2] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getFromChangeRateCurrency1ToCurrency2, setFromChangeRateCurrency1ToCurrency2] = useState<string>("");
    const [getFromTotalAmountCurrencyType2, setFromTotalAmountCurrencyType2] = useState<string>("");
    const [getFromDebtStatusTypeCurrency2, setFromDebtStatusTypeCurrency2] = useState<InterfaceSelectedItemForSelectCommon | undefined>(confComps.component.selectorCommon.SelectStateDebt[0]);
    // to
    const [getToCustomerSelected, setToCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getToSelectedCurrencyType1, setToSelectedCurrencyType1] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getToAmountValueTypeCurrency1, setToAmountValueTypeCurrency1] = useState<string>("");
    const [getToFixCommissionTypeCurrency1, setToFixCommissionTypeCurrency1] = useState<string>("");
    const [getToTotalAmountTypeCurrency1, setToTotalAmountTypeCurrency1] = useState<string>("");
    const [getToDebtStatusTypeCurrency1, setToDebtStatusTypeCurrency1] = useState<InterfaceSelectedItemForSelectCommon | undefined>(confComps.component.selectorCommon.SelectStateDebt[0]);

    const [getToSelectedCurrencyType2, setToSelectedCurrencyType2] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getToChangeRateCurrency1ToCurrency2, setToChangeRateCurrency1ToCurrency2] = useState<string>("");
    const [getToTotalAmountCurrencyType2, setToTotalAmountCurrencyType2] = useState<string>("");
    const [getToDebtStatusTypeCurrency2, setToDebtStatusTypeCurrency2] = useState<InterfaceSelectedItemForSelectCommon | undefined>(confComps.component.selectorCommon.SelectStateDebt[0]);
    const [getCommissions, setCommissions] = useState<InterfaceNTTCommissionOfTrade[]>([]);
    const [getProfits, setProfits] = useState<InterfaceNTTProfitOfTrade[]>([]);
    const [getInnerBeneficiaryOrders, setInnerBeneficiaryOrders] = useState<InterfaceInnerBeneficiaryOrder[]>([]);
    const [getStepType, setStepType] = useState<InterfaceNTTTypeOfTradeStep>();
    const [getPriorityStep, setPriorityStep] = useState<number>();
    const [getDescriptionValue, setDescriptionValue] = useState<string>("");

    useEffect(() => {
        setIsShowThisBox(paramsComponent.isShowThisBox);
    }, [paramsComponent.isShowThisBox])
    useEffect(() => {
        if (paramsComponent.updateStep) {
            setFromCustomerSelected(paramsComponent.updateStep.step.from_person);
            setFromSelectedCurrencyType1(paramsComponent.updateStep.step.from_initial_currency_unit);
            setFromAmountValueTypeCurrency1(paramsComponent.updateStep.step.from_initial_amount);
            setFromFixCommissionTypeCurrency1(paramsComponent.updateStep.step.from_fix_commission);
            setFromTotalAmountTypeCurrency1(paramsComponent.updateStep.step.from_initial_total_amount);
            setFromDebtStatusTypeCurrency1(confComps.component.selectorCommon.SelectStateDebt
                .filter(item => item.serverNeeded === paramsComponent.updateStep?.step.from_initial_debt_status.value)[0]
            );

            setFromSelectedCurrencyType2(paramsComponent.updateStep.step.from_final_currency_unit);
            setFromChangeRateCurrency1ToCurrency2(paramsComponent.updateStep.step.from_change_rate);
            setFromTotalAmountCurrencyType2(paramsComponent.updateStep.step.from_final_total_amount);
            setFromDebtStatusTypeCurrency2(confComps.component.selectorCommon.SelectStateDebt
                .filter(item => item.serverNeeded === paramsComponent.updateStep?.step.from_final_debt_status.value)[0]
            );
            // to
            setToCustomerSelected(paramsComponent.updateStep.step.to_person);
            setToSelectedCurrencyType1(paramsComponent.updateStep.step.to_initial_currency_unit);
            setToAmountValueTypeCurrency1(paramsComponent.updateStep.step.to_initial_amount);
            setToFixCommissionTypeCurrency1(paramsComponent.updateStep.step.to_fix_commission);
            setToTotalAmountTypeCurrency1(paramsComponent.updateStep.step.to_initial_total_amount);
            setToDebtStatusTypeCurrency1(confComps.component.selectorCommon.SelectStateDebt
                .filter(item => item.serverNeeded === paramsComponent.updateStep?.step.to_initial_debt_status.value)[0]
            );

            setToSelectedCurrencyType2(paramsComponent.updateStep.step.to_final_currency_unit);
            setToChangeRateCurrency1ToCurrency2(paramsComponent.updateStep.step.to_change_rate);
            setToTotalAmountCurrencyType2(paramsComponent.updateStep.step.to_final_total_amount);
            setToDebtStatusTypeCurrency2(confComps.component.selectorCommon.SelectStateDebt
                .filter(item => item.serverNeeded === paramsComponent.updateStep?.step.to_final_debt_status.value)[0]
            );
            setCommissions(paramsComponent.updateStep.step.commissions);
            setProfits(paramsComponent.updateStep.step.profits);
            setInnerBeneficiaryOrders(paramsComponent.updateStep.step.beneficiary_orders);
            setStepType(paramsComponent.updateStep.step.service_type);
            setPriorityStep(paramsComponent.updateStep.step.priority.value - 1);
            setDescriptionValue(paramsComponent.updateStep.step.description);

        }
    }, [paramsComponent.updateStep?.step])

    useEffect(() => {
        setTradeMain(paramsComponent.trade);
    }, [paramsComponent.trade])
    const callApiForDeleteThisStep = async (idStep: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.trade.deleteOneStep;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.updateStep?.step.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.updateStep?.callbackDeleteThisStep();
                dispatch(ShowAlert({ text: "This Step Removed.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callAPIForCreateOrUpdateThisStepInServer = async (idStep?: number) => {
        if (!getFromCustomerSelected) {
            dispatch(ShowAlert({ text: 'Please Select Customer In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        // else if (!getFromSelectedCurrency) {
        //     dispatch(ShowAlert({ text: 'Please Select Currency Type In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // else if (!getFromAmountValue) {
        //     dispatch(ShowAlert({ text: 'Please Fill Amount In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // else if (getFromCommissionActive && !getFromCommissionFixPrice) {
        //     dispatch(ShowAlert({ text: 'Please Fill Commission Fix In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // else if (getFromCommissionActive && !getFromCommissionPercentPrice) {
        //     dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // else if (getFromCommissionActive && !getFromCommissionPercentPrice) {
        //     dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // to
        else if (!getToCustomerSelected) {
            dispatch(ShowAlert({ text: 'Please Select Customer In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        // else if (!getToSelectedCurrency) {
        //     dispatch(ShowAlert({ text: 'Please Select Currency Type In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // else if (getToCommissionActive && !getToCommissionFixPrice) {
        //     dispatch(ShowAlert({ text: 'Please Fill Commission Fix In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // else if (getToCommissionActive && !getToCommissionPercentPrice) {
        //     dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        // else if (getToCommissionActive && !getToCommissionPercentPrice) {
        //     dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
        //     return;
        // }
        else if (!getStepType) {
            dispatch(ShowAlert({ text: 'Please Select Step Type.', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (getPriorityStep === undefined) {
            dispatch(ShowAlert({ text: 'Please Select Priority.', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        let urlObj = urlsList.panel.deals.trade.postCreateStepOfTrade;
        let fullURLRequest = urlObj.url + `${paramsComponent.trade.id}/steps/`;
        if (idStep !== undefined) {
            urlObj = urlsList.panel.deals.trade.updateTradeStep;
            fullURLRequest = urlObj.url + `${idStep}/`;
        }

        let bodyObj = {
            "service_type": getStepType.id,
            "title": "",
            "priority": getPriorityStep + 1, // from 1 (lowest) to 5 (highest)
            ...(
                idStep !== undefined && {
                    "state": EnumStateValueOfTradeStep.pendingForManager,
                }
            ),
            "description": getDescriptionValue,
            /** From Details **/
            "from_person": getFromCustomerSelected.user.id,
            ...(
                getFromSelectedCurrencyType1 && getFromSelectedCurrencyType1 != null && {
                    "from_initial_currency_unit": getFromSelectedCurrencyType1.id,
                }
            ),
            "from_initial_amount": getFromAmountValueTypeCurrency1,
            "from_fix_commission": getFromFixCommissionTypeCurrency1,
            "from_initial_total_amount": getFromTotalAmountTypeCurrency1,
            ...(
                getFromDebtStatusTypeCurrency1 && getFromDebtStatusTypeCurrency1 != null && {
                    "from_initial_debt_status": getFromDebtStatusTypeCurrency1.serverNeeded, // 1: Debit (بدهکار), 2: Credit (بستانکار), 3: None
                }
            ),
            "from_final_currency_unit": getFromSelectedCurrencyType2?.id,
            "from_change_rate": getFromChangeRateCurrency1ToCurrency2,
            "from_final_total_amount": getFromTotalAmountCurrencyType2,
            ...(
                getFromDebtStatusTypeCurrency2 && getFromDebtStatusTypeCurrency2 != null && {
                    "from_final_debt_status": getFromDebtStatusTypeCurrency2.serverNeeded,
                }
            ),
            /** To Details **/
            "to_person": getToCustomerSelected.user.id,
            ...(
                getToSelectedCurrencyType1 && getToSelectedCurrencyType1 != null && {
                    "to_initial_currency_unit": getToSelectedCurrencyType1?.id,
                }
            ),
            "to_initial_amount": getToAmountValueTypeCurrency1,
            "to_fix_commission": getToFixCommissionTypeCurrency1,
            "to_initial_total_amount": getToAmountValueTypeCurrency1,
            ...(
                getToDebtStatusTypeCurrency1 && getToDebtStatusTypeCurrency1 != null && {
                    "to_initial_debt_status": getToDebtStatusTypeCurrency1.serverNeeded,
                }
            ),
            ...(
                getToSelectedCurrencyType2 && getToSelectedCurrencyType2 != null && {
                    "to_final_currency_unit": getToSelectedCurrencyType2?.id,
                }
            ),

            "to_change_rate": getToChangeRateCurrency1ToCurrency2,
            "to_final_total_amount": getToTotalAmountCurrencyType2,
            ...(
                getToDebtStatusTypeCurrency2 && getToDebtStatusTypeCurrency2 != null && {
                    "to_final_debt_status": getToDebtStatusTypeCurrency2.serverNeeded,
                }
            ),
            /** Extra Fields **/
            "profits": getProfits.map((profit) => {
                return {
                    "total_profit": profit.total_profit,
                    "currency_unit": profit.currency_unit.id,
                    "final_total_profit": profit.final_total_profit,
                }
            }),
            "commissions": getCommissions.map((commission, index, arr) => {
                return (
                    {
                        ...(
                            commission.person && commission.person != null && {
                                "person": commission.person.user.id,
                            }
                        ),
                        ...(
                            commission.currency_unit && commission.currency_unit != null && {
                                "currency_unit": commission.currency_unit.id,
                            }
                        ),
                        "amount": commission.amount,
                        ...(
                            commission.debt_status && commission.debt_status != null && {
                                "debt_status": commission.debt_status.value,
                            }
                        ),
                        "description": commission.description,
                    }
                )
            }),
            "beneficiary_orders": getInnerBeneficiaryOrders.map((innerBeneficiary, index, arr) => {
                return {
                    "user": innerBeneficiary.user.id,
                    "mediator_type": innerBeneficiary.mediator_type.value, // or SMT, EX: see doc
                    "order": index + 1,
                }
            }),
        }
        dispatch(isVisibilityProgressBar(true));
        await RequestHandler(dispatch, HandlerLangUrl(fullURLRequest, mTheme),
            urlObj.method, urlObj.isTokenNecessary, (idStep === undefined) ? [bodyObj] : bodyObj, profileInStore)
            .then((response: any) => {
                if (idStep === undefined) {
                    paramsComponent.createStep?.callbackOnClickStepAdded();
                    cleanAllValue();
                    dispatch(isVisibilityProgressBar(false));
                    dispatch(ShowAlert({ text: 'This Step Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
                }
                else {
                    paramsComponent.updateStep?.callbackOnClickStepUpdated();
                    // cleanAllValue();
                    dispatch(isVisibilityProgressBar(false));
                    dispatch(ShowAlert({ text: 'This Step Updated.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
                }

            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const cleanAllValue = () => {

        setFromCustomerSelected(undefined);
        setFromSelectedCurrencyType1(undefined);
        setFromAmountValueTypeCurrency1("");
        setFromFixCommissionTypeCurrency1("");
        setFromTotalAmountTypeCurrency1("");
        setFromDebtStatusTypeCurrency1(confComps.component.selectorCommon.SelectStateDebt
            .filter(item => item.serverNeeded === paramsComponent.updateStep?.step.from_initial_debt_status.value)[0]
        );

        setFromSelectedCurrencyType2(undefined);
        setFromChangeRateCurrency1ToCurrency2("");
        setFromTotalAmountCurrencyType2("");
        setFromDebtStatusTypeCurrency2(confComps.component.selectorCommon.SelectStateDebt[0]);
        // to
        setToCustomerSelected(undefined);
        setToSelectedCurrencyType1(undefined);
        setToAmountValueTypeCurrency1("");
        setToFixCommissionTypeCurrency1("");
        setToTotalAmountTypeCurrency1("");
        setToDebtStatusTypeCurrency1(confComps.component.selectorCommon.SelectStateDebt[0]);

        setToSelectedCurrencyType2(undefined);
        setToChangeRateCurrency1ToCurrency2("");
        setToTotalAmountCurrencyType2("");
        setToDebtStatusTypeCurrency2(confComps.component.selectorCommon.SelectStateDebt[0]);

        setCommissions([]);
        setProfits([]);
        setInnerBeneficiaryOrders([]);
        setStepType(undefined);
        setPriorityStep(undefined);
        setDescriptionValue("");

        setDescriptionValue("");
    }

    return (
        <ParentWithHeightAnimation in={getIsShowThisBox} style={{ width: '100%' }}>
            <Box sx={{
                width: '100%',
                border: `0.5px solid ${Colors.theme.blue.border}`,
                boxSizing: 'border-box',
                boxShadow: Colors.shadow.cardList,
                paddingInline: { xs: "0.7rem", sm: "1rem", md: "2.66rem", lg: "2.96rem", xl: "3.25rem" },
                paddingBlock: "1.18rem",
                borderRadius: '0.38rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'flex-start',
                gap: "1rem",
            }}>
                {paramsComponent.updateStep &&
                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                        <h5 style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                            justifyContent: 'start',
                            font: confComps.font.poppins.Medium,
                            fontSize: '1.18rem',
                            lineHeight: '1.77rem',
                            fontWeight: '600',
                            color: Colors.theme.orange.light,
                            gap: '0.4rem',
                        }}>
                            {`Step ${paramsComponent.updateStep?.step.id}`}
                            <div style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'start',
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                            }}>
                                {paramsComponent.updateStep.step.state.value === EnumStateValueOfTradeStep.pendingForManager &&
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: '1rem',
                                        lineHeight: 'inherit',
                                        color: Colors.theme.orange.light,
                                    }}>
                                        {"(Pending Manager...)"}
                                    </p>
                                }

                                {paramsComponent.updateStep.step.state.value === EnumStateValueOfTradeStep.approvedByManager &&
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: '1rem',
                                        lineHeight: 'inherit',
                                        color: Colors.theme.green.dark,
                                    }}>
                                        {"(Approved)"}
                                    </p>
                                }
                                {paramsComponent.updateStep.step.state.value === EnumStateValueOfTradeStep.refinement &&
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: '1rem',
                                        lineHeight: 'inherit',
                                        color: Colors.theme.red.red,
                                    }}>
                                        {"(Rejected)"}
                                    </p>
                                }
                                {paramsComponent.updateStep.step.state.value === EnumStateValueOfTradeStep.completed &&
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: '1rem',
                                        lineHeight: 'inherit',
                                        color: Colors.theme.green.dark,
                                    }}>
                                        {"(Completed)"}
                                    </p>
                                }
                            </div>
                        </h5>
                    </div>
                }
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    >
                        <h6 style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start',
                            justifyContent: 'start',
                            color: Colors.theme.blue.Secondary,
                            fontSize: "1.3rem",
                            marginTop: "0.5rem",
                            lineHeight: "1.77rem",
                            fontFamily: confComps.font.poppins.Medium,
                        }}>
                            {"From Person"}
                        </h6>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'flex-start',
                    }}>
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                            rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectTrustyOrCustomer
                                    title={"Customer/Trusty"}
                                    defaultUser={{
                                        realUserSelect: getFromCustomerSelected,
                                        isAllSelected: false
                                    }}
                                    onChangeSelectedUser={(selectCustomer: InterfaceTypeSelectTrustyOrCustomer) => {
                                        setFromCustomerSelected(selectCustomer.realUserSelect);
                                    }}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCurrencyWithTypeAndLocation
                                    title={"Currency Type 1"}
                                    onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                        setFromSelectedCurrencyType1(selectCountry)
                                    }}
                                    style={{
                                        alignItems: "stretch",
                                    }}
                                    defaultCurrency={getFromSelectedCurrencyType1}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`${confComps.translate.amount} ${getFromSelectedCurrencyType1?.currency.symbol ? `(${getFromSelectedCurrencyType1?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getFromAmountValueTypeCurrency1.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getFromAmountValueTypeCurrency1.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={function (newText: string): void {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setFromAmountValueTypeCurrency1(validText)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`Fix Commission ${getFromSelectedCurrencyType1?.currency.symbol ? `(${getFromSelectedCurrencyType1?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getFromFixCommissionTypeCurrency1.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getFromFixCommissionTypeCurrency1.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={function (newText: string): void {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setFromFixCommissionTypeCurrency1(validText)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`Total Amount ${getFromSelectedCurrencyType1?.currency.symbol ? `(${getFromSelectedCurrencyType1?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getFromTotalAmountTypeCurrency1.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getFromTotalAmountTypeCurrency1.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={function (newText: string): void {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setFromTotalAmountTypeCurrency1(validText)
                                    }}
                                    iconOrComponent={<AutorenewRounded />}
                                    callbackOnClickIcon={getFromAmountValueTypeCurrency1.length <= 0 ? undefined : () => {
                                        setFromTotalAmountTypeCurrency1(`${Number(getFromFixCommissionTypeCurrency1) + Number(getFromAmountValueTypeCurrency1)}`);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCommon
                                    title={"Debt Status Type1"}
                                    onChangeSelectStateDebt={(selected?: InterfaceSelectedItemForSelectCommon) => {
                                        setFromDebtStatusTypeCurrency1(selected);
                                    }}
                                    defaultSelected={getFromDebtStatusTypeCurrency1}
                                    listItem={confComps.component.selectorCommon.SelectStateDebt} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCurrencyWithTypeAndLocation
                                    title={"Currency Type 2"}
                                    onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                        setFromSelectedCurrencyType2(selectCountry)
                                    }}
                                    style={{
                                        alignItems: "stretch",
                                    }}
                                    defaultCurrency={getFromSelectedCurrencyType2}
                                />
                            </Grid>
                            {getFromSelectedCurrencyType1 && getFromSelectedCurrencyType2 &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ChangeRateWithAmount
                                        callbackOnChangeRate={(rate: string) => {
                                            setFromChangeRateCurrency1ToCurrency2(rate);
                                        }}
                                        defaultRate={getFromChangeRateCurrency1ToCurrency2}
                                        defaultFromCurrency={getFromSelectedCurrencyType1}
                                        defaultToCurrency={getFromSelectedCurrencyType2}
                                        title="Change Rate"
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`Total Amount ${getFromSelectedCurrencyType2?.currency.symbol ? `(${getFromSelectedCurrencyType2?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getFromTotalAmountCurrencyType2.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getFromTotalAmountCurrencyType2.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={function (newText: string): void {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setFromTotalAmountCurrencyType2(validText)
                                    }}
                                    iconOrComponent={<AutorenewRounded />}
                                    callbackOnClickIcon={getFromTotalAmountTypeCurrency1.length <= 0 ? undefined : () => {
                                        const result = Number(getFromTotalAmountTypeCurrency1) * Number(getFromChangeRateCurrency1ToCurrency2);
                                        setFromTotalAmountCurrencyType2(`${result}`);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCommon
                                    title={"Debt Status Type2"}
                                    onChangeSelectStateDebt={(selected?: InterfaceSelectedItemForSelectCommon) => {
                                        setFromDebtStatusTypeCurrency2(selected);
                                    }}
                                    defaultSelected={getFromDebtStatusTypeCurrency2}
                                    listItem={confComps.component.selectorCommon.SelectStateDebt} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {/* start section "to" */}
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    >
                        <h6 style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start', justifyContent: 'start',
                            color: Colors.theme.blue.Secondary,
                            fontSize: "1.3rem",
                            marginTop: "0.5rem",
                            lineHeight: "1.77rem",
                            fontFamily: confComps.font.poppins.Medium,
                        }}>
                            {"To Person"}
                        </h6>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'flex-start',
                    }}>
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                            rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                <SelectTrustyOrCustomer
                                    title={"Customer/Trusty"}
                                    defaultUser={{
                                        realUserSelect: getToCustomerSelected,
                                        isAllSelected: false
                                    }}
                                    onChangeSelectedUser={(selectCustomer: InterfaceTypeSelectTrustyOrCustomer) => {
                                        setToCustomerSelected(selectCustomer.realUserSelect);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCurrencyWithTypeAndLocation
                                    title={"Currency Type 1"}
                                    onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                        setToSelectedCurrencyType1(selectCountry)
                                    }}
                                    style={{
                                        alignItems: "stretch",
                                    }}
                                    defaultCurrency={getToSelectedCurrencyType1}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`${confComps.translate.amount} ${getToSelectedCurrencyType1?.currency.symbol ? `(${getToSelectedCurrencyType1?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getToAmountValueTypeCurrency1.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getToAmountValueTypeCurrency1.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={function (newText: string): void {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setToAmountValueTypeCurrency1(validText)
                                    }}
                                    iconOrComponent={<AutorenewRounded />}
                                    callbackOnClickIcon={getFromAmountValueTypeCurrency1.length <= 0 ? undefined : () => {
                                        setToAmountValueTypeCurrency1(getFromAmountValueTypeCurrency1);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`Fix Commission ${getToSelectedCurrencyType1?.currency.symbol ? `(${getToSelectedCurrencyType1?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getToFixCommissionTypeCurrency1.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getToFixCommissionTypeCurrency1.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={(newText: string) => {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setToFixCommissionTypeCurrency1(validText)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`Total Amount ${getToSelectedCurrencyType1?.currency.symbol ? `(${getToSelectedCurrencyType1?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getToTotalAmountTypeCurrency1.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getToTotalAmountTypeCurrency1.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={function (newText: string): void {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setToTotalAmountTypeCurrency1(validText)
                                    }}
                                    iconOrComponent={<AutorenewRounded />}
                                    callbackOnClickIcon={getToAmountValueTypeCurrency1.length <= 0 ? undefined : () => {
                                        setToTotalAmountTypeCurrency1(`${Number(getToFixCommissionTypeCurrency1) + Number(getToAmountValueTypeCurrency1)}`)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCommon
                                    title={"Debt Status Type1"}
                                    onChangeSelectStateDebt={(selected?: InterfaceSelectedItemForSelectCommon) => {
                                        setToDebtStatusTypeCurrency1(selected);
                                    }}
                                    defaultSelected={getToDebtStatusTypeCurrency1}
                                    listItem={confComps.component.selectorCommon.SelectStateDebt} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCurrencyWithTypeAndLocation
                                    title={"Currency Type 2"}
                                    onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                        setToSelectedCurrencyType2(selectCountry)
                                    }}
                                    style={{
                                        alignItems: "stretch",
                                    }}
                                    defaultCurrency={getToSelectedCurrencyType2}
                                />
                            </Grid>
                            {getToSelectedCurrencyType1 && getToSelectedCurrencyType2 &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ChangeRateWithAmount
                                        callbackOnChangeRate={(rate: string) => {
                                            setToChangeRateCurrency1ToCurrency2(rate);
                                        }}
                                        defaultRate={getToChangeRateCurrency1ToCurrency2}
                                        defaultFromCurrency={getToSelectedCurrencyType1}
                                        defaultToCurrency={getToSelectedCurrencyType2}
                                        title="Change Rate"
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`Total Amount ${getToSelectedCurrencyType2?.currency.symbol ? `(${getToSelectedCurrencyType2?.currency.symbol})` : ""}`}
                                    textValue={
                                        `${[getToTotalAmountCurrencyType2.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                        ...getToTotalAmountCurrencyType2.replace(/[^0-9.]/g, '').split(".").slice(1)
                                        ].join(".")}`
                                    }
                                    type="text"
                                    onChangeValue={function (newText: string): void {
                                        const validText = newText.replace(/[^0-9.]/g, '');
                                        setToTotalAmountCurrencyType2(validText)
                                    }}
                                    iconOrComponent={<AutorenewRounded />}
                                    callbackOnClickIcon={getToTotalAmountTypeCurrency1.length <= 0 ? undefined : () => {
                                        const result = Number(getToTotalAmountTypeCurrency1) * Number(getToChangeRateCurrency1ToCurrency2);
                                        setToTotalAmountCurrencyType2(`${result}`);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCommon
                                    title={"Debt Status Type2"}
                                    onChangeSelectStateDebt={(selected?: InterfaceSelectedItemForSelectCommon) => {
                                        setToDebtStatusTypeCurrency2(selected);
                                    }}
                                    defaultSelected={getToDebtStatusTypeCurrency2}
                                    listItem={confComps.component.selectorCommon.SelectStateDebt} />
                            </Grid>

                        </Grid>

                    </div>
                </div >
                {/* other section */}
                < div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                }
                }>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    >
                        <h6 style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start', justifyContent: 'start',
                            color: Colors.theme.blue.Secondary,
                            fontSize: "1.3rem",
                            marginTop: "0.5rem",
                            lineHeight: "1.77rem",
                            fontFamily: confComps.font.poppins.Medium,
                        }}>
                            {"Other Details"}
                        </h6>
                    </div>
                    <Box sx={{
                        width: '100%',
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'flex-start',
                    }}>
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                            rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectTypeOfTradeStep
                                    callbackOnChangeTypeOfTradeStep={(selected?: InterfaceNTTTypeOfTradeStep) => {
                                        setStepType(selected);
                                    }}
                                    defaultSelected={getStepType}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectPriority
                                    callbackOnChangeTypeOfFreestyleStep={(selected?: number) => {
                                        setPriorityStep(selected);
                                    }}
                                    defaultSelected={getPriorityStep}
                                />
                            </Grid>
                            {/* commissions */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <MultiCommissionOfTrade
                                    callbackUpdateList={(list: InterfaceNTTCommissionOfTrade[]) => {
                                        setCommissions(list);
                                    }} defaultList={getCommissions}
                                    isCreationActive={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    height: '1px',
                                    background: Colors.theme.blue.border,
                                    position: 'relative',
                                    marginTop: '1rem',
                                }}>
                                    <p style={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: "translate(-50%,-50%)",
                                        zIndex: '2',
                                        background: 'white',
                                        paddingInline: '0.5rem',
                                        fontFamily: confComps.font.poppins.Medium,
                                        fontSize: "1.12rem",
                                        color: Colors.theme.green.dark,
                                    }}>
                                        Calculate Net profit
                                    </p>

                                </div>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <MultiNetProfit
                                    callbackUpdateList={(list: InterfaceNTTProfitOfTrade[]) => {
                                        setProfits(list);
                                    }}
                                    defaultList={getProfits}
                                    isCreationActive={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InnerBeneficiary
                                    callbackUpdateList={(list: InterfaceInnerBeneficiaryOrder[]) => {
                                        setInnerBeneficiaryOrders(list);
                                    }}
                                    defaultList={getInnerBeneficiaryOrders}
                                    isCreationActive={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldMultiLine
                                    title={confComps.translate.explanation}
                                    text={getDescriptionValue}
                                    onChangeText={setDescriptionValue}
                                    styleParent={{
                                        boxSizing: 'border-box',
                                        border: `1px solid ${Colors.theme.blue.border}`,
                                        background: Colors.theme.white.white90,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: "row",
                                    bottom: "1vh",
                                    alignItems: "center",
                                    justifyContent: "end", width: "100%",
                                }}>
                                    {paramsComponent.createStep &&
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", alignItems: "center", gap: "1rem" }}>
                                            <ButtonColor text={confComps.translate.cancel} iconStart={<CancelRounded />}
                                                color={ColorThemForButton.orangeColorAndTransparentBackground}
                                                onclick={() => paramsComponent.createStep?.callbackOnClickCancel()}
                                            />
                                            <ButtonColor text={confComps.translate.submit} iconStart={<DoneRounded />} color={ColorThemForButton.greenColorAndTransparentBackground}
                                                onclick={() => {
                                                    callAPIForCreateOrUpdateThisStepInServer();
                                                }}
                                            />
                                        </div>
                                    }
                                    {paramsComponent.updateStep &&
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", alignItems: "center", gap: "1rem" }}>
                                            <ButtonColor text={"Delete This Step"} iconStart={<CancelRounded />}
                                                color={ColorThemForButton.orangeColorAndTransparentBackground}
                                                onclick={() => callApiForDeleteThisStep(paramsComponent.updateStep?.step.id ?? -1)}
                                            />
                                            <ButtonColor text={confComps.translate.update} iconStart={<UpgradeRounded />} color={ColorThemForButton.greenColorAndTransparentBackground}
                                                onclick={() => {
                                                    callAPIForCreateOrUpdateThisStepInServer(paramsComponent.updateStep?.step.id);
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                    </Box>
                </div >
            </Box >

        </ParentWithHeightAnimation >
    )
}
export default CreateOneStepOfTrade;