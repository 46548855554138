import { useEffect, useState } from "react";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useTheme } from "@mui/material";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../api/API";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../../api/UrlsList";
import { InterfacePaginationInServer, InterfaceSearchInServer } from "../../../../../api/interfaces/InterfaceSearchInServer";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import ContainerPage from "../../../../../components/basic/containerPage/ContainerPage";
import { EnumStateFetchList } from "../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import AdvanceSearchBox from "../../../../../components/complex/advanceSearchBox/AdvanceSearchBox";
import SortingBox from "../../../../../components/complex/SortingBox/SortingBox";
import AddButtonWithShadowEffect from "../../../../../components/basic/Buttons/AddButtonWithShadowEffect/AddButtonWithShadowEffect";
import Colors from "../../../../../config/Colors";
import Links from "../../../../../config/Links";
import { InterfaceGetAPISlipsList, InterfaceSlipsList } from "../../../../../screens/Deals/Slip/SlipsList/InterfaceSlipsList";
import { EnumSortingType, InterfaceItemOfSorting } from "../../../../../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceAdvanceBox, InterfaceAdvanceSearchParams } from "../../../../../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import InfiniteScrollList from "../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import RowSlip from "../../../../../screens/Deals/Slip/SlipsList/RowSlip/RowSlip";
import { InterfaceNTTSlipMain } from "../../../../../NTTs/Slip/InterfaceNTTSlipMain";
import { InterfaceSlipStepTrustyOrCustomer } from "./InterfaceSlipStepTrustyOrCustomer";

function SlipStepTrustyOrCustomer(paramsComponent: InterfaceSlipStepTrustyOrCustomer) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const [getResponseSlips, setResponseSlips] = useState<(InterfaceNTTSlipMain)[]>();
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getSmartSearch, setSmartSearch] = useState<InterfaceAdvanceSearchParams>({
        smartSearch: "", ...confComps.component.advanceSearchBox.allKinds.recap,

    });
    const [getSelectedItemOfSorting, setSelectedItemOfSorting] = useState<InterfaceItemOfSorting[]>(confComps.sorting.recapList);
    const profileInStore = useSelector(SelectedProfile);
    const getApiListOfSlips = async (sortingFilter: InterfaceItemOfSorting[], advanceSearch: InterfaceAdvanceSearchParams, paginationParams: InterfacePaginationInServer) => {
        // dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.deals.slip.getAllSlip;
        // let bodyObj: InterfaceSearchInServer = ConvertSmartSearchItemToItemSearchForServer(sortingFilter, advanceSearch)
        let bodyObj = {
            filter_criteria: [
                {
                    "from_person": [paramsComponent.userInfoBasic.user.id],
                    // "to_person": [3],
                    // "currency_unit": [3, 2]
                },
                {
                    // "from_person": [paramsComponent.userInfoBasic.user.id],
                    "to_person": [paramsComponent.userInfoBasic.user.id],
                    // "currency_unit": [3, 2]
                }
            ]
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetAPISlipsList;
                if (paginationParams.page === 1) {
                    setResponseSlips(t.data);
                }
                else {
                    setResponseSlips(prev => {
                        return (prev ?
                            [...prev, ...t.data]
                            : t.data
                        );
                    }
                    );
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }
                // dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));



            })
            .catch((e: any) => {
                setStateFetchList(EnumStateFetchList.errorInRequest);
                console.log("response:(:(");
                console.log(e);
                // dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            getApiListOfSlips(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
        }
    }, [getStateFetchList])
    useEffect(() => {
        setStateFetchList(EnumStateFetchList.initiate)
    }, [paramsComponent.userInfoBasic])

    return (

        <div style={{
            display: "flex",
            boxSizing: "border-box", flexDirection: "column", alignItems: "center",
            justifyContent: "space-between",
            width: "100%", flexGrow: 1,
        }}
        >
            <InfiniteScrollList
                isDataExist={(getResponseSlips ?? []).length !== 0}
                onEndReached={async () => {
                    let oldPage = getPaginationForLastRequest;
                    oldPage = {
                        ...oldPage,
                        page: (oldPage.page + 1)
                    };
                    setPaginationForLastRequest(oldPage);
                    console.log("callback for change state response2:", oldPage);
                    getApiListOfSlips(getSelectedItemOfSorting, getSmartSearch, oldPage);
                }}
                onRetryClicked={() => {
                    getApiListOfSlips(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
                }}
                stateResponseList={getStateFetchList}
                styleSpacingMUI={{
                    columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                    rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                    columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                }}

            >
                <GridFromMUI container
                    justifyContent="flex-start" alignItems="stretch"
                    columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                >
                    {getResponseSlips && getResponseSlips.map((slip, index: number) => {
                        return (
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <RowSlip
                                    callbackReloadPage={() => {
                                        getApiListOfSlips(getSelectedItemOfSorting, getSmartSearch, confComps.defaultValue.paginationConfigForServer);
                                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                    }}
                                    isSelected={false}
                                    SlipMainInfo={slip}
                                    key={index}
                                />
                            </Grid>
                        )
                    })}
                </GridFromMUI>
            </InfiniteScrollList>
        </div>

    )
}
export default SlipStepTrustyOrCustomer;
