import { InterfaceFinalRecap } from "../../screens/Deals/Slip/CreateSlip/InterfaceCreateSlip";
import { EnumStepsNameOfSlip } from "../../screens/Deals/Slip/SlipsList/InterfaceSlipsList";
import { InterfaceNTTActivity } from "../InterfaceNTTActivity";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../InterfaceNTTCurrency";
import { InterfaceNTTProfile } from "../InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../InterfaceNTTProfileIranLegal";
import { InterfaceNTTProfileType } from "../InterfaceNTTProfileType";
import { InterfaceNTTUser } from "../InterfaceNTTUser";
export enum EnumStateValueOfSlip {
    created = 'CR',
    completed = 'DN',
}
export interface InterfaceNTTSlipMain {
    id: number;
    from_person: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    to_person: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    code: string;
    currency_unit: InterfaceNTTCurrencyWithTypeAndLocation;
    amount: string;
    paid_at: number;
    state: {
        label: string;
        value: EnumStateValueOfSlip;
    };
    description: string;
    file: null | string;
    created_at: number;
    updated_at: number;
}