import { PaymentsRounded, CancelRounded, ThumbDownRounded, ThumbUpRounded } from "@mui/icons-material";
import { Box, Typography, Grid, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WalletIcon } from "../../../../../../assets/icons/svg/WalletIcon";
import ButtonCancel from "../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonColor from "../../../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import ShowPriority from "../../../../../../components/basic/ShowPriority/ShowPriority";
import ViewChangeRate from "../../../../../../components/complex/ChangeRateWithAmount/ViewChangeRate/ViewChangeRate";
import DialogAcceptProcess from "../../../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess";
import { DialogAttachDetailOfFile } from "../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile";
import DialogRejectWithText from "../../../../../../components/complex/Dialog/DialogRejectWithText/DialogRejectWithText";
import InnerBeneficiary from "../../../../../../components/complex/InnerBeneficiary/InnerBeneficiary";
import { InterfaceFile } from "../../../../../../components/complex/ListFiles/InterfaceListFiles";
import MultiCommissionOfTrade from "../../../../../../components/complex/MultiCommissionOfTrade/MultiCommissionOfTrade";
import MultiNetProfit from "../../../../../../components/complex/MultiNetProfit/MultiNetProfit";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import Colors, { ColorThemForButton } from "../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { EnumSTepsParent, EnumStepsTrade } from "../../../../../../config/enums/EnumSteps";
import { EnumTypeStateDebt } from "../../../../../../config/enums/EnumTypeStateDebt";
import Links from "../../../../../../config/Links";
import { CurrencySymbols } from "../../../../../../helper/CurrencySymbols";
import { InterfaceNTTProfile } from "../../../../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../../../../NTTs/InterfaceNTTProfileType";
import { InterfaceNTTCommissionOfTrade } from "../../../../../../NTTs/Trade/InterfaceNTTCommissionOfTrade";
import { InterfaceNTTProfitOfTrade } from "../../../../../../NTTs/Trade/InterfaceNTTProfitOfTrade";
import { InterfaceNTTStepOfTrade, EnumStateValueOfTradeStep } from "../../../../../../NTTs/Trade/InterfaceNTTStepOfTrade";
import { EnumStateValueOfTrade } from "../../../../../../NTTs/Trade/InterfaceNTTTradeMain";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { InterfaceInnerBeneficiaryOrder } from "../../../../../Deals/Trade/ShowStateTheTrade/ListStepsOfTrade/OneStepOfTrade/InterfaceCreateOneStepOfTrade";
import ViewOneStepOfTrade from "../../../../../Deals/Trade/ShowStateTheTrade/ListStepsOfTrade/OneStepOfTrade/ViewOneStepOfTrade/ViewOneStepOfTrade";
import { InterfaceViewOneTradeStepOfProfile } from "./InterfaceViewOneTradeStepOfProfile";
import ShowUnitOfMoneyThreePart from "../../../../../../components/complex/ShowUnitOfMoneyThreePart/ShowUnitOfMoneyThreePart";
import ToolTipText from "../../../../../../components/basic/ToolTipText/ToolTipText";
import moment from "moment-jalaali";

function ViewOneTradeStepOfProfile(paramsComponent: InterfaceViewOneTradeStepOfProfile) {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getThisStep, setThisStep] = useState<InterfaceNTTStepOfTrade>(paramsComponent.step);
    const [getNewFileForAttach, setNewFileForAttach] = useState<File>();

    useEffect(() => {
        setThisStep(paramsComponent.step);
    }, [paramsComponent.step]);

    return (
        <Box
            sx={{
                width: '100%',
                border: `0.5px solid ${Colors.theme.blue.border}`,
                background: Colors.theme.white.white90,
                boxSizing: 'border-box',
                boxShadow: Colors.shadow.cardList,
                paddingInline: { xs: '0.7rem', sm: '1rem', md: '2.66rem', lg: '2.96rem', xl: '3.25rem' },
                paddingBlock: '2rem',
                borderRadius: '0.38rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'flex-start',
                gap: '1rem',
                marginTop: "2.3rem",
            }}
        >
            <div style={{
                display: 'flex', flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}>
                <h5 style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'start',
                    font: confComps.font.poppins.Medium,
                    fontSize: '1.18rem',
                    lineHeight: '1.77rem',
                    fontWeight: '600',
                    color: Colors.theme.orange.light,
                    gap: '0.4rem',
                }}>
                    {`Step ${paramsComponent.index}`}
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'start',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1rem',
                    }}>
                        {getThisStep.state.value === EnumStateValueOfTradeStep.pendingForManager &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.orange.light,
                            }}>
                                {"(Pending Manager...)"}
                            </p>
                        }

                        {getThisStep.state.value === EnumStateValueOfTradeStep.approvedByManager &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.green.dark,
                            }}>
                                {"(Approved)"}
                            </p>
                        }
                        {getThisStep.state.value === EnumStateValueOfTradeStep.refinement &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.red.red,
                            }}>
                                {"(Rejected)"}
                            </p>
                        }
                        {getThisStep.state.value === EnumStateValueOfTradeStep.completed &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.green.dark,
                            }}>
                                {"(Completed)"}
                            </p>
                        }
                    </div>
                </h5>
                <div>
                    <ShowPriority priority={getThisStep.priority} />
                </div>

            </div>

            {/* From Section */}
            <Box sx={{ width: '100%' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'From'}
                </Typography>
                <Grid container
                    justifyContent="start" alignItems="flex-start"
                    // columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                    rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >

                    {/* Customer */}
                    {getThisStep.from_person && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2">
                                {`${confComps.translate.customer}:`}
                            </Typography>
                            <Typography variant="body1">
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfile).first_name}  ${(getThisStep.from_person.profile as InterfaceNTTProfile).last_name}`
                                }
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfileIranLegal).name} (${(getThisStep.from_person.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }

                            </Typography>
                            <p style={{
                                color: Colors.theme.blue.Secondary,
                                fontFamily: confComps.font.poppins.SemiBold,
                                fontSize: "0.9rem",
                                lineHeight: "1.6rem",
                                flexWrap: "nowrap",
                                textWrap: "nowrap",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (getThisStep.from_person?.is_trusty) {
                                        navigate(Links.screens.panel.people.trusties.trustyProfile + `${getThisStep.from_person.user.id}/`)
                                    }
                                    else {
                                        navigate(Links.screens.panel.people.customers.customersProfile + `${getThisStep.from_person?.user.id}/`)
                                    }
                                }}
                            >
                                {confComps.translate.openProfile}
                            </p>
                        </Box>
                    </Grid>}
                    {/* Amount */}
                    {getThisStep.from_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`${confComps.translate.amount} (${getThisStep.from_initial_currency_unit.currency.symbol}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.from_initial_amount))}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* commission fix */}
                    {getThisStep.from_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`Fix Commission (${getThisStep.from_initial_currency_unit.currency.symbol}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.from_fix_commission))}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* total in currency type 1 */}
                    {getThisStep.from_initial_currency_unit && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`Total value (${getThisStep.from_initial_currency_unit.currency.symbol})`}


                            </Typography>
                            <Typography variant="subtitle2" sx={{
                                color:
                                    getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light
                            }}>
                                {`(${getThisStep.from_initial_debt_status.label}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                    Colors.theme.green.dark :
                                    getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                        Colors.theme.red.red :
                                        Colors.theme.blue.light,
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                {Number(parseFloat(getThisStep.from_initial_total_amount))}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_initial_currency_unit} />

                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>}
                    {/* change rate */}
                    {getThisStep.from_initial_currency_unit && getThisStep.from_final_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {'Change Rate:'}
                                </Typography>
                                <Typography variant="body1" sx={{ color: Colors.theme.blue.dark }}>
                                    <ViewChangeRate
                                        rate={getThisStep.from_change_rate}
                                        defaultFromCurrency={getThisStep.from_initial_currency_unit}
                                        defaultToCurrency={getThisStep.from_final_currency_unit}
                                    />
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* second */}
                    {getThisStep.from_final_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`Total value (${getThisStep.from_final_currency_unit.currency.symbol})`}


                                </Typography>
                                <Typography variant="subtitle2" sx={{
                                    color:
                                        getThisStep.from_final_debt_status.value === EnumTypeStateDebt.credit ?
                                            Colors.theme.green.dark :
                                            getThisStep.from_final_debt_status.value === EnumTypeStateDebt.debit ?
                                                Colors.theme.red.red :
                                                Colors.theme.blue.light
                                }}>
                                    {`(${getThisStep.from_final_debt_status.label}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: getThisStep.from_final_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.from_final_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.from_final_total_amount))}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_final_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                </Grid>
            </Box>
            {/* To Section */}
            {/* To Section */}
            <Box sx={{ width: '100%', marginTop: '2rem' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'To'}
                </Typography>
                <Grid container
                    justifyContent="start" alignItems="flex-start"
                    rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    {/* Customer */}
                    {getThisStep.to_person && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2">
                                {`${confComps.translate.customer}:`}
                            </Typography>
                            <Typography variant="body1">
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfile).first_name} ${(getThisStep.to_person.profile as InterfaceNTTProfile).last_name}`
                                }
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfileIranLegal).name} (${(getThisStep.to_person.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }
                            </Typography>
                            <p style={{
                                color: Colors.theme.blue.Secondary,
                                fontFamily: confComps.font.poppins.SemiBold,
                                fontSize: "0.9rem",
                                lineHeight: "1.6rem",
                                flexWrap: "nowrap",
                                textWrap: "nowrap",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (getThisStep.to_person?.is_trusty) {
                                        navigate(Links.screens.panel.people.trusties.trustyProfile + `${getThisStep.to_person.user.id}/`)
                                    }
                                    else {
                                        navigate(Links.screens.panel.people.customers.customersProfile + `${getThisStep.to_person?.user.id}/`)
                                    }
                                }}
                            >
                                {confComps.translate.openProfile}
                            </p>
                        </Box>
                    </Grid>}
                    {/* Amount */}
                    {getThisStep.to_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`${confComps.translate.amount} (${getThisStep.to_initial_currency_unit.currency.symbol}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.to_initial_amount))}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* commission fix */}
                    {getThisStep.to_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`Fix Commission (${getThisStep.to_initial_currency_unit.currency.symbol}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.to_fix_commission))}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* total in currency type 1 */}
                    {getThisStep.to_initial_currency_unit && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`Total value (${getThisStep.to_initial_currency_unit.currency.symbol})`}
                            </Typography>
                            <Typography variant="subtitle2" sx={{
                                color:
                                    getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light
                            }}>
                                {`(${getThisStep.to_initial_debt_status.label}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                    Colors.theme.green.dark :
                                    getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                        Colors.theme.red.red :
                                        Colors.theme.blue.light,
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'start', gap: '0.4rem',
                            }}>
                                {Number(parseFloat(getThisStep.to_initial_total_amount))}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_initial_currency_unit} />
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>}
                    {/* change rate */}
                    {getThisStep.to_initial_currency_unit && getThisStep.to_final_currency_unit && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {'Change Rate:'}
                            </Typography>
                            <Typography variant="body1" sx={{ color: Colors.theme.blue.dark }}>
                                <ViewChangeRate
                                    rate={getThisStep.to_change_rate}
                                    defaultFromCurrency={getThisStep.to_initial_currency_unit}
                                    defaultToCurrency={getThisStep.to_final_currency_unit}
                                />
                            </Typography>
                        </Box>
                    </Grid>}
                    {/* total in currency type 2 */}
                    {getThisStep.to_final_currency_unit && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`Total value (${getThisStep.to_final_currency_unit.currency.symbol})`}
                            </Typography>
                            <Typography variant="subtitle2" sx={{
                                color:
                                    getThisStep.to_final_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.to_final_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light
                            }}>
                                {`(${getThisStep.to_final_debt_status.label}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: getThisStep.to_final_debt_status.value === EnumTypeStateDebt.credit ?
                                    Colors.theme.green.dark :
                                    getThisStep.to_final_debt_status.value === EnumTypeStateDebt.debit ?
                                        Colors.theme.red.red :
                                        Colors.theme.blue.light,
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'start', gap: '0.4rem',
                            }}>
                                {Number(parseFloat(getThisStep.to_final_total_amount))}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_final_currency_unit} />
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>


            {/* Other Details */}
            <Box sx={{ width: '100%', marginTop: '2rem' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'Other Details'}
                </Typography>
                <Grid
                    container
                    justifyContent="start"
                    alignItems="flex-start"
                    rowSpacing={{ xs: '0.86rem', sm: '0.96rem', md: '1.06rem', lg: '1.18rem', xl: '1.30rem' }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    {/* Deal Type */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2">{'Deal Type:'}</Typography>
                            <Typography variant="body1">
                                {getThisStep.service_type.title}
                            </Typography>
                        </Box>
                    </Grid>
                    {/* time create this step */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2">{'Create Time:'}</Typography>
                            <Typography variant="body1">
                                <ToolTipText
                                    textHint={`Jalali: ${moment.unix(getThisStep?.created_at ?? 0).format("jYYYY/jM/jD")}`}
                                >
                                    {`${dayjs.unix(getThisStep.created_at).format("YYYY/MM/DD")}`}
                                </ToolTipText>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <MultiCommissionOfTrade
                            callbackUpdateList={(list: InterfaceNTTCommissionOfTrade[]) => {
                            }}
                            defaultList={getThisStep.commissions}
                            isCreationActive={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <MultiNetProfit
                            isCreationActive={false}
                            callbackUpdateList={(list: InterfaceNTTProfitOfTrade[]) => {
                            }}
                            defaultList={getThisStep.profits}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InnerBeneficiary
                            isCreationActive={false}
                            callbackUpdateList={function (list: InterfaceInnerBeneficiaryOrder[]): void {

                            }}
                            defaultList={getThisStep.beneficiary_orders}
                        />
                    </Grid>
                    {/* Description */}
                    {getThisStep.description && (
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{`${confComps.translate.explanation}:`}</Typography>
                                {<div style={{
                                    display: 'flex', flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    paddingInline: '0.6rem',
                                    marginTop: '0.6rem',
                                    paddingBlock: '0.6rem',
                                    borderRadius: '0.5rem',
                                    boxSizing: 'border-box',
                                    background: (getThisStep.state.value === EnumStateValueOfTradeStep.refinement) ? Colors.theme.orange.lemonLight : Colors.theme.blue.white2,
                                    boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
                                    width: '100%',
                                }}>
                                    <div>
                                        {(getThisStep.state.value === EnumStateValueOfTradeStep.refinement) && "Manager Rejected! "}
                                    </div>
                                    <Typography variant="body1" align={mTheme.direction === 'rtl' ? "right" : "left"}>
                                        {getThisStep.description.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                </div>}
                            </Box>
                        </Grid>
                    )}




                </Grid>
            </Box>
            <DialogAttachDetailOfFile
                callbackOnCloseDialog={
                    () => {
                        setNewFileForAttach(undefined);
                    }
                }
                callbackSave={(newFile: InterfaceFile) => {
                    // setAttachFileThatNeedUpload(old => [...old, newFile]);
                    setNewFileForAttach(undefined);
                }}
                isOpen={getNewFileForAttach !== undefined}
                file={
                    {
                        file: getNewFileForAttach,
                        description: "",
                        title: "",
                        document: "",
                        id: -1,
                    }
                }
            />

        </Box>
    );
}

export default ViewOneTradeStepOfProfile;
