import { Box, Grid, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import TextFieldWithIconBottomLine from "../../../../../components/basic/TextField/TextFieldWithIconBottomLine/TextFieldWithIconBottomLine";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { formatDateGregorian } from "../../../../../helper/Helper";
import { EditPenIcon } from "../../../../../assets/icons/svg/EditPenIcon";
import SubTitleSection from "../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import Colors from "../../../../../config/Colors";
import KeyTitleH6 from "../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import TextFieldMultiLine from "../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import { AddIcon } from "../../../../../assets/icons/svg/AddIcon";
import { ImageSquare } from "../../../../../components/complex/imageSquare/ImageSquare";
import ButtonSave from "../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { InterfaceNTTActivity } from "../../../../../NTTs/InterfaceNTTActivity";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
// import DatePickerMUIWithUnderLine from "../../../../../components/basic/Dates/DatePickerMUIWithUnderLine";
import TextFieldMultiSelectActivity from "../../../../../components/basic/TextField/ActivitiesJob/TextFieldMultiSelectActivity/TextFieldMultiSelectActivity";
import { CheckRounded } from "@mui/icons-material";
import DatePickerMUIWithUnderLine from "../../../../../components/basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine";
import { InterfaceNTTProfile } from "../../../../../NTTs/InterfaceNTTProfile";
import { InterfaceIranLegalBasicInformation } from "./InterfaceIranLegalBasicInformation";
import { InterfaceNTTCompanyTypeOwnershipStructure } from "../../../../../NTTs/InterfaceNTTCompanyTypeOwnershipStructure";
import { EnumActivitiesStatusCompany, InterfaceNTTProfileIranLegal } from "../../../../../NTTs/InterfaceNTTProfileIranLegal";
import { InterfaceNTTUser } from "../../../../../NTTs/InterfaceNTTUser";
import SelectCompanyTypeOwnershipStructure from "../../../../../components/complex/Selector/SelectCompanyTypeOnwershipStructure/SelectCompanyTypeOwnershipStructure";
import SelectGloballyWithUnderLine from "../../../../../components/complex/Selector/SelectGloballyWithUnderLine/SelectGloballyWithUnderLine";
import { InterfaceNTTGlobalSelectorArray } from "../../../../../NTTs/InterfaceNTTGlobalSelectorArray";
import { EnumTypeInput } from "../../../../../components/basic/TextField/TextFieldWithIconBottomLine/InterfaceTextFieldWithIconBottomLine";
import { BottomTriangleIcon } from "../../../../../assets/icons/svg/BottomTriangleIcon";

function IranLegalBasicInformation(paramsComponent: InterfaceIranLegalBasicInformation) {
    const confComps = ConfigComps();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsModeAddActivityActive, setIsModeAddActivityActive] = useState<boolean>(false);
    const [getUserProfileInfo, setUserProfileInfo] = useState<Partial<InterfaceNTTProfileIranLegal>>();
    const [getUserInfo, setUserInfo] = useState<Partial<InterfaceNTTUser>>();
    const [getAddOnUserInfo, setAddOnUserInfo] = useState<{
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    }>({
        credit: "",
        activities: [],
        description: "",
    });

    useEffect(() => {
        if (paramsComponent.userInfoBasic.user.id !== paramsComponent.userInfoBasic.user.id) {
            setUserInfo(paramsComponent.userInfoBasic.user);
            setUserProfileInfo(paramsComponent.userInfoBasic.profile as InterfaceNTTProfileIranLegal);
            setAddOnUserInfo(paramsComponent.userInfoBasic);
        }
    }, [paramsComponent.userInfoBasic]);
    // Function to update user profile info
    const handleChangeUserProfileInfo = <K extends keyof InterfaceNTTProfileIranLegal>(key: K, value: InterfaceNTTProfileIranLegal[K]) => {
        setUserProfileInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleChangeUser = <K extends keyof InterfaceNTTUser>(key: K, value: InterfaceNTTUser[K]) => {
        setUserInfo((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    const handleChangeActivity = (activities: InterfaceNTTActivity[]) => {
        setAddOnUserInfo((prev) => {
            return {
                ...prev,
                activities: activities,
            }
        });
    };
    const handleChangeDescription = (description: string) => {
        setAddOnUserInfo((prev) => {
            return {
                ...prev,
                description: description,
            }
        });
    };

    const callApiUpdateTheProfile = async () => {
        dispatch(isVisibilityProgressBar(true));

        let urlObj = urlsList.panel.customer.updateCustomerList;

        let dataObj = {
            "username": getUserInfo?.username,
            "email": getUserInfo?.email,
            "credit": getAddOnUserInfo.credit,
            "activities": getAddOnUserInfo.activities.map(ac => ac.id),
            "description": getAddOnUserInfo.description,
            "name": getUserProfileInfo?.name,
            "name_fa": getUserProfileInfo?.name_fa,
            "company_type": getUserProfileInfo?.company_type?.id,
            "doe": getUserProfileInfo?.doe,
            "registration_code": getUserProfileInfo?.registration_code,
            "economic_code": getUserProfileInfo?.economic_code,
            "status": getUserProfileInfo?.status,
            "address": getUserProfileInfo?.address,
            "postal_code": getUserProfileInfo?.postal_code,
            "national_id": getUserProfileInfo?.national_id,
            "landline": getUserProfileInfo?.landline,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                paramsComponent.callbackReload();
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: confComps.translate.updatedProfile, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    return (
        <Box sx={{ boxSizing: "border-box", width: "100%" }}>
            <SubTitleSection title={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.personalInformation} />
            <Box sx={{
                marginInlineStart: { xs: 1, sm: 2, md: 1, lg: 1.5, xl: 2 },
                marginInlineEnd: { xs: 1, sm: 2, md: 5, lg: 12, xl: "10vw" },
                boxSizing: "border-box",
            }}>
                <Grid container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 0, md: "8.81rem", lg: "14.81rem", xl: "9vw" }}
                    rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                >
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Email"}
                            defaultValue={getUserInfo?.email}
                            onChangeValue={(value) => handleChangeUser("email", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.username}
                            defaultValue={getUserInfo?.username}
                            onChangeValue={(value) => handleChangeUser("username", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Company Name"}
                            defaultValue={getUserProfileInfo?.name}
                            onChangeValue={(value) => handleChangeUserProfileInfo("name", value)}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Company Name (FA)"}
                            defaultValue={getUserProfileInfo?.name_fa}
                            onChangeValue={(value) => handleChangeUserProfileInfo("name_fa", value)}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <SelectCompanyTypeOwnershipStructure
                            title='Ownership Structure'
                            onChangeSelectedItem={(value) => {
                                if (value) {
                                    handleChangeUserProfileInfo("company_type", value)
                                }
                            }}
                            defaultIDSelected={getUserProfileInfo?.company_type?.id}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <DatePickerMUIWithUnderLine
                            title={"Expire Date"}
                            onChangeValue={(value) => {
                                if (value) {
                                    handleChangeUserProfileInfo("doe", value)
                                }
                            }}
                            defaultTimeInSecond={getUserProfileInfo?.doe}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Registration Code"}
                            onChangeValue={(value) => {
                                handleChangeUserProfileInfo("registration_code", value)
                            }}
                            defaultValue={getUserProfileInfo?.registration_code}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"economic Code"}
                            onChangeValue={(value) => {
                                handleChangeUserProfileInfo("economic_code", value)
                            }}
                            defaultValue={getUserProfileInfo?.economic_code}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <SelectGloballyWithUnderLine
                            title='Activity Status'
                            allItems={[
                                {
                                    id: EnumActivitiesStatusCompany.active,
                                    title: "Active"
                                },
                                {
                                    id: EnumActivitiesStatusCompany.inactive,
                                    title: "Inactive"
                                },
                                {
                                    id: EnumActivitiesStatusCompany.suspended,
                                    title: "Suspended"
                                }
                            ]}

                            selectedItems={getUserProfileInfo?.status ? [getUserProfileInfo?.status] : []}
                            onChangeSelectedItems={(items: InterfaceNTTGlobalSelectorArray[]) => {
                                if (items.length > 0) {
                                    handleChangeUserProfileInfo("status", items[0].id as EnumActivitiesStatusCompany)
                                }
                            }}
                            limitSelector={1}
                        />

                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Postal Code"}
                            onChangeValue={(value) => {
                                handleChangeUserProfileInfo("postal_code", value)
                            }}
                            defaultValue={getUserProfileInfo?.postal_code}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Company National ID"}
                            onChangeValue={(value) => {
                                handleChangeUserProfileInfo("national_id", value)
                            }}
                            defaultValue={getUserProfileInfo?.national_id}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                            customType={EnumTypeInput.nationalID}
                            maxCountCharacter={12}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Landline"}
                            onChangeValue={(value) => {
                                handleChangeUserProfileInfo("landline", value)
                            }}
                            defaultValue={getUserProfileInfo?.landline}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.address}
                            onChangeValue={(value) => {
                                handleChangeUserProfileInfo("address", value);
                            }}
                            defaultValue={getUserProfileInfo?.address}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>



                </Grid>
            </Box>
            <Box sx={{
                marginTop: { xs: 3, sm: 4, md: 5, lg: 6, xl: 6 },
                width: "100%",
                boxSizing: "border-box",
            }}>
                <SubTitleSection title={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.recentJob} />
                <Box sx={{
                    boxSizing: "border-box",
                    border: `1px solid ${Colors.theme.blue.border}`,
                    borderRadius: "0.4rem",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    paddingInline: { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 },
                    paddingBlockStart: { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 },
                    paddingBlockEnd: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 1.5 },
                }}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.activities} />
                        {!getIsModeAddActivityActive && getAddOnUserInfo.activities.map((activity, index) => (
                            <div key={index} style={{
                                background: Colors.theme.blue.white,
                                borderRadius: "500px",
                                paddingInline: "1.28rem",
                                paddingBlock: "0.1rem",
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: "0.96rem",
                                boxSizing: "border-box",
                            }}>
                                {activity.title}
                            </div>
                        ))
                        }
                        {getIsModeAddActivityActive &&
                            <TextFieldMultiSelectActivity
                                styleItems={{
                                    background: Colors.theme.blue.light_2,
                                    borderRadius: "500px",
                                }}
                                styleMain={{
                                    width: "100%",
                                    maxWidth: "20rem",
                                }}
                                onChangeSelectedActivities={(items: InterfaceNTTActivity[]) => {
                                    console.log("selected activities updagted", items)
                                    handleChangeActivity(items);
                                }} selectedActivities={getAddOnUserInfo.activities}
                            />

                        }
                        <ImageSquare
                            width={"1.9rem"}
                            callbackOnclick={() => { setIsModeAddActivityActive(prev => !prev) }}
                            borderRadius="500px"
                            background={getIsModeAddActivityActive ? Colors.theme.green.dark : undefined}
                            border={`2px solid ${getIsModeAddActivityActive ? Colors.theme.green.dark : Colors.theme.blue.blue}`}
                            element={getIsModeAddActivityActive ? <CheckRounded style={{ color: Colors.theme.white.white80 }} /> : <AddIcon style={{ color: Colors.theme.blue.blue }} />}
                            elementStyle={{ background: "red" }}
                        />
                    </div>
                    <TextFieldMultiLine
                        text={getAddOnUserInfo.description}
                        hint={confComps.translate.description}
                        onChangeText={(newText) => handleChangeDescription(newText)}
                        styleParent={{ marginTop: "2%", boxSizing: "border-box" }}
                    />
                </Box>
            </Box>
            <Box sx={{
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                marginTop: "3.33rem",
                width: "100%",
                boxSizing: "border-box",
            }}>
                <ButtonSave
                    text={confComps.translate.save}
                    callbackOnClick={callApiUpdateTheProfile}
                />
            </Box>
        </Box>
    );
}

export default IranLegalBasicInformation;
