import { DoneRounded, CircleOutlined } from "@mui/icons-material";
import { Box, Grid, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import DatePickerMUI from "../../../../../../components/basic/Dates/DatePickerMUI/DatePickerMUI";
import TitleMedium16 from "../../../../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16";
import BoxRecap from "../../../../../../components/basic/‌Box/BoxRecap/BoxRecap";
import Colors from "../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { InterfaceNTTRecapMain } from "../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapMoneyReceiveService } from "../../../../../../NTTs/Recap/InterfaceNTTRecapMoneyReceiveService";
import { InterfaceNTTOneServiceWorkflowMoneyReceiveRecap } from "../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneServiceWorkflowMoneyReceiveRecap";
import { InterfaceBoxExportTrade } from "./InterfaceBoxExportTrade";
import { useDispatch, useSelector } from "react-redux";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../../../api/UrlsList";
import SelectCurrency from "../../../../../../components/complex/Selector/SelectCurrency/SelectCurrency";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { InterfaceNTTCurrency } from "../../../../../../NTTs/InterfaceNTTCurrency";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";


function BoxExportTrade(paramsComp: InterfaceBoxExportTrade) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsExportTradeActive, setIsExportTradeActive] = useState<boolean>(false);
    const [getEndDate, setEndDate] = useState<number>();
    const [getStartDate, setStartDate] = useState<number>();
    const [getStateIsInRequesting, setStateIsInRequesting] = useState<boolean>(false);
    const callApiForGetExcel = async () => {
        if (!getStartDate) {
            dispatch(ShowAlert({ text: `Please Select Start Time`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        setStateIsInRequesting(true);
        let urlObj = urlsList.panel.reportAsExcelFile.getAllTradeSteps;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme,
            {
                person: paramsComp.userInfoBasic.user.id,
                from_date: getStartDate,
                ...(getEndDate !== undefined && { to_date: getEndDate }),
            } as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);


                dispatch(isVisibilityProgressBar(false));
                // Check if response contains a file (e.g., Blob or binary data)
                if (response && response.filename && response.file) {
                    // Decode the base64 string into a binary data array
                    const byteCharacters = atob(response.file);
                    const byteArrays = [];

                    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                        const slice = byteCharacters.slice(offset, offset + 1024);
                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }

                    // Combine all byte arrays into one
                    const fileBlob = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const fileURL = URL.createObjectURL(fileBlob);

                    // Create an anchor element to trigger the download
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.download = response.filename || 'download.xlsx'; // Use filename from the response or fallback to a default name
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(ShowAlert({ text: "The Excel File Received", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: "Can not parse info From Server!!", typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }

            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsInRequesting(false);


    }

    return (
        <Box sx={{
            border: `1px solid ${Colors.theme.blue.border}`,
            borderRadius: "0.74rem",
            paddingBlock: "1.48rem",
            paddingInline: "1.77rem",
            width: '100%',
            boxSizing: 'border-box',
        }}>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'space-between',
                    boxSizing: "border-box",
                }}
            >
                <TitleMedium16>
                    {confComps.translate.exportTrade}
                </TitleMedium16>
                <div
                    style={{
                        cursor: "pointer",
                        display: 'inline-flex',
                    }}
                    onClick={() => {
                        setIsExportTradeActive(prev => !prev)
                    }}
                >
                    {getIsExportTradeActive ?
                        <DoneRounded style={{
                            color: "#fff", background: Colors.theme.blue.blue,
                            borderRadius: "500px", fontSize: "1.5rem",
                            padding: "0.15rem",
                        }} /> :
                        <CircleOutlined style={{ color: Colors.theme.blue.blue, fontSize: "1.8rem" }} />
                    }
                </div>

            </div>
            <ParentWithHeightAnimation in={getIsExportTradeActive}
                loading={getStateIsInRequesting}
                style={{
                    boxSizing: "border-box",
                    width: "100%",
                }}
            >
                <Box sx={{
                    boxSizing: "border-box",
                    paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                    paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                    display: "flex", flexDirection: 'column',
                    alignItems: 'start',
                    width: "100%",
                    marginTop: "1.18rem",
                }}>
                    <Grid container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 0, sm: 0, md: "1rem", lg: "1.18rem", xl: "2rem" }}
                        rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                    >

                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <DatePickerMUI title={confComps.translate.startDate} posixTime={0}
                                onChangeValue={(timeSecond: number | undefined) => {
                                    setStartDate(timeSecond);
                                }}
                                defaultTimeInSecond={getStartDate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <DatePickerMUI title={confComps.translate.endDate} posixTime={0}
                                onChangeValue={(timeSecond: number | undefined) => {
                                    setEndDate(timeSecond);
                                }}
                                defaultTimeInSecond={getEndDate}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <div style={{
                    width: "100%",
                    alignItems: 'center',
                    justifyContent: 'end',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1.18rem',
                }}>

                    <ButtonSave
                        text={confComps.translate.export}
                        callbackOnClick={callApiForGetExcel}
                    />
                </div>

            </ParentWithHeightAnimation>

        </Box>
    )


}
export default BoxExportTrade;