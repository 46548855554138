import React, { useState, useRef, useEffect, useCallback } from "react";
import { InterfaceToolTipText } from "./InterfaceToolTipText";
import { styled } from "@mui/material/styles";

// Styled component for the tooltip
const TooltipContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "tooltipPosition",
})<{ tooltipPosition: "top" | "bottom" }>(({ theme, tooltipPosition }) => ({
  position: "absolute",
  padding: "8px",
  backgroundColor: "#333",
  color: "#fff",
  borderRadius: "4px",
  fontSize: "12px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  whiteSpace: "nowrap",
  overflowWrap: "break-word",
  zIndex: 1000,
  top: tooltipPosition === "bottom" ? "110%" : "auto",
  bottom: tooltipPosition === "top" ? "110%" : "auto",
  left: "50%",
  transform: "translateX(-50%)",
  transition: "opacity 0.3s ease, visibility 0.3s ease",
  opacity: 1,
  visibility: "visible",
  [theme.breakpoints.down("md")]: {
    whiteSpace: "break-spaces",
  },
}));

// Wrapper to handle positioning
const Wrapper = styled("div")({
  position: "relative",
  display: "inline-block",
});

const ToolTipText: React.FC<InterfaceToolTipText> = ({ children, textHint }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState<"top" | "bottom">("bottom");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Function to determine tooltip position
  const determineTooltipPosition = useCallback(() => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

      const elementMiddle = rect.top + rect.height / 2;

      if (elementMiddle > viewportHeight / 2) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  }, []);

  // Toggle tooltip visibility on click
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    determineTooltipPosition();
    setShowTooltip((prev) => !prev);
  };

  // Show tooltip with a delay on hover
  const handleMouseEnter = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      determineTooltipPosition();
      setShowTooltip(true);
    }, 300); // 300ms delay
  };

  // Hide tooltip and clear timeout on mouse leave
  const handleMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setShowTooltip(false);
  };

  // Close tooltip when clicking outside
  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        showTooltip &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowTooltip(false);
      }
    },
    [showTooltip]
  );

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showTooltip, handleOutsideClick]);

  return (
    <Wrapper
      ref={wrapperRef}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: "default" }}
    >
      {children}

      {showTooltip && (
        <TooltipContainer tooltipPosition={tooltipPosition} ref={tooltipRef}>
          {textHint}
        </TooltipContainer>
      )}
    </Wrapper>
  );
};

export default ToolTipText;
