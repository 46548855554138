import { Button, styled } from "@mui/material";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { CancelRounded, SaveRounded } from "@mui/icons-material";
import { InterfaceButtonCancel } from "./InterfaceButtonCancel";
const ButtonMain = styled("button")(({ theme }) => ({
    paddingInline: "0.8rem",
    lineHeight: "1.44rem",
    fontSize: "0.96rem",
    cursor: "pointer",
    borderRadius: "500px",
    display: "flex", flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: '0.1rem',
    // styleMainDiv && ...styleMainDiv,
    color: Colors.theme.orange.sharp,
    background: "#fff",
    pointerEvents: "auto",
    paddingBlock: "0.16px",
    textAlign: "center",
    border: "1px solid",
    borderColor: `${Colors.theme.orange.sharp}`,
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    boxSizing: "border-box",
    boxShadow: "unset",

    '&:hover': {
        background: Colors.theme.orange.lemonLight,
        boxShadow: Colors.shadow.shadowButtonBoxHovered,
        borderColor: `${Colors.theme.orange.sharp}`,
    },
    '&:active': {
        ransition: "transform 0.3s ease, background-color 0.3s ease",
        backgroundColor:"#eeeded",
        transform: "scale(0.95)",
    }
}));
function ButtonCancel(paramsComponent: InterfaceButtonCancel) {
    var confComps = ConfigComps();
    return (
        <ButtonMain
            style={{
                userSelect: "none",
                lineHeight: "1.481rem",
                fontSize: "0.96rem",
                paddingBlock: "0.14rem",
                paddingInline: "0.92rem",
                boxSizing: "border-box",
                fontFamily: confComps.font.poppins.Medium,
                fontWeight: "200",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
                ...paramsComponent.style,
            }}
            onClick={paramsComponent.callbackOnClick}
        >
            {!(paramsComponent.iconStart) && <CancelRounded />}
            {(paramsComponent.iconStart) && paramsComponent.iconStart}
            {paramsComponent.text}
        </ButtonMain>
    )
}
export default ButtonCancel;
