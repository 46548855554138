import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { EnumSTepsParent, EnumStepsTrade } from "../../../../../config/enums/EnumSteps";
import { InterfaceNTTTradeMain, EnumStateValueOfTrade } from "../../../../../NTTs/Trade/InterfaceNTTTradeMain";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import ViewOneStepOfTrade from "../../../../Deals/Trade/ShowStateTheTrade/ListStepsOfTrade/OneStepOfTrade/ViewOneStepOfTrade/ViewOneStepOfTrade";
import { InterfaceServerResponseGetAllTradeSteps, InterfaceTradeStepTrustyOrCustomer } from "./InterfaceTradeStepTrustyOrCustomer";
import { InterfaceNTTStepOfTrade } from "../../../../../NTTs/Trade/InterfaceNTTStepOfTrade";
import ViewOneTradeStepOfProfile from "./ViewOneTradeStepOfProfile/ViewOneTradeStepOfProfile";
import InfiniteScrollList from "../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import { InterfaceNTTSlipMain } from "../../../../../NTTs/Slip/InterfaceNTTSlipMain";
import {
    InterfacePaginationInServer,
    InterfaceSearchInServer
} from "../../../../../api/interfaces/InterfaceSearchInServer";
import { EnumStateFetchList } from "../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfaceItemOfSorting } from "../../../../../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceAdvanceSearchParams } from "../../../../../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import { InterfaceGetAPISlipsList } from "../../../../Deals/Slip/SlipsList/InterfaceSlipsList";

function TradeStepTrustyOrCustomer(paramsComponent: InterfaceTradeStepTrustyOrCustomer) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getTradeSteps, setTradeSteps] = useState<InterfaceNTTStepOfTrade[]>([]);
    const [getIsLoading, setIsLoading] = useState<boolean>(true);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);

    const callApiForRetrieveAllTradeSteps = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.deals.trade.getAllAnyTradeSteps;
        let objBody = {
            filter_criteria: [
                {
                    "from_person": [paramsComponent.userInfoBasic.user.id],
                    // "to_person": [],
                    // "from_final_currency_unit": [3, 2] //, ...
                },
                {
                    // "from_person": [2],
                    "to_person": [paramsComponent.userInfoBasic.user.id],
                    // "from_final_currency_unit": [3, 2] //, ...
                },
            ]
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, objBody, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceServerResponseGetAllTradeSteps;
                if (paginationParams.page === 1) {
                    setTradeSteps(t.data);
                }
                else {
                    setTradeSteps(prev => {
                        return (prev ?
                            [...prev, ...t.data]
                            : t.data
                        );
                    }
                    );
                }

                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }
                setIsLoading(false);
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }


    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForRetrieveAllTradeSteps(getPaginationForLastRequest);
        }
    }, [getStateFetchList])
    useEffect(() => {
        setStateFetchList(EnumStateFetchList.initiate)
    }, [paramsComponent.userInfoBasic])
    return (
        <div style={{
            display: "flex",
            boxSizing: "border-box", flexDirection: "column", alignItems: "center",
            justifyContent: "space-between",
            width: "100%", flexGrow: 1,
        }}
        >
            <InfiniteScrollList
                isDataExist={(getTradeSteps ?? []).length !== 0}
                onEndReached={async () => {
                    let oldPage = getPaginationForLastRequest;
                    oldPage = {
                        ...oldPage,
                        page: (oldPage.page + 1)
                    };
                    setPaginationForLastRequest(oldPage);
                    console.log("callback for change state response2:", oldPage);
                    callApiForRetrieveAllTradeSteps(oldPage);
                }}
                onRetryClicked={() => {
                    callApiForRetrieveAllTradeSteps(getPaginationForLastRequest);
                }}
                stateResponseList={getStateFetchList}
                styleSpacingMUI={{
                    columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                    rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                    columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                }}

            >
                <div>
                    {getTradeSteps.map((step, index, arr) => {
                        return (

                            <ViewOneTradeStepOfProfile
                                step={step}
                                key={index}
                                index={step.id}
                            />
                        )

                    })}

                </div>

            </InfiniteScrollList>


        </div>
    )
}
export default TradeStepTrustyOrCustomer;