// import { useRef, useCallback, useEffect, useState } from "react";
// import { InterfaceInfiniteScrollList } from "./InterfaceInfiniteScrollList";
// import { EnumStateFetchList } from "../ShowStateFetchList/InterfaceShowStateFetchList";
// import { GridFromMUI } from "../../MUIConfig/GridFromMUI";
// import { Box, Grid } from "@mui/material";
// import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

// function InfiniteScrollList(paramsComponent: InterfaceInfiniteScrollList) {

//     const [getStateFetchRequest, setStateFetchRequest] = useState<EnumStateFetchList>(paramsComponent.stateResponseList);

//     const listRef = useRef<HTMLDivElement>(null);
//     const observerRef = useRef<IntersectionObserver | null>(null);


//     useEffect(() => {
//         console.log("callback for change state response:", paramsComponent.stateResponseList);
//         setStateFetchRequest(paramsComponent.stateResponseList);
//         if (paramsComponent.stateResponseList === EnumStateFetchList.foundData) {
//             paramsComponent.onEndReached();
//         }
//     }, [paramsComponent.stateResponseList])

//     return (
//         <div
//             ref={listRef}
//             style={{
//                 width: "100%",
//                 flexGrow: 1,
//                 background: "red",
//                 overflowY: 'auto',
//                 position: 'relative',
//                 padding: '10px',
//             }}
//         >
//             {paramsComponent.children}
//             {(getStateFetchRequest === EnumStateFetchList.searching) && (
//                 <Box marginTop={{ ...paramsComponent.styleSpacingMUI?.rowSpacing }}>
//                     <Grid container
//                         justifyContent="flex-start" alignItems="stretch"
//                         columnSpacing={{ ...paramsComponent.styleSpacingMUI?.columnSpacing }}
//                         rowSpacing={{ ...paramsComponent.styleSpacingMUI?.rowSpacing }}
//                         columns={{ ...paramsComponent.styleSpacingMUI?.columns }}>
//                         <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
//                             <SkeletonLoader />
//                         </Grid>
//                         <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
//                             <SkeletonLoader />
//                         </Grid>
//                         <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
//                             <SkeletonLoader />
//                         </Grid>
//                         <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
//                             <SkeletonLoader />
//                         </Grid>

//                     </Grid>
//                 </Box>
//             )}
//         </div>
//     );

// }
// export default InfiniteScrollList;


import { useRef, useCallback, useEffect, useState } from "react";
import { InterfaceInfiniteScrollList } from "./InterfaceInfiniteScrollList";
import { EnumStateFetchList } from "../ShowStateFetchList/InterfaceShowStateFetchList";
import { Box, Button, Grid } from "@mui/material";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { ConfigComps } from "../../../config/ConfigCOMP";
import ButtonColor from "../Buttons/ButtonColor/ButtonColor";
import Colors, { ColorThemForButton } from "../../../config/Colors";
import { ReplayCircleFilledRounded } from "@mui/icons-material";
import { NoDataFoundImage } from "../../../assets/image/svg/NoDataFoundImage";
import { ErrorResponseImage } from "../../../assets/image/svg/ErrorResponseImage";

function InfiniteScrollList(paramsComponent: InterfaceInfiniteScrollList) {
    var confComps = ConfigComps();
    const [getStateFetchRequest, setStateFetchRequest] = useState<EnumStateFetchList>(paramsComponent.stateResponseList);
    const [getIsDataExist, setIsDataExist] = useState<boolean>(paramsComponent.isDataExist);

    const listRef = useRef<HTMLDivElement>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const bottomRef = useRef<HTMLDivElement>(null);

    const handleIntersection = useCallback((entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];
        if (entry.isIntersecting && entry.intersectionRatio > 0 && getStateFetchRequest === EnumStateFetchList.foundData) {
            paramsComponent.onEndReached();
        }
    }, [getStateFetchRequest]);

    useEffect(() => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }

        observerRef.current = new IntersectionObserver(handleIntersection, {
            root: null, // Use the viewport as the root
            rootMargin: "0px",
            threshold: 0.1, // Trigger when 10% of the bottomRef is visible
        });

        if (bottomRef.current) {
            observerRef.current.observe(bottomRef.current);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [handleIntersection]);

    useEffect(() => {
        setStateFetchRequest(paramsComponent.stateResponseList);
    }, [paramsComponent.stateResponseList]);
    useEffect(() => {
        setIsDataExist(paramsComponent.isDataExist);
    }, [paramsComponent.isDataExist]);

    return (
        <div
            ref={listRef}
            style={{
                width: "100%",
                flexGrow: 1,
                // background: "red",
                overflowY: 'auto',
                position: 'relative',
                padding: '10px',
                // backgroundColor: "blue"
            }}
        >
            {paramsComponent.children}
            <div ref={bottomRef} style={{ height: '1px' }}></div>
            {getStateFetchRequest === EnumStateFetchList.NotFounded && !getIsDataExist &&
                <div style={{
                    display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column",
                    // backgroundColor: "#b01313"


                }}>
                    <h5 style={{
                        marginTop: "1rem",
                        fontSize: "2rem", lineHeight: "3rem",
                        fontFamily: confComps.font.poppins.Regular,
                        color: Colors.theme.orange.sharp,
                        marginBottom: "-80px", zIndex: "1",
                    }}>
                        No Data Found!
                    </h5>
                    {/*<div style={{width: "100%", height: "auto", overflowY: "hidden", overflowX: "hidden", display: "flex", justifyContent: "center", alignItems: "center", margin: "0", padding: "0", boxSizing: "border-box"}}>*/}
                        <NoDataFoundImage style={{width: "100%", maxWidth: "min(calc(200px + 18vw),100%)",  maxHeight: "35vh", objectFit: "contain", marginTop: "4rem"}} />
                    {/*</div>*/}
                </div>
            }
            {
                (getStateFetchRequest === EnumStateFetchList.searching) && (
                    <Box marginTop={{ ...paramsComponent.styleSpacingMUI?.rowSpacing }}>
                        <Grid container
                            justifyContent="flex-start" alignItems="stretch"
                            columnSpacing={{ ...paramsComponent.styleSpacingMUI?.columnSpacing }}
                            rowSpacing={{ ...paramsComponent.styleSpacingMUI?.rowSpacing }}
                            columns={{ ...paramsComponent.styleSpacingMUI?.columns }}>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <SkeletonLoader />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <SkeletonLoader />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <SkeletonLoader />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <SkeletonLoader />
                            </Grid>
                        </Grid>
                    </Box>
                )
            }
            {
                (getStateFetchRequest === EnumStateFetchList.errorInRequest) && (
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: "center",
                        gap: "1rem",
                        marginTop: "3rem",
                        border: `1px solid ${Colors.theme.orange.light}`,
                        borderRadius: "1rem",
                        paddingBlock: '1rem',
                    }}>
                        <ErrorResponseImage style={{ width: "min(calc(400px + 20vw),100%)" }} />
                        <ButtonColor
                            text={confComps.translate.retry} onclick={() => {
                                paramsComponent.onRetryClicked();
                            }}
                            iconStart={<ReplayCircleFilledRounded />}
                            color={ColorThemForButton.orangeColorAndTransparentBackground}
                        />
                    </div>
                )
            }
        </div >
    );
}

export default InfiniteScrollList;
