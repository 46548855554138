import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RequestHandler } from '../../../api/API';
import { isAnySimilarInterfaceResponseErrorFromServer } from '../../../api/APIErrorResponse';
import {urlsList, HandlerLangUrl, baseUrl} from '../../../api/UrlsList';
import { ConfigComps } from '../../../config/ConfigCOMP';
import { ShowAlert } from '../../../store/AlertStateModeSlice';
import { SelectedProfile } from '../../../store/profileStateSlice';
import { isVisibilityProgressBar } from '../../../store/ProgressbarInRootSlice';
import { enumTypeOfAlert } from '../../MUIConfig/alert_popup/interfaceAlertPopup';
import { useTheme } from '@emotion/react';
import { InterfaceNTTProfile } from '../../../NTTs/InterfaceNTTProfile';
import { InterfaceNTTProfileForeignLegal } from '../../../NTTs/InterfaceNTTProfileForeignLegal';
import { InterfaceNTTProfileIranLegal } from '../../../NTTs/InterfaceNTTProfileIranLegal';
import {EnumProfileTypeNameInServer, InterfaceNTTProfileType} from '../../../NTTs/InterfaceNTTProfileType';
import { InterfaceNTTUser } from '../../../NTTs/InterfaceNTTUser';
import Colors from '../../../config/Colors';
import { InterfaceMentionTextarea } from './InterfaceMentionTextarea';
import {ImageSquare} from "../imageSquare/ImageSquare";
import {ApartmentRounded, CorporateFareRounded, Person2Rounded} from "@mui/icons-material";
import {InterfaceNTTEmployee} from "../../../NTTs/InterfaceNTTEmployee";
import {EmployeeIcon} from "../../../assets/icons/svg/EmployeeIcon";

// Define the InterfaceMentionTextarea interface
// interface InterfaceMentionTextarea {
//   callbackOnChange: (params: { mentionedUsers: interfaceUser[]; textParts: string[] }) => void;
// }

export interface interfaceUser extends InterfaceNTTUser {
    profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
    person_type: InterfaceNTTProfileType;
}

function MentionTextarea(paramsComponent: InterfaceMentionTextarea) {
    const { callbackOnChange } = paramsComponent;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);

    const [content, setContent] = useState('');
    const [filteredUsers, setFilteredUsers] = useState<interfaceUser[]>([]);
    const [usernameToUserMap, setUsernameToUserMap] = useState<Map<string, interfaceUser>>(new Map());
    const [showUserList, setShowUserList] = useState(false);
    const [mentionStart, setMentionStart] = useState<number | null>(null);
    const [searchText, setSearchText] = useState('');
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [stateIsSearching, setStateIsSearching] = useState(false);
    const [dropdownDirection, setDropdownDirection] = useState<'up' | 'down'>('down');

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);

    const callApiForGetListAllUsers = async (searchText: string) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.user.getAllUser;

        await RequestHandler(
            dispatch,
            HandlerLangUrl(urlObj.url, mTheme, { search: searchText }),
            urlObj.method,
            urlObj.isTokenNecessary,
            undefined,
            profileInStore
        )
            .then((response: any) => {
                console.log('response user:):)');
                console.log(response);
                let users = response.data as interfaceUser[];
                setFilteredUsers(users);

                // Update the map of usernames to user objects
                setUsernameToUserMap((prevMap) => {
                    const newMap = new Map(prevMap);
                    users.forEach((user) => {
                        newMap.set(user.username, user);
                    });
                    return newMap;
                });

                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(
                        ShowAlert({
                            text: `${e.data.errors.list[0].attr} : ${e.data.errors.list[0].detail}`,
                            typeAlert: enumTypeOfAlert.error,
                            lastTimeecondsUpdate: new Date().getSeconds(),
                        })
                    );
                } else {
                    dispatch(
                        ShowAlert({
                            text: `Please inform us`,
                            typeAlert: enumTypeOfAlert.error,
                            lastTimeecondsUpdate: new Date().getSeconds(),
                        })
                    );
                }
            });
        setStateIsSearching(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value;
        const cursorPosition = e.target.selectionStart;
        setContent(text);

        const textUpToCursor = text.slice(0, cursorPosition);
        const mentionMatch = textUpToCursor.match(/@(\w*)$/);
        if (mentionMatch) {
            const query = mentionMatch[1];
            setSearchText(query);
            setMentionStart(cursorPosition - query.length - 1);
            setShowUserList(true);

            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            const newTimeoutId = setTimeout(() => {
                callApiForGetListAllUsers(query);
            }, 500);
            setTimeoutId(newTimeoutId);
        } else {
            setShowUserList(false);
            setMentionStart(null);
            setSearchText('');
        }

        // After updating the content, parse mentions and call callbackOnChange
        parseMentionsAndNotify(text);
    };

    const handleUserSelect = (user: interfaceUser) => {
        if (mentionStart !== null) {
            const mentionText = `@${user.username}`;
            const beforeMention = content.slice(0, mentionStart);
            const afterMention = content.slice(textareaRef.current?.selectionStart || 0);
            const newContent = beforeMention + mentionText + ' ' + afterMention;
            setContent(newContent);

            const newCursorPosition = mentionStart + mentionText.length + 1;

            setShowUserList(false);
            setMentionStart(null);
            setSearchText('');

            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.selectionStart = newCursorPosition;
                    textareaRef.current.selectionEnd = newCursorPosition;
                    textareaRef.current.focus();
                }
            }, 0);

            // Parse mentions and call callbackOnChange after selecting a user
            parseMentionsAndNotify(newContent);
        }
    };

    const parseMentionsAndNotify = (text: string) => {
        // Regular expression to match @mentions
        const mentionRegex = /@(\w+)/g;
        let match;
        const mentionedUsers: interfaceUser[] = [];
        const textParts: string[] = [];
        let lastIndex = 0;

        while ((match = mentionRegex.exec(text)) !== null) {
            const username = match[1];
            const start = match.index;
            const end = mentionRegex.lastIndex;

            // Extract the text before the mention
            if (start > lastIndex) {
                textParts.push(text.substring(lastIndex, start));
            } else if (start === 0) {
                // If mention is at the start, add empty string to textParts
                textParts.push('');
            }

            // Check if the username exists in the usernameToUserMap
            const user = usernameToUserMap.get(username);
            if (user) {
                // Valid mention
                mentionedUsers.push(user);
                // Since it's a valid mention, we do not add it to textParts
            } else {
                // Invalid or incomplete mention; include it in textParts
                textParts.push(text.substring(start, end));
            }

            // Update lastIndex to the end of the mention
            lastIndex = end;
        }

        // Add any remaining text after the last mention
        if (lastIndex < text.length) {
            textParts.push(text.substring(lastIndex));
        } else {
            // If the last character is a mention, add empty string to textParts
            textParts.push('');
        }

        // Call the callback function with the mentioned users and text parts
        callbackOnChange({ mentionedUsers, textParts });
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    }, [content]);

    // Effect to adjust dropdown position
    useEffect(() => {
        if (showUserList && textareaRef.current) {
            const textareaRect = textareaRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const spaceBelow = viewportHeight - textareaRect.bottom;
            const spaceAbove = textareaRect.top;

            // Estimate dropdown height
            const dropdownHeight = 150;

            if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
                setDropdownDirection('up');
            } else {
                setDropdownDirection('down');
            }
        }
    }, [showUserList, filteredUsers]);

    // Function to apply styles to the mentions (@username)
    const formatText = (input: string) => {
        // Regular expression to match @mentions
        const mentionRegex = /@(\w+)/g;

        // Split text into an array including the mentions
        const parts = [];
        let lastIndex = 0;
        let match;

        while ((match = mentionRegex.exec(input)) !== null) {
            const start = match.index;
            const end = mentionRegex.lastIndex;
            const username = match[1];

            // Add normal text before the mention
            if (start > lastIndex) {
                parts.push(
                    <span key={lastIndex}>{input.substring(lastIndex, start)}</span>
                );
            }

            // Check if the username exists in the usernameToUserMap
            const userExists = usernameToUserMap.has(username);

            // Add the mention with appropriate style
            parts.push(
                <span
                    key={start}
                    style={{ color: userExists ? 'blue' : 'black' }}
                >
                    {input.substring(start, end)}
                </span>
            );

            lastIndex = end;
        }

        // Add any remaining text after the last mention
        if (lastIndex < input.length) {
            parts.push(<span key={lastIndex}>{input.substring(lastIndex)}</span>);
        }

        return parts;
    };

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            flexGrow: 1,
            display: 'inline-flex',
            flexBasis: 0,
            resize: 'none', // Prevent manual resizing by user
            boxSizing: 'border-box',
            alignItems: 'center',
            fontFamily: confComps.font.poppins.Regular,
            fontSize: '0.96rem',
            outline: 'none',
            border: 'none',
        }}>
            {/* This div displays the formatted text with mentions highlighted */}

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    pointerEvents: 'none', // Prevent interaction
                    color: 'black', // Default text color
                    overflowWrap: 'break-word',
                    padding: '0', // Match padding and margin with the textarea
                    margin: '0',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '0.96rem',
                }}
            >
                {formatText(content)}
            </div>

            {/* The textarea where the user types */}

            <textarea
                ref={textareaRef}
                value={content}
                onChange={handleChange}
                rows={1}
                placeholder={'Write new solution and mention users using "@"'}
                style={{
                    height: 'auto',
                    resize: 'none',
                    width: '100%',
                    flexGrow: 1,
                    flexBasis: 0,
                    boxSizing: 'border-box',
                    backgroundColor: 'transparent',
                    color: 'transparent',
                    caretColor: 'black',
                    zIndex: 1, // Ensure textarea is on top
                    outline: 'none',
                    border: 'none',
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '0.96rem',
                }}
            />

            {/* Render user list when mentioning */}
            {showUserList && filteredUsers.length > 0 && (
                <ul
                    ref={dropdownRef}
                    style={{
                        position: 'absolute',
                        [dropdownDirection === 'down' ? 'top' : 'bottom']: '100%',
                        left: '18.5px',
                        width: '100%',
                        border: '2.5px solid rgba(128, 128, 128, 0.2)',
                        backgroundColor: '#fff',
                        zIndex: 2,
                        listStyle: 'none',
                        padding: '5px',
                        maxHeight: '150px',
                        overflowY: 'scroll',
                        margin: 0,
                        borderRadius: '7px',
                        boxShadow: '0 2px 8px rgba(128, 128, 128, 0.15)',

                    }}
                >
                    {filteredUsers.map((user) => (
                        <li
                            key={user.id}
                            style={{
                                padding: '8px',
                                cursor: 'pointer',
                                borderBottom: '1px solid #f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',

                            }}
                            onClick={() => handleUserSelect(user)}
                        >

                            <ImageSquare width={"3.7%"}
                            borderRadius="500px"
                            style={{ position: "relative", overflow: "visible", boxSizing: "border-box" }}
                            iconSvg={user.profile.profile_pic ? undefined :
                                user.person_type.value === EnumProfileTypeNameInServer.individualProfile ? Person2Rounded :
                                    user.person_type.value === EnumProfileTypeNameInServer.employee?EmployeeIcon : ApartmentRounded}
                            img={user.profile.profile_pic && `${baseUrl}${user.profile.profile_pic}`}
                            element={
                                user.profile.profile_pic &&
                                    user.person_type.value !== EnumProfileTypeNameInServer.individualProfile ?
                                    <CorporateFareRounded
                                        style={{
                                            zIndex: 5,
                                            borderRadius: "500px",
                                            background: "#fff",
                                            position: "absolute",
                                            width: "1.4rem",
                                            height: "1.4rem",
                                            transform: "translate(50%,50%)",
                                            bottom: "14.64466094%", right: "14.64466094%",
                                        }}
                                    />
                                    :
                                    user.profile.profile_pic &&
                                    <Person2Rounded
                                        style={{
                                            zIndex: 5,
                                            borderRadius: "500px",
                                            background: "#fff",
                                            position: "absolute",
                                            width: "1.4rem",
                                            height: "1.4rem",
                                            transform: "translate(50%,50%)",
                                            bottom: "14.64466094%", right: "14.64466094%",
                                        }}
                                    />


                            }
                            elementStyle={{
                                zIndex: 4,
                                position: "absolute",
                                width: "28px",
                                transform: "translate(0,0)",
                                top: "100px", left: "100%",
                            }}
                            color={Colors.theme.blue.blue}
                            background={Colors.theme.white.PrimaryLight}
                        />

                            <span style={{marginLeft: "7px"}}>
                            {user.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                `${(user.profile as InterfaceNTTProfile).first_name}  ${(user.profile as InterfaceNTTProfile).last_name}`
                            }
                            {user.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                `${(user.profile as InterfaceNTTProfileForeignLegal).name}`
                            }
                            {user.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                `${(user.profile as InterfaceNTTProfileIranLegal).name} (${(user.profile as InterfaceNTTProfileIranLegal).name_fa})`
                            }
                            {user.person_type.value === EnumProfileTypeNameInServer.employee &&
                                `${(user.profile as InterfaceNTTEmployee).first_name}  ${(user.profile as InterfaceNTTEmployee).last_name}`
                            }
                            </span> <span style={{color:'#9a9a9a', marginLeft:'10px', marginRight:'10px'}}>|</span>
                            <span style={{color:'#322980', flexGrow:'1'}}>Balance : </span>
                            <button style={{color:'#0b52a4', textDecoration:"none", cursor:'pointer'}}>open profile</button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default MentionTextarea;
