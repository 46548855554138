import { useLocation, useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "../../../../../assets/icons/svg/ArrowRightIcon";
import { DownloadIcon } from "../../../../../assets/icons/svg/DownloadIcon";
import { PaperIcon } from "../../../../../assets/icons/svg/PaperIcon";
import { TickWithCircleIcon } from "../../../../../assets/icons/svg/TickWithCircleIcon";
import { WalletIcon } from "../../../../../assets/icons/svg/WalletIcon";
import Colors from "../../../../../config/Colors";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { interfaceReceiptItem } from "./interfaceReceiptItem";
import { ItemOfMoenyBox, ItemOfReceiptItem, MoneyBox } from "./ReceiptItemStyle";
import Links from "../../../../../config/Links";

function ReceiptItem(componentsParams: interfaceReceiptItem) {
    var confComps = ConfigComps();
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <div style={{
            width: "100%", boxSizing: "border-box",
            boxShadow: Colors.shadow.cardList,
            borderRadius: "0.5rem",
            display: "grid",
            columnGap: "2rem",
            padding: "2rem",
            background: "white",
            justifyContent: "space-between",
            rowGap: "1rem",
        }}>
            <MoneyBox>
                <ItemOfMoenyBox>
                    <WalletIcon />
                </ItemOfMoenyBox>
                <ItemOfMoenyBox>
                    {componentsParams.amount}
                </ItemOfMoenyBox>
                <ItemOfMoenyBox>
                    {componentsParams.country}
                </ItemOfMoenyBox>
                <ItemOfMoenyBox>
                    {componentsParams.unitValue}
                </ItemOfMoenyBox>
            </MoneyBox>
            <ItemOfReceiptItem style={{
                gridColumn: "2",
                gridRow: "1",
            }}>
                <div>{componentsParams.name}</div>
                <div>{componentsParams.family}</div>
                <p style={{
                    color: Colors.theme.blue.Secondary,
                    cursor: "pointer",
                    fontFamily: "Poppins-SemiBold",
                }}>{confComps.panel.services.receipt.list.item.buttonLinkProfile}</p>
            </ItemOfReceiptItem>
            <ItemOfReceiptItem style={{
                gridRow: "1",
                gridColumn: "3",
            }}>
                <div>
                    {confComps.panel.services.receipt.list.item.agencyTitle}
                </div>
                <div>
                    -500$
                </div>
            </ItemOfReceiptItem>
            <ItemOfReceiptItem style={{
                gridRow: "2",
                gridColumn: "2",
            }}>
                <h6 style={{ color: Colors.theme.blue.light }}>
                    {confComps.panel.services.receipt.list.item.accNumber}
                </h6>
                <div>
                    123123131341415
                </div>
            </ItemOfReceiptItem>
            <ItemOfReceiptItem style={{
                gridRow: "2",
                gridColumn: "3",
            }}>
                <h6>
                    {confComps.panel.services.receipt.list.item.date}
                </h6>
                <div>
                    2023/10/03
                </div>
            </ItemOfReceiptItem>
            <ItemOfReceiptItem style={{
                gridRow: "3",
                gridColumn: "1",
                color: Colors.theme.green.dark,
            }}>
                <TickWithCircleIcon />
                <h6 style={{ fontFamily: "Poppins-SemiBold", }}>
                    {confComps.panel.services.receipt.list.item.state[0].title}
                </h6>
            </ItemOfReceiptItem>
            <ItemOfReceiptItem style={{
                gridRow: "3",
                gridColumn: "2",
                color: Colors.theme.green.dark,
            }}>
                <PaperIcon />
                <h6 style={{
                    fontFamily: "Poppins-Medium",
                    color: Colors.theme.blue.dark,
                }}>{"file_name.png"}</h6>
                <h6 style={{ color: Colors.theme.blue.light }}>{"8 MB"}</h6>
                <DownloadIcon />
                <h6>
                    {confComps.panel.services.receipt.list.item.Download}
                </h6>
            </ItemOfReceiptItem>
            <ItemOfReceiptItem style={{
                gridRow: "3",
                gridColumn: "3",
                color: Colors.theme.green.dark,
            }}>
                <button style={{
                    borderRadius: "0.25rem", border: `1px solid ${"#9C9C9D"}`,
                    color: "#9C9C9D",
                    paddingInline: "4px",
                    paddingBlock: "4px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                }}
                    onClick={() => {
                        navigate(Links.screens.panel.services.receipt.receiptSteps.chooseCustomer)
                    }}>
                    <h6>
                        {confComps.panel.services.receipt.list.item.showDetails}
                    </h6>
                    <ArrowRightIcon />

                </button>
            </ItemOfReceiptItem>
        </div >
    )
}
export default ReceiptItem;