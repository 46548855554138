import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Colors from '../../../../../../../config/Colors';
import { ConfigComps } from '../../../../../../../config/ConfigCOMP';
import { InterfaceCreateFileForAttach, InterfaceViewOneStepOfFreestyle } from './InterfaceViewOneStepOfFreestyle';
import { EnumProfileTypeNameInServer } from '../../../../../../../NTTs/InterfaceNTTProfileType';
import { InterfaceNTTProfile } from '../../../../../../../NTTs/InterfaceNTTProfile';
import { InterfaceNTTProfileForeignLegal } from '../../../../../../../NTTs/InterfaceNTTProfileForeignLegal';
import { InterfaceNTTProfileIranLegal } from '../../../../../../../NTTs/InterfaceNTTProfileIranLegal';
import { CurrencySymbols } from '../../../../../../../helper/CurrencySymbols';
import { EnumStateValueOfFreestyle } from '../../../../../../../NTTs/Freestyle/InterfaceNTTFreestyleMain';
import { inherits } from 'util';
import { orange } from '@mui/material/colors';
import { EnumStateValueOfFreestyleStep, InterfaceNTTStepOfFreestyle } from '../../../../../../../NTTs/Freestyle/InterfaceNTTStepOfFreestyle';
import ShowPriority from '../../../../../../../components/basic/ShowPriority/ShowPriority';
import { useNavigate } from 'react-router-dom';
import Links from '../../../../../../../config/Links';
import { WalletIcon } from '../../../../../../../assets/icons/svg/WalletIcon';
import { AttachFileRounded, PaymentsRounded, ThumbDownRounded, ThumbUpRounded } from '@mui/icons-material';
import ViewChangeRate from '../../../../../../../components/complex/ChangeRateWithAmount/ViewChangeRate/ViewChangeRate';
import ShowDescriptionWithUsers from '../../../../../../../components/complex/ShowDescriptionWithUsers/ShowDescriptionWithUsers';
import dayjs from 'dayjs';
import ButtonSave from '../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave';
import { TrashIcon } from '../../../../../../../assets/icons/svg/TrashIcon';
import DialogAcceptProcess from '../../../../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess';
import { useDispatch, useSelector } from 'react-redux';
import { RequestHandler } from '../../../../../../../api/API';
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from '../../../../../../../api/APIErrorResponse';
import { urlsList, HandlerLangUrl } from '../../../../../../../api/UrlsList';
import { enumTypeOfAlert } from '../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup';
import { ShowAlert } from '../../../../../../../store/AlertStateModeSlice';
import { isVisibilityProgressBar } from '../../../../../../../store/ProgressbarInRootSlice';
import { SelectedProfile } from '../../../../../../../store/profileStateSlice';
import ButtonCancel from '../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel';
import { EnumStepsFreestyle, EnumSTepsParent } from '../../../../../../../config/enums/EnumSteps';
import DialogRejectWithText from '../../../../../../../components/complex/Dialog/DialogRejectWithText/DialogRejectWithText';
import SubTitleSection from '../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection';
import FilesDragAndDrop from '../../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop';
import { InterfaceFile } from '../../../../../../../components/complex/ListFiles/InterfaceListFiles';
import ListFiles from '../../../../../../../components/complex/ListFiles/ListFiles';
import { DialogAttachDetailOfFile } from '../../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile';
import { InterfaceNTTAttachmentOfFreestyle } from '../../../../../../../NTTs/Freestyle/InterfaceNTTAttachmentOfFreestyle';
import ListFilesForUploadToStepOfFreestyle from './ListFilesForUploadToStepOfFreestyle/ListFilesForUploadToStepOfFreestyle';
import ListFilesThatAttachedToStepOfFreestyle from './ListFilesThatAttachedToStepOfFreestyle/ListFilesThatAttachedToStepOfFreestyle';

function ViewOneStepOfFreestyle(paramsComponent: InterfaceViewOneStepOfFreestyle) {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getThisStep, setThisStep] = useState<InterfaceNTTStepOfFreestyle>(paramsComponent.step);
    const [getStateOfMainFreestyle, setStateOfMainFreestyle] = useState<EnumStateValueOfFreestyle>(paramsComponent.stateOfMainFreestyle)
    const [getIsDialogDeleteShow, setIsDialogDeleteShow] = useState<boolean>(false);
    const [getIsDialogRejectStepShow, setIsDialogRejectStepShow] = useState<boolean>(false);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("2");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    const [getNewFileForAttach, setNewFileForAttach] = useState<File>();
    const [getAttachFileThatNeedUpload, setAttachFileThatNeedUpload] = useState<InterfaceFile[]>([]);
    const [getAttachFile, setAttachFile] = useState<InterfaceNTTAttachmentOfFreestyle[]>(paramsComponent.step?.attachments ?? []);
    const callApiForDeleteThisStep = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.deleteOneStep;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getThisStep.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackDeleteThisStep();
                dispatch(ShowAlert({ text: "This Step Removed.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForUpdateStateOfThisFreeStyleStep = async (stateValue: EnumStateValueOfFreestyleStep) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.updateFreestyleStep;
        let DataObj = {
            "state": stateValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getThisStep.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackReload();
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForRejectThisFreeStyleStep = async (description: string) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.updateFreestyleStep;
        let DataObj = {
            "state": EnumStateValueOfFreestyleStep.refinement,
            "description": description,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getThisStep.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackReload();
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setThisStep(paramsComponent.step);
    }, [paramsComponent.step]);
    useEffect(() => {
        setStateOfMainFreestyle(paramsComponent.stateOfMainFreestyle);
    }, [paramsComponent.stateOfMainFreestyle]);
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.keyOfStepThatSelected);
    }, [paramsComponent.keyOfStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <Box
            sx={{
                width: '100%',
                border: `0.5px solid ${Colors.theme.blue.border}`,
                background: Colors.theme.white.white90,
                boxSizing: 'border-box',
                boxShadow: Colors.shadow.cardList,
                paddingInline: { xs: '0.7rem', sm: '1rem', md: '2.66rem', lg: '2.96rem', xl: '3.25rem' },
                paddingBlock: '2rem',
                borderRadius: '0.38rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'flex-start',
                gap: '1rem',
            }}
        >
            <div style={{
                display: 'flex', flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}>
                <h5 style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'start',
                    font: confComps.font.poppins.Medium,
                    fontSize: '1.18rem',
                    lineHeight: '1.77rem',
                    fontWeight: '600',
                    color: Colors.theme.orange.light,
                    gap: '0.4rem',
                }}>
                    {`Step ${paramsComponent.index}`}
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'start',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1rem',
                    }}>
                        {getThisStep.state.value === EnumStateValueOfFreestyleStep.pendingForManager &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.orange.light,
                            }}>
                                {"(Pending Manager...)"}
                            </p>
                        }

                        {getThisStep.state.value === EnumStateValueOfFreestyleStep.approvedByManager &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.green.dark,
                            }}>
                                {"(Approved)"}
                            </p>
                        }
                        {getThisStep.state.value === EnumStateValueOfFreestyleStep.refinement &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.red.red,
                            }}>
                                {"(Rejected)"}
                            </p>
                        }
                        {getThisStep.state.value === EnumStateValueOfFreestyleStep.completed &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.green.dark,
                            }}>
                                {"(Completed)"}
                            </p>
                        }
                    </div>
                </h5>
                <div>
                    <ShowPriority priority={getThisStep.priority} />
                </div>

            </div>

            {/* From Section */}
            <Box sx={{ width: '100%' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'From'}
                </Typography>
                <Grid container
                    justifyContent="start" alignItems="flex-start"
                    // columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                    rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >

                    {/* Customer */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2">
                                {`${confComps.translate.customer}:`}
                            </Typography>
                            <Typography variant="body1">
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfile).first_name}  ${(getThisStep.from_person.profile as InterfaceNTTProfile).last_name}`
                                }
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfileIranLegal).name} (${(getThisStep.from_person.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }

                            </Typography>
                            <p style={{
                                color: Colors.theme.blue.Secondary,
                                fontFamily: confComps.font.poppins.SemiBold,
                                fontSize: "0.9rem",
                                lineHeight: "1.6rem",
                                flexWrap: "nowrap",
                                textWrap: "nowrap",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (getThisStep.from_person.is_trusty) {
                                        navigate(Links.screens.panel.people.trusties.trustyProfile + `${getThisStep.from_person.user.id}/`)
                                    }
                                    else {
                                        navigate(Links.screens.panel.people.customers.customersProfile + `${getThisStep.from_person.user.id}/`)
                                    }
                                }}
                            >
                                {confComps.translate.openProfile}
                            </p>
                        </Box>
                    </Grid>
                    {/* Amount */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`${confComps.translate.amount} (${getThisStep.from_currency_unit.currency.symbol}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: Colors.theme.blue.dark,
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                {Number(parseFloat(getThisStep.from_amount))}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    {"("}
                                    {getThisStep.from_currency_unit.is_cash ?
                                        <PaymentsRounded style={{
                                            fontSize: '1.12rem',
                                            lineHeight: "1.68rem",
                                        }} />
                                        :
                                        <WalletIcon
                                            style={{
                                                fontSize: '1.12rem',
                                                lineHeight: "1.68rem",
                                            }}
                                        />
                                    }
                                    {getThisStep.from_currency_unit.country && getThisStep.from_currency_unit.country.iso && <img
                                        src={`https://flagcdn.com/w320/${getThisStep.from_currency_unit.country.iso}.png`} // Fallback image
                                        alt={`${getThisStep.from_currency_unit.country.iso}`}
                                        style={{ width: 'auto', height: '1.12rem' }}
                                    />}
                                    {CurrencySymbols[getThisStep.from_currency_unit.currency.symbol]}
                                    {")"}
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Commission Info, if applicable */}
                    {getThisStep.from_commission_currency_unit && (parseFloat(getThisStep.from_commission_fix) > 0 || parseFloat(getThisStep.from_commission_percent) > 0) && (
                        <>
                            {/* rate */}
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                        {'Commission Rate:'}
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: Colors.theme.blue.dark }}>
                                        <ViewChangeRate
                                            rate={getThisStep.from_commission_change_rate}
                                            defaultFromCurrency={getThisStep.from_currency_unit}
                                            defaultToCurrency={getThisStep.from_commission_currency_unit}
                                        />
                                    </Typography>
                                </Box>
                            </Grid>
                            {/* Gain Commission */}
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                        {'Gain Commission:'}
                                    </Typography>
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark,
                                        gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                    }}>
                                        {`${Number(parseFloat(getThisStep.from_commission_fix))} + ${Number(parseFloat(getThisStep.from_commission_percent))}% = `}
                                        {`(${Number(parseFloat(getThisStep.from_commission_total))}`}
                                        {getThisStep.from_commission_currency_unit.is_cash ?
                                            <PaymentsRounded style={{
                                                fontSize: '1.12rem',
                                                lineHeight: "1.68rem",
                                            }} />
                                            :
                                            <WalletIcon
                                                style={{
                                                    fontSize: '1.12rem',
                                                    lineHeight: "1.68rem",
                                                }}
                                            />
                                        }
                                        {getThisStep.from_commission_currency_unit.country && getThisStep.from_commission_currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${getThisStep.from_commission_currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${getThisStep.from_commission_currency_unit.country.iso}`}
                                            style={{ width: 'auto', height: '1.12rem' }}
                                        />}
                                        {CurrencySymbols[getThisStep.from_commission_currency_unit.currency.symbol]}
                                        {")"}

                                    </Typography>
                                </Box>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
            {/* To Section */}
            <Box sx={{ width: '100%', marginTop: '2rem' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'To'}
                </Typography>
                <Grid
                    container
                    justifyContent="start"
                    alignItems="flex-start"
                    rowSpacing={{ xs: '0.86rem', sm: '0.96rem', md: '1.06rem', lg: '1.18rem', xl: '1.30rem' }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    {/* Customer */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2">{`${confComps.translate.customer}:`}</Typography>
                            <Typography variant="body1">
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfile).first_name}  ${(getThisStep.to_person.profile as InterfaceNTTProfile).last_name
                                    }`}
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfileForeignLegal).name}`}
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfileIranLegal).name} (${(getThisStep.to_person.profile as InterfaceNTTProfileIranLegal).name_fa
                                    })`}
                            </Typography>
                            <p
                                style={{
                                    color: Colors.theme.blue.Secondary,
                                    fontFamily: confComps.font.poppins.SemiBold,
                                    fontSize: '0.9rem',
                                    lineHeight: '1.6rem',
                                    flexWrap: 'nowrap',
                                    textWrap: 'nowrap',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (getThisStep.to_person.is_trusty) {
                                        navigate(
                                            Links.screens.panel.people.trusties.trustyProfile +
                                            `${getThisStep.to_person.user.id}/`,
                                        );
                                    } else {
                                        navigate(
                                            Links.screens.panel.people.customers.customersProfile +
                                            `${getThisStep.to_person.user.id}/`,
                                        );
                                    }
                                }}
                            >
                                {confComps.translate.openProfile}
                            </p>
                        </Box>
                    </Grid>
                    {/* Amount */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`${confComps.translate.amount} (${getThisStep.to_currency_unit.currency.symbol}):`}
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                {Number(parseFloat(getThisStep.to_amount))}
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: Colors.theme.blue.dark,
                                        gap: '0.4rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {'('}
                                    {getThisStep.to_currency_unit.is_cash ? (
                                        <PaymentsRounded
                                            style={{
                                                fontSize: '1.12rem',
                                                lineHeight: '1.68rem',
                                            }}
                                        />
                                    ) : (
                                        <WalletIcon
                                            style={{
                                                fontSize: '1.12rem',
                                                lineHeight: '1.68rem',
                                            }}
                                        />
                                    )}
                                    {getThisStep.to_currency_unit.country &&
                                        getThisStep.to_currency_unit.country.iso && (
                                            <img
                                                src={`https://flagcdn.com/w320/${getThisStep.to_currency_unit.country.iso}.png`}
                                                alt={`${getThisStep.to_currency_unit.country.iso}`}
                                                style={{ width: 'auto', height: '1.12rem' }}
                                            />
                                        )}
                                    {CurrencySymbols[getThisStep.to_currency_unit.currency.symbol]}
                                    {')'}
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Commission Info, if applicable */}
                    {getThisStep.to_commission_currency_unit &&
                        (parseFloat(getThisStep.to_commission_fix) > 0 ||
                            parseFloat(getThisStep.to_commission_percent) > 0) && (
                            <>
                                {/* Rate */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            gap: '0.4rem',
                                        }}
                                    >
                                        <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                            {'Commission Rate:'}
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: Colors.theme.blue.dark }}>
                                            <ViewChangeRate
                                                rate={getThisStep.to_commission_change_rate}
                                                defaultFromCurrency={getThisStep.to_currency_unit}
                                                defaultToCurrency={getThisStep.to_commission_currency_unit}
                                            />
                                        </Typography>
                                    </Box>
                                </Grid>
                                {/* Gain Commission */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            gap: '0.4rem',
                                        }}
                                    >
                                        <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                            {'Gain Commission:'}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: Colors.theme.blue.dark,
                                                gap: '0.4rem',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'start',
                                            }}
                                        >
                                            {`${Number(parseFloat(getThisStep.to_commission_fix))} + ${Number(parseFloat(getThisStep.to_commission_percent))}% = `}
                                            {`(${Number(parseFloat(getThisStep.to_commission_total))}`}
                                            {getThisStep.to_commission_currency_unit.is_cash ? (
                                                <PaymentsRounded
                                                    style={{
                                                        fontSize: '1.12rem',
                                                        lineHeight: '1.68rem',
                                                    }}
                                                />
                                            ) : (
                                                <WalletIcon
                                                    style={{
                                                        fontSize: '1.12rem',
                                                        lineHeight: '1.68rem',
                                                    }}
                                                />
                                            )}
                                            {getThisStep.to_commission_currency_unit.country &&
                                                getThisStep.to_commission_currency_unit.country.iso && (
                                                    <img
                                                        src={`https://flagcdn.com/w320/${getThisStep.to_commission_currency_unit.country.iso}.png`}
                                                        alt={`${getThisStep.to_commission_currency_unit.country.iso}`}
                                                        style={{ width: 'auto', height: '1.12rem' }}
                                                    />
                                                )}
                                            {CurrencySymbols[getThisStep.to_commission_currency_unit.currency.symbol]}
                                            {')'}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        )}
                </Grid>
            </Box>

            {/* Other Details */}
            <Box sx={{ width: '100%', marginTop: '2rem' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'Other Details'}
                </Typography>
                <Grid
                    container
                    justifyContent="start"
                    alignItems="flex-start"
                    rowSpacing={{ xs: '0.86rem', sm: '0.96rem', md: '1.06rem', lg: '1.18rem', xl: '1.30rem' }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    {/* Deal Type */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2">{'Deal Type:'}</Typography>
                            <Typography variant="body1">{getThisStep.deal_type.title}</Typography>
                        </Box>
                    </Grid>
                    {/* Priority */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2">{'Create Time:'}</Typography>
                            <Typography variant="body1">
                                {`${dayjs.unix(getThisStep.created_at).format("YYYY/MM/DD")}`}
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Description */}
                    {getThisStep.description && (
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{`${confComps.translate.explanation}:`}</Typography>
                                {<div style={{
                                    display: 'flex', flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    paddingInline: '0.6rem',
                                    marginTop: '0.6rem',
                                    paddingBlock: '0.6rem',
                                    borderRadius: '0.5rem',
                                    boxSizing: 'border-box',
                                    background: (getThisStep.state.value === EnumStateValueOfFreestyleStep.refinement) ? Colors.theme.orange.lemonLight : Colors.theme.blue.white2,
                                    boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
                                    width: '100%',
                                }}>
                                    <div>
                                        {(getThisStep.state.value === EnumStateValueOfFreestyleStep.refinement) && "Manager Rejected! "}
                                    </div>
                                    <Typography variant="body1" align={mTheme.direction === 'rtl' ? "right" : "left"}>
                                        {getThisStep.description.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                </div>}
                            </Box>
                        </Grid>
                    )}
                    {/* Attachments */}
                    {getThisStep.attachments && getThisStep.attachments.length > 0 && (
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{'Attachments:'}</Typography>
                                <ListFilesThatAttachedToStepOfFreestyle
                                    attachments={getThisStep.attachments}
                                    step={getThisStep}
                                    callbackRemoveFile={() => {
                                        paramsComponent.callbackReload();
                                    }}
                                    keyOfStepThatSelected={getKeyOfStepThatSelected}
                                    keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                                    stateOfMainFreestyle={getStateOfMainFreestyle}
                                />
                            </Box>
                        </Grid>
                    )}
                    {getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}` &&
                        getStateOfMainFreestyle === EnumStateValueOfFreestyle.approvedByManager &&
                        getThisStep.state.value === EnumStateValueOfFreestyleStep.approvedByManager &&
                        <Grid item xs={12}>
                            <SubTitleSection title={"Attach Documents For this Step"} />
                            <FilesDragAndDrop
                                onUpload={(files) => {
                                    files.forEach((value) => {
                                        setNewFileForAttach(value);
                                    })
                                }}
                                count={1}
                                formats={['jpg', 'png']}
                                containerStyles={{
                                    borderRadius: "4px",
                                    boxSizing: "border-box",

                                }}
                                openDialogOnClick
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: `1px dotted ${Colors.theme.blue.blue}`,
                                    cursor: "pointer",
                                    paddingInline: "1.6rem",
                                    paddingBlock: "1.2rem",
                                    background: "#E3F9E9",
                                    borderRadius: "0.8rem",
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                    }}>
                                        <AttachFileRounded style={{ color: Colors.theme.blue.dark }} />
                                        {confComps.translate.dragDropFiles}
                                    </div>
                                    <div style={{
                                        borderRadius: "0.4rem", background: Colors.theme.blue.light_2,
                                        paddingInline: "0.5rem",
                                        paddingBlock: "0.1rem",
                                        boxSizing: "border-box",
                                        border: `1px solid ${Colors.theme.blue.dark}`,
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        lineHeight: "1.2rem",
                                        color: Colors.theme.blue.dark,

                                    }}>
                                        {"Choose File"}
                                    </div>

                                </div>
                            </FilesDragAndDrop>
                            <div style={{ marginTop: "2rem" }}>
                                <ListFilesForUploadToStepOfFreestyle
                                    Files={getAttachFileThatNeedUpload}
                                    step={getThisStep}
                                    callbackRemoveFiles={(index: number) => {
                                        setAttachFileThatNeedUpload(prev => prev.filter((prev, indexP) => indexP !== index));
                                    }}
                                    callbackUploadFiles={function (index: number): void {
                                        setAttachFileThatNeedUpload(prev => prev.filter((prev, indexP) => indexP !== index));
                                        paramsComponent.callbackReload();
                                    }}
                                />

                            </div>

                        </Grid>}

                    {/* in create step show Delete button */}
                    {
                        (getStateOfMainFreestyle === EnumStateValueOfFreestyle.created &&
                            getThisStep.state.value === EnumStateValueOfFreestyleStep.pendingForManager &&
                            getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}`) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                }}>
                                    <div style={{
                                        display: 'inline-flex', alignItems: "center", justifyContent: "center", cursor: "pointer",
                                        flexDirection: 'row',
                                        gap: '0.4rem',
                                        color: Colors.theme.orange.sharp,
                                        background: "#FCF4ED",
                                        border: `1px solid ${Colors.theme.orange.sharp}`,
                                        paddingInline: '1rem',
                                        paddingBlock: '0.13rem',
                                        borderRadius: '0.59rem',
                                    }}
                                        onClick={() => {
                                            setIsDialogDeleteShow(true)
                                        }}
                                    >
                                        <TrashIcon style={{
                                            color: Colors.theme.orange.sharp,
                                        }} />
                                        {confComps.translate.delete}
                                    </div>
                                </div>
                            </Grid>
                        </>
                    }
                    {/* reject/approve this step by manager (it must edit so it not has View)*/}
                    {
                        (getStateOfMainFreestyle === EnumStateValueOfFreestyle.pendingForCheckByManager &&
                            getThisStep.state.value === EnumStateValueOfFreestyleStep.pendingForManager &&
                            getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.approval}`
                        ) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    gap: '0.59rem',
                                }}>
                                    <ButtonCancel
                                        text={"Reject"}
                                        callbackOnClick={() => {
                                            setIsDialogRejectStepShow(true);
                                        }}
                                        iconStart={<ThumbDownRounded
                                            style={{
                                                fontSize: '1.1rem', marginBottom: '-5%',
                                                color: Colors.theme.orange.sharp,
                                            }}
                                        />}
                                        style={{
                                            background: "#fff",
                                            color: Colors.theme.orange.sharp,
                                            borderColor: `${Colors.theme.orange.sharp}`,
                                        }
                                        }
                                    />
                                    <ButtonSave
                                        text={"Accept"}
                                        callbackOnClick={() => {
                                            callApiForUpdateStateOfThisFreeStyleStep(EnumStateValueOfFreestyleStep.approvedByManager);
                                        }}
                                        iconStart={<ThumbUpRounded
                                            style={{
                                                fontSize: '1.1rem',
                                                color: Colors.theme.green.dark,
                                            }} />
                                        }
                                        style={{
                                            background: "#fff",
                                            color: Colors.theme.green.dark,
                                            borderColor: `${Colors.theme.green.dark}`,
                                        }}
                                    />
                                </div>
                            </Grid>
                        </>
                    }
                    {/* in create step show Delete button */}
                    {
                        (getStateOfMainFreestyle === EnumStateValueOfFreestyle.approvedByManager &&
                            getThisStep.state.value === EnumStateValueOfFreestyleStep.approvedByManager &&
                            getKeyOfStepThatSelected === `${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}`) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                }}>
                                    <ButtonSave
                                        iconStart={<AttachFileRounded
                                            style={{
                                                fontSize: '1.1rem',
                                                color: Colors.theme.green.dark,
                                            }} />
                                        }
                                        style={{
                                            background: "#fff",
                                            color: Colors.theme.green.dark,
                                            borderColor: `${Colors.theme.green.dark}`,
                                            opacity: (getThisStep.attachments.length > 0 && getAttachFileThatNeedUpload.length === 0) ?
                                                1 : 0.5,
                                        }}
                                        text={"Submit all Documents"}
                                        callbackOnClick={() => {
                                            if (getThisStep.attachments.length > 0 && getAttachFileThatNeedUpload.length === 0) {
                                                callApiForUpdateStateOfThisFreeStyleStep(EnumStateValueOfFreestyleStep.completed);
                                            }
                                            else {
                                                dispatch(ShowAlert({ text: `You must attach one file at least`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            <DialogAttachDetailOfFile
                callbackOnCloseDialog={
                    () => {
                        setNewFileForAttach(undefined);
                    }
                }
                callbackSave={(newFile: InterfaceFile) => {
                    setAttachFileThatNeedUpload(old => [...old, newFile]);
                    setNewFileForAttach(undefined);
                }}
                isOpen={getNewFileForAttach !== undefined}
                file={
                    {
                        file: getNewFileForAttach,
                        description: "",
                        title: "",
                        document: "",
                        id: -1,
                    }
                }
            />
            <DialogAcceptProcess
                greenButtonTitle={confComps.translate.yes}
                redButtonTitle={confComps.translate.no}
                isOpen={getIsDialogDeleteShow} title={"Are you Sure For Delete This Step?"} description={""}
                callbackClickAccept={function (): void {
                    setIsDialogDeleteShow(false);
                    callApiForDeleteThisStep();
                }}
                callbackClickReject={function (): void {
                    setIsDialogDeleteShow(false);
                }}
            >
                <div>
                    {`#${getThisStep.id}`}
                </div>
            </DialogAcceptProcess>
            <DialogRejectWithText
                greenButtonTitle={confComps.translate.save}
                redButtonTitle={confComps.translate.cancel}
                isOpen={getIsDialogRejectStepShow} title={"Are you Sure For Reject This Step?"} description={""}
                callbackClickAccept={function (valueText: string): void {
                    setIsDialogRejectStepShow(false);
                    callApiForRejectThisFreeStyleStep(valueText);
                }}
                callbackClickReject={function (): void {
                    setIsDialogRejectStepShow(false);
                }}
                defaultText={getThisStep.description}>
                <div>
                    {`Please complete The description For Step (#${getThisStep.id})`}
                </div>
            </DialogRejectWithText>
        </Box>
    );
}

export default ViewOneStepOfFreestyle;
