import { styled } from "@mui/material";
import Colors from "../../../config/Colors";
import {Simulate} from "react-dom/test-utils";
import pointerDown = Simulate.pointerDown;

const borderRadius = "1rem";
const paddingInline = "0.5rem";
export const SearchBoxParent = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            border: isDropdownOpen ? undefined : "1px solid rgba(112,119,161,0.01)",
            borderStartStartRadius: borderRadius,
            borderStartEndRadius: borderRadius,
            borderEndEndRadius: isDropdownOpen ? "0" : borderRadius,
            borderEndStartRadius: isDropdownOpen ? "0" : borderRadius,
            boxShadow: isDropdownOpen ? "0px 1px 6px rgba(112,119,161,0.3)" : "0px 0px 5px rgba(112,119,161,0.4)",
            background: Colors.theme.white.white90,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingInline: paddingInline,
            paddingBlock: "8px",
            boxSizing: "border-box",
            borderInline: isDropdownOpen ? "1px solid #ccc" : undefined,
            borderBlockStart: isDropdownOpen ? "1px solid #ccc" : undefined,
        }
    )
});
export const AdvanceBoxParent = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: Colors.theme.white.white90,
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            borderEndEndRadius: borderRadius,
            borderEndStartRadius: borderRadius,
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            gap: '0.7rem',
            paddingBlockEnd: '0.7rem',



        }
    )
});
export const AdvanceItemTitleOfStringType = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "start",
    gap: "8px",
    width: "100%",
    boxSizing: "border-box",
    color: Colors.theme.blue.light,
    borderBlockEnd: "1px solid #DFDFDF",
    paddingInline: paddingInline,
    paddingBlock: "4px",

}));
export const AdvanceItemInputOfStringType = styled("input")(({ theme }) => ({
    color: Colors.theme.blue.dark,
    flexGrow: "1",
    boxSizing: "border-box",

}));
export const AdvanceItemInputOfNumberMoneyType = styled("input")(({ theme }) => ({
    color: Colors.theme.blue.dark,
    boxSizing: "border-box",
    cursor: "pointer",

}));
export const AdvanceItemOfNumberMoneyType = styled("div")(({ theme }) => ({
    borderRadius: "500px",
    cursor: "pointer",
    flexGrow: "1",
    display: "flex", flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    paddingInline: "0.5rem",
    // paddingInline: paddingInline,  //change input size
    paddingBlock: "0.2rem",
    boxSizing: "border-box",
    gap: "0.3rem",
    background: Colors.theme.white.white90,
    boxShadow: Colors.shadow.shadowEditTextBoxNC,
    "&:hover": {
        boxShadow: Colors.shadow.shadowEditTextBoxClicked,
    }

}));

