import * as React from 'react';
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { InterfaceAutoCompleteSimple, InterfaceItemOfAutoComplete } from './InterfaceAutoCompleteSimple';
import { useEffect, useState } from 'react';
import Colors from '../../../config/Colors';
import { BottomTriangleIcon } from "../../../assets/icons/svg/BottomTriangleIcon";
import { Grid } from "@mui/material";
import { ConfigComps } from '../../../config/ConfigCOMP';
import { CancelRounded } from '@mui/icons-material';
import LineSeparator from '../../basic/LineSeprator/LineSeparator';


// const Label = styled('label')`
//   line-height: 1.68rem;
//   display: block;
//   margin-bottom: 0.22rem;
//   color: ${Colors.theme.blue.light};
//   &.focused {
//     color: ${Colors.theme.blue.blue};
//   }
//   marginBottom: '0.22rem';
// `;

// const InputWrapper = styled('div')(
//   ({ theme }) => `
//   width: 100%;
//   box-sizing: border-box;
//   border-radius: 0.4rem;  
//   gap: 0.4rem;
//   box-sizing:border-box;
//   display: flex;
//   flex-wrap: wrap;
//   border: 1px solid ${Colors.theme.blue.border};
//   boxShadow: undefined;
//   &:hover {
//     border: 1px solid ${Colors.theme.blue.blue};
//     boxShadow:${Colors.shadow.shadowEditTextBoxClicked};
//   }

//   &.focused {
//     border: 1px solid ${Colors.theme.blue.blue};
//     boxShadow:${Colors.shadow.shadowEditTextBoxClicked};
//   }

//   & input {
//     line-height: 1.68rem;
//     font-size: 1.12rem;
//     box-sizing: border-box;
//     font-family: Poppins-Regular;
//     padding-block: 0.32rem;
//     padding-inline: 0.64rem;
//     width: 0;
//     min-width: 30px;
//     flex-grow: 1;
//     border: 0;
//     margin: 0;
//     outline: 0;
//   }
// `,
// );

// interface TagProps extends ReturnType<AutocompleteGetTagProps> {
//   label: string;
// }

// function Tag(props: TagProps) {
//   const { label, onDelete, ...other } = props;
//   return (
//     <div {...other}>
//       <span>{label}</span>
//       <CloseIcon onClick={onDelete} />
//     </div>
//   );
// }

// const StyledTag = styled(Tag)<TagProps>(
//   ({ theme }) => `
//   display: flex;
//   align-items: center;
//   padding-inline-start: 0.32rem;
//   box-sizing:border-box;

//   font-family: "Poppins-Regular";
//   font-size: '1.12rem';
//   line-height: '1.68rem';
//   background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
//     };
//   border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
//   border-radius: 2px;
//   box-sizing: content-box;
//   outline: 0;
//   overflow: hidden;

//   &:focus {
//     border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
//     background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
//   }

//   & span {
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }

//   & svg {
//     font-size: 12px;
//     cursor: pointer;
//     padding: 4px;
//   }
// `,
// );

// const Listbox = styled('ul')(
//   ({ theme }) => `
//   width: 100%;
//   margin: 2px 0 0;
//   padding: 0;
//   position: absolute;
//   list-style: none;
//   background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
//   overflow: auto;
//   max-height: 250px;
//   border-radius: 4px;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//   z-index: 1;

//   & li {
//     padding: 5px 12px;
//     display: flex;

//     & span {
//       flex-grow: 1;
//     }

//     & svg {
//       color: transparent;
//     }
//   }

//   & li[aria-selected='true'] {
//     background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
//     font-weight: 600;

//     & svg {
//       color: #1890ff;
//     }
//   }

//   & li.${autocompleteClasses.focused} {
//     background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
//     cursor: pointer;

//     & svg {
//       color: currentColor;
//     }
//   }
// `,
// );

export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
  theme,
  isDropdownOpen
}) => {
  return (
    {
      overflow: "hidden",
      position: "absolute",
      top: "calc(100% + 0.5rem)",
      left: "0",
      zIndex: isDropdownOpen ? "30" : "-1",
      width: "100%",
      background: "#F8F8F8",
      marginTop: "-10px",
      boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
      borderInline: "1px solid #ccc",
      borderBlockStart: "1px solid #DFDFDF",
      borderBlockEnd: "1px solid #ccc",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: "start",
      borderEndEndRadius: "1rem",
      borderEndStartRadius: "1rem",
      opacity: isDropdownOpen ? 1 : 0,
      transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
      transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
      visibility: isDropdownOpen ? "visible" : "hidden",
    }
  )
});
const RowOfList = styled("div")(({ theme }) => ({
  display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",
  fontSize: "1rem",
  width: "100%",
  paddingInline: "1rem",
  boxSizing: "border-box",
  cursor: "pointer",
  "&:hover": {
    background: Colors.theme.orange.lemonLight,

  }
}));

function AutoCompleteSimple(componentsParams: InterfaceAutoCompleteSimple) {
  var confComps = ConfigComps();
  const [isFocused, setIsFocused] = useState(false);
  const [selectedValues, setSelectedValues] = useState<InterfaceItemOfAutoComplete[]>(componentsParams.selectedValueTitle ?? []);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<InterfaceItemOfAutoComplete[]>(componentsParams.valuesList);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {

    setSelectedValues(componentsParams.selectedValueTitle);

  }, [componentsParams.selectedValueTitle, componentsParams.valuesList]);

  React.useEffect(() => {
    setFilteredOptions(
      componentsParams.valuesList.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, componentsParams.valuesList]);

  const handleFocus = () => {
    setIsFocused(true);
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (option: InterfaceItemOfAutoComplete) => {
    console.log("clicked", option);
    if (!selectedValues.some((item) => item.title === option.title)) {
      const newSelectedValues = [...selectedValues, option];
      setSelectedValues(newSelectedValues);

      componentsParams.onChangeSelectedItem(newSelectedValues);

    }
    else {
      const newSelectedValues = selectedValues.filter(selected => selected.title !== option.title);
      setSelectedValues(newSelectedValues);
      componentsParams.onChangeSelectedItem(newSelectedValues);
    }
    setInputValue('');
  };
  useEffect(() => {
    if (isFocused) {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setIsFocused(false);

        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isFocused]);

  const handleTagRemove = (option: InterfaceItemOfAutoComplete) => {
    const newSelectedValues = selectedValues.filter((item) => item.title !== option.title);
    setSelectedValues(newSelectedValues);
    componentsParams.onChangeSelectedItem(newSelectedValues);

  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: "relative", height: "100%", width: "100%" }}
      ref={containerRef}
    >
      <label style={{
        fontWeight: "300",
        fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
        lineHeight: "1.68rem",
        userSelect: "none",
        color: isFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
      }}>
        {componentsParams.title}
      </label>
      <div style={{
        background: Colors.theme.white.white90,
        border: isFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
        boxShadow: isFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
        borderRadius: '0.4rem',
        fontFamily: confComps.font.poppins.Regular,
        fontSize: '1.12rem',
        width: "100%",
        color: Colors.theme.blue.dark,
        outline: 'none',
        lineHeight: "1.68rem",
        display: "flex", flexDirection: "row",
        alignItems: 'center',
        paddingInline: '0.64rem',
        boxSizing: "border-box",
      }}

        onClick={() => inputRef.current?.focus()}
      >
        <div style={{
          width: 0, flexGrow: 1, flexBasis: 0, display: 'flex', flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'start',
          justifyContent: 'start',
          gap: '0.2rem',
        }}>
          {selectedValues.map((option, index) => (
            <div key={index} style={{
              display: 'flex', alignItems: 'center', height: '24px', margin: '2px', lineHeight: '22px',
              backgroundColor: '#f9f9f9',
              borderRadius: '2px', boxSizing: 'content-box',
              padding: '0 4px 0 10px',
              outline: 0, overflow: 'hidden',
              gap: "0.4rem",
              paddingBlock: "0.24rem",
              // ...componentsParams.styleItems,
            }}
            >
              <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {option.title}
              </span>

              <CancelRounded
                style={{
                  color: Colors.theme.orange.sharp,
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  handleTagRemove(option)
                  event.stopPropagation();
                }}
              />

            </div>
          ))}
          <input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleFocus}

            style={{
              width: 0,
              boxSizing: "border-box",
              paddingInline: '0.64rem',
              paddingBlock: '0.32rem',
              flexGrow: "1",
              cursor: "pointer",
              fontFamily: confComps.font.poppins.Regular,
              fontSize: '1.12rem',
              color: Colors.theme.blue.dark,
              outline: 'none',
              lineHeight: "1.68rem",
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {
            <BottomTriangleIcon
              style={{
                justifySelf: 'end',
                transform: isFocused ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.5s ease-in-out",
                color: isFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                width: "0.9rem",
              }}
            />

          }
        </div>

      </div>
      {isFocused && filteredOptions.length > 0 && (
        <DropdownBox isDropdownOpen={isFocused}>
          {filteredOptions.filter(item => item.title.toLowerCase().includes(inputValue.toLowerCase())).flatMap((option, index, arr) => {
            let listElement = [
              <RowOfList
                onClick={() => handleOptionClick(option)}
              >
                <span>{option.title}</span>
                {selectedValues.some((item) => item.title === option.title) && <CheckIcon fontSize="small" />}
              </RowOfList>
            ];
            if ((index + 1) !== arr.length) {
              listElement.push(
                <LineSeparator />
              )
            }
            return listElement;

          })}

        </DropdownBox>
      )
      }
    </div >
  );
}
export default AutoCompleteSimple;