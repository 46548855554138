import { light } from "@mui/material/styles/createPalette";

const Colors = {
    theme: {
        gray: {
            gray: "#77787B",
            light: "#9C9C9D",
        },
        blue: {
            dark: "#2D3250",
            blue: "#22357E",
            borderWhite:"#B7BBD0",
            white: "#D7E4F9",
            white2: "#F3F6FC",
            light: "#59618C",
            light_2: "rgba(89,97,140,0.2)",
            Secondary: "#387ADF",
            border: "#7A85B1",
            verticalSeparator: "rgba(89,97,140,0.4)",


        },
        orange: {
            lemonLight: "#FFEECE",
            sharp: "#FF2E00",
            light: "#EEC55A",
        },
        white: {
            white90: "#FDFDFD",
            white80: "#F8F8F8",
            white70: "#DDDDDD",
            PrimaryLight: "#F0F0FF",
            SecondaryLight: "#E1E2F7",

        },
        transparent: 'rgba(0,0,0,0)',
        green: {
            dark: "#41B06E",
            light: "#74E291",
            lightLemon: "#D3F6DC",
        },
        red: {
            red: "#FD322F",
        }

    },
    gradients: {
        blueGreenBlue: "linear-gradient(45deg, rgba(34, 53, 126, 0.7) 0%, rgba(50, 115, 118, 0.9) 19%, rgba(65, 176, 110, 1) 63%, rgba(34, 53, 126, 0.5) 99%)"
    },
    shadow: {
        cardList: "0px 0px 4px 1px rgba(45,50,80,0.2)",
        blueLightCard: "0px 0px 4px 0px rgba(56,122,223,0.25)",
        greenCard: "0px 0px 4px 0px rgba(65,176,110,0.4)",
        orangeCard: "0px 0px 4px 0px rgba(255,121,0,0.35)",
        dropShadow: "0px 0px 5px rgba(112,119,161,0.5)",
        shadowEditTextBoxNC: "0px 0px 10px rgba(119,120,123,0.15)",
        shadowEditTextBoxClicked: "0px 0px 3px 1px rgba(34,53,126,0.3)",
        shadowEditTextMultiLineBoxNC: "0px 0px 3px 0.2px rgba(56,122,223,0.3)",
        // shadowTextFieldWithIconBottomLineNC: "0px 1px 3px rgba(34,53,126,0.3)",
        shadowTextFieldWithIconBottomLineClicked: "0px 2px 2px -2px rgba(112,119,161,0.95)",
        shadowButtonBoxHovered: "0px 0px 4px 2px rgba(34, 53, 126, 0.3)",
        BoxShadow: "0px 0px 4px 0px rgba(99, 107, 147, 0.5)",
    },
    buttonShadow: {
        backgroundDarkBlue: {
            background: "#22357E",
            hoverBackground: "#1E2E6C",
            text: "#FFFFFF",
            hoverText: "#22357E",
        },
    },

}
export const ColorThemForButton = {
    greenColorAndTransparentBackground: {
        color: Colors.theme.green.dark,
        background: Colors.theme.transparent,
        borderColor: Colors.theme.green.dark,
        hover: {
            color: Colors.theme.green.dark,
            background: '#EAF6EF',
            borderColor: Colors.theme.green.dark,
        },
        click: {
            color: Colors.theme.white.PrimaryLight,
            background: Colors.theme.green.dark,
            borderColor: Colors.theme.green.dark,
        }
    },
    transparentAndGreemColorAndTransparentBackground: {
        color: Colors.theme.green.dark,
        background: Colors.theme.transparent,
        borderColor: Colors.theme.green.dark,
        hover: {
            color: Colors.theme.green.dark,
            background: '#EAF6EF',
            borderColor: Colors.theme.green.dark,
        },
        click: {
            color: Colors.theme.white.PrimaryLight,
            background: Colors.theme.green.dark,
            borderColor: Colors.theme.green.dark,
        }
    },
    LightBlueColorAndTransparentBackground: {
        color: Colors.theme.blue.blue,
        background: "#edf0fa",
        borderColor: Colors.theme.transparent,
        hover: {
            color: Colors.theme.blue.blue,
            background: "#c3cdef",
            borderColor: Colors.theme.blue.dark,
        },
        click: {
            color: Colors.theme.green.dark,
            background: "#bde0aa",
            borderColor: Colors.theme.transparent,
        }
    },

    grayColorAndTransparentBackground: {
        color: Colors.theme.gray.gray,
        background: Colors.theme.transparent,
        borderColor: Colors.theme.gray.light,
        hover: {
            color: Colors.theme.gray.gray,
            background: Colors.theme.white.white90,
            borderColor: Colors.theme.gray.gray,
        },
        click: {
            color: Colors.theme.gray.gray,
            background: Colors.theme.white.white80,
            borderColor: Colors.theme.gray.gray,
        }
    },
    orangeColorAndTransparentBackground: {
        color: Colors.theme.orange.sharp,
        background: Colors.theme.transparent,
        borderColor: Colors.theme.orange.sharp,
        hover: {
            color: Colors.theme.orange.sharp,
            background: '#FCF4ED',
            borderColor: Colors.theme.orange.sharp,
        },
        click: {
            color: Colors.theme.white.PrimaryLight,
            background: Colors.theme.orange.sharp,
            borderColor: Colors.theme.orange.sharp,
        }
    }
}
export default Colors;