import { EnumRolePerson } from "./EnumRolePerson"
export enum EnumSTepsParent {
    recap = 1,
    freestyle = 2,
    trade = 3,
    slip = 4,
    project = 5,
    service = 6,
}
export enum EnumSTepsRecap {
    initiate = 1,
    checkWalletOfTheRequesterByFundControl = 2,
    complianceRiskMeasurement = 3,
    solutions = 4,
    chooseSolution = 5,
    acceptByManager = 6,
    summarize = 7,
}
export enum EnumStepsFreestyle {
    selectRecap = 1,
    addSteps = 2,
    approval = 3,
    uploadDocumentsForSteps = 4,
    calculatePANDL = 5,

}
export enum EnumStepsTrade {
    creator = 1,
    addSteps = 2,
    approval = 3,
    // uploadDocumentsForSteps=3,
    calculatePANDL = 4,

}
export enum EnumStepsSlip {
    creator = 1,
    waitingForApprove = 2,
    approved = 3,
}
export enum EnumSTepsSolutionRecap {
    addSolution = 1,
    solutionRiskMeasurement = 2,
    checkWalletOfTrustyForSolution = 3,
    checkSolutionByTrusty = 4,
}