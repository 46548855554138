import { useEffect, useRef } from "react";

/**
 * Deep equality check for arrays.
 */
const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item === arr2[index]);
};

/**
 * A custom hook that triggers a callback when the dependencies change, with deep comparison for arrays.
 * 
 * @param callback - The function to execute when dependencies change.
 * @param dependencies - The array of dependencies to watch.
 */
const useChange = (callback: () => void, dependencies: any[]) => {
    const isFirstRender = useRef(true);
    const previousDependencies = useRef(dependencies);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        // Deep compare arrays in dependencies
        const hasChanged = dependencies.some((dep, i) => {
            if (Array.isArray(dep) && Array.isArray(previousDependencies.current[i])) {
                return !areArraysEqual(dep, previousDependencies.current[i]);
            }
            return dep !== previousDependencies.current[i];
        });

        if (hasChanged) {
            callback();
        }

        // Update previous dependencies
        previousDependencies.current = dependencies;
    }, dependencies);
};

export default useChange;
