import { interfaceUser } from "../../../../../../components/complex/MentionTextarea/MentionTextarea";
import { InterfaceSelectCommon, InterfaceSelectedItemForSelectCommon } from "../../../../../../components/complex/Selector/SelectCommon/InterfaceSelectCommon";
import { InterfaceGetAPITrustyOrCustomer } from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../NTTs/InterfaceNTTCurrency";
import { InterfaceNTTUser } from "../../../../../../NTTs/InterfaceNTTUser";
import { InterfaceNTTStepOfTrade } from "../../../../../../NTTs/Trade/InterfaceNTTStepOfTrade";
import { InterfaceNTTTradeMain } from "../../../../../../NTTs/Trade/InterfaceNTTTradeMain";

export interface InterfaceCreateOneStepOfTrade {
    trade: InterfaceNTTTradeMain;
    updateStep?: {
        step: InterfaceNTTStepOfTrade;
        callbackOnClickStepUpdated: () => void;
        callbackDeleteThisStep: () => void;
        keyOfStepThatSelected: string;
        keyOfGroupThatSelected: string;
    }
    createStep?: {
        callbackOnClickCancel: () => void;
        callbackOnClickStepAdded: () => void;
    }
    isShowThisBox: boolean;
}

export interface InterfaceInnerBeneficiaryOrder {
    user: interfaceUser;
    mediator_type: {
        label: string;
        value: EnumInnerBeneficiaryOrderForStepTrade;
    }

    order: number;
}
export enum EnumInnerBeneficiaryOrderForStepTrade {
    from = "SMF",
    to = "SMT",
    exchanger = "EX",
}
