import { EnumTypeStateDebt } from "../../config/enums/EnumTypeStateDebt";
import { EnumInnerBeneficiaryOrderForStepTrade, InterfaceInnerBeneficiaryOrder } from "../../screens/Deals/Trade/ShowStateTheTrade/ListStepsOfTrade/OneStepOfTrade/InterfaceCreateOneStepOfTrade";
import { InterfaceNTTActivity } from "../InterfaceNTTActivity";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../InterfaceNTTCurrency";
import { InterfaceNTTProfile } from "../InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../InterfaceNTTProfileIranLegal";
import { InterfaceNTTProfileType } from "../InterfaceNTTProfileType";
import { InterfaceNTTUser } from "../InterfaceNTTUser";
import { InterfaceNTTAttachmentOfTrade } from "./InterfaceNTTAttachmentOfTrade";
import { InterfaceNTTCommissionOfTrade } from "./InterfaceNTTCommissionOfTrade";
import { InterfaceNTTTypeOfTradeStep } from "./InterfaceNTTTypeOfTradeStep";
export enum EnumStateValueOfTradeStep {
    pendingForManager = 1,
    refinement = 2,
    approvedByManager = 3,
    completed = 4,
}
export interface InterfaceNTTStepOfTrade {
    id: number;
    service_type: InterfaceNTTTypeOfTradeStep;
    title: string;
    priority: {
        label: string;
        value: number;
    };
    code: string;
    state: {
        label: string;
        value: EnumStateValueOfTradeStep;
    };
    description: string;
    from_person?: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    from_initial_currency_unit?: InterfaceNTTCurrencyWithTypeAndLocation;
    from_initial_amount: string;
    from_fix_commission: string;
    from_initial_total_amount: string;
    from_initial_debt_status: {
        label: string;
        value: EnumTypeStateDebt;
    };
    from_final_currency_unit?: InterfaceNTTCurrencyWithTypeAndLocation;
    from_change_rate: string;
    from_final_total_amount: string;
    from_final_debt_status: {
        label: string;
        value: EnumTypeStateDebt;
    };
    to_person?: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    to_initial_currency_unit?: InterfaceNTTCurrencyWithTypeAndLocation;
    to_initial_amount: string;
    to_fix_commission: string;
    to_initial_total_amount: string;
    to_initial_debt_status: {
        label: string;
        value: EnumTypeStateDebt;
    };
    to_final_currency_unit?: InterfaceNTTCurrencyWithTypeAndLocation;
    to_change_rate: string;
    to_final_total_amount: string;
    to_final_debt_status: {
        label: string;
        value: EnumTypeStateDebt;
    };
    commissions: InterfaceNTTCommissionOfTrade[];
    beneficiary_orders: InterfaceInnerBeneficiaryOrder[];
    profits: InterfaceProfitOfTradeFromServer[];
    created_at: number;
    updated_at: number;
}
export interface InterfaceProfitOfTradeFromServer {
    id: number;
    total_profit: string;
    currency_unit: InterfaceNTTCurrencyWithTypeAndLocation;
    final_total_profit: string;
    description: string;
    created_at: number;
}

export interface InterfaceBeneficiaryOrderFromServer {
    id: number;
    user: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    mediator_type: {
        label: string;
        value: EnumInnerBeneficiaryOrderForStepTrade;
    },
    order: number;
};
export interface InterfaceCommissionFromServer {
    id: number;
    person: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    currency_unit: InterfaceNTTCurrencyWithTypeAndLocation;
    debt_status: {
        label: string;
        value: EnumTypeStateDebt;
    };
    amount: string;
    description: string;
    created_at: number;
    updated_at: number;
};
