import { ChangeEvent, useState } from "react";
import { InterfaceTextFieldSimple } from "./InterfaceTextFieldSimple";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import {ShowAlert} from "../../../../store/AlertStateModeSlice";
import {enumTypeOfAlert} from "../../../MUIConfig/alert_popup/interfaceAlertPopup";
import {useDispatch} from "react-redux";
import {styled} from "@mui/material";
import {InterfaceColorOfButton} from "../../Buttons/ButtonColor/InterfaceButtonColor";

const DivIcon = styled("div", { shouldForwardProp: (prop) => prop !== 'isCallbackExist' })<{
    isCallbackExist: boolean;
}>(({
    theme,
    isCallbackExist,

}) => {
    return (
        {
           marginInlineStart: '0.32rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: isCallbackExist ? 'pointer' : 'default',
            color: isCallbackExist ? 'black' : 'grey',
            transition:'transform 0.3s ease',
            transform: 'rotate(0deg)',
            "&:active": {
               transform: 'rotate(180deg)',
            },
        }
    )
});
function TextFieldSimple(paramsComponent: InterfaceTextFieldSimple) {
    const [getIsFocused, setIsFocused] = useState(false);
    const [isRotated, setIsRotated] = useState(false);
    const dispatch = useDispatch();
    var confComps = ConfigComps();
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            ...paramsComponent.style,
        }}>
            {paramsComponent.title && (
                <label style={{
                    fontWeight: "300",
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    lineHeight: "1.68rem",
                    color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                    marginBottom: '0.22rem',
                }}>
                    {paramsComponent.title}
                </label>
            )}
            <div style={{
                display: 'flex',
                alignItems: 'center',
                background: Colors.theme.white.white90,
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                borderRadius: '0.4rem',
                paddingInline: '0.32rem',
                paddingBlock: '0.16rem',
            }}>
                <input
                    autoComplete="off"
                    type={paramsComponent.type ?? "text"}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    style={{
                        flex: 1,
                        background: 'transparent',
                        border: 'none',
                        outline: 'none',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        lineHeight: "1.68rem",
                        paddingInline: '0.32rem',
                        paddingBlock: "0.16rem",
                    }}
                    value={paramsComponent.textValue}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        paramsComponent.onChangeValue(event.target.value);
                    }}
                />
                {paramsComponent.iconOrComponent && (
                    <DivIcon
                        isCallbackExist={paramsComponent.callbackOnClickIcon !==undefined}

                        onClick={() => {
                            setIsRotated(!isRotated);
                            paramsComponent.callbackOnClickIcon?.();
                            if(paramsComponent.callbackOnClickIcon === undefined) {
                                dispatch(ShowAlert({
                                    text: 'Can not reload',
                                    typeAlert: enumTypeOfAlert.warning,
                                    lastTimeecondsUpdate: (new Date()).getSeconds()
                                }))
                            }
                        }}>
                        {paramsComponent.iconOrComponent}
                    </DivIcon>
                )}
            </div>
        </div>
    );
}

export default TextFieldSimple;