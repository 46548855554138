import { useNavigate } from "react-router-dom";
import { DialogFromMUI } from "../../../MUIConfig/DialogFromMUI";
import { InterfaceDialogRejectWithText } from "./InterfaceDialogRejectWithText";
import { ButtonBase, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import ButtonColor from "../../../basic/Buttons/ButtonColor/ButtonColor";
import { CancelRounded, ChatRounded, DoneAllRounded, DoneRounded } from "@mui/icons-material";
import Colors, { ColorThemForButton } from "../../../../config/Colors";
import TextDescription from "../../../basic/Texts/TextDescription/TextDescription";
import TextFieldMultiLine from "../../../basic/TextField/TextFieldMultiLine/TextFieldMultiLine";

function DialogRejectWithText(paramsDialog: InterfaceDialogRejectWithText) {

    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getOpen, setOpen] = useState<boolean>(false);
    const [getDescriptionValue, setDescriptionValue] = useState<string>("");


    useEffect(() => {
        setOpen(paramsDialog.isOpen);
    }, [paramsDialog.isOpen])

    useEffect(() => {
        setDescriptionValue(paramsDialog.defaultText);
    }, [paramsDialog.defaultText])
    const handleClose = async () => {
        // setOpen(false);
        paramsDialog.callbackClickReject();
    };


    // const handleOnclickCancelDialog = () => {
    //     paramsDialog.callbackClickReject();
    // }



    return (
        <DialogFromMUI onClose={handleClose} open={getOpen} dir={mTheme.direction}
            onClick={(event) => {
                event.stopPropagation();
            }}
        // fullWidth
        >
            <div style={{
                display: "flex", flexDirection: "column",
                justifyContent: "space-between", alignItems: "center",
                gap: "1rem",
                paddingInline: "2rem",
                paddingBlock: "2.3rem",
            }}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "end" }}>
                    <h3 style={{ fontFamily: confComps.font.poppins.SemiBold, color: Colors.theme.blue.blue }}>
                        {paramsDialog.title}
                    </h3>
                </div>
                {paramsDialog.children}
                {paramsDialog.description}
                <TextFieldMultiLine
                    title={confComps.translate.explanation}
                    text={getDescriptionValue}
                    onChangeText={setDescriptionValue}
                    styleParent={{
                        boxSizing: 'border-box',
                        border: `1px solid ${Colors.theme.blue.border}`,
                        background: Colors.theme.white.white90,
                    }}
                />
                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between", gap: "20vh" }}>
                    <ButtonColor text={paramsDialog.redButtonTitle ?? confComps.translate.cancel}
                        onclick={paramsDialog.callbackClickReject}
                        iconStart={<CancelRounded />}
                        color={ColorThemForButton.orangeColorAndTransparentBackground}
                    />
                    <ButtonColor
                        text={paramsDialog.greenButtonTitle ?? confComps.translate.save}
                        onclick={() => paramsDialog.callbackClickAccept(getDescriptionValue)}
                        iconStart={<ChatRounded />}
                        color={ColorThemForButton.greenColorAndTransparentBackground}
                    />
                </div>

            </div>

        </DialogFromMUI >
    );

};
export default DialogRejectWithText;