import { useNavigate, useParams } from "react-router-dom";
import ContainerPage from "../../../../../components/basic/containerPage/ContainerPage";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { InterfaceCustomerProfileParent, InterfaceGetAPITheCustomerInfo } from "./InterfaceCustomerProfileParent";
import { useDispatch, useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../api/API";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ImageSquare } from "../../../../../components/complex/imageSquare/ImageSquare";
import { Person2Rounded } from "@mui/icons-material";
import Colors from "../../../../../config/Colors";
import { EditPenCircle } from "../../../../../assets/icons/svg/EditPenCircleIcon";
import { PinLocationIcon } from "../../../../../assets/icons/svg/PinLocationIcon";
import ArrayText from "../../../../../components/basic/Texts/ArrayText/ArrayText";
import { getColorCodeOfBalanceNumber } from "../../../../../helper/Helper";
import CustomerProfileHeader from "./CustomerProfileHeader/CustomerProfileHeader";
import { MainCard } from "./CustomerProfileParentStyle";
import NavbarHorizontal from "../../../../../components/complex/NavbarHorizontal/NavbarHorizontal";
import AllRecommender from "../../SubInfoProfile/Recommendation/AllRecommender/AllRecommender";
import AllRecommended from "../../SubInfoProfile/Recommendation/AllRecommended/AllRecommended";
import CustomerBasicInformation from "../../SubInfoProfile/CustomerBasicInformation/CustomerBasicInformation";
import CashAccounts from "../../SubInfoProfile/CashAccounts/CashAccounts";
import DomesticCompany from "../../SubInfoProfile/Companies/DomesticCompany/DomesticCompany";
import ForeignCompany from "../../SubInfoProfile/Companies/ForeignCompany/ForeignCompany";
import AllNestaWallets from "../../SubInfoProfile/NestaWallets/AllNestaWallets/AllNestaWallets";
import { EnumProfileTypeNameInServer } from "../../../../../NTTs/InterfaceNTTProfileType";
import { InterfaceNTTProfile } from "../../../../../NTTs/InterfaceNTTProfile";
import ForeignLegalBasicInformation from "../../SubInfoProfile/ForeignLegalBasicInformation/ForeignLegalBasicInformation";
import IranLegalBasicInformation from "../../SubInfoProfile/IranLegalBasicInformation/IranLegalBasicInformation";
import TradeStepTrustyOrCustomer from "../../DealTrustyOrCustomer/TradeStepTrustyOrCustomer/TradeStepTrustyOrCustomer";
import SlipStepTrustyOrCustomer from "../../DealTrustyOrCustomer/SlipStepTrustyOrCustomer/SlipStepTrustyOrCustomer";
import ExportStepTrustyOrCustomer from "../../DealTrustyOrCustomer/ExportStepTrustyOrCustomer/ExportStepTrustyOrCustomer";

export enum EnumSubPageProfileCustomerId {
    profileSection = 1,
    nestaWalletSection,
    cashAccountSection,



    recommendation,
    recommendedSection,
    recommenderSection,

    dealSection,
    TradeStepsTrustyOrCustomer,
    slipStepsTrustyOrCustomer,
    exportStepsTrustyOrCustomer,
    serviceSection,

    company,
    foreignCompanySection,
    domesticCompanySection,
}
function CustomerProfileParent(paramsPage: InterfaceCustomerProfileParent) {
    var confComps = ConfigComps();
    const { idCustomer } = useParams();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getSelectedSubPage, setSelectedSubPage] = useState<EnumSubPageProfileCustomerId>();
    const [getResponseCustomerInfo, setResponseCustomerInfo] = useState<InterfaceGetAPITheCustomerInfo>();
    const getApiTheCustomerInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.customer.getTheCustomerInfo;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idCustomer}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetAPITheCustomerInfo;
                setResponseCustomerInfo(t);
                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApiTheCustomerInfo();
    }, [idCustomer])
    return (
        <ContainerPage title={confComps.panel.people.customerInfo.pageInfo.title}>
            <MainCard>
                <CustomerProfileHeader userInfo={getResponseCustomerInfo}
                    callbackSelectWallet={() => {
                        setSelectedSubPage(EnumSubPageProfileCustomerId.nestaWalletSection);
                    }}
                    isWalletSelected={getSelectedSubPage === EnumSubPageProfileCustomerId.nestaWalletSection}
                />


                <NavbarHorizontal titleList={confComps.panel.people.customerInfo.pageInfo.navbarHorizontalItem}
                    callbackOnClick={(id: number) => {
                        setSelectedSubPage(id);
                    }}
                    isUnderLineShow={getSelectedSubPage !== EnumSubPageProfileCustomerId.nestaWalletSection} />
                <Box sx={{
                    paddingInline: { xs: 2, sm: 4, md: 5, lg: 6, xl: 6 },
                    paddingBlockStart: { xs: 1, sm: 2, md: 3, lg: 4, xl: 4 },
                    boxSizing: "border-box",
                }}>
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.profileSection) && getResponseCustomerInfo &&
                        (getResponseCustomerInfo.person_type.value === EnumProfileTypeNameInServer.individualProfile ?
                            <CustomerBasicInformation
                                userInfoBasic={getResponseCustomerInfo}
                                callbackReload={getApiTheCustomerInfo}
                            />
                            :
                            getResponseCustomerInfo.person_type.value === EnumProfileTypeNameInServer.iranLegal ?
                                <IranLegalBasicInformation
                                    userInfoBasic={{
                                        ...getResponseCustomerInfo,
                                        profile: getResponseCustomerInfo.profile as InterfaceNTTProfile
                                    }}
                                    callbackReload={getApiTheCustomerInfo}
                                />
                                :
                                <ForeignLegalBasicInformation
                                    userInfoBasic={{
                                        ...getResponseCustomerInfo,
                                        profile: getResponseCustomerInfo.profile as InterfaceNTTProfile
                                    }}
                                    callbackReload={getApiTheCustomerInfo}
                                />
                        )
                    }
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.recommenderSection) && getResponseCustomerInfo && <AllRecommender userInfoBasic={getResponseCustomerInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.recommendedSection) && getResponseCustomerInfo && <AllRecommended userInfoBasic={getResponseCustomerInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.cashAccountSection) && getResponseCustomerInfo && <CashAccounts userInfoBasic={getResponseCustomerInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.domesticCompanySection) && getResponseCustomerInfo && <DomesticCompany userInfoBasic={getResponseCustomerInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.foreignCompanySection) && getResponseCustomerInfo && <ForeignCompany userInfoBasic={getResponseCustomerInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.nestaWalletSection) && getResponseCustomerInfo && <AllNestaWallets userInfoBasic={getResponseCustomerInfo} />}
                    {/* deals */}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.TradeStepsTrustyOrCustomer) && getResponseCustomerInfo && <TradeStepTrustyOrCustomer userInfoBasic={getResponseCustomerInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.slipStepsTrustyOrCustomer) && getResponseCustomerInfo && <SlipStepTrustyOrCustomer userInfoBasic={getResponseCustomerInfo} />}
                    {(getSelectedSubPage === EnumSubPageProfileCustomerId.exportStepsTrustyOrCustomer) && getResponseCustomerInfo && <ExportStepTrustyOrCustomer userInfoBasic={getResponseCustomerInfo} />}
                </Box>
            </MainCard>

        </ContainerPage>
    );
}
export default CustomerProfileParent;