import { Box, Grid, useTheme } from "@mui/material";
import Colors from "../../../../../../config/Colors";
import SubTitleSection from "../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { useEffect, useState } from "react";
import TextFieldSimple from "../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import FilesDragAndDrop from "../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { PaperUploadIcon } from "../../../../../../assets/icons/svg/PaperUploadIcon";
import ListFiles from "../../../../../../components/complex/ListFiles/ListFiles";
import { InterfaceFile } from "../../../../../../components/complex/ListFiles/InterfaceListFiles";
import { DialogAttachDetailOfFile } from "../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";

import { isVisibilityProgressBar, updateProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../api/API";
import { InterfaceNTTActivity } from "../../../../../../NTTs/InterfaceNTTActivity";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import AutoCompleteSimple from "../../../../../../components/complex/AutoCompleteSimple/AutoCompleteSimple";
import { wait } from "@testing-library/user-event/dist/utils";
import ButtonCancel from "../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import { InterfaceNTTCurrency, InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../NTTs/InterfaceNTTCurrency";
import { InterfaceNTTCountry } from "../../../../../../NTTs/InterfaceNTTCountry";
import { InterfaceNTTCity } from "../../../../../../NTTs/InterfaceNTTCity";
import SelectCity from "../../../../../../components/complex/Selector/SelectCity/SelectCity";
import SelectCountry from "../../../../../../components/complex/Selector/SelectCountry/SelectCountry";
import SelectCurrency from "../../../../../../components/complex/Selector/SelectCurrency/SelectCurrency";
import MultiSelectCurrencies from "../../../../../../components/complex/MultiSelectCurrencies/MultiSelectCurrencies";
import SelectUser from "../../../../../../components/complex/Selector/SelectUser/SelectUser";
import { InterfaceNTTUser } from "../../../../../../NTTs/InterfaceNTTUser";
import SelectCurrencyWithTypeAndLocation from "../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import { XELogoIcon } from "../../../../../../assets/icons/svg/XELogoIcon";
import { ImageSquare } from "../../../../../../components/complex/imageSquare/ImageSquare";
import { TwoArrowIcon } from "../../../../../../assets/icons/svg/TwoArrowIcon";
import { AddIcon } from "../../../../../../assets/icons/svg/AddIcon";
import { InterfaceCreateNestaWallet } from "./InterfaceCreateNestaWallet";
function CreateNestaWallet(paramsComponent: InterfaceCreateNestaWallet) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getBalance, setBalance] = useState<string>("0");//Number(paramsComponent.defaultNestaWallet?.balance ?? 0));
    const [getSelectedCurrency, setSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation | undefined>(paramsComponent.defaultNestaWallet?.currency_unit);
    const [getDescription, setDescription] = useState<string>("");

    const callApiCreateNestaWallet = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.wallet.nestaWallet.postCreateNewNestaWallet;
        let dataObj = {
            "balance": getBalance,
            "currency_unit": getSelectedCurrency?.id ?? -1,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/nesta-wallets/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackCancel();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: confComps.translate.createdAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }

    useEffect(() => {
    }, [])
    return (
        <Box sx={{
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
            width: "100%",
            boxSizing: "border-box",
            paddingInline: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
            border: `1px solid ${Colors.theme.blue.border}`,
            borderRadius: "0.4rem",
            position: "sticky",
            top: "20px",
            zIndex: 2,
            background: "white",
        }}>
            <Box sx={{
                boxSizing: "border-box",
                width: "100%",
                marginBlockEnd: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
            }}>

                <SubTitleSection title={confComps.translate.addNewNestaWallet} />


                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                    marginInline: { xs: 0, sm: 1, md: 1, lg: 2, xl: 2 },
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "start",
                    columnGap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
                    rowGap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
                    flexWrap: "wrap",
                    height: '100%',
                }}>

                    <SelectCurrencyWithTypeAndLocation
                        title={"type wallet"}
                        style={{
                            
                            alignItems: "stretch",
                        }}
                        onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                            setSelectedCurrency(selectCountry)
                        }}
                        defaultCurrency={getSelectedCurrency}
                    />


                        <TextFieldSimple
                            style={{
                                alignItems: "stretch",
                                boxSizing: "border-box",
                            }}

                            title="value"
                            textValue={`${getBalance}`}
                            onChangeValue={(newText: string) => {
                                const validText = newText.replace(/[^0-9.]/g, '');
                                setBalance(validText);
                            }}
                            type="text"
                        />
                </Box>

            </Box>

            <div style={{
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                marginBlock: "1rem",
                width: "100%",
                gap: "0.5rem",
                // position: "sticky",
                // bottom:"20px",
            }}>
                <ButtonCancel text={confComps.translate.cancel}
                    callbackOnClick={() => {
                        paramsComponent.callbackCancel();
                    }} />
                <ButtonSave text={paramsComponent.defaultNestaWallet ? confComps.translate.update : confComps.translate.save}
                    callbackOnClick={() => {
                        callApiCreateNestaWallet();
                    }} />


            </div>

        </Box >

    );

}
export default CreateNestaWallet;
