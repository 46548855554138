import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import Colors, { ColorThemForButton } from '../../../../../../../config/Colors';
import { ConfigComps } from '../../../../../../../config/ConfigCOMP';
import { InterfaceCreateFileForAttach, InterfaceViewOneStepOfTrade } from './InterfaceViewOneStepOfTrade';
import { EnumProfileTypeNameInServer } from '../../../../../../../NTTs/InterfaceNTTProfileType';
import { InterfaceNTTProfile } from '../../../../../../../NTTs/InterfaceNTTProfile';
import { InterfaceNTTProfileForeignLegal } from '../../../../../../../NTTs/InterfaceNTTProfileForeignLegal';
import { InterfaceNTTProfileIranLegal } from '../../../../../../../NTTs/InterfaceNTTProfileIranLegal';
import { CurrencySymbols } from '../../../../../../../helper/CurrencySymbols';
import ShowPriority from '../../../../../../../components/basic/ShowPriority/ShowPriority';
import { useNavigate } from 'react-router-dom';
import Links from '../../../../../../../config/Links';
import { WalletIcon } from '../../../../../../../assets/icons/svg/WalletIcon';
import { AttachFileRounded, CancelRounded, PaymentsRounded, ThumbDownRounded, ThumbUpRounded, UpgradeRounded } from '@mui/icons-material';
import ViewChangeRate from '../../../../../../../components/complex/ChangeRateWithAmount/ViewChangeRate/ViewChangeRate';
import ShowDescriptionWithUsers from '../../../../../../../components/complex/ShowDescriptionWithUsers/ShowDescriptionWithUsers';
import dayjs from 'dayjs';
import ButtonSave from '../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave';
import { TrashIcon } from '../../../../../../../assets/icons/svg/TrashIcon';
import DialogAcceptProcess from '../../../../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess';
import { useDispatch, useSelector } from 'react-redux';
import { RequestHandler } from '../../../../../../../api/API';
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from '../../../../../../../api/APIErrorResponse';
import { urlsList, HandlerLangUrl } from '../../../../../../../api/UrlsList';
import { enumTypeOfAlert } from '../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup';
import { ShowAlert } from '../../../../../../../store/AlertStateModeSlice';
import { isVisibilityProgressBar } from '../../../../../../../store/ProgressbarInRootSlice';
import { SelectedProfile } from '../../../../../../../store/profileStateSlice';
import ButtonCancel from '../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel';
import { EnumStepsTrade, EnumSTepsParent } from '../../../../../../../config/enums/EnumSteps';
import DialogRejectWithText from '../../../../../../../components/complex/Dialog/DialogRejectWithText/DialogRejectWithText';
import SubTitleSection from '../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection';
import FilesDragAndDrop from '../../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop';
import { InterfaceFile } from '../../../../../../../components/complex/ListFiles/InterfaceListFiles';
import ListFiles from '../../../../../../../components/complex/ListFiles/ListFiles';
import { DialogAttachDetailOfFile } from '../../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile';
import { EnumStateValueOfTradeStep, InterfaceNTTStepOfTrade } from '../../../../../../../NTTs/Trade/InterfaceNTTStepOfTrade';
import { EnumStateValueOfTrade } from '../../../../../../../NTTs/Trade/InterfaceNTTTradeMain';
import { InterfaceNTTAttachmentOfTrade } from '../../../../../../../NTTs/Trade/InterfaceNTTAttachmentOfTrade';
import { EnumTypeStateDebt } from '../../../../../../../config/enums/EnumTypeStateDebt';
import MultiCommissionOfTrade from '../../../../../../../components/complex/MultiCommissionOfTrade/MultiCommissionOfTrade';
import { InterfaceNTTCommissionOfTrade } from '../../../../../../../NTTs/Trade/InterfaceNTTCommissionOfTrade';
import MultiNetProfit from '../../../../../../../components/complex/MultiNetProfit/MultiNetProfit';
import { InterfaceNTTProfitOfTrade } from '../../../../../../../NTTs/Trade/InterfaceNTTProfitOfTrade';
import InnerBeneficiary from '../../../../../../../components/complex/InnerBeneficiary/InnerBeneficiary';
import { InterfaceInnerBeneficiaryOrder } from '../InterfaceCreateOneStepOfTrade';
import ButtonColor from '../../../../../../../components/basic/Buttons/ButtonColor/ButtonColor';
import ShowUnitOfMoneyThreePart from '../../../../../../../components/complex/ShowUnitOfMoneyThreePart/ShowUnitOfMoneyThreePart';
import ToolTipText from '../../../../../../../components/basic/ToolTipText/ToolTipText';
import moment from "moment-jalaali";

function ViewOneStepOfTrade(paramsComponent: InterfaceViewOneStepOfTrade) {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getThisStep, setThisStep] = useState<InterfaceNTTStepOfTrade>(paramsComponent.step);
    const [getStateOfMainTrade, setStateOfMainTrade] = useState<EnumStateValueOfTrade>(paramsComponent.stateOfMainTrade)
    const [getIsDialogDeleteShow, setIsDialogDeleteShow] = useState<boolean>(false);
    const [getIsDialogRejectStepShow, setIsDialogRejectStepShow] = useState<boolean>(false);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("2");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    const [getNewFileForAttach, setNewFileForAttach] = useState<File>();
    const callApiForDeleteThisStep = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.trade.deleteOneStep;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getThisStep.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackDeleteThisStep();
                dispatch(ShowAlert({ text: "This Step Removed.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForUpdateStateOfThisTradeStep = async (stateValue: EnumStateValueOfTradeStep) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.trade.updateTradeStep;
        let DataObj = {
            "state": stateValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getThisStep.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackReload();
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForRejectThisTradeStep = async (description: string) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.trade.updateTradeStep;
        let DataObj = {
            "state": EnumStateValueOfTradeStep.refinement,
            "description": description,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getThisStep.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, DataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackReload();
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setThisStep(paramsComponent.step);
    }, [paramsComponent.step]);
    useEffect(() => {
        setStateOfMainTrade(paramsComponent.stateOfMainTrade);
    }, [paramsComponent.stateOfMainTrade]);
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.keyOfStepThatSelected);
    }, [paramsComponent.keyOfStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <Box
            sx={{
                width: '100%',
                border: `0.5px solid ${Colors.theme.blue.border}`,
                background: Colors.theme.white.white90,
                boxSizing: 'border-box',
                boxShadow: Colors.shadow.cardList,
                paddingInline: { xs: '0.7rem', sm: '1rem', md: '2.66rem', lg: '2.96rem', xl: '3.25rem' },
                paddingBlock: '2rem',
                borderRadius: '0.38rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'flex-start',
                gap: '1rem',
            }}
        >
            <div style={{
                display: 'flex', flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}>
                <h5 style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'start',
                    font: confComps.font.poppins.Medium,
                    fontSize: '1.18rem',
                    lineHeight: '1.77rem',
                    fontWeight: '600',
                    color: Colors.theme.orange.light,
                    gap: '0.4rem',
                }}>
                    {`Step ${paramsComponent.index}`}
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'start',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1rem',
                    }}>
                        {getThisStep.state.value === EnumStateValueOfTradeStep.pendingForManager &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.orange.light,
                            }}>
                                {"(Pending Manager...)"}
                            </p>
                        }

                        {getThisStep.state.value === EnumStateValueOfTradeStep.approvedByManager &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.green.dark,
                            }}>
                                {"(Approved)"}
                            </p>
                        }
                        {getThisStep.state.value === EnumStateValueOfTradeStep.refinement &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.red.red,
                            }}>
                                {"(Rejected)"}
                            </p>
                        }
                        {getThisStep.state.value === EnumStateValueOfTradeStep.completed &&
                            <p style={{
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1rem',
                                lineHeight: 'inherit',
                                color: Colors.theme.green.dark,
                            }}>
                                {"(Completed)"}
                            </p>
                        }
                    </div>
                </h5>
                <div>
                    <ShowPriority priority={getThisStep.priority} />
                </div>

            </div>

            {/* From Section */}
            <Box sx={{ width: '100%' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'From'}
                </Typography>
                <Grid container
                    justifyContent="start" alignItems="flex-start"
                    // columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                    rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >

                    {/* Customer */}
                    {getThisStep.from_person && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2">
                                {`${confComps.translate.customer}:`}
                            </Typography>
                            <Typography variant="body1">
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfile).first_name}  ${(getThisStep.from_person.profile as InterfaceNTTProfile).last_name}`
                                }
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {getThisStep.from_person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getThisStep.from_person.profile as InterfaceNTTProfileIranLegal).name} (${(getThisStep.from_person.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }

                            </Typography>
                            <p style={{
                                color: Colors.theme.blue.Secondary,
                                fontFamily: confComps.font.poppins.SemiBold,
                                fontSize: "0.9rem",
                                lineHeight: "1.6rem",
                                flexWrap: "nowrap",
                                textWrap: "nowrap",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (getThisStep.from_person?.is_trusty) {
                                        navigate(Links.screens.panel.people.trusties.trustyProfile + `${getThisStep.from_person?.user.id}/`)
                                    }
                                    else {
                                        navigate(Links.screens.panel.people.customers.customersProfile + `${getThisStep.from_person?.user.id}/`)
                                    }
                                }}
                            >
                                {confComps.translate.openProfile}
                            </p>
                        </Box>
                    </Grid>}
                    {/* Amount */}
                    {getThisStep.from_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`${confComps.translate.amount} (${getThisStep.from_initial_currency_unit.currency.symbol}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.from_initial_amount)).toLocaleString()}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* commission fix */}
                    {getThisStep.from_initial_currency_unit && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`Fix Commission (${getThisStep.from_initial_currency_unit.currency.symbol}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: Colors.theme.blue.dark,
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                {Number(parseFloat(getThisStep.from_fix_commission)).toLocaleString()}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_initial_currency_unit} />
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>}
                    {/* total in currency type 1 */}
                    {getThisStep.from_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`Total value (${getThisStep.from_initial_currency_unit.currency.symbol})`}


                                </Typography>
                                <Typography variant="subtitle2" sx={{
                                    color:
                                        getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                            Colors.theme.green.dark :
                                            getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                                Colors.theme.red.red :
                                                Colors.theme.blue.light
                                }}>
                                    {`(${getThisStep.from_initial_debt_status.label}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.from_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.from_initial_total_amount)).toLocaleString()}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* change rate */}
                    {getThisStep.from_final_currency_unit && getThisStep.from_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {'Change Rate:'}
                                </Typography>
                                <Typography variant="body1" sx={{ color: Colors.theme.blue.dark }}>
                                    <ViewChangeRate
                                        rate={getThisStep.from_change_rate}
                                        defaultFromCurrency={getThisStep.from_initial_currency_unit}
                                        defaultToCurrency={getThisStep.from_final_currency_unit}
                                    />
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* second */}
                    {getThisStep.from_final_currency_unit && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`Total value (${getThisStep.from_final_currency_unit.currency.symbol})`}


                            </Typography>
                            <Typography variant="subtitle2" sx={{
                                color:
                                    getThisStep.from_final_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.from_final_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light
                            }}>
                                {`(${getThisStep.from_final_debt_status.label}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: getThisStep.from_final_debt_status.value === EnumTypeStateDebt.credit ?
                                    Colors.theme.green.dark :
                                    getThisStep.from_final_debt_status.value === EnumTypeStateDebt.debit ?
                                        Colors.theme.red.red :
                                        Colors.theme.blue.light,
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                {Number(parseFloat(getThisStep.from_final_total_amount)).toLocaleString()}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.from_final_currency_unit} />
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>
            {/* "To" Section */}
            <Box sx={{ width: '100%', marginTop: '2rem' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'To'}
                </Typography>
                <Grid container
                    justifyContent="start" alignItems="flex-start"
                    rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    {/* Customer */}
                    {getThisStep.to_person && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2">
                                {`${confComps.translate.customer}:`}
                            </Typography>
                            <Typography variant="body1">
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfile).first_name} ${(getThisStep.to_person.profile as InterfaceNTTProfile).last_name}`
                                }
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {getThisStep.to_person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getThisStep.to_person.profile as InterfaceNTTProfileIranLegal).name} (${(getThisStep.to_person.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }
                            </Typography>
                            <p style={{
                                color: Colors.theme.blue.Secondary,
                                fontFamily: confComps.font.poppins.SemiBold,
                                fontSize: "0.9rem",
                                lineHeight: "1.6rem",
                                flexWrap: "nowrap",
                                textWrap: "nowrap",
                                cursor: "pointer",
                                userSelect: "none",
                            }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (getThisStep.to_person?.is_trusty) {
                                        navigate(Links.screens.panel.people.trusties.trustyProfile + `${getThisStep.to_person.user.id}/`)
                                    }
                                    else {
                                        navigate(Links.screens.panel.people.customers.customersProfile + `${getThisStep.to_person?.user.id}/`)
                                    }
                                }}
                            >
                                {confComps.translate.openProfile}
                            </p>
                        </Box>
                    </Grid>}
                    {/* Amount */}
                    {getThisStep.to_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`${confComps.translate.amount} (${getThisStep.to_initial_currency_unit.currency.symbol}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.to_initial_amount)).toLocaleString()}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>
                    }
                    {/* commission fix */}
                    {getThisStep.to_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`Fix Commission (${getThisStep.to_initial_currency_unit.currency.symbol}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark,
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.to_fix_commission)).toLocaleString()}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_initial_currency_unit} />

                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* total in currency type 1 */}
                    {getThisStep.to_initial_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {`Total value (${getThisStep.to_initial_currency_unit.currency.symbol})`}
                                </Typography>
                                <Typography variant="subtitle2" sx={{
                                    color:
                                        getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                            Colors.theme.green.dark :
                                            getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                                Colors.theme.red.red :
                                                Colors.theme.blue.light
                                }}>
                                    {`(${getThisStep.to_initial_debt_status.label}):`}
                                </Typography>
                                <Typography variant="body1" sx={{
                                    color: getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.to_initial_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light,
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    justifyContent: 'start', gap: '0.4rem',
                                }}>
                                    {Number(parseFloat(getThisStep.to_initial_total_amount)).toLocaleString()}
                                    <Typography variant="body1" sx={{
                                        color: Colors.theme.blue.dark, gap: '0.4rem',
                                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_initial_currency_unit} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* change rate */}
                    {getThisStep.to_initial_currency_unit && getThisStep.to_final_currency_unit &&
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}>
                                <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                    {'Change Rate:'}
                                </Typography>
                                <Typography variant="body1" sx={{ color: Colors.theme.blue.dark }}>
                                    <ViewChangeRate
                                        rate={getThisStep.to_change_rate}
                                        defaultFromCurrency={getThisStep.to_initial_currency_unit}
                                        defaultToCurrency={getThisStep.to_final_currency_unit}
                                    />
                                </Typography>
                            </Box>
                        </Grid>}
                    {/* total in currency type 2 */}
                    {getThisStep.to_final_currency_unit && <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'start',
                            gap: '0.4rem',
                        }}>
                            <Typography variant="subtitle2" sx={{ color: Colors.theme.blue.light }}>
                                {`Total value (${getThisStep.to_final_currency_unit.currency.symbol})`}
                            </Typography>
                            <Typography variant="subtitle2" sx={{
                                color:
                                    getThisStep.to_final_debt_status.value === EnumTypeStateDebt.credit ?
                                        Colors.theme.green.dark :
                                        getThisStep.to_final_debt_status.value === EnumTypeStateDebt.debit ?
                                            Colors.theme.red.red :
                                            Colors.theme.blue.light
                            }}>
                                {`(${getThisStep.to_final_debt_status.label}):`}
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: getThisStep.to_final_debt_status.value === EnumTypeStateDebt.credit ?
                                    Colors.theme.green.dark :
                                    getThisStep.to_final_debt_status.value === EnumTypeStateDebt.debit ?
                                        Colors.theme.red.red :
                                        Colors.theme.blue.light,
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'start', gap: '0.4rem',
                            }}>
                                {Number(parseFloat(getThisStep.to_final_total_amount)).toLocaleString()}
                                <Typography variant="body1" sx={{
                                    color: Colors.theme.blue.dark, gap: '0.4rem',
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                }}>
                                    <ShowUnitOfMoneyThreePart currencyNTT={getThisStep.to_final_currency_unit} />
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>


            {/* Other Details */}
            <Box sx={{ width: '100%', marginTop: '2rem' }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: Colors.theme.blue.Secondary,
                        fontSize: '1.3rem',
                        marginTop: '0.5rem',
                        lineHeight: '1.77rem',
                        fontFamily: confComps.font.poppins.Medium,
                    }}
                >
                    {'Other Details'}
                </Typography>
                <Grid
                    container
                    justifyContent="start"
                    alignItems="flex-start"
                    rowSpacing={{ xs: '0.86rem', sm: '0.96rem', md: '1.06rem', lg: '1.18rem', xl: '1.30rem' }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    {/* Deal Type */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2">{'Deal Type:'}</Typography>
                            <Typography variant="body1">
                                {getThisStep.service_type.title}
                            </Typography>
                        </Box>
                    </Grid>
                    {/* time create this step */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                gap: '0.4rem',
                            }}
                        >
                            <Typography variant="subtitle2">{'Create Time:'}</Typography>
                            <Typography variant="body1">
                                <ToolTipText
                                    textHint={`Jalali: ${moment.unix(getThisStep?.created_at ?? 0).format("jYYYY/jM/jD")}`}
                                >
                                    {`${dayjs.unix(getThisStep.created_at).format("YYYY/MM/DD")}`}
                                </ToolTipText>
                            </Typography>
                        </Box>
                    </Grid>
                    {
                        (getThisStep.commissions.length > 0) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MultiCommissionOfTrade
                                callbackUpdateList={(list: InterfaceNTTCommissionOfTrade[]) => {
                                }}
                                defaultList={getThisStep.commissions}
                                isCreationActive={false}
                            />
                        </Grid>
                    }
                    {
                        (getThisStep.profits.length > 0) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MultiNetProfit
                                isCreationActive={false}
                                callbackUpdateList={(list: InterfaceNTTProfitOfTrade[]) => {
                                }}
                                defaultList={getThisStep.profits}
                            />
                        </Grid>
                    }
                    {
                        (getThisStep.beneficiary_orders.length > 0) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InnerBeneficiary
                                isCreationActive={false}
                                callbackUpdateList={function (list: InterfaceInnerBeneficiaryOrder[]): void {

                                }}
                                defaultList={getThisStep.beneficiary_orders}
                            />
                        </Grid>
                    }
                    {/* Description */}
                    {getThisStep.description && (
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    gap: '0.4rem',
                                }}
                            >
                                <Typography variant="subtitle2">{`${confComps.translate.explanation}:`}</Typography>
                                {<div style={{
                                    display: 'flex', flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    paddingInline: '0.6rem',
                                    marginTop: '0.6rem',
                                    paddingBlock: '0.6rem',
                                    borderRadius: '0.5rem',
                                    boxSizing: 'border-box',
                                    background: (getThisStep.state.value === EnumStateValueOfTradeStep.refinement) ? Colors.theme.orange.lemonLight : Colors.theme.blue.white2,
                                    boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
                                    width: '100%',
                                }}>
                                    <div>
                                        {(getThisStep.state.value === EnumStateValueOfTradeStep.refinement) && "Manager Rejected! "}
                                    </div>
                                    <Typography variant="body1" align={mTheme.direction === 'rtl' ? "right" : "left"}>
                                        {getThisStep.description.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                </div>}
                            </Box>
                        </Grid>
                    )}



                    {/* in create step show Delete button */}
                    {
                        (getStateOfMainTrade === EnumStateValueOfTrade.created &&
                            getThisStep.state.value === EnumStateValueOfTradeStep.pendingForManager &&
                            getKeyOfStepThatSelected === `${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}`) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", alignItems: "center", gap: "1rem" }}>
                                        <ButtonColor text={"Delete This Step"} iconStart={<CancelRounded />}
                                            color={ColorThemForButton.orangeColorAndTransparentBackground}
                                            onclick={() => callApiForDeleteThisStep()}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </>
                    }
                    {/* reject/approve this step by manager (it must edit so it not has View)*/}
                    {
                        (getStateOfMainTrade === EnumStateValueOfTrade.pendingForCheckByManager &&
                            getThisStep.state.value === EnumStateValueOfTradeStep.pendingForManager &&
                            getKeyOfStepThatSelected === `${EnumSTepsParent.trade}.${EnumStepsTrade.approval}`
                        ) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    gap: '0.59rem',
                                }}>
                                    <ButtonCancel
                                        text={"Reject"}
                                        callbackOnClick={() => {
                                            setIsDialogRejectStepShow(true);
                                        }}
                                        iconStart={<ThumbDownRounded
                                            style={{
                                                fontSize: '1.1rem', marginBottom: '-5%',
                                                color: Colors.theme.orange.sharp,
                                            }}
                                        />}
                                        style={{
                                            background: "#fff",
                                            color: Colors.theme.orange.sharp,
                                            borderColor: `${Colors.theme.orange.sharp}`,
                                        }
                                        }
                                    />
                                    <ButtonSave
                                        text={"Accept"}
                                        callbackOnClick={() => {
                                            callApiForUpdateStateOfThisTradeStep(EnumStateValueOfTradeStep.approvedByManager);
                                        }}
                                        iconStart={<ThumbUpRounded
                                            style={{
                                                fontSize: '1.1rem',
                                                color: Colors.theme.green.dark,
                                            }} />
                                        }
                                        style={{
                                            background: "#fff",
                                            color: Colors.theme.green.dark,
                                            borderColor: `${Colors.theme.green.dark}`,
                                        }}
                                    />
                                </div>
                            </Grid>
                        </>
                    }
                    {/* in create step show Delete button */}
                    {/* {
                        (getStateOfMainTrade === EnumStateValueOfTrade.approvedByManager &&
                            getThisStep.state.value === EnumStateValueOfTradeStep.approvedByManager &&
                            getKeyOfStepThatSelected === `${EnumSTepsParent.trade}.${EnumStepsTrade.uploadDocumentsForSteps}`) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                }}>
                                    <ButtonSave
                                        iconStart={<AttachFileRounded
                                            style={{
                                                fontSize: '1.1rem',
                                                color: Colors.theme.green.dark,
                                            }} />
                                        }
                                        style={{
                                            background: "#fff",
                                            color: Colors.theme.green.dark,
                                            borderColor: `${Colors.theme.green.dark}`,
                                            opacity: (getThisStep.attachments.length > 0 && getAttachFileThatNeedUpload.length === 0) ?
                                                1 : 0.5,
                                        }}
                                        text={"Submit all Documents"}
                                        callbackOnClick={() => {
                                            if (getThisStep.attachments.length > 0 && getAttachFileThatNeedUpload.length === 0) {
                                                callApiForUpdateStateOfThisTradeStep(EnumStateValueOfTradeStep.completed);
                                            }
                                            else {
                                                dispatch(ShowAlert({ text: `You must attach one file at least`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                        </>
                    } */}
                </Grid>
            </Box>
            <DialogAttachDetailOfFile
                callbackOnCloseDialog={
                    () => {
                        setNewFileForAttach(undefined);
                    }
                }
                callbackSave={(newFile: InterfaceFile) => {
                    // setAttachFileThatNeedUpload(old => [...old, newFile]);
                    setNewFileForAttach(undefined);
                }}
                isOpen={getNewFileForAttach !== undefined}
                file={
                    {
                        file: getNewFileForAttach,
                        description: "",
                        title: "",
                        document: "",
                        id: -1,
                    }
                }
            />
            <DialogAcceptProcess
                greenButtonTitle={confComps.translate.yes}
                redButtonTitle={confComps.translate.no}
                isOpen={getIsDialogDeleteShow} title={"Are you Sure For Delete This Step?"} description={""}
                callbackClickAccept={function (): void {
                    setIsDialogDeleteShow(false);
                    callApiForDeleteThisStep();
                }}
                callbackClickReject={function (): void {
                    setIsDialogDeleteShow(false);
                }}
            >
                <div>
                    {`#${getThisStep.id}`}
                </div>
            </DialogAcceptProcess>
            <DialogRejectWithText
                greenButtonTitle={confComps.translate.save}
                redButtonTitle={confComps.translate.cancel}
                isOpen={getIsDialogRejectStepShow} title={"Are you Sure For Reject This Step?"} description={""}
                callbackClickAccept={function (valueText: string): void {
                    setIsDialogRejectStepShow(false);
                    callApiForRejectThisTradeStep(valueText);
                }}
                callbackClickReject={function (): void {
                    setIsDialogRejectStepShow(false);
                }}
                defaultText={getThisStep.description}>
                <div>
                    {`Please complete The description For Step (#${getThisStep.id})`}
                </div>
            </DialogRejectWithText>
        </Box>
    );
}

export default ViewOneStepOfTrade;
