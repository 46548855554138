import { useEffect, useState } from "react";
import ParentWithHeightAnimation from "../../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { InterfaceCreateInitiateMoneyReceive } from "./InterfaceCreateInitiateMoneyReceive";
import { Box, Grid, useTheme } from "@mui/material";
import TextFieldSimple from "../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../../NTTs/InterfaceNTTCurrency";
import SelectCurrencyWithTypeAndLocation from "../../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import SubTitleSection from "../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import TextFieldMultiLine from "../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../../config/Colors";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import SelectUser from "../../../../../../../components/complex/Selector/SelectUser/SelectUser";
import { InterfaceNTTUser } from "../../../../../../../NTTs/InterfaceNTTUser";
import SelectTrustyOrCustomer from "../../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceGetAPITrustyOrCustomer, InterfaceTypeSelectTrustyOrCustomer } from "../../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import { InterfaceNTTRecapMain } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { InterfaceSearchInServer } from "../../../../../../../api/interfaces/InterfaceSearchInServer";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../../../../api/UrlsList";
import { EnumStateFetchList } from "../../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceGetAPICustomersList } from "../../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Links from "../../../../../../../config/Links";

function CreateInitiateMoneyReceive(paramsComponent: InterfaceCreateInitiateMoneyReceive) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsCreateMoneyReceiveActive, setIsCreateMoneyReceiveActive] = useState<boolean>(false);
    const [getAmountValue, setAmountValue] = useState<string>("");
    const [getDescriptionValue, setDescriptionValue] = useState<string>("");
    const [getSelectedFromCurrency, setSelectedFromCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation | undefined>();
    const [getSelectedToCurrency, setSelectedToCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation | undefined>();
    const [getCustomerSelected, setCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getIsWaitingForRecapMain, setIsWaitingForRecapMain] = useState<boolean>();
    const handleOnClickDone = async () => {
        if (getRecapMain) {
            callApiForCreateMoneyReceive(getRecapMain);
        }
        else {
            paramsComponent.callBackCreateMainRecap && paramsComponent.callBackCreateMainRecap();
            setIsWaitingForRecapMain(true);
            paramsComponent.callBackSetWaiting(true);
        }
    }

    const callApiForCreateMoneyReceive = async (recapMain: InterfaceNTTRecapMain) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.createMoneyReceive;
        let bodyObj = {
            "customer": getCustomerSelected?.user.id,
            "from_currency_unit": getSelectedFromCurrency?.id,
            "amount": getAmountValue,
            "to_currency_unit": getSelectedToCurrency?.id,
            "description": getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${recapMain.id}/receipts/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // let t = response as InterfaceGetAPICustomersList;
                let getOldStateWaiting = getIsWaitingForRecapMain;
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackReLoad();
                paramsComponent.callBackSetWaiting(false);
                dispatch(isVisibilityProgressBar(false));
                if (getOldStateWaiting) {
                    navigate(Links.screens.panel.recap.ShowStateTheRecap + `${recapMain.id}/`);
                }
            })
            .catch((e: any) => {
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackSetWaiting(false);
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setIsCreateMoneyReceiveActive(paramsComponent.isCreateMoneyReceiveActive);
    }, [paramsComponent.isCreateMoneyReceiveActive])
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
        if (getIsWaitingForRecapMain && paramsComponent.recapMain) {
            callApiForCreateMoneyReceive(paramsComponent.recapMain)
        }
    }, [paramsComponent.recapMain, getIsWaitingForRecapMain])
    return (
        <ParentWithHeightAnimation in={getIsCreateMoneyReceiveActive}
            loading={getIsWaitingForRecapMain}
            style={{
                boxSizing: "border-box",
                width: "100%",
            }}
        >
            <Box sx={{
                boxSizing: "border-box",
                paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                display: "flex", flexDirection: 'column',
                alignItems: 'start',
                width: "100%",
                marginTop: "1.18rem",
            }}>
                <Grid container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 0, md: "1rem", lg: "1.18rem", xl: "2rem" }}
                    rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SelectTrustyOrCustomer
                            title={confComps.translate.customer}
                            defaultUser={{
                                realUserSelect: getCustomerSelected,
                                isAllSelected: false
                            }}
                            onChangeSelectedUser={(selectCustomer: InterfaceTypeSelectTrustyOrCustomer) => {
                                setCustomerSelected(selectCustomer.realUserSelect);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>

                        <SelectCurrencyWithTypeAndLocation
                            title={confComps.translate.fromCurrencyType}
                            onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                setSelectedFromCurrency(selectCountry)
                            }}
                            style={{
                                alignItems: "stretch",
                            }}
                            defaultCurrency={getSelectedFromCurrency}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
                        <TextFieldSimple
                            title={`${confComps.translate.amount} (AED)`}
                            textValue={getAmountValue}
                            type="text"

                            onChangeValue={function (newText: string): void {
                                const validText = newText.replace(/[^0-9.]/g, '');
                                setAmountValue(validText)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <SelectCurrencyWithTypeAndLocation
                            title={confComps.translate.toCurrencyType}
                            onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                setSelectedToCurrency(selectCountry)
                            }}
                            style={{
                                alignItems: "stretch",
                            }}
                            defaultCurrency={getSelectedToCurrency}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextFieldMultiLine
                            title={confComps.translate.explanation}
                            text={getDescriptionValue}
                            onChangeText={setDescriptionValue}
                            styleParent={{
                                boxSizing: "border-box",
                                border: `1px solid ${Colors.theme.blue.border}`,
                                background: Colors.theme.white.white90,
                            }}
                        />
                    </Grid>
                </Grid>

            </Box>
            <div style={{
                width: "100%",
                alignItems: 'center',
                justifyContent: 'end',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '1.18rem',
            }}>
                <ButtonSave
                    text={confComps.translate.create}
                    callbackOnClick={() => {
                        handleOnClickDone();
                    }}
                />

            </div>
        </ParentWithHeightAnimation>
    )

}
export default CreateInitiateMoneyReceive;