import { Box, Grid, useTheme } from "@mui/material";
import { InterfaceCreateDomesticCompany } from "./InterfaceCreateDomesticCompany";
import Colors from "../../../../../../../config/Colors";
import SubTitleSection from "../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { useEffect, useState } from "react";
import TextFieldSimple from "../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import FilesDragAndDrop from "../../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { PaperUploadIcon } from "../../../../../../../assets/icons/svg/PaperUploadIcon";
import ListFiles from "../../../../../../../components/complex/ListFiles/ListFiles";
import { InterfaceFile } from "../../../../../../../components/complex/ListFiles/InterfaceListFiles";
import { DialogAttachDetailOfFile } from "../../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";

import { isVisibilityProgressBar, updateProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../api/API";
import { InterfaceNTTActivity } from "../../../../../../../NTTs/InterfaceNTTActivity";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import AutoCompleteSimple from "../../../../../../../components/complex/AutoCompleteSimple/AutoCompleteSimple";
import { wait } from "@testing-library/user-event/dist/utils";
import ButtonCancel from "../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import TextFieldMultiSelectActivity from "../../../../../../../components/basic/TextField/ActivitiesJob/TextFieldMultiSelectActivity/TextFieldMultiSelectActivity";

function CreateDomesticCompany(paramsComponent: InterfaceCreateDomesticCompany) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getResponseAllActivity, setResponseAllActivity] = useState<InterfaceNTTActivity[]>([]);
    const [getCompanyName, setCompanyName] = useState<string>(paramsComponent.companyInformation?.name ?? '');
    const [getCity, setCity] = useState<string>(paramsComponent.companyInformation?.city ?? '');
    const [getNationalID, setNationalID] = useState<string>(paramsComponent.companyInformation?.national_id ?? '');
    const [getEconomicCode, setEconomicCode] = useState<string>(paramsComponent.companyInformation?.economic_code ?? '');
    const [getCeoName, setCeoName] = useState<string>(paramsComponent.companyInformation?.ceo_name ?? '');
    const [getCeoID, setCeoID] = useState<string>(paramsComponent.companyInformation?.ceo_national_id ?? '');
    const [getDescription, setDescription] = useState<string>(paramsComponent.companyInformation?.description ?? '');
    const [getActivitySelected, setActivitySelected] = useState<InterfaceNTTActivity[]>(paramsComponent.companyInformation?.activities ?? []);
    const [getNewFileForAttach, setNewFileForAttach] = useState<File>();
    const [getAttachFile, setAttachFile] = useState<InterfaceFile[]>(paramsComponent.companyInformation?.docs ?? []);
    const getApiListActivity = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.common.activity.getActivity;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTActivity[]);
                setResponseAllActivity(t);
                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiCreateCompany = async () => {
        dispatch(isVisibilityProgressBar(true));
        dispatch(updateProgressBar((1 * 100) / (getAttachFile.length + 2)));
        let urlObj = urlsList.panel.company.postCreateNewDomesticCompany;
        let dataObj = {
            "name": getCompanyName,
            "economic_code": getEconomicCode,
            "national_id": getNationalID,
            "ceo_name": getCeoName,
            "ceo_national_id": getCeoID,
            "activities": getActivitySelected.map(ac => ac.id),
            "city": getCity,
            "description": getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/domestic-companies/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(updateProgressBar((2 * 100) / (getAttachFile.length + 2)));
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                uploadFile(response.data.id);




            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForUpdateCompany = async () => {
        dispatch(isVisibilityProgressBar(true));
        dispatch(updateProgressBar((1 * 100) / (getAttachFile.length + 2)));
        let urlObj = urlsList.panel.company.patchUpdateDomesticCompany;
        let dataObj = {
            "name": getCompanyName,
            "economic_code": getEconomicCode,
            "national_id": getNationalID,
            "ceo_name": getCeoName,
            "ceo_national_id": getCeoID,
            "activities": getActivitySelected.map(ac => ac.id),
            "city": getCity,
            "description": getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.companyInformation?.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(updateProgressBar((2 * 100) / (getAttachFile.length + 2)));
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: confComps.translate.updatedCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                if (paramsComponent.companyInformation) {
                    updateFiles(paramsComponent.companyInformation.id);
                }



            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const uploadFile = async (idCompany: number) => {
        for (let index = 0; index < getAttachFile.length; index++) {
            const file = getAttachFile[index];
            await callApiForAddDocInCompany(file, idCompany);
            dispatch(updateProgressBar(((index + 2) * 100) / (getAttachFile.length + 2)));
            await wait(2000)
        }
        paramsComponent.callbackCancel();
        dispatch(isVisibilityProgressBar(false));
    }
    const updateFiles = async (idCompany: number) => {
        for (let index = 0; index < getAttachFile.length; index++) {
            const file = getAttachFile[index];
            if (!file.document) {
                await callApiForAddDocInCompany(file, idCompany);
            }
            dispatch(updateProgressBar(((index + 2) * 100) / (getAttachFile.length + 2)));
            await wait(2000)
        }
        if (paramsComponent.companyInformation) {
            console.log("safajooon check file for remove", paramsComponent.companyInformation.docs, getAttachFile);
            for (let index = 0; index < paramsComponent.companyInformation.docs.length; index++) {
                if (getAttachFile.filter(att => att.document === paramsComponent.companyInformation?.docs[index].document).length === 0) {
                    await callApiForDeleteAttachFile(paramsComponent.companyInformation?.docs[index].id);
                }
            }
        }
        paramsComponent.callbackCancel();
        dispatch(isVisibilityProgressBar(false));
    }
    const callApiForDeleteAttachFile = async (fileIDInServer: number) => {
        let urlObj = urlsList.panel.company.deleteDocFromDomesticCompany;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${fileIDInServer}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: confComps.translate.removedFile + ` ${fileIDInServer}`, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForAddDocInCompany = async (file: InterfaceFile, idCompany: number) => {
        console.log("callApiForAddDocInCompany", file, idCompany);
        let urlObj = urlsList.panel.company.postAddFileToDomesticCompany;
        const formData = new FormData();
        formData.append("title", file.title);
        formData.append("description", file.description);
        if (file.file) {
            formData.append("document", file.file);
        }
        else {
            return;
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idCompany}/documents/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, formData, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(ShowAlert({ text: confComps.translate.addedFile, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApiListActivity();
    }, [])
    return (
        <Box sx={{
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
            width: "100%",
            boxSizing: "border-box",
            paddingInline: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
            border: `1px solid ${Colors.theme.blue.border}`,
            borderRadius: "0.4rem",
            position: "sticky",
            top: "20px",
            zIndex: 2,
            background: "white",
        }}>
            <Box sx={{
                boxSizing: "border-box",
                width: "100%",
                marginBlockEnd: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
            }}>
                <SubTitleSection title={confComps.translate.addNewCompany} />
                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                    marginInlineEnd: { xs: 0, sm: 16, md: 24, lg: 32, xl: 40 },
                    boxSizing: "border-box",
                }}>
                    <Grid container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: '1.72rem', sm: '1.9rem', md: '2.17rem', lg: "2.37rem", xl: "2.57rem" }}
                        rowSpacing={{ xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                    >

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.companyName} textValue={getCompanyName}
                                onChangeValue={setCompanyName}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.nationalID} textValue={getNationalID}
                                onChangeValue={setNationalID}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.economicCode} textValue={getEconomicCode}
                                onChangeValue={setEconomicCode}
                            />
                        </Grid>
                        {(getResponseAllActivity.length > 0) && <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>

                            <AutoCompleteSimple
                                title={confComps.translate.activity}
                                valuesList={getResponseAllActivity}
                                onChangeSelectedItem={(items) => {
                                    setActivitySelected(items.map((item, index, arr) => {
                                        return ({
                                            ...getResponseAllActivity.filter(ac => ac.id == item.id)[0]
                                        })

                                    }));
                                }}
                                selectedValueTitle={getActivitySelected}

                            />
                        </Grid>}
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.ceoName} textValue={getCeoName}
                                onChangeValue={setCeoName}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.ceoID} textValue={getCeoID}
                                onChangeValue={setCeoID}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.city} textValue={getCity}
                                onChangeValue={setCity}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldMultiLine title={confComps.translate.explanation} text={getDescription}
                                onChangeText={setDescription}
                                styleParent={{
                                    boxSizing: "border-box",
                                    border: `1px solid ${Colors.theme.blue.border}`,
                                    background: Colors.theme.white.white90,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <SubTitleSection title={confComps.translate.uploadFiles} />
                            <FilesDragAndDrop
                                onUpload={(files) => {
                                    files.forEach((value) => {
                                        setNewFileForAttach(value);
                                    })
                                }}
                                count={1}
                                formats={['jpg', 'png']}
                                containerStyles={{
                                    borderRadius: "4px",
                                    boxSizing: "border-box",

                                }}
                                openDialogOnClick
                            >
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    border: `1px dotted ${Colors.theme.blue.blue}`,
                                    cursor: "pointer",
                                    paddingInline: "1.6rem",
                                    paddingBlock: "1.2rem",
                                    background: "#E3F9E9",
                                    borderRadius: "0.8rem",
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "0.5rem",
                                    }}>
                                        <PaperUploadIcon style={{ color: Colors.theme.blue.dark }} />
                                        {confComps.translate.dragDropFiles}
                                    </div>
                                    <div style={{
                                        borderRadius: "0.4rem", background: Colors.theme.blue.light_2,
                                        paddingInline: "0.5rem",
                                        paddingBlock: "0.1rem",
                                        boxSizing: "border-box",
                                        border: `1px solid ${Colors.theme.blue.dark}`,
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        lineHeight: "1.2rem",
                                        color: Colors.theme.blue.dark,

                                    }}>
                                        {confComps.translate.chooseFile}
                                    </div>

                                </div>
                            </FilesDragAndDrop>
                            <div style={{ marginTop: "2rem" }}>
                                <ListFiles Files={getAttachFile}
                                    callbackRemoveFiles={(index: number) => {
                                        setAttachFile(prev => prev.filter((prev, indexP) => indexP !== index));
                                    }} />

                            </div>

                        </Grid>
                    </Grid>
                </Box>

            </Box>
            <DialogAttachDetailOfFile
                callbackOnCloseDialog={
                    () => {
                        setNewFileForAttach(undefined);
                    }
                }
                callbackSave={(newFile: InterfaceFile) => {
                    setAttachFile(old => [...old, newFile]);
                    setNewFileForAttach(undefined);
                }}
                isOpen={getNewFileForAttach !== undefined}
                file={
                    {
                        file: getNewFileForAttach,
                        description: "",
                        title: "",
                        document: "",
                        id: -1,
                    }
                }
            />
            <div style={{
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                marginBlock: "1rem",
                width: "100%",
                gap: "0.5rem",
                // position: "sticky",
                // bottom:"20px",
            }}>
                <ButtonCancel text={confComps.translate.cancel}
                    callbackOnClick={() => {
                        paramsComponent.callbackCancel();
                    }} />
                <ButtonSave text={paramsComponent.companyInformation ? confComps.translate.update : confComps.translate.save}
                    callbackOnClick={() => {
                        paramsComponent.companyInformation ?
                            callApiForUpdateCompany() :
                            callApiCreateCompany();
                    }} />


            </div>

        </Box>

    );

}
export default CreateDomesticCompany;