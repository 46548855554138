import { useEffect, useState } from "react";
import { InterfaceListStepsOfTrade } from "./InterfaceListStepsOfTrade";
import { useTheme } from "@mui/material";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import Colors from "../../../../../config/Colors";
import ParentWithHeightAnimation from "../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ViewOneStepOfTrade from "./OneStepOfTrade/ViewOneStepOfTrade/ViewOneStepOfTrade";
import CreateOneStepOfTrade from "./OneStepOfTrade/CreateOneStepOfTrade";
import { EnumSTepsParent, EnumStepsTrade } from "../../../../../config/enums/EnumSteps";
import { EnumStateValueOfTradeStep } from "../../../../../NTTs/Trade/InterfaceNTTStepOfTrade";
import { InterfaceNTTTradeMain, EnumStateValueOfTrade } from "../../../../../NTTs/Trade/InterfaceNTTTradeMain";


function ListStepsOfTrade(paramsComponent: InterfaceListStepsOfTrade) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getTradeMain, setTradeMain] = useState<InterfaceNTTTradeMain>();
    const [getIsBoxShow, setIsBoxShow] = useState<boolean>(true);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("1");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    useEffect(() => {
        setTradeMain(paramsComponent.trade);
    }, [paramsComponent.trade])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.keyOfStepThatSelected);
    }, [paramsComponent.keyOfStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '0.88rem',
            }}
                onClick={() => {
                    setIsBoxShow(prev => !prev);
                }}
            >
                <h6 style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'start', justifyContent: 'start',
                    color: Colors.theme.blue.dark,
                    fontSize: "1.18rem",
                    lineHeight: "1.77rem",
                    fontFamily: confComps.font.poppins.Medium,
                }}>
                    {`Steps:${getTradeMain?.steps.length === 0 ? "" : `(${getTradeMain?.steps.length})`}`}
                </h6>

                <KeyboardArrowDownRounded
                    style={{
                        fontSize: "1.9rem",

                        color: Colors.theme.blue.dark,
                        cursor: "pointer",
                        transform: getIsBoxShow ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "all 0.5s ease-in-out",
                    }}
                />
            </div>
            <ParentWithHeightAnimation in={getIsBoxShow} style={{ width: '100%' }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: "1rem",
                }}>
                    {getTradeMain?.steps
                        .map((step, index, arr) => {
                            if (step.state.value === EnumStateValueOfTradeStep.refinement 
                                && getKeyOfStepThatSelected === `${EnumSTepsParent.trade}.${EnumStepsTrade.addSteps}` 
                                && getTradeMain.state.value === EnumStateValueOfTrade.refinement
                            ) {
                                return (
                                    <CreateOneStepOfTrade
                                        key={index}
                                        trade={getTradeMain}
                                        updateStep={{
                                            callbackDeleteThisStep: () => {
                                                paramsComponent.callbackReload();
                                            },
                                            callbackOnClickStepUpdated: () => {
                                                paramsComponent.callbackReload();
                                            },
                                            step: step,
                                            keyOfStepThatSelected: getKeyOfStepThatSelected,
                                            keyOfGroupThatSelected: getKeyOfGroupThatSelected,

                                        }}
                                        isShowThisBox={true}
                                    />)
                            }
                            else {
                                return (
                                    <ViewOneStepOfTrade
                                        step={step}
                                        key={index}
                                        index={step.id}
                                        stateOfMainTrade={getTradeMain.state.value}
                                        keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                                        keyOfStepThatSelected={getKeyOfStepThatSelected}
                                        callbackDeleteThisStep={function (): void {
                                            paramsComponent.callbackOnClickDeleteOneStep();
                                        }}
                                        callbackApproveThisStepByCreator={() => {
                                            paramsComponent.callbackReload();
                                        }}
                                        callbackReload={paramsComponent.callbackReload}
                                    />

                                )
                            }
                        })}
                </div>
            </ParentWithHeightAnimation>
        </div >
    )
}
export default ListStepsOfTrade;