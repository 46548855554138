import { useNavigate } from "react-router-dom";
import { InterfaceAllServiceOfFinalRecap, InterfaceCreateSlip, InterfaceFinalRecap, InterfaceGetAPIFinalRecapsList } from "./InterfaceCreateSlip";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { MainCard } from "../../../myProfile/MyProfileStyle";
import { useEffect, useState } from "react";
import { EnumStateStepGroup } from "../../../../components/basic/ArrowStep/InterfaceArrowStep";
import SteperGroups from "../../../../components/complex/SteperGuide/SteperGroup/SteperGroup";
import SteperGuide from "../../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../api/UrlsList";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { InterfacePaginationInServer, InterfaceSearchInServer } from "../../../../api/interfaces/InterfaceSearchInServer";
import { EnumStateFetchList } from "../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfaceAdvanceSearchParams } from "../../../../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import { InterfaceItemOfSorting } from "../../../../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceGetAPIRecapsList } from "../../../recap/RecapsList/InterfaceRecapsList";
import AddButtonWithShadowEffect from "../../../../components/basic/Buttons/AddButtonWithShadowEffect/AddButtonWithShadowEffect";
import InfiniteScrollList from "../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import AdvanceSearchBox from "../../../../components/complex/advanceSearchBox/AdvanceSearchBox";
import SortingBox from "../../../../components/complex/SortingBox/SortingBox";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import Colors from "../../../../config/Colors";
import Links from "../../../../config/Links";
import RowRecap from "../../../recap/RecapsList/RowRecap/RowRecap";
import TextFieldSimple from "../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import SteperGuideForSlip from "../../../../components/complex/SteperGuide/SteperGuideForSlip/SteperGuideForSlip";
import SubTitleSection from "../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import ButtonCancel from "../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { EnumSTepsParent, EnumStepsSlip } from "../../../../config/enums/EnumSteps";
import SelectTrustyOrCustomer from "../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceGetAPITrustyOrCustomer, InterfaceTypeSelectTrustyOrCustomer } from "../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../NTTs/InterfaceNTTCurrency";
import FilesDragAndDrop from "../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { AttachFileRounded, DownloadRounded, UploadFileRounded } from "@mui/icons-material";
import SelectCurrencyWithTypeAndLocation from "../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import DatePickerMUI from "../../../../components/basic/Dates/DatePickerMUI/DatePickerMUI";
import { TrashIcon } from "../../../../assets/icons/svg/TrashIcon";
import { getTypeOfFileFromLink } from "../../../../helper/Helper";

function CreateSlip(paramsComponent: InterfaceCreateSlip) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getFromCustomerSelected, setFromCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getToCustomerSelected, setToCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getSelectedCurrencyType, setSelectedCurrencyType] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getAmount, setAmount] = useState<string>("");
    const [getCode, setCode] = useState<string>("");
    const [getDateTimePaid, setDateTimePaid] = useState<number | undefined>();
    const [getDescription, setDescription] = useState<string>("");
    const [getNewFileForAttach, setNewFileForAttach] = useState<File>();



    const callApiForCreateSlipMain = async () => {
        if (!getFromCustomerSelected?.user) {
            dispatch(ShowAlert({ text: `Please Select From Customer`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        if (!getToCustomerSelected) {
            dispatch(ShowAlert({ text: `Please Select To Customer`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        if (!getSelectedCurrencyType) {
            dispatch(ShowAlert({ text: `Please Select Currency Type`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.slip.postCreateSlipInitiate;
        const formData = new FormData();
        formData.append("from_person", `${getFromCustomerSelected.user.id}`);
        formData.append("to_person", `${getToCustomerSelected.user.id}`);
        formData.append("code", getCode);
        formData.append("currency_unit", `${getSelectedCurrencyType.id}`);
        formData.append("amount", getAmount);
        getNewFileForAttach && formData.append("file", getNewFileForAttach);
        formData.append("paid_at", `${getDateTimePaid}`);
        formData.append("state", "CR");
        formData.append("description", getDescription);

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, formData, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // setSlipMain(response);
                // let t = response as InterfaceGetAPICustomersList;
                navigate(Links.screens.panel.deals.slip.ShowStateTheSlip + `${response.id}`)

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }

    return (
        <ContainerPage title={"New Slip"}>
            <SteperGuideForSlip
                steps={confComps.steperGuide.slip.steps}
                callbackOnClickOneStep={(keySelected: string) => {
                    if (keySelected !== `${EnumSTepsParent.slip}.${EnumStepsSlip.creator}` && keySelected !== `${EnumSTepsParent.slip}`) {
                        dispatch(ShowAlert({ text: confComps.translate.youMustCreateAtFirst, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                    }
                }}
                callbackOnClickOneGroup={(keySelected: string) => {
                }}
                keyOfGroupThatSelected={`${EnumSTepsParent.slip}`}
                keyOfOneStepThatSelected={`${EnumSTepsParent.slip}.${EnumStepsSlip.creator}`}
                slip={undefined}
            />
            <div style={{
                display: "flex", boxSizing: "border-box", flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                width: "100%", flexGrow: 1,
                background: Colors.theme.white.white80,
                border: `1px solid ${Colors.theme.white.white70}`,
                padding: "1.6rem",
                borderRadius: "0.32rem",
            }}
            >
                <Box sx={{
                    display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                    width: "100%",
                    boxSizing: "border-box",
                    paddingInline: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
                    border: `1px solid ${Colors.theme.blue.border}`,
                    borderRadius: "0.4rem",
                    position: "sticky",
                    top: "20px",
                    zIndex: 2,
                    background: "white",
                }}>
                    <Box sx={{
                        boxSizing: "border-box",
                        width: "100%",
                        marginBlockEnd: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
                    }}>
                        <SubTitleSection title={"Create New Slip"} />
                        <Box sx={{
                            marginInline: { xs: 1, sm: 2, md: 1, lg: 1.5, xl: 2 },
                            // marginInlineStart: { xs: 1, sm: 2, md: 1, lg: 1.5, xl: 2 },
                            // marginInlineEnd: { xs: 1, sm: 2, md: 5, lg: 12, xl: "10vw" },
                            boxSizing: "border-box",
                        }}>
                            <Grid container
                                justifyContent="space-between" alignItems="stretch"
                                // columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 7, xl: "9vw" }}
                                // rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}
                                columnSpacing={{ xs: '1.72rem', sm: '1.9rem', md: '2.17rem', lg: "2.37rem", xl: "2.57rem" }}
                                rowSpacing={{ xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" }}
                                columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                            >
                                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                    <SelectTrustyOrCustomer
                                        title={"From Customer/Trusty"}
                                        defaultUser={{
                                            realUserSelect: getFromCustomerSelected,
                                            isAllSelected: false
                                        }}
                                        onChangeSelectedUser={(selectCustomer: InterfaceTypeSelectTrustyOrCustomer) => {
                                            setFromCustomerSelected(selectCustomer.realUserSelect);
                                        }}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelectTrustyOrCustomer
                                        title={"To Customer/Trusty"}
                                        defaultUser={{
                                            realUserSelect: getToCustomerSelected,
                                            isAllSelected: false
                                        }}
                                        onChangeSelectedUser={(selectCustomer: InterfaceTypeSelectTrustyOrCustomer) => {
                                            setToCustomerSelected(selectCustomer.realUserSelect);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <TextFieldSimple
                                        title={"Code"}
                                        textValue={getCode}
                                        type="text"
                                        onChangeValue={setCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectCurrencyWithTypeAndLocation
                                        title={"Currency Type"}
                                        onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                            setSelectedCurrencyType(selectCountry)
                                        }}
                                        style={{
                                            alignItems: "stretch",
                                        }}
                                        defaultCurrency={getSelectedCurrencyType}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldSimple
                                        title={`${confComps.translate.amount} ${getSelectedCurrencyType?.currency.symbol ? `(${getSelectedCurrencyType?.currency.symbol})` : ""}`}
                                        textValue={
                                            `${[getAmount.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                            ...getAmount.replace(/[^0-9.]/g, '').split(".").slice(1)
                                            ].join(".")}`
                                        }
                                        type="text"
                                        onChangeValue={function (newText: string): void {
                                            const validText = newText.replace(/[^0-9.]/g, '');
                                            setAmount(validText)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <DatePickerMUI title={confComps.translate.authenticityDate} posixTime={0}
                                        onChangeValue={(timeSecond: number | undefined) => {
                                            setDateTimePaid(timeSecond);
                                        }}
                                        defaultTimeInSecond={getDateTimePaid}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SubTitleSection title={"Attach Document"} />
                                    <div>
                                        <FilesDragAndDrop
                                            onUpload={(files) => {
                                                if (files.length > 0)
                                                    setNewFileForAttach(files[files.length - 1]);
                                                else {
                                                    setNewFileForAttach(undefined);
                                                }
                                            }}
                                            count={1}
                                            // formats={['jpg', 'png',"*"]}
                                            containerStyles={{
                                                borderRadius: "4px",
                                                boxSizing: "border-box",

                                            }}
                                            openDialogOnClick
                                        >
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                border: `1px dotted ${Colors.theme.blue.blue}`,
                                                // cursor: "pointer",
                                                paddingInline: "1.6rem",
                                                paddingBlock: "1.2rem",
                                                background: "#E3F9E9",
                                                borderRadius: "0.8rem",
                                            }}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    gap: "0.5rem",
                                                }}>
                                                    <AttachFileRounded style={{ color: Colors.theme.blue.dark }} />
                                                    {confComps.translate.dragDropFiles}
                                                </div>
                                                <div style={{
                                                    borderRadius: "0.4rem", background: Colors.theme.blue.light_2,
                                                    paddingInline: "0.5rem",
                                                    paddingBlock: "0.1rem",
                                                    boxSizing: "border-box",
                                                    border: `1px solid ${Colors.theme.blue.dark}`,
                                                    fontFamily: confComps.font.poppins.Regular,
                                                    fontSize: "0.96rem",
                                                    lineHeight: "1.2rem",
                                                    color: Colors.theme.blue.dark,

                                                }}>
                                                    {"Choose File"}
                                                </div>

                                            </div>
                                        </FilesDragAndDrop>
                                    </div>
                                </Grid>
                                {getNewFileForAttach &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div style={{
                                            display: "flex", flexDirection: 'row',
                                            alignItems: "center", justifyContent: "space-between",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            border: `1px solid ${Colors.theme.blue.border}`,
                                            borderRadius: "0.8rem",
                                            paddingInline: "1rem",
                                            paddingBlock: "1rem",
                                            cursor: undefined,
                                        }}>
                                            <div style={{
                                                display: "flex", flexDirection: "row", alignItems: "center",
                                                justifyContent: "start",
                                                gap: "1rem",
                                            }}>

                                                <DownloadRounded style={{ color: Colors.theme.blue.blue }} />

                                                <p style={{
                                                    fontFamily: confComps.font.poppins.Regular,
                                                    fontSize: "0.96rem",
                                                    color: Colors.theme.blue.dark,
                                                }}>
                                                    {`${(getNewFileForAttach.name)} (${(getNewFileForAttach.size / (1024 * 1024)).toFixed(2)} MB)`}
                                                </p>
                                            </div>
                                            <div>
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                }}
                                                    onClick={() => {
                                                        setNewFileForAttach(undefined);
                                                    }}
                                                >
                                                    <TrashIcon style={{ marginInlineStart: '0.58rem' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                }


                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextFieldMultiLine
                                        title={confComps.translate.explanation}
                                        text={getDescription}
                                        onChangeText={setDescription}
                                        styleParent={{
                                            boxSizing: "border-box",
                                            border: `1px solid ${Colors.theme.blue.border}`,
                                            background: Colors.theme.white.white90,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div style={{
                                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                                        marginBlock: "1rem",
                                        width: "100%",
                                        gap: "0.5rem",
                                        position: "sticky",
                                        bottom: "20px",
                                    }}>
                                        <ButtonCancel text={confComps.translate.cancel}
                                            callbackOnClick={() => {
                                                navigate(-1);
                                            }} />
                                        <ButtonSave text={"Create Slip"}
                                            callbackOnClick={() => {
                                                callApiForCreateSlipMain();
                                            }} />


                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box >
                </Box>
            </div>

            {/* </MainCard> */}
        </ContainerPage >

    );
}
export default CreateSlip;
