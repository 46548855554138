import { PaymentsRounded, TrendingDownRounded, TrendingUpRounded, TrendingFlatRounded, AddRounded } from "@mui/icons-material";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { TrashIcon } from "../../../assets/icons/svg/TrashIcon";
import { WalletIcon } from "../../../assets/icons/svg/WalletIcon";
import Colors from "../../../config/Colors";
import { CurrencySymbols } from "../../../helper/CurrencySymbols";
import { getColorCodeOfBalanceNumber } from "../../../helper/Helper";
import { InterfaceNTTProfile } from "../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../NTTs/InterfaceNTTProfileType";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { InterfaceMultiNetProfit } from "./InterfaceMultiNetProfit";
import { InterfaceNTTProfitOfTrade } from "../../../NTTs/Trade/InterfaceNTTProfitOfTrade";
import CreateMultiNetProfit from "./CreateMultiNetProfit/CreateMultiNetProfit";
import ArrayText from "../../basic/Texts/ArrayText/ArrayText";
import ShowUnitOfMoneyThreePart from "../ShowUnitOfMoneyThreePart/ShowUnitOfMoneyThreePart";

function MultiNetProfit(paramsComponent: InterfaceMultiNetProfit) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getAddMultiNetProfitActive, setAddMultiNetProfitActive] = useState<boolean>(false);
    const [getMultiNetProfitOrders, setMultiNetProfitOrders] = useState<InterfaceNTTProfitOfTrade[]>([]);
    useEffect(() => {
        setMultiNetProfitOrders(paramsComponent.defaultList)
    }, [paramsComponent.defaultList])
    return (
        <Box sx={{
            boxSizing: 'border-box',
            display: 'flex', flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
        }}>

            <label style={{
                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: Colors.theme.blue.light,
                marginBottom: '0.22rem',
            }}>
                {"profits:"}
            </label>


            {!getAddMultiNetProfitActive && <div style={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                border: `1px solid ${Colors.theme.gray.gray}`,
                background: Colors.theme.white.white90,
                borderRadius: '0.37rem',
                paddingBlock: '0.37rem',
                boxSizing: 'border-box',
                width: '100%',
            }}>



                {getMultiNetProfitOrders.flatMap((profit, index, arr) => {
                    let listElements = [
                        <div style={{
                            width: '100%',
                            display: 'flex', flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'start',
                            boxSizing: 'border-box',
                            paddingInline: "0.88rem",
                            marginBlockEnd: '0.4rem',
                        }}>
                            <p style={{
                                display: 'inline-flex', flexDirection: 'row',
                                alignItems: 'start',
                                justifyContent: 'start',
                                flexGrow: 0,
                                flexBasis: '2rem',
                                fontSize: '0.96rem',
                                lineHeight: '1.55rem',
                                flexWrap: 'nowrap',
                            }}>
                                {`${index + 1} :`}
                            </p>
                            <div style={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: "0.57rem",
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '0.9rem',
                                flexGrow: '1',
                                flexBasis: '0',
                            }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    flexGrow: '0',
                                }}>
                                    <ArrayText
                                        texts={[
                                            {
                                                text: `net profit: `,
                                                style: {
                                                    color: Colors.theme.blue.light,
                                                }
                                            },
                                            {
                                                text:
                                                    `${[profit.total_profit.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                                    ...profit.total_profit.replace(/[^0-9.]/g, '').split(".").slice(1)
                                                    ].join(".")}`,
                                                style: {
                                                    color: getColorCodeOfBalanceNumber(profit.total_profit),
                                                }
                                            },

                                        ]}
                                    />
                                    <div style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        textAlign: "start",
                                        fontSize: "0.96rem",
                                        lineHeight: "1.44rem",
                                        color: getColorCodeOfBalanceNumber(profit.total_profit),
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '0.32rem',
                                    }}>
                                        <ShowUnitOfMoneyThreePart currencyNTT={profit.currency_unit} />
                                    </div>
                                    {Number(profit.total_profit) < 0 &&
                                        <TrendingDownRounded
                                            style={{
                                                textAlign: "start",
                                                fontSize: "1.03rem",
                                                lineHeight: "1.44rem",
                                                color: getColorCodeOfBalanceNumber(profit.total_profit),
                                            }} />}
                                    {Number(profit.total_profit) > 0 &&
                                        <TrendingUpRounded style={{
                                            textAlign: "start",
                                            fontSize: "0.96rem",
                                            lineHeight: "1.44rem",
                                            color: getColorCodeOfBalanceNumber(profit.total_profit),
                                        }} />
                                    }
                                    {Number(profit.total_profit) === 0 &&
                                        <TrendingFlatRounded style={{
                                            textAlign: "start",
                                            fontSize: "0.96rem",
                                            lineHeight: "1.44rem",
                                            color: getColorCodeOfBalanceNumber(profit.total_profit),
                                        }} />
                                    }



                                </div>
                                {/* company */}
                                <div style={{
                                    display: 'flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'end',
                                    flexGrow: '0',
                                }}>
                                    <ArrayText
                                        texts={[
                                            {
                                                text: `Company net profit: `,
                                                style: {
                                                    color: Colors.theme.blue.light,
                                                }
                                            },
                                            {
                                                text:
                                                    `${[profit.final_total_profit.replace(/[^0-9.]/g, '').split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                                                    ...profit.final_total_profit.replace(/[^0-9.]/g, '').split(".").slice(1)
                                                    ].join(".")}`,
                                                style: {
                                                    color: getColorCodeOfBalanceNumber(profit.final_total_profit),
                                                }
                                            },

                                        ]}
                                    />
                                    <div style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        textAlign: "start",
                                        fontSize: "0.96rem",
                                        lineHeight: "1.44rem",
                                        color: getColorCodeOfBalanceNumber(profit.final_total_profit),
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '0.32rem',
                                    }}>
                                        {`(`}
                                        {profit.currency_unit.is_cash ?
                                            <PaymentsRounded style={{
                                                fontSize: '1.12rem',
                                                lineHeight: "1.68rem",
                                            }} />
                                            : <WalletIcon
                                                style={{
                                                    fontSize: '1.12rem',
                                                    lineHeight: "1.68rem",
                                                }}
                                            />
                                        }
                                        {profit.currency_unit.country && profit.currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${profit.currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${profit.currency_unit.country.iso}`}
                                            style={{ width: 'auto', maxWidth: '1.3rem' }}
                                        />}
                                        {CurrencySymbols[profit.currency_unit.currency.symbol]}
                                        {")"}
                                    </div>
                                    {Number(profit.final_total_profit) < 0 &&
                                        <TrendingDownRounded
                                            style={{
                                                textAlign: "start",
                                                fontSize: "1.03rem",
                                                lineHeight: "1.44rem",
                                                color: getColorCodeOfBalanceNumber(profit.total_profit),
                                            }} />}
                                    {Number(profit.final_total_profit) > 0 &&
                                        <TrendingUpRounded style={{
                                            textAlign: "start",
                                            fontSize: "0.96rem",
                                            lineHeight: "1.44rem",
                                            color: getColorCodeOfBalanceNumber(profit.final_total_profit),
                                        }} />
                                    }
                                    {Number(profit.final_total_profit) === 0 &&
                                        <TrendingFlatRounded style={{
                                            textAlign: "start",
                                            fontSize: "0.96rem",
                                            lineHeight: "1.44rem",
                                            color: getColorCodeOfBalanceNumber(profit.final_total_profit),
                                        }} />
                                    }

                                    {paramsComponent.isCreationActive && <div style={{
                                        display: 'flex', flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                        onClick={() => {
                                            const updatedCommissions = getMultiNetProfitOrders.filter((_, i) => i !== index);
                                            paramsComponent.callbackUpdateList(updatedCommissions);
                                        }}
                                    >
                                        <TrashIcon style={{ marginInlineStart: '0.58rem' }} />
                                    </div>}

                                </div>
                            </div>
                        </div>
                    ]
                    if (index !== (arr.length - 1) || paramsComponent.isCreationActive) {
                        listElements.push(<div style={{
                            width: '100%',
                            height: '1px',
                            borderRadius: '10px',
                            background: '#E2E4EC',
                        }} />)
                    }
                    return listElements;

                })}


                {paramsComponent.isCreationActive &&
                    <div style={{//add button design
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'start',
                        width: '100%',
                        paddingInline: '0.88rem',
                        boxSizing: 'border-box',

                    }}>
                        <div style={{
                            display: 'inline-flex', flexDirection: 'row',
                            alignItems: 'center', justifyContent: 'start',
                            fontFamily: confComps.font.poppins.Medium,
                            fontSize: '0.88rem',
                            lineHeight: '1.33rem',
                            color: Colors.theme.orange.light,
                            cursor: 'pointer',
                            userSelect: 'none',
                        }}
                            onClick={() => { setAddMultiNetProfitActive(true) }}
                        >
                            <AddRounded style={{
                                color: Colors.theme.orange.light, fontSize: '0.88rem',
                                userSelect: 'none',
                            }} />

                            {"Add New Profit"}


                        </div>

                    </div>
                }
            </div>}
            {getAddMultiNetProfitActive && paramsComponent.isCreationActive &&
                <Box sx={{
                    width: '100%',
                    border: `0.5px solid ${Colors.theme.blue.border}`,
                    boxSizing: 'border-box',
                    boxShadow: Colors.shadow.cardList,
                    paddingInline: { xs: "0.7rem", sm: "1rem", md: "2.66rem", lg: "2.96rem", xl: "3.25rem" },
                    paddingBlock: "1.18rem",
                    borderRadius: '0.38rem',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'flex-start',
                    gap: "1rem",
                }}>
                    <CreateMultiNetProfit
                        callbackCancel={() => {
                            setAddMultiNetProfitActive(false);
                        }}
                        callbackSave={function (params: InterfaceNTTProfitOfTrade): void {
                            setAddMultiNetProfitActive(false);
                            paramsComponent.callbackUpdateList([...getMultiNetProfitOrders,
                            {
                                ...params,
                            }])
                        }}
                    />
                </Box>
            }

        </Box>
    );
}
export default MultiNetProfit;