import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { ArrowRightRounded, PaymentsRounded, SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "../../../basic/LineSeprator/LineSeparator";
import { InterfaceNTTCity } from "../../../../NTTs/InterfaceNTTCity";
import { BottomTriangleIcon } from "../../../../assets/icons/svg/BottomTriangleIcon";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../NTTs/InterfaceNTTCurrency";
import { WalletIcon } from "../../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../../helper/CurrencySymbols";
import { InterfaceViewChangeRate } from "./InterfaceChangeRateWithAmount";

function ViewChangeRate(paramsComponent: InterfaceViewChangeRate) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const [getFromCurrency, setFromCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getToCurrency, setToCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getRate, setRate] = useState<string>("");

    const [getIsFocused, setIsFocused] = useState(false);
    const containerRef2 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setFromCurrency(paramsComponent.defaultFromCurrency);
    }, [paramsComponent.defaultFromCurrency])
    useEffect(() => {
        setToCurrency(paramsComponent.defaultToCurrency);
    }, [paramsComponent.defaultToCurrency])
    useEffect(() => {
        setRate(paramsComponent.rate);
    }, [paramsComponent.rate])

    return (
        <div style={{
            display: 'flex', flexDirection: 'column', position: "relative",
            ...paramsComponent.style
        }}
            ref={containerRef2}
        >
            {getFromCurrency && getToCurrency &&
                < div style={{
                    fontWeight: "300",
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    lineHeight: "1.68rem",
                    gap: '0.1rem',
                    marginBottom: "0.22rem",
                    color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                }}>
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        lineHeight: "1.68rem",
                        color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                        textWrap: 'wrap',
                    }}>
                        {`(1X`}
                        {getFromCurrency.is_cash ?
                            <PaymentsRounded style={{
                                fontSize: '1.12rem',
                                lineHeight: "1.68rem",
                            }} />
                            : <WalletIcon
                                style={{
                                    fontSize: '1.12rem',
                                    lineHeight: "1.68rem",
                                }}
                            />
                        }
                        {getFromCurrency.country && getFromCurrency.country.iso && <img
                            src={`https://flagcdn.com/w320/${getFromCurrency.country.iso}.png`} // Fallback image
                            alt={`${getFromCurrency.country.iso}`}
                            style={{ width: 'auto', height: '1.12rem' }}
                        />}
                        {CurrencySymbols[getFromCurrency.currency.symbol]}
                        {")"}
                    </div>
                    {/*  */}
                    =
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        lineHeight: "1.68rem",
                        color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                    }}>
                        {`(${Number(parseFloat(getRate))} X`}
                        {getToCurrency.is_cash ?
                            <PaymentsRounded style={{
                                fontSize: '1.12rem',
                                lineHeight: "1.68rem",
                            }} />
                            :
                            <WalletIcon
                                style={{
                                    fontSize: '1.12rem',
                                    lineHeight: "1.68rem",
                                }}
                            />
                        }
                        {getToCurrency.country && getToCurrency.country.iso && <img
                            src={`https://flagcdn.com/w320/${getToCurrency.country.iso}.png`} // Fallback image
                            alt={`${getToCurrency.country.iso}`}
                            style={{ width: 'auto', height: '1.12rem' }}
                        />}
                        {CurrencySymbols[getToCurrency.currency.symbol]}
                        {")"}
                    </div>
                </div>
            }
        </div >

    );
}
export default ViewChangeRate;