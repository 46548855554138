import ContainerPage from "../../../components/basic/containerPage/ContainerPage";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { MainCard } from "../../myProfile/MyProfileStyle";
import BoxExportTradeForCustomers from "./BoxExportTradeForCustomers/BoxExportTradeForCustomers";
import { InterfaceExcelReport } from "./InterfaceExcelReport";

function ExcelReport(paramsComponent: InterfaceExcelReport) {
    var confComps = ConfigComps();
    return (
        <ContainerPage title={confComps.translate.newRecap}>
            <MainCard sx={{
                gap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "6vh" },
                display: 'flex', flexDirection: 'column', alignItems: 'start',
                justifyContent: 'start',
            }}>

                <BoxExportTradeForCustomers />
            </MainCard>
        </ContainerPage>

    )
}
export default ExcelReport;